import { store } from '../../redux'
import { DefaultDTO } from '../../dto'
import { NetworkCall } from '../../utils'

interface FavouriteProps {
    id: number
    onSuccess: () => void
}

export const favourite = async (props: FavouriteProps) => {
    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('GET', `/1.0.0/cars/${props.id}/favourite`)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(200, DefaultDTO, (responseBody) => {
            props.onSuccess()
        })
        .catch((response) => {})
        .call()
}
