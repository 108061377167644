import { useTranslation } from 'react-i18next'
import './photo-guide-step.scss'

interface PhotoGuideStepProps {
    nr: number
    title: string
    items: string[]
}

export const PhotoGuideStep = (props: PhotoGuideStepProps) => {
    const { t } = useTranslation()

    return (
        <div className="photo-guide-step">
            <div className="title">
                <div className="title-badge" dangerouslySetInnerHTML={{ __html: t('guide_photo_guide_section_step_by_step_step_mask', { stepNr: props.nr }) }}></div>
                <div className="title-text" dangerouslySetInnerHTML={{ __html: props.title }}></div>
            </div>
            <ul>
                {props.items.map((item, index) => {
                    return <li key={`photo-guide-step-${index}`} dangerouslySetInnerHTML={{ __html: item }}></li>
                })}
            </ul>
        </div>
    )
}
