import { BaseState } from '../states'
import { createReducer } from '@reduxjs/toolkit'
import { HideAcceptTermsModal, ShowAcceptTermsModal, ShowLoginModal, UpdateMenu } from '../actions'
import { ErrorMessage, RemoveCarId } from '../actions/base'
import { Menu } from '../../enum'
import { ShowToast } from '../actions/base/show-toast.action'
import { store } from '../../redux'
import { handleServerErrors } from '../../utils/error.helper'

let timeout: NodeJS.Timeout | null = null

const initialState: BaseState = {
    menu: Menu.Auctions,
    loginVisible: false,
    carId: null,

    errorTitle: null,
    errorMessage: null,
    errorCode: null,
    toastMessage: null,
    showAcceptTermsModal: false,
}

export const baseReducer = createReducer(initialState, (builder) => {
    builder.addCase(UpdateMenu, (state, action) => {
        state.menu = action.payload.menu
    })
    builder.addCase(ShowLoginModal, (state, action) => {
        state.loginVisible = action.payload.visible
        state.carId = action.payload.carId
    })
    builder.addCase(RemoveCarId, (state, action) => {
        state.carId = null
    })
    builder.addCase(ErrorMessage, (state, action) => {
        state.errorTitle = action.payload.title

        if (action.payload.code) {
            const serverError = handleServerErrors(action.payload.code)
            state.errorMessage = serverError !== null ? serverError : action.payload.message
        } else {
            state.errorMessage = action.payload.message
        }
        state.errorCode = action.payload.code
    })
    builder.addCase(ShowAcceptTermsModal, (state) => {
        state.showAcceptTermsModal = true
    })
    builder.addCase(HideAcceptTermsModal, (state) => {
        state.showAcceptTermsModal = false
    })
    builder.addCase(ShowToast, (state, action) => {
        if (timeout !== null) {
            clearTimeout(timeout)
        }

        state.toastMessage = action.payload.message

        timeout = setTimeout(() => {
            store.dispatch(ShowToast({ message: null }))
        }, 3000)
    })
})
