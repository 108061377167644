import { Expose } from 'class-transformer'
import { IsBoolean, IsDefined, IsNumber, IsOptional, ValidateIf } from 'class-validator'
import { Bid } from './bid.entity'
import { Car } from './car.entity'

export class Auction {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    currentPrice!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    startDate!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    endDate!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    status!: number

    @Expose()
    @IsDefined()
    @IsBoolean()
    @ValidateIf((object, value) => value !== undefined)
    isFirstBid!: boolean | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null)
    bidCount!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== undefined)
    winnerBidId!: number | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null)
    finalBuyersFee?: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null)
    highestBid!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null)
    winnerUserId!: number | null

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    bids?: Bid[] | null

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    car?: Car | null

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number
}
