import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../redux'
import { CarStatusEnum } from '../../../../enum'
import { CarsSoldElement } from './components/cars-sold-element'
import { LoadMoreButton } from '../../../../components'
import { CarsSoldPagination } from '../../../../redux/actions'
import { getCarsSold } from '../../../../api'
import './cars-sold.tab.scss'

interface CarsSoldTabProps {}

export const CarsSoldTab = (props: CarsSoldTabProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { cars, pagination } = useAppSelector((state) => state.user.carsSold)

    const onLoadMoreClick = async () => {
        dispatch(CarsSoldPagination({}))
        await getCarsSold()
    }

    return (
        <main className="tab tab-cars-sold">
            <div className="tab-div">
                <div className="inner-container-div">
                    <label className={`title`}>{t('my_ev_garage_did_not_sell_title') + ' (' + pagination.didNotSellCount + ')'}</label>

                    <ul className={`car-list ${pagination.didNotSellCount > 0 ? `` : `hidden`}`}>
                        <div className={`divider`} />
                        {cars
                            .filter((car) => car.status === CarStatusEnum.DidNotSell)
                            .map((car, index) => (
                                <CarsSoldElement car={car} reauctionVisible={true} />
                            ))}
                    </ul>
                </div>

                <div className="inner-container-div">
                    <label className={`title`}>{t('my_ev_garage_cars_sold_title') + ' (' + pagination.soldCount + ')'}</label>

                    <ul className={`car-list ${pagination.soldCount > 0 ? `` : `hidden`}`}>
                        <div className={`divider`} />
                        {cars
                            .filter((car) => car.status === CarStatusEnum.Sold || car.status === CarStatusEnum.Paid || car.status === CarStatusEnum.Unpaid || car.status === CarStatusEnum.Overdue)
                            .map((car, index) => (
                                <CarsSoldElement car={car} reauctionVisible={false} />
                            ))}
                    </ul>
                </div>

                <div className={`bottom-div ${pagination.didNotSellCount + pagination.soldCount > 12 ? `` : `hidden`}`}>
                    <LoadMoreButton onClick={onLoadMoreClick} />
                </div>
            </div>
        </main>
    )
}
