import { createReducer } from '@reduxjs/toolkit'
import { PrivacyState } from '../../states'
import { GetPrivacySuccess } from '../../actions'

const initialState: PrivacyState = {
    privacy: null,
}

export const privacyReducer = createReducer(initialState, (builder) => {
    builder.addCase(GetPrivacySuccess, (state, action) => {
        state.privacy = action.payload.privacy
    })
})
