import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Blurhash } from 'react-blurhash'
import { useState } from 'react'
import { useAppSelector } from '../../../../redux'
import { GalleryItem } from '../../../auction/components/gallery-item/gallery-item'
import { MediaFileTypeEnum } from '../../../../enum'
import { Checkbox, ViewPager } from '../../../../components'
import { CarInformations } from '../../../auction/components/car-information'
import Lightbox from 'yet-another-react-lightbox'
import Video from 'yet-another-react-lightbox/plugins/video'
import { Address, Car, CarDetail } from '../../../../entity'
import { UpdateCarForm } from '../../../../redux/actions'
import './summary.tab.scss'

interface SummaryTabProps {}

export const SummaryTab = (props: SummaryTabProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { form } = useAppSelector((state) => state.car)

    const [imageLoaded, setImageLoaded] = useState(false)
    const [primaryImageUrl, setPrimaryImageUrl] = useState(form.mediaFiles.length !== 0 ? form.mediaFiles[0].url : '')
    const [primaryImageIndex, setPrimaryImageIndex] = useState(0)
    const [openGallery, setOpenGallery] = useState(false)

    const onMagnifyingGlassClick = () => {
        setPrimaryImageIndex(0)

        setOpenGallery(true)
    }

    const getCar = () => {
        const car = new Car()
        car.mediaFiles = form.mediaFiles
        car.address = new Address()
        car.address.country = form.country.value
        car.address.city = form.city.value
        car.address.postalCode = Number(form.postalCode.value)
        car.manufacturingYear = Number(form.manufacturingYear.value)
        car.make = form.make.value
        car.model = form.model.value
        car.variant = form.variant.value
        car.vin = form.vin.value
        car.batteryCapacity = Number(form.batteryCapacity.value.replace(',', '.'))
        car.manufacturerEstimatedRange = Number(form.manufacturerEstimatedRange.value)
        car.horsepower = Number(form.horsepower.value)
        car.mileage = Number(form.mileage.value)
        car.batteryStatus = form.batteryStatus.value
        car.drivetrain = form.drivetrain.value
        car.chargingConnectorAc = form.chargingConnectorAc.value
        car.chargingConnectorAcOtherName = form.chargingConnectorAcOtherName.value
        car.chargingConnectorDc = form.chargingConnectorDc.value
        car.chargingConnectorDcOtherName = form.chargingConnectorDcOtherName.value
        car.maximumAcCharging = Number(form.maximumAcCharging.value.replace(',', '.'))
        car.maximumDcCharging = Number(form.maximumDcCharging.value.replace(',', '.'))

        if (form.mfkDateYear.value != null && form.mfkDateYear.value.length !== 0) {
            car.mfkDate = new Date(Number(form.mfkDateYear.value), Number(form.mfkDateMonth.value)).getTime()
        }

        if (form.validWarrantyYear.value != null && form.validWarrantyYear.value.length !== 0) {
            car.validWarranty = new Date(Number(form.validWarrantyYear.value), Number(form.validWarrantyMonth.value)).getTime()
        }
        car.reservePrice = Number(form.reservePrice.value)

        car.carDetail = new CarDetail()
        car.carDetail.documents = form.documents

        car.carDetail.exteriorColour = form.exteriorColour.value
        car.carDetail.interiorColour = form.interiorColour.value
        car.carDetail.headUpDisplay = form.headUpDisplay.value
        car.carDetail.laneAssist = form.laneAssist.value
        car.carDetail.threeSixtydegreeCamera = form.threeSixtydegreeCamera.value
        car.carDetail.parkingAssist = form.parkingAssist.value
        car.carDetail.rearViewCamera = form.rearViewCamera.value
        car.carDetail.autoParking = form.autoParking.value
        car.carDetail.cruiseControl = form.cruiseControl.value
        car.carDetail.adaptiveCruiseControl = form.adaptiveCruiseControl.value
        car.carDetail.parkingSensors = form.parkingSensors.value
        car.carDetail.centralDisplay = form.centralDisplay.value
        car.carDetail.navigationSystem = form.navigationSystem.value
        car.carDetail.appleCarplay = form.appleCarplay.value
        car.carDetail.androidAuto = form.androidAuto.value
        car.carDetail.bluetoothInterface = form.bluetoothInterface.value
        car.carDetail.dabRadio = form.dabRadio.value
        car.carDetail.wirelessPhoneCharger = form.wirelessPhoneCharger.value
        car.carDetail.usbPorts = form.usbPorts.value
        car.carDetail.soundSystem = form.soundSystem.value
        car.carDetail.blindSpotMonitor = form.blindSpotMonitor.value
        car.carDetail.abs = form.abs.value
        car.carDetail.esc = form.esc.value
        car.carDetail.tyrePressureMonitoringSystem = form.tyrePressureMonitoringSystem.value
        car.carDetail.autoEmergencyBreak = form.autoEmergencyBreak.value
        car.carDetail.driverAlertSystem = form.driverAlertSystem.value
        car.carDetail.isofix = form.isofix.value
        car.carDetail.nightVision = form.nightVision.value
        car.carDetail.frontCollisionWarning = form.frontCollisionWarning.value
        car.carDetail.crossTrafficWarning = form.crossTrafficWarning.value
        car.carDetail.airbags = form.airbags.value
        car.carDetail.heatPump = form.heatPump.value
        car.carDetail.rainSensor = form.rainSensor.value
        car.carDetail.electricTrunkOpening = form.electricTrunkOpening.value
        car.carDetail.electricSideMirrors = form.electricSideMirrors.value
        car.carDetail.heatedSideMirrors = form.heatedSideMirrors.value
        car.carDetail.keylessEntry = form.keylessEntry.value
        car.carDetail.heatedSteeringWheel = form.heatedSteeringWheel.value
        car.carDetail.airConditioning = form.airConditioning.value
        car.carDetail.electricSeats = form.electricSeats.value
        car.carDetail.heatedSeats = form.heatedSeats.value
        car.carDetail.cooledSeats = form.cooledSeats.value
        car.carDetail.ledHeadLights = form.ledHeadLights.value
        car.carDetail.ledRearLights = form.ledRearLights.value
        car.carDetail.xenonHeadLights = form.xenonHeadLights.value
        car.carDetail.xenonRearLights = form.xenonRearLights.value
        car.carDetail.matrixHeadlights = form.matrixHeadlights.value
        car.carDetail.corneringLights = form.corneringLights.value
        car.carDetail.fogLights = form.fogLights.value
        car.carDetail.ambientLighting = form.ambientLighting.value
        car.carDetail.tintedWindows = form.tintedWindows.value
        car.carDetail.skiRack = form.skiRack.value
        car.carDetail.towBar = form.towBar.value
        car.carDetail.slidingRoof = form.slidingRoof.value
        car.carDetail.panoramicRoof = form.panoramicRoof.value
        car.carDetail.sportSuspension = form.sportSuspension.value
        car.carDetail.alloyWheels = form.alloyWheels.value
        car.carDetail.sportSeats = form.sportSeats.value
        car.carDetail.seatMaterial = form.seatMaterial.value
        car.carDetail.otherEquipment = form.otherEquipment.value
        car.carDetail.ownersTake = form.ownersTake.value
        car.carDetail.ownershipHistory = form.ownershipHistory.value
        car.carDetail.serviceHistoryAndRepairs = form.serviceHistoryAndRepairs.value
        car.carDetail.otherItemsIncluded = form.otherItemsIncluded.value
        car.carDetail.modifications = form.modifications.value
        car.carDetail.knownProblemsOrFlaws = form.knownProblemsOrFlaws.value
        car.carDetail.otherInformation = form.otherInformation.value

        return car
    }

    const getGalleryItems = () => {
        const cards: React.ReactElement[] = []

        form.mediaFiles!!.map((mediaFile, index) =>
            cards.push(
                <GalleryItem
                    index={index}
                    mediaFile={mediaFile}
                    onClick={() => {
                        setPrimaryImageIndex(index)

                        if (mediaFile.type === MediaFileTypeEnum.Photo) {
                            setPrimaryImageUrl(mediaFile.url)
                        } else {
                            setOpenGallery(true)
                        }
                    }}
                />
            )
        )

        return cards
    }

    const getSlides = () => {
        let slides: any[] = []
        form.mediaFiles!!.map((mediaFile, index) => {
            if (mediaFile.type === MediaFileTypeEnum.Photo) {
                slides.push({ src: mediaFile.url, type: 'image' })
            } else {
                slides.push({
                    type: 'video',
                    poster: mediaFile.thumbnail,
                    sources: [
                        {
                            src: mediaFile.url,
                            type: 'video/mp4',
                        },
                    ],
                })
            }
        })

        return slides
    }

    return (
        <main className="tab tab-summary">
            <div className="tab-div">
                <h2>{t('add_car_summary_title')}</h2>

                <div className="details-container-div">
                    <div className="details-sub-container-div">
                        <div className="details-div">
                            <div className="left-div">
                                <div className="picture-div">
                                    <div className="car-img-div">
                                        {form.mediaFiles.length > 0 && <Blurhash className={`blurhash-img ${imageLoaded ? `hidden` : ``}`} hash={form.mediaFiles.length > 0 ? form.mediaFiles[0].blurHash : ''} width={'100%'} height={'100%'} resolutionX={32} resolutionY={32} punch={1} />}

                                        <img
                                            alt="Car image"
                                            className="car-img"
                                            src={primaryImageUrl}
                                            onLoad={() => {
                                                setImageLoaded(true)
                                            }}
                                        />

                                        <img alt="Magnifying glass icon" className="icon-img" src={'/images/ic_search_white.svg'} onClick={onMagnifyingGlassClick} />
                                    </div>

                                    <div className="car-details-div">
                                        <div className="detail-item-div">
                                            <label className="title">{t('auction_battery_capacity_title')}</label>
                                            <div className="detail-item-value-div">
                                                <label className="value">{form.batteryCapacity.value.replace('.', ',')} </label>

                                                <label className="unit">{t('car_card_kWh_title')}</label>
                                            </div>
                                        </div>

                                        <div className="divider" />

                                        <div className="detail-item-div">
                                            <label className="title">{t('auction_horsepower_title')}</label>

                                            <div className="detail-item-value-div">
                                                <label className="value">{form.horsepower.value} </label>
                                                <label className="unit">{t('auction_ps_title')}</label>
                                            </div>
                                        </div>
                                        <div className="divider" />

                                        <div className="detail-item-div">
                                            <label className="title">{t('auction_range_title')}</label>
                                            <div className="detail-item-value-div">
                                                <label className="value">{form.manufacturerEstimatedRange.value}* </label>
                                                <label className="unit">{t('car_card_km_title')}</label>
                                            </div>
                                        </div>
                                        <div className="divider" />

                                        <div className="detail-item-div">
                                            <label className="title">{t('auction_mileage_title')}</label>
                                            <div className="detail-item-value-div">
                                                <label className="value">{form.mileage.value.toLocaleString()} </label>
                                                <label className="unit">{t('car_card_km_title')}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="gallery-div">
                                    <ViewPager cards={getGalleryItems()} />
                                </div>
                            </div>
                        </div>
                        <div className="mobile-details-div">
                            <div className="top-div">
                                <div className="name-div">
                                    <h2>
                                        {form.manufacturingYear.value} {form.make.value} {form.model.value}
                                    </h2>
                                    <p className={`no-reserve ${form.reservePrice == null || form.reservePrice.value === '' ? `` : `hidden`}`}>{t('car_card_no_reserve_title')}</p>
                                </div>
                            </div>

                            <div className="bottom-detail-div">
                                <div className="detail-item-div">
                                    <label className="title">{t('auction_battery_capacity_title')}</label>
                                    <div className="detail-item-value-div">
                                        <label className="value">{form.batteryCapacity.value.replace('.', ',')} </label>
                                        <label className="unit">{t('car_card_kWh_title')}</label>
                                    </div>
                                </div>

                                <div className="detail-item-div">
                                    <label className="title">{t('auction_horsepower_title')}</label>

                                    <div className="detail-item-value-div">
                                        <label className="value">{form.horsepower.value} </label>
                                        <label className="unit">{t('auction_ps_title')}</label>
                                    </div>
                                </div>

                                <div className="detail-item-div">
                                    <label className="title">{t('auction_range_title')}</label>
                                    <div className="detail-item-value-div">
                                        <label className="value">{form.manufacturerEstimatedRange.value}* </label>
                                        <label className="unit">{t('car_card_km_title')}</label>
                                    </div>
                                </div>

                                <div className="detail-item-div">
                                    <label className="title">{t('auction_mileage_title')}</label>
                                    <div className="detail-item-value-div">
                                        <label className="value">{form.mileage.value.toLocaleString()} </label>
                                        <label className="unit">{t('car_card_km_title')}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="divider-div" />
                        <div className="owners-take-div">
                            <h2>{t('auction_owner_take_title')}</h2>

                            <div className="content-div">
                                <label className="body-text">{form.ownersTake.value}</label>
                            </div>
                        </div>
                        <div className="divider-div" />
                        <CarInformations car={getCar()} deepLEnabled={false} />

                        <Lightbox plugins={[Video]} index={primaryImageIndex} open={openGallery} close={() => setOpenGallery(false)} slides={getSlides()} />
                    </div>

                    <Checkbox
                        error={form.terms.error}
                        checked={form.terms.value}
                        id="terms"
                        titleElement={
                            <label className="small-text">
                                {t('add_car_terms_first_part_title')}&nbsp;
                                <a href="/terms" target="_blank">
                                    {t('add_car_terms_second_part_title')}
                                </a>
                                {t('add_car_terms_third_title')}&nbsp;
                                <a href="/privacy-policy" target="_blank">
                                    {t('add_car_terms_fourth_title')}
                                </a>
                            </label>
                        }
                        onChange={(e) => dispatch(UpdateCarForm({ key: 'terms', value: !form.terms.value, error: null }))}
                    />
                </div>
            </div>
        </main>
    )
}
