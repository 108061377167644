import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsString } from 'class-validator'

export class Impressum {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsString()
    contentHtml!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    updated!: number
}
