import { store } from '../../redux'
import { DefaultDTO, ErrorDTO } from '../../dto'
import { ErrorMessage, ShowToast } from '../../redux/actions'
import { NetworkCall } from '../../utils'

interface ResetPasswordProps {
    token: string
    password: string
    onSuccess: () => void
}

export const resetPassword = async (props: ResetPasswordProps) => {
    const requestBody = {
        token: props.token,
        password: props.password,
    }

    return await new NetworkCall('PATCH', `/1.0.0/users/reset-password`, requestBody)
        .then(200, DefaultDTO, (responseBody) => {
            props.onSuccess()

            store.dispatch(ShowToast({ message: 'toast_reset_password_success' }))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
