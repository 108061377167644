import { Expose } from 'class-transformer'
import { IsBoolean, IsDefined, IsIn, IsNumber, IsOptional, IsString, ValidateIf, isBoolean } from 'class-validator'

export class EvDatabaseCar {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsString()
    make!: string

    @Expose()
    @IsDefined()
    @IsString()
    model!: string

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    modelVersion!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    batteryCapacity!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    rangeWLTP!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    rangeWLTPTEH!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    efficiency!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    realRangeCityCold!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    realRangeCityMild!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    realRangeHighwayCold!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    realRangeHighwayMild!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    realRangeCombinedCold!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    realRangeCombinedMild!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    acceleration!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    topSpeed!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    electricRange!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    totalPower!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    horsepower!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    totalTorque!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    drive!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    batteryNominalCapacity!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    batteryUsableCapacity!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    batteryType!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    batteryCells!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    batteryArchitecture!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    batteryCathodeMaterial!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    batteryPackConfiguration!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    batteryNominalVoltage!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    batteryFormFactor!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    chargePort!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    chargePortPrimaryLocation!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    chargePortSecondaryLocation!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    chargePower!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    chargeTime!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    chargeSpeed!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    fastChargePort!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    fastChargePortLocation!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    fastChargePowerMax!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    fastChargePower!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    fastChargeTime!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    fastChargeSpeed!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsBoolean()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    autoChargeSupported!: boolean | null | undefined

    @Expose()
    @IsDefined()
    @IsBoolean()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    plugAndChargeSupported!: boolean | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    plugAndChargeSupportedProtocol!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsBoolean()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    v2lSupported!: boolean | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    v2lMaxOutputPower!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    v2lExteriorOutlets!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    v2lInteriorOutlets!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsBoolean()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    v2hAcSupported!: boolean | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    v2hAcMaxOutputPower!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsBoolean()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    v2hDcSupported!: boolean | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    v2hDcMaxOutputPower!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    vehicleConsumption!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    co2Emission!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    fuelEquivalent!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    length!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    width!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    widthWithMirrors!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    height!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    wheelbase!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    weightUnladen!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    grossVehicleWeight!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    maxPayload!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    cargoVolume!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    cargoVolumeMax!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    cargoVolumeFrunk!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    roofLoad!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsBoolean()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    towHitchPossible!: boolean | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    towingWeightUnbraked!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    towingWeightBraked!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    verticalLoadMax!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    seats!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    turningCircle!: number | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    platform!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsBoolean()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    evDedicatedPlatform!: boolean | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    carBody!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    segment!: string | null | undefined

    @Expose()
    @IsDefined()
    @IsBoolean()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    roofRails!: boolean | null | undefined

    @Expose()
    @IsDefined()
    @IsBoolean()
    @ValidateIf((object, value) => value !== null && value !== undefined)
    heatPump!: boolean | null | undefined
}
