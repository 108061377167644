import { MouseEventHandler } from 'react'
import './link.button.scss'

interface LinkButtonProps {
    title: string
    icon: string
    small?: boolean
    reverse?: boolean
    hidden?: boolean
    onClick?: MouseEventHandler
}

export const LinkButton = (props: LinkButtonProps) => {
    return (
        <div className={`link-button${props.reverse ? ` reverse` : ``}${props.hidden ? ` hidden` : ``}`} onClick={props.onClick}>
            <label className={`label`}>{props.title}</label>
            <img alt="Icon" className={`icon`} src={props.icon} />
        </div>
    )
}