import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, IsString, ValidateIf } from 'class-validator'
import { FaqItem } from './faq-item.entity'
import { Language } from './language.entity'

export class Faq {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsString()
    title!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    order!: number

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    language?: Language | null

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    faqItems?: FaqItem[] | null
}
