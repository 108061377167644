import { useTranslation } from 'react-i18next'
import { Section } from './components/section'
import { Card } from './components/card'
import { CardRow } from './components/card-row'
import { CardGrid } from './components/card-grid'
import './why-go-electric.page.scss'

interface WhyGoElectricPageProps {}

export const WhyGoElectricPage = (props: WhyGoElectricPageProps) => {
    const { t } = useTranslation()

    const _getIcon = (name: string) => `/images/why-go-electric/${name}.svg`

    return (
        <main className="page page-why-go-electric">
            <div className="main-div">
                <Section title={t('why_go_electric_title')}>
                    <Section body={t('why_go_electric_introduction')} isSub={true}></Section>

                    <Section title={t('why_go_electric_benefits_title')} isSub={true}>
                        <CardGrid>
                            <CardRow>
                                <Card icon={_getIcon('ic_environmental_impact')} title={t('why_go_electric_benefits_environmental_impact_card_title')} body={t('why_go_electric_benefits_environmental_impact_card_body')}></Card>
                                <Card icon={_getIcon('ic_cost_savings')} title={t('why_go_electric_benefits_cost_savings_card_title')} body={t('why_go_electric_benefits_cost_savings_card_body')}></Card>
                            </CardRow>
                            <CardRow>
                                <Card icon={_getIcon('ic_energy_efficiency')} title={t('why_go_electric_benefits_energy_efficiency_card_title')} body={t('why_go_electric_benefits_energy_efficiency_card_body')}></Card>
                                <Card icon={_getIcon('ic_performance_and_technology')} title={t('why_go_electric_benefits_performance_and_technology_card_title')} body={t('why_go_electric_benefits_performance_and_technology_card_body')}></Card>
                            </CardRow>
                        </CardGrid>
                    </Section>

                    <Section title={t('why_go_electric_disadvantages_title')} isSub={true}>
                        <CardGrid>
                            <CardRow>
                                <Card icon={_getIcon('ic_charging_infra')} title={t('why_go_electric_disadvantages_charging_infra_card_title')} body={t('why_go_electric_disadvantages_charging_infra_card_body')}></Card>
                                <Card icon={_getIcon('ic_upfront_cost')} title={t('why_go_electric_disadvantages_upfront_cost_card_title')} body={t('why_go_electric_disadvantages_upfront_cost_card_body')}></Card>
                            </CardRow>
                            <CardRow>
                                <Card icon={_getIcon('ic_range_health')} title={t('why_go_electric_disadvantages_range_health_card_title')} body={t('why_go_electric_disadvantages_range_health_card_body')}></Card>
                                <Card icon={_getIcon('ic_charging_time')} title={t('why_go_electric_disadvantages_charging_time_card_title')} body={t('why_go_electric_disadvantages_charging_time_card_body')}></Card>
                            </CardRow>
                        </CardGrid>
                    </Section>

                    <Section body={t('why_go_electric_conclusion')} isSub={true}></Section>

                    <Section title={t('why_go_electric_swiss_roadmap_title')} body={t('why_go_electric_swiss_roadmap_body')} isSub={true}></Section>
                </Section>
            </div>
        </main>
    )
}
