import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, ValidateIf, ValidateNested } from 'class-validator'

export class EvDatabseFilterDTO {
    @Expose()
    @IsDefined()
    make!: string[]

    @Expose()
    @IsDefined()
    model!: string[]

    @Expose()
    @IsDefined()
    minRange!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    maxRange!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    minBatteryCapacity!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    maxBatteryCapacity!: number

    @Expose()
    @IsDefined()
    chargePlug!: string[]
}
