import { DefaultDTO, ErrorDTO } from '../../dto'
import { store } from '../../redux'
import { SignUpSuccess, SignUpFailed, ErrorMessage } from '../../redux/actions'
import { NetworkCall } from '../../utils'

export const registration = async () => {
    const languages = []
    if (store.getState().signUp.form.english.value) {
        languages.push('en')
    }
    if (store.getState().signUp.form.german.value) {
        languages.push('de')
    }
    if (store.getState().signUp.form.italian.value) {
        languages.push('it')
    }
    if (store.getState().signUp.form.french.value) {
        languages.push('fr')
    }

    const requestBody = {
        username: store.getState().signUp.form.username.value,
        email: store.getState().signUp.form.email.value,
        password: store.getState().signUp.form.password.value,
        languages: languages,
        newsletter: store.getState().signUp.form.newsletter.value,
    }

    return await new NetworkCall('POST', `/1.0.0/users/registration`, requestBody)
        .then(201, DefaultDTO, (responseBody) => {
            store.dispatch(SignUpSuccess({}))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
            store.dispatch(SignUpFailed({}))
        })
        .call()
}
