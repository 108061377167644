import { MouseEventHandler } from 'react'
import './modal.scss'

interface ModalProps {
    title: string
    onCloseClick?: MouseEventHandler
    children: JSX.Element | JSX.Element[]
    showClose?: boolean;
    className?: string;
}

export const Modal = (props: ModalProps) => {
    return (
        <div className="modal-container">
            <div className={ `modal ${ props.className || `` }` }>
                <div className="modal__header">
                    <h3> {props.title} </h3>
                    { props.showClose === undefined || props.showClose === true ? (
                        <img className="close-button" alt="Close button" src="/images/ic_close.svg" onClick={props.onCloseClick} />
                    ) : null }
                </div>

                <div className="modal__body">{props.children}</div>
            </div>
        </div>
    )
}
