import { useTranslation } from 'react-i18next'
import { TabView } from '../../../../components'
import { TabViewPage } from '../../../../components/tabview/tabview-page'
import { Questions } from './questions'
import { Bids } from './bids'
import { Comments } from './comments'
import { Car } from '../../../../entity'
import { useAppSelector } from '../../../../redux'
import { ReportTypeEnum } from '../../../../enum'
import { RefObject, useEffect } from 'react'
import { getBids } from '../../../../api'
import './comments-question-bids.scss'

interface CommentsQuestionBidsProps {
    car: Car
    commentRef: RefObject<HTMLDivElement>
    onReportClick: (id: number, type: number) => void
}

export const CommentsQuestionBids = (props: CommentsQuestionBidsProps) => {
    const { t } = useTranslation()

    const { comments } = useAppSelector((state) => state.comments)
    const commentCount = useAppSelector((state) => state.comments.pagination.count)

    const { questions } = useAppSelector((state) => state.questions)
    const questionCount = useAppSelector((state) => state.questions.pagination.count)

    const { bids } = useAppSelector((state) => state.bids)
    const bidCount = useAppSelector((state) => state.bids.pagination.count)

    useEffect(() => {
        getBids(props.car.auctions[0].id, true)
    }, [props.car])

    return (
        <div className="comments-question-bids">
            <h2>{t('car_information_leave_comment_or_ask_the_seller_title')}</h2>

            <div className="section-div">
                <TabView tabs={{ comments: t('car_information_comments_title') + ' (' + commentCount + ')', questions: t('car_information_seller_qa_title') + ' (' + questionCount + ')', bids: t('car_information_bid_history_title') + ' (' + bidCount + ')' }}>
                    <TabViewPage key="comments">
                        <Comments comments={comments} car={props.car} commentRef={props.commentRef} onReportClick={(id) => props.onReportClick(id, ReportTypeEnum.Comment)} />
                    </TabViewPage>

                    <TabViewPage key="questions">
                        <Questions questions={questions} car={props.car} onReportClick={(id, type) => props.onReportClick(id, type)} />
                    </TabViewPage>

                    <TabViewPage key="bids">
                        <Bids bids={bids} auctionId={props.car.auctions[0].id} />
                    </TabViewPage>
                </TabView>
            </div>
        </div>
    )
}
