import { useTranslation } from 'react-i18next'
import { MouseEvent, useEffect, useState } from 'react'
import { Section } from './components/section'
import { Submenu } from './components/submenu'
import { useRef } from 'react'
import { useAppSelector } from '../../redux'
import { HowItWorksSection } from '../../enum/how-it-works-section-enum'
import { StepsSection } from './components/steps/steps-section'
import { LinkButton } from './components/link.button'
import { useDispatch } from 'react-redux'
import { UpdateHowItWorksSection } from '../../redux/actions'
import { useWindowDimensions } from '../../utils'
import { useParams } from 'react-router-dom'

import { HowBiddingWorksModal } from '../../components/modals'
import './how-it-works.page.scss'

interface HowItWorksPageProps {}

export const HowItWorksPage = (props: HowItWorksPageProps) => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()

    const { lng } = useParams()

    const { width } = useWindowDimensions()
    const currentSection = useAppSelector((state) => state.howItWorks.currentSection)

    const howItWorksSectionRef = useRef<HTMLDivElement | null>(null)
    const buyingCarSectionRef = useRef<HTMLDivElement | null>(null)
    const sellingCarSectionRef = useRef<HTMLDivElement | null>(null)
    const concludingSectionRef = useRef<HTMLDivElement | null>(null)
    const reservePriceSectionRef = useRef<HTMLDivElement | null>(null)
    const batterySectionRef = useRef<HTMLDivElement | null>(null)
    const [howBiddingWorksModalVisible, setHowBiddingWorksModalVisible] = useState(false)

    useEffect(() => {
        if (lng) {
            i18n.changeLanguage(lng)
        }
    }, [lng])

    const onConcludingClick = (event: MouseEvent) => {
        const targetLink = (event.target as HTMLElement).closest('a')
        if (!targetLink) return
        event.preventDefault()

        dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.Concluding }))

        setTimeout(() => {
            if (width <= 640) {
                window.scrollTo(0, 0)
            } else {
                window.scrollTo(0, (concludingSectionRef.current?.offsetTop ?? 0) - 104)
            }
        }, 100)
    }

    const onHowBiddingWorksClick = (event: MouseEvent) => {
        const targetLink = (event.target as HTMLElement).closest('a')
        if (!targetLink) return
        event.preventDefault()

        setHowBiddingWorksModalVisible(!howBiddingWorksModalVisible)
    }

    return (
        <main className="page page-how-it-works">
            <div className="main-div">
                <div className="submenu-div">
                    <Submenu refs={[howItWorksSectionRef, buyingCarSectionRef, sellingCarSectionRef, concludingSectionRef, reservePriceSectionRef, batterySectionRef]} />
                </div>
                <div className="content-div">
                    <Section title={t('how_it_works_title')} ref={howItWorksSectionRef} additionalClassNames={currentSection === HowItWorksSection.HowItWorks ? '' : 'hiddenOnSmallScreen'}>
                        <div dangerouslySetInnerHTML={{ __html: t('how_it_works_introduction') }}></div>
                    </Section>

                    <StepsSection additionalClassNames={currentSection === HowItWorksSection.HowItWorks ? '' : 'hiddenOnSmallScreen'}></StepsSection>

                    <Section title={t('how_it_works_buying_car_section_title')} ref={buyingCarSectionRef} additionalClassNames={currentSection === HowItWorksSection.BuyingCar ? '' : 'hiddenOnSmallScreen'}>
                        <Section body={t('how_it_works_buying_car_section_introduction')} isSub={true}></Section>

                        <Section title={t('how_it_works_buying_car_register_section_title')} body={t('how_it_works_buying_car_register_section_body')} isSub={true}></Section>

                        <Section title={t('how_it_works_buying_car_read_learn_ask_section_title')} isSub={true}>
                            <div>
                                <p dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_car_read_learn_ask_section_body_p') }}></p>
                                <ul>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_car_read_learn_ask_section_body_li_1') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_car_read_learn_ask_section_body_li_2') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_car_read_learn_ask_section_body_li_3') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_car_read_learn_ask_section_body_li_4') }}></li>
                                </ul>
                            </div>
                        </Section>

                        <Section title={t('how_it_works_buying_car_plan_ahead_section_title')} isSub={true}>
                            <div>
                                <p dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_car_plan_ahead_section_body_p') }}></p>
                                <ul>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_car_plan_ahead_section_li_1') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_car_plan_ahead_section_li_2') }}></li>
                                </ul>
                            </div>
                        </Section>

                        <Section title={t('how_it_works_buying_car_bidding_section_title')} isSub={true}>
                            <div>
                                <p dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_car_bidding_section_body_p') }}></p>
                                <ul>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_car_bidding_section_body_li_1') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_car_bidding_section_body_li_2') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_car_bidding_section_body_li_3') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_car_bidding_section_body_li_4') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_car_bidding_section_body_li_5') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_car_bidding_section_body_li_6') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_car_bidding_section_body_li_7') }} onClick={(e) => onHowBiddingWorksClick(e)}></li>
                                </ul>
                            </div>
                        </Section>

                        <Section title={t('how_it_works_buying_car_winning_section_title')} isSub={true}>
                            <div>
                                <p dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_car_winning_section_body_p1') }}></p>
                                <p dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_car_winning_section_body_p2') }}></p>
                                <p dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_car_winning_section_body_p3') }} onClick={(e) => onConcludingClick(e)}></p>
                            </div>
                        </Section>
                    </Section>

                    <Section title={t('how_it_works_selling_car_section_title')} ref={sellingCarSectionRef} additionalClassNames={currentSection === HowItWorksSection.SellingCar ? '' : 'hiddenOnSmallScreen'}>
                        <Section body={t('how_it_works_selling_car_section_body')} isSub={true}></Section>

                        <Section title={t('how_it_works_selling_car_register_section_title')} isSub={true}>
                            <div>
                                <p dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_register_section_body') }}></p>
                            </div>
                        </Section>

                        <Section title={t('how_it_works_selling_car_prepare_section_title')} isSub={true}>
                            <div>
                                <p dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_prepare_section_body_p') }}></p>
                                <ul>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_prepare_section_body_li_1') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_prepare_section_body_li_2') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_prepare_section_body_li_3') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_prepare_section_body_li_4') }}></li>
                                    {/* <li dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_prepare_section_body_li_5') }}></li> */}
                                    {/* <li dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_prepare_section_body_li_6') }}></li> */}
                                </ul>
                            </div>
                        </Section>

                        <Section title={t('how_it_works_selling_car_submit_section_title')} isSub={true}>
                            <div>
                                <p dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_submit_section_body_p_1') }}></p>
                                <p dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_submit_section_body_p_2') }}></p>
                                <p dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_submit_section_body_p_3') }}></p>
                                <ul>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_submit_section_body_li_1') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_submit_section_body_li_2') }}></li>
                                </ul>
                                <p dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_submit_section_body_p_4') }}></p>
                                <p dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_submit_section_body_p_5') }}></p>
                            </div>
                        </Section>

                        <Section title={t('how_it_works_selling_car_participate_section_title')} isSub={true}>
                            <div>
                                <p dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_participate_section_body_p') }}></p>
                                <ul>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_participate_section_body_li_1') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_participate_section_body_li_2') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_participate_section_body_li_3') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_participate_section_body_li_4') }}></li>
                                </ul>
                            </div>
                        </Section>

                        <Section title={t('how_it_works_selling_car_at_the_end_section_title')} isSub={true}>
                            <div>
                                <p dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_at_the_end_section_body_p_1') }}></p>
                                <p dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_at_the_end_section_body_p_2') }}></p>
                                <p dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_at_the_end_section_body_p_3') }}></p>
                                <p dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_car_at_the_end_section_body_p_4') }}></p>
                            </div>
                        </Section>
                    </Section>

                    <Section title={t('how_it_works_concluding_section_title')} ref={concludingSectionRef} additionalClassNames={currentSection === HowItWorksSection.Concluding ? '' : 'hiddenOnSmallScreen'}>
                        <Section title={t('how_it_works_concluding_contact_section_title')} isSub={true}>
                            <div id="concluding-the-sale">
                                <ul>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_concluding_contact_section_body_li_1') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_concluding_contact_section_body_li_2') }}></li>
                                </ul>
                            </div>
                        </Section>

                        <Section title={t('how_it_works_concluding_payment_section_title')} isSub={true}>
                            <div>
                                <ul>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_concluding_payment_section_li_1') }}></li>
                                    <li>
                                        <p dangerouslySetInnerHTML={{ __html: t('how_it_works_concluding_payment_section_li_2') }}></p>
                                        <ul>
                                            <li dangerouslySetInnerHTML={{ __html: t('how_it_works_concluding_payment_section_li_2_1') }}></li>
                                            <li dangerouslySetInnerHTML={{ __html: t('how_it_works_concluding_payment_section_li_2_2') }}></li>
                                            <li dangerouslySetInnerHTML={{ __html: t('how_it_works_concluding_payment_section_li_2_3') }}></li>
                                            <li dangerouslySetInnerHTML={{ __html: t('how_it_works_concluding_payment_section_li_2_4') }}></li>
                                        </ul>
                                    </li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_concluding_payment_section_li_3') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('how_it_works_concluding_payment_section_li_4') }}></li>
                                </ul>
                            </div>
                        </Section>

                        <Section title={t('how_it_works_concluding_share_section_title')} body={t('how_it_works_concluding_share_section_body')} isSub={true}></Section>
                    </Section>

                    <Section title={t('how_it_works_reserve_price_section_title')} ref={reservePriceSectionRef} additionalClassNames={currentSection === HowItWorksSection.ReservePrice ? '' : 'hiddenOnSmallScreen'}>
                        <div>
                            <p dangerouslySetInnerHTML={{ __html: t('how_it_works_reserve_price_section_body_p_1') }}></p>
                            <p dangerouslySetInnerHTML={{ __html: t('how_it_works_reserve_price_section_body_p_2') }}></p>
                            <p dangerouslySetInnerHTML={{ __html: t('how_it_works_reserve_price_section_body_p_3') }}></p>
                            <p dangerouslySetInnerHTML={{ __html: t('how_it_works_reserve_price_section_body_p_4') }}></p>
                            <p dangerouslySetInnerHTML={{ __html: t('how_it_works_reserve_price_section_body_p_5') }}></p>
                            <p dangerouslySetInnerHTML={{ __html: t('how_it_works_reserve_price_section_body_p_6') }}></p>
                            <p dangerouslySetInnerHTML={{ __html: t('how_it_works_reserve_price_section_body_p_7') }}></p>
                            <ul>
                                <li dangerouslySetInnerHTML={{ __html: t('how_it_works_reserve_price_section_body_li_1') }}></li>
                                <li dangerouslySetInnerHTML={{ __html: t('how_it_works_reserve_price_section_body_li_2') }}></li>
                            </ul>
                        </div>
                    </Section>

                    <Section title={t('how_it_works_battery_section_title')} ref={batterySectionRef} additionalClassNames={currentSection === HowItWorksSection.Battery ? '' : 'hiddenOnSmallScreen'}>
                        <div>
                            <p dangerouslySetInnerHTML={{ __html: t('how_it_works_battery_section_body_p_1') }}></p>
                            <p dangerouslySetInnerHTML={{ __html: t('how_it_works_battery_section_body_p_2') }}></p>
                            <p dangerouslySetInnerHTML={{ __html: t('how_it_works_battery_section_body_p_3') }}></p>
                            <p dangerouslySetInnerHTML={{ __html: t('how_it_works_battery_section_body_p_4') }}></p>
                            <p dangerouslySetInnerHTML={{ __html: t('how_it_works_battery_section_body_p_5') }}></p>
                        </div>
                    </Section>

                    {currentSection === HowItWorksSection.HowItWorks && (
                        <div className="one-button-div hidden-on-desktop-screen">
                            <LinkButton
                                title={t('how_it_works_buying_menu')}
                                icon="/images/ic_arrow_right_blue.svg"
                                onClick={() => {
                                    dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.BuyingCar }))
                                    // @ts-ignore
                                    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' })
                                }}
                            ></LinkButton>
                        </div>
                    )}
                    {currentSection === HowItWorksSection.BuyingCar && (
                        <div className="two-button-div hidden-on-desktop-screen">
                            <LinkButton
                                title={t('how_it_works_how_it_works_menu')}
                                icon="/images/ic_arrow_left_blue.svg"
                                reverse
                                onClick={() => {
                                    dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.HowItWorks }))
                                    // @ts-ignore
                                    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' })
                                }}
                            ></LinkButton>
                            <LinkButton
                                title={t('how_it_works_selling_menu')}
                                icon="/images/ic_arrow_right_blue.svg"
                                onClick={() => {
                                    dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.SellingCar }))
                                    // @ts-ignore
                                    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' })
                                }}
                            ></LinkButton>
                        </div>
                    )}
                    {currentSection === HowItWorksSection.SellingCar && (
                        <div className="two-button-div hidden-on-desktop-screen">
                            <LinkButton
                                title={t('how_it_works_buying_menu')}
                                icon="/images/ic_arrow_left_blue.svg"
                                reverse
                                onClick={() => {
                                    dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.BuyingCar }))
                                    // @ts-ignore
                                    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' })
                                }}
                            ></LinkButton>
                            <LinkButton
                                title={t('how_it_works_concluding_menu')}
                                icon="/images/ic_arrow_right_blue.svg"
                                onClick={() => {
                                    // @ts-ignore
                                    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' })
                                    dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.Concluding }))
                                }}
                            ></LinkButton>
                        </div>
                    )}
                    {currentSection === HowItWorksSection.Concluding && (
                        <div className="two-button-div hidden-on-desktop-screen">
                            <LinkButton
                                title={t('how_it_works_selling_menu')}
                                icon="/images/ic_arrow_left_blue.svg"
                                reverse
                                onClick={() => {
                                    dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.SellingCar }))
                                    // @ts-ignore
                                    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' })
                                }}
                            ></LinkButton>
                            <LinkButton
                                title={t('how_it_works_reserve_price_menu')}
                                icon="/images/ic_arrow_right_blue.svg"
                                onClick={() => {
                                    dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.ReservePrice }))
                                    // @ts-ignore
                                    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' })
                                }}
                            ></LinkButton>
                        </div>
                    )}
                    {currentSection === HowItWorksSection.ReservePrice && (
                        <div className="two-button-div hidden-on-desktop-screen">
                            <LinkButton
                                title={t('how_it_works_concluding_menu')}
                                icon="/images/ic_arrow_left_blue.svg"
                                reverse
                                onClick={() => {
                                    dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.Concluding }))
                                    // @ts-ignore
                                    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' })
                                }}
                            ></LinkButton>
                            <LinkButton
                                title={t('how_it_works_battery_menu')}
                                icon="/images/ic_arrow_right_blue.svg"
                                onClick={() => {
                                    dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.Battery }))
                                    // @ts-ignore
                                    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' })
                                }}
                            ></LinkButton>
                        </div>
                    )}
                    {currentSection === HowItWorksSection.Battery && (
                        <div className="one-button-div hidden-on-desktop-screen">
                            <LinkButton
                                title={t('how_it_works_reserve_price_menu')}
                                icon="/images/ic_arrow_left_blue.svg"
                                reverse
                                onClick={() => {
                                    dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.ReservePrice }))
                                    // @ts-ignore
                                    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' })
                                }}
                            ></LinkButton>
                        </div>
                    )}
                </div>
            </div>

            {howBiddingWorksModalVisible ? (
                <HowBiddingWorksModal
                    onClose={() => {
                        setHowBiddingWorksModalVisible(!howBiddingWorksModalVisible)
                    }}
                />
            ) : null}
        </main>
    )
}
