import { Question } from '../../../../../entity'
import { useTranslation } from 'react-i18next'
import { IconButton } from '../../../../../components'
import { ReportTypeEnum } from '../../../../../enum'
import { useNavigate } from 'react-router-dom'
import { translate } from '../../../../../api'
import { useEffect, useState } from 'react'
import { formatTimeLeft } from '../../../../../utils'
import './question-element.scss'

interface QuestionElementProps {
    question: Question
    hideReply: boolean
    onReportClick: (id: number, type: number) => void
    onReplyClick: (id: number, username: string) => void
}

export const QuestionElement = (props: QuestionElementProps) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const [translatedQuestion, setTranslatedQuestion] = useState('')
    const [translatedAnswer, setTranslatedAnswer] = useState('')

    useEffect(() => {
        setTranslatedQuestion('')
        setTranslatedAnswer('')
    }, [props.question])

    const onTranslateQuestionClick = async () => {
        // if (user) {
        if (props.question.question.length != 0) {
            await translate({
                text: props.question.question,
                language: i18n.language.includes('de') ? 'de' : i18n.language.includes('fr') ? 'fr' : 'en',
                onSuccess(text: string) {
                    setTranslatedQuestion(text)
                },
            })
        }
        // } else {
        //     dispatch(ShowLoginModal({ visible: true, carId: null }))
        // }
    }

    const onTranslateAnswerClick = async () => {
        // if (user) {
        if ((props.question.answer?.answer ?? '').length != 0) {
            await translate({
                text: props.question.answer?.answer ?? '',
                language: i18n.language.includes('de') ? 'de' : i18n.language.includes('fr') ? 'fr' : 'en',
                onSuccess(text: string) {
                    setTranslatedAnswer(text)
                },
            })
        }
        // } else {
        //     dispatch(ShowLoginModal({ visible: true, carId: null }))
        // }
    }

    return (
        <li key={props.question.id} className={`question-element`}>
            <div className={`container-div`}>
                <div className="question-div">
                    <label className="comment question">Q: {translatedQuestion != '' ? translatedQuestion : props.question.question}</label>

                    <img alt="Translate icon" className="deepl-img" src="/images/ic_deepl.svg" onClick={onTranslateQuestionClick} />
                </div>

                <div className="action-div">
                    <div className={`user-div`}>
                        <label
                            className="user"
                            onClick={() => {
                                navigate(`/user/${props.question.user.id}`)
                            }}
                        >
                            {props.question.user.username}
                        </label>
                        <label className="date">{formatTimeLeft(props.question.created!!, t)}</label>

                        {/* <label className="date">{new Date(props.question.created!!).toLocaleString('de-CH', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</label> */}
                    </div>

                    {!props.hideReply && (
                        <>
                            <IconButton hidden={props.question.answer != null} small={true} title={t('car_information_reply_button')} icon="/images/ic_reply.svg" onClick={() => props.onReplyClick(props.question.id, props.question.user.username)} />

                            <div className="report-button">
                                <IconButton small={true} title={t('car_information_report_question_button')} icon="/images/ic_report.svg" onClick={() => props.onReportClick(props.question.id, ReportTypeEnum.Question)} />
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className={`container-div answer-div${props.question.answer != null ? `` : ` hidden`}`}>
                <div className="question-div">
                    <label className="comment">A: {translatedAnswer !== '' ? translatedAnswer : props.question.answer?.answer}</label>

                    <img alt="Translate icon" className="deepl-img" src="/images/ic_deepl.svg" onClick={onTranslateAnswerClick} />
                </div>

                <div className="action-div">
                    <div className={`user-div`}>
                        <label
                            className="user"
                            onClick={() => {
                                navigate(`/user/${props.question.answer?.user.id}`)
                            }}
                        >
                            {props.question.answer?.user.username}
                        </label>

                        <label className="date">{formatTimeLeft(props.question.answer?.created!!, t)}</label>

                        {/* <label className="date">{new Date(props.question.answer?.created!!).toLocaleString('de-CH', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</label> */}
                    </div>

                    {!props.hideReply && (
                        <div className="report-button">
                            <IconButton small={true} title={t('car_information_report_question_button')} icon="/images/ic_report.svg" onClick={() => props.onReportClick(props.question.answer ? props.question.answer.id : 0, ReportTypeEnum.Answer)} />
                        </div>
                    )}
                </div>
            </div>

            <div className="divider" />
        </li>
    )
}
