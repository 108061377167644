import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { Faq } from '../../entity'

export class FaqsDTO {
    @Expose()
    @ValidateNested()
    @Type(() => Faq)
    faqs!: Faq[]
}
