import { ClassConstructor, plainToInstance } from 'class-transformer'
import { validate } from 'class-validator'
import { InvalidResponseException, NetworkCallException, NetworkException, UnexpectedResponseException, UnexpectedStatusException } from '../exception'
import { ErrorDTO } from '../dto'

interface CatchClause {
    status: number
    callback: (response: ErrorDTO | Response) => void
}

interface SpecialCatchClause {
    exception: ClassConstructor<NetworkCallException>
    callback: () => void
}

interface ThenClause {
    status: number
    dto: ClassConstructor<any> | undefined
    callback: Function
}

export class NetworkCall {
    private static catches: CatchClause[] = []

    private readonly body?: any
    private headers: { [index: string]: string } = { 'App-Platform': 'web' }

    private readonly method: string
    private readonly url: string

    private catches: CatchClause[] = []
    private specialCatches: SpecialCatchClause[] = []
    private catchFinally: ((response: Response) => void) | undefined = undefined
    private thens: ThenClause[] = []

    constructor(method: string, url: string, body?: any) {
        this.body = body
        this.method = method
        this.url = url
    }

    static catch(status: number, callback: (response: ErrorDTO | Response) => void): void {
        this.catches.push({ status: status, callback: callback as (response: ErrorDTO | Response) => void })
    }

    setHeader(name: string, value: string | null) {
        if (value != null) {
            this.headers[name] = value
        }
        return this
    }

    then(status: number, callback: (body: any) => void): this
    then<T>(status: number, dto: ClassConstructor<T>, callback: (body: T) => void): this
    then<T>(status: number, dtoOrCallback: ClassConstructor<T> | ((body: T) => void), callback?: (body: T) => void) {
        if (callback === undefined) {
            this.thens.push({ status, dto: undefined, callback: dtoOrCallback as (body: T) => void })
        } else {
            this.thens.push({ status, dto: dtoOrCallback as ClassConstructor<T>, callback: callback as (body: T) => void })
        }
        return this
    }

    catch(callback: (response: ErrorDTO | Response) => void): this
    catch(status: number, callback: (response: ErrorDTO | Response) => void): this
    catch(exception: ClassConstructor<NetworkCallException>, callback: () => void): this
    catch(statusOrCallbackOrException: number | ((response: ErrorDTO | Response) => void) | ClassConstructor<NetworkCallException>, callback?: (response: ErrorDTO | Response) => void) {
        if ((statusOrCallbackOrException as any).isNetworkCallException) {
            this.specialCatches.push({
                exception: statusOrCallbackOrException as ClassConstructor<NetworkCallException>,
                callback: callback as () => void,
            })
        } else if (typeof statusOrCallbackOrException === 'number') {
            this.catches.push({ status: statusOrCallbackOrException, callback: callback as (response: ErrorDTO | Response) => void })
        } else {
            this.catchFinally = statusOrCallbackOrException as (body: any) => void
        }
        return this
    }

    async call() {
        let response: Response, body: any

        try {
            response = await fetch(`${process.env.REACT_APP_API_ORIGIN}${this.url}`, {
                body: this.body === undefined ? undefined : this.body instanceof FormData ? this.body : JSON.stringify(this.body),
                headers: this.body instanceof FormData ? this.headers : { ...this.headers, 'Content-Type': 'application/json', Language: localStorage.getItem('language') ?? 'en' },
                method: this.method,
            })
        } catch {
            await this.catchOrThrow(NetworkException)
            return false
        }

        for (const then of this.thens) {
            if (then.status === response.status) {
                try {
                    body = await response.json()
                } catch (exception) {
                    await this.catchOrThrow(InvalidResponseException)
                    return false
                }

                if (then.dto) {
                    const errors = await validate(plainToInstance(then.dto, body))
                    if (errors.length > 0) {
                        console.log(errors)
                        await this.catchOrThrow(UnexpectedResponseException)
                        return false
                    }
                }

                then.callback(body)
                return true
            }
        }

        for (const c of NetworkCall.catches) {
            if (c.status === response.status) {
                try {
                    const errorBody = await response.json()
                    c.callback(errorBody)
                } catch (exception) {
                    c.callback(response)
                }

                //return false
            }
        }

        for (const c of this.catches) {
            if (c.status === response.status) {
                try {
                    const errorBody = await response.json()
                    c.callback(errorBody)
                } catch (exception) {
                    c.callback(response)
                }

                return false
            }
        }

        if (this.catchFinally) {
            try {
                const errorBody = await response.json()
                await this.catchFinally(errorBody)
            } catch (exception) {
                await this.catchFinally(response)
            }

            return false
        }

        throw new UnexpectedStatusException(response.status, response.statusText)
    }

    async catchOrThrow(Exception: ClassConstructor<NetworkCallException>) {
        const specialCatch = this.specialCatches.find((c) => c.exception === Exception)
        if (specialCatch !== undefined) {
            await specialCatch.callback()
            return
        }
        throw new Exception()
    }
}
