import { GuidePageEnum } from '../../../enum/guide-page-enum'
import { Section } from '../components/section'
import { GuideBasePage } from '../guide/guide-base.page'
import { PhotoGuideStep } from './photo-guide-step'
import { WhyDoQualityPhotosList } from './why-do-quality-photos-list'
import { WhyDoQualityPhotosItem } from './why-do-quality-photos-item'
import { Button } from '../../../components'
import { useDispatch } from 'react-redux'
import { ResetCarForm, ShowLoginModal, UpdateAddCarTab, UpdateMenu } from '../../../redux/actions'
import { Menu } from '../../../enum'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../redux'
import { useTranslation } from 'react-i18next'
import './photo-guide.page.scss'

export const PhotoGuidePage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const user = useAppSelector((state) => state.login.user)

    return (
        <GuideBasePage page={GuidePageEnum.PhotoGuide}>
            <Section>
                <Section body={t('guide_photo_guide_section_title')} isSub={true}></Section>

                <Section body={t('guide_photo_guide_section_body')} isSub={true}></Section>

                <Section title={t('guide_photo_guide_section_why')} isSub={true}>
                    <WhyDoQualityPhotosList>
                        <WhyDoQualityPhotosItem titleNumber={1} titleText={t('guide_photo_guide_section_attractiveness_title')} body={t('guide_photo_guide_section_attractiveness_body')}></WhyDoQualityPhotosItem>
                        <WhyDoQualityPhotosItem titleNumber={2} titleText={t('guide_photo_guide_section_transparency_title')} body={t('guide_photo_guide_section_transparency_body')}></WhyDoQualityPhotosItem>
                        <WhyDoQualityPhotosItem titleNumber={3} titleText={t('guide_photo_guide_section_accurate_title')} body={t('guide_photo_guide_section_accurate_body')}></WhyDoQualityPhotosItem>
                        <WhyDoQualityPhotosItem titleNumber={4} titleText={t('guide_photo_guide_section_time_saver_title')} body={t('guide_photo_guide_section_time_saver_body')}></WhyDoQualityPhotosItem>
                    </WhyDoQualityPhotosList>
                </Section>
            </Section>

            <Section>
                <Section title={t('guide_photo_guide_section_step_by_step_title')} isSub={true}>
                    <PhotoGuideStep nr={1} title={t('guide_photo_guide_section_step1_title')} items={[t('guide_photo_guide_section_step1_body1'), t('guide_photo_guide_section_step1_body2'), t('guide_photo_guide_section_step1_body3')]} />
                    <PhotoGuideStep nr={2} title={t('guide_photo_guide_section_step2_title')} items={[t('guide_photo_guide_section_step2_body1'), t('guide_photo_guide_section_step2_body2'), t('guide_photo_guide_section_step2_body3'), t('guide_photo_guide_section_step2_body4')]} />
                    <PhotoGuideStep nr={3} title={t('guide_photo_guide_section_step3_title')} items={[t('guide_photo_guide_section_step3_body1'), t('guide_photo_guide_section_step3_body2'), t('guide_photo_guide_section_step3_body3'), t('guide_photo_guide_section_step3_body4'), t('guide_photo_guide_section_step3_body5')]} />
                    <PhotoGuideStep nr={4} title={t('guide_photo_guide_section_step4_title')} items={[t('guide_photo_guide_section_step4_body1'), t('guide_photo_guide_section_step4_body2'), t('guide_photo_guide_section_step4_body3')]} />
                    <PhotoGuideStep nr={5} title={t('guide_photo_guide_section_step5_title')} items={[t('guide_photo_guide_section_step5_body1'), t('guide_photo_guide_section_step5_body2'), t('guide_photo_guide_section_step5_body3')]} />
                    <PhotoGuideStep nr={6} title={t('guide_photo_guide_section_step6_title')} items={[t('guide_photo_guide_section_step6_body1'), t('guide_photo_guide_section_step6_body2'), t('guide_photo_guide_section_step6_body3')]} />
                    <PhotoGuideStep nr={7} title={t('guide_photo_guide_section_step7_title')} items={[t('guide_photo_guide_section_step7_body1'), t('guide_photo_guide_section_step7_body2')]} />
                    <PhotoGuideStep nr={8} title={t('guide_photo_guide_section_step8_title')} items={[t('guide_photo_guide_section_step8_body1'), t('guide_photo_guide_section_step8_body2')]} />
                    <PhotoGuideStep nr={9} title={t('guide_photo_guide_section_step9_title')} items={[t('guide_photo_guide_section_step9_body1'), t('guide_photo_guide_section_step9_body2')]} />
                </Section>
            </Section>

            <div className="photo-guide-selling-section">
                <div className="selling-text">{t('guide_photo_guide_section_selling_text')}</div>
                <Button
                    title={t('guide_photo_guide_section_selling_button')}
                    onClick={() => {
                        if (user) {
                            if (user.address == null) {
                                navigate('/my-profile')
                            } else {
                                dispatch(ResetCarForm({}))
                                dispatch(UpdateAddCarTab({ tab: 'cardetails' }))
                                dispatch(UpdateMenu({ menu: Menu.SellACar }))
                                navigate('/add-car')
                            }
                        } else {
                            dispatch(ShowLoginModal({ visible: true, carId: null }))
                        }
                    }}
                />
            </div>
        </GuideBasePage>
    )
}
