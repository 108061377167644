import { store } from '../../redux'
import { QuestionsDTO } from '../../dto'
import { QuestionsFailed, QuestionsSuccess } from '../../redux/actions'
import { NetworkCall } from '../../utils'

export const getQuestions = async (carId: number, reset: boolean = false) => {
    const page = reset ? 1 : store.getState().questions.pagination.page
    const pageSize = store.getState().questions.pagination.pageSize

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('GET', `/1.0.0/questions?page=${page}&pageSize=${pageSize}&carId=${carId}`)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(200, QuestionsDTO, (responseBody) => {
            store.dispatch(QuestionsSuccess({ questions: responseBody.data, page: responseBody.page, pageSize: responseBody.pageSize, count: responseBody.count, reset: reset }))
        })
        .catch((response) => {
            store.dispatch(QuestionsFailed({}))
        })
        .call()
}
