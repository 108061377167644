import { Expose, Type } from 'class-transformer'
import { IsDefined, ValidateNested } from 'class-validator'
import { ErrorDTO } from '../error.dto'

export class AuctionErrorDTO {
    @Expose()
    @IsDefined()
    userId!: number

    @Expose()
    @IsDefined()
    auctionId!: number

    @Expose()
    @ValidateNested()
    @Type(() => ErrorDTO)
    error!: ErrorDTO
}
