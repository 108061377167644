import { store } from '../../redux'
import { ErrorDTO, MakesDTO } from '../../dto'
import { ErrorMessage } from '../../redux/actions'
import { NetworkCall } from '../../utils'
import { GetMakeAndModelsSuccess } from '../../redux/actions/car'

export const getMakesAndModels = async () => {
    return await new NetworkCall('GET', `/1.0.0/makes`)
        .then(200, MakesDTO, (responseBody) => {
            store.dispatch(GetMakeAndModelsSuccess(responseBody))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
