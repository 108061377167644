import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from '../buttons/button'
import { useState } from 'react'
import { NewsletterModal } from '../modals'
import { useDispatch } from 'react-redux'
import { UpdateCurrentSection, UpdateHowItWorksSection, UpdateMenu } from '../../redux/actions'
import { AboutUsSection, HowItWorksSection, Menu } from '../../enum'
import './footer.scss'

interface FooterProps {}

export const Footer = (props: FooterProps) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [newsletterModalVisible, setNewsletterModalVisible] = useState(false)

    return (
        <footer className={`footer`}>
            <section className={`footer__info`}>
                <div className="logo-div">
                    <Link to="/" onClick={() => dispatch(UpdateMenu({ menu: Menu.Auctions }))}>
                        <img alt="Logo" className="logo-img" src="/images/logo.svg" />
                    </Link>

                    <a href="https://www.instagram.com/lot.number.one" target="_blank" rel="noreferrer">
                        <img alt="social icon" className="instagram-img" src="/images/ic_instagram.svg" />
                    </a>
                </div>

                <div className="menu-div">
                    <ul className="menu-bar">
                        <li className="menu-bar-item">
                            <Link
                                className={`title title--bold`}
                                to={'why-choose'}
                                onClick={() => {
                                    dispatch(UpdateCurrentSection({ currentSection: AboutUsSection.AboutUs }))
                                    dispatch(UpdateMenu({ menu: Menu.AboutUs }))
                                }}
                            >
                                {t('footer_about_us_button')}
                            </Link>
                        </li>

                        <li className="menu-bar-item">
                            <Link
                                className={`title`}
                                to={'how-it-works'}
                                onClick={() => {
                                    dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.HowItWorks }))
                                    dispatch(UpdateMenu({ menu: Menu.HowItWorks }))
                                }}
                            >
                                {t('footer_how_its_work_button')}
                            </Link>
                        </li>

                        <li className="menu-bar-item">
                            <Link
                                className={`title`}
                                to={'how-it-works'}
                                onClick={() => {
                                    dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.BuyingCar }))
                                    dispatch(UpdateMenu({ menu: Menu.HowItWorks }))
                                }}
                            >
                                {t('footer_buying_an_electric_car_button')}
                            </Link>
                        </li>

                        <li className="menu-bar-item">
                            <Link
                                className={`title`}
                                to={'how-it-works'}
                                onClick={() => {
                                    dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.SellingCar }))
                                    dispatch(UpdateMenu({ menu: Menu.HowItWorks }))
                                }}
                            >
                                {t('footer_selling_an_electric_car_button')}
                            </Link>
                        </li>
                    </ul>

                    <ul className="menu-bar">
                        <li className="menu-bar-item">
                            <Link className={`title title--bold`} to={'/'} onClick={() => dispatch(UpdateMenu({ menu: Menu.Links }))}>
                                {t('footer_helpful_links_button')}
                            </Link>
                        </li>

                        <li className="menu-bar-item">
                            <Link className={`title`} to={'why-go-electric'} onClick={() => dispatch(UpdateMenu({ menu: Menu.WhyGoElectric }))}>
                                {t('footer_why_go_electric_button')}
                            </Link>
                        </li>

                        <li className="menu-bar-item">
                            <Link className={`title`} to={'guide'} onClick={() => dispatch(UpdateMenu({ menu: Menu.Guide }))}>
                                {t('footer_photo_video_guide_button')}
                            </Link>
                        </li>

                        <li className="menu-bar-item" onClick={() => dispatch(UpdateMenu({ menu: Menu.FAQ }))}>
                            <Link className={`title`} to={'faqs'}>
                                {t('footer_faqs_button')}
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className={`newsletter-div ${i18n.language.includes('en') ? `` : `margin-top`}`}>
                    <h3>{t('footer_newsletter_title')}</h3>
                    <label className="small_text">{t('footer_newsletter_description')}</label>

                    <Button title={t('footer_join_button')} onClick={() => setNewsletterModalVisible(!newsletterModalVisible)} />
                </div>

                <div className={`newsletter-div ${i18n.language.includes('en') ? `` : `margin-top`}`}>
                    <h3>{t('footer_contact_us_title')}</h3>
                    <label className="small_text">{t('footer_contact_us_description')}</label>

                    <Button
                        type={'black'}
                        title={t('footer_contact_us_button')}
                        onClick={() => {
                            dispatch(UpdateMenu({ menu: Menu.Contacts }))
                            navigate('/contact-us')
                        }}
                    />
                </div>
            </section>

            <section className={`footer__bottom`}>
                <Link className="footer__bottom__copyright" to="/" onClick={() => dispatch(UpdateMenu({ menu: Menu.Auctions }))}>
                    <div className="footer__bottom__copyright" dangerouslySetInnerHTML={{ __html: t('footer_copyright') }} />
                </Link>

                <ul className="footer__bottom__menu-bar">
                    <li className="menu-bar-item" onClick={() => dispatch(UpdateMenu({ menu: Menu.Terms }))}>
                        <Link className={`title`} to={'terms'}>
                            {t('footer_terms_button')}
                        </Link>
                    </li>

                    <li className="menu-bar-item" onClick={() => dispatch(UpdateMenu({ menu: Menu.Impressum }))}>
                        <Link className={`title`} to={'impressum'}>
                            {t('footer_impressum_button')}
                        </Link>
                    </li>

                    {/* <li className="menu-bar-item">
                        <Link className={`title`} to={'cookies'}>
                            {t('footer_cookies_button')}
                        </Link>
                    </li> */}

                    <li className="menu-bar-item">
                        <Link className={`title`} to={'privacy-policy'} onClick={() => dispatch(UpdateMenu({ menu: Menu.Privacy }))}>
                            {t('footer_privacy_policy_button')}
                        </Link>
                    </li>
                </ul>

                <div className="footer__bottom__made-in-div">
                    <a className="made-in-label" href="https://www.swisstomato.ch" target="_blank" rel="noreferrer">
                        {t('footer_made_in_title')}
                    </a>

                    <img alt="CH icon" className="ch-icon" src="/images/ic_ch.svg" />
                </div>
            </section>

            {newsletterModalVisible ? (
                <NewsletterModal
                    onClose={() => {
                        setNewsletterModalVisible(!newsletterModalVisible)
                    }}
                />
            ) : null}
        </footer>
    )
}
