import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRef } from 'react'
import { useWindowDimensions } from '../../../../utils'
import './ev-database-table.scss'

type PageChangeFunction = (page: number) => void

interface EvDatabaseTableProps {
    dataset: any[]
    pagination?: { page: number; pageSize: number; count: number; onPageChange: PageChangeFunction }
}

export const EvDatabaseTable = (props: EvDatabaseTableProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const scrollRef = useRef<HTMLDivElement>(null)
    const { width } = useWindowDimensions()

    const _getCurrentPageRowCount = () => {
        if (!props.pagination) {
            return 0
        }

        const pageCount = Math.floor(props.pagination.count / props.pagination.pageSize) + (props.pagination.count % props.pagination.pageSize > 0 ? 1 : 0)

        if (props.pagination.page < pageCount) {
            return props.pagination.pageSize
        } else {
            return props.pagination.count % props.pagination.pageSize
        }
    }

    const _getNameColumnValue = (row: any): string => {
        let valueParts = []

        if (row.make) {
            valueParts.push(`${row.make}`)
        }

        if (row.model) {
            valueParts.push(`${row.model}`)
        }

        if (row.modelVersion) {
            valueParts.push(`${row.modelVersion}`)
        }

        return valueParts.join(' ')
    }

    const onPageScroll = (page: number) => {
        if (scrollRef.current) {
            const element = document.getElementById('' + page)
            if (!element) return

            scrollRef.current.scrollLeft = element.offsetLeft - scrollRef.current.offsetLeft
        }
    }

    return (
        <div className="ev-database-table">
            <div className="ev-database-table__content">
                <div className="ev-database-table__title">{t('ev_database_table_title', { current: _getCurrentPageRowCount(), total: props.pagination?.count })}</div>
                <div className="ev-database-table__content__body">
                    {props.dataset?.length === 0 ? <div className="ev-database-table__content__body__empty">{t('ev_database_body_empty')}</div> : null}
                    {props.dataset?.map((row, index) => (
                        <>
                            <div key={index} className="ev-database-table__content__body__row">
                                <div key={`cars.name[${index}]`} className={'ev-database-table__content__body__col ev-database-table__content__body__col--name'}>
                                    <div className="ev-database-table__content__body__col__value">{_getNameColumnValue(row)}</div>
                                </div>
                                <div key={`cars.batteryCapacity[${index}]`} className={'ev-database-table__content__body__col ev-database-table__content__body__col--battery-capacity'}>
                                    <div className="ev-database-table__content__body__col__title">{t('ev_database_table_battery_capacity')}</div>
                                    <div className="ev-database-table__content__body__col__value">
                                        {row.batteryCapacity} <span className="ev-database-table__content__body__col__unit">kWh</span>
                                    </div>
                                </div>
                                <div key={`cars.range[${index}]`} className={'ev-database-table__content__body__col ev-database-table__content__body__col--range'}>
                                    <div className="ev-database-table__content__body__col__title">{t('ev_database_table_range')}</div>
                                    <div className="ev-database-table__content__body__col__value">
                                        {row.rangeWLTP && row.rangeWLTPTEH && (
                                            <>
                                                {`${row.rangeWLTPTEH} - ${row.rangeWLTP}`} <span className="ev-database-table__content__body__col__unit">km</span>
                                            </>
                                        )}
                                        {row.rangeWLTP && !row.rangeWLTPTEH && (
                                            <>
                                                {`${row.rangeWLTP}`} <span className="ev-database-table__content__body__col__unit">km</span>
                                            </>
                                        )}
                                        {!row.rangeWLTP && row.rangeWLTPTEH && (
                                            <>
                                                {`${row.rangeWLTPTEH}`} <span className="ev-database-table__content__body__col__unit">km</span>
                                            </>
                                        )}
                                        {!row.rangeWLTP && !row.rangeWLTPTEH && <>{'-'}</>}
                                    </div>
                                </div>
                                <div key={`cars.efficiency[${index}]`} className={'ev-database-table__content__body__col ev-database-table__content__body__col--efficiency'}>
                                    <div className="ev-database-table__content__body__col__title">{t('ev_database_table_efficiency')}</div>
                                    <div className="ev-database-table__content__body__col__value">
                                        {`${row.efficiency ?? '-'}`} <span className="ev-database-table__content__body__col__unit">Wh/km</span>
                                    </div>
                                </div>
                                <div key={`cars.fastChargeSpeed[${index}]`} className={'ev-database-table__content__body__col ev-database-table__content__body__col--fast-charge-speed'}>
                                    <div className="ev-database-table__content__body__col__title">{t('ev_database_table_fastcharge')}</div>
                                    <div className="ev-database-table__content__body__col__value">
                                        {row.fastChargeSpeed && (
                                            <>
                                                {`${row.fastChargeSpeed}`} <span className="ev-database-table__content__body__col__unit">km/h</span>
                                            </>
                                        )}
                                        {!row.fastChargeSpeed && <>{'-'}</>}
                                    </div>
                                </div>
                                <div key={`cars.readMore[${index}]`} className={'ev-database-table__content__body__col ev-database-table__content__body__col--read-more'}>
                                    <div
                                        className="ev-database-table__content__body__col--read-more--link"
                                        onClick={async () => {
                                            navigate(`/electric-car-database/${row.id}`)
                                        }}
                                    >
                                        <span className="ev-database-table__content__body__col--read-more--link--text">{t('ev_database_table_read_more')}</span>
                                        <img className="ev-database-table__content__body__col--read-more--link--img" alt="" src="/images/ev-database/ic_more_arrow.svg" />
                                    </div>
                                </div>
                            </div>
                            <div className="ev-database-table__content__body__separator"></div>
                        </>
                    ))}
                </div>
            </div>
            <div className="ev-database-table__pagination">
                <div className="ev-database-table__pagination__pager">
                    <button
                        disabled={(props.pagination?.page ?? 1) === 1}
                        onClick={(e) => {
                            onPageScroll(props.pagination ? props.pagination?.page - 1 : 0)
                            props.pagination?.onPageChange(props.pagination?.page - 1)
                        }}
                    >
                        <img src="/images/ev-database/ic_left_icon.svg" />
                    </button>

                    <div className="inner-scroll" ref={scrollRef}>
                        {(() => {
                            const rows = []

                            const page = (props.pagination?.count ?? 1) / (props.pagination?.pageSize ?? 1)

                            for (let i = 0; i < page; i++) {
                                rows.push(
                                    <button
                                        id={'' + (i + 1)}
                                        className={(props.pagination?.page ?? 1) === i + 1 ? 'selected' : ''}
                                        onClick={(e) => {
                                            onPageScroll(i + 1)
                                            props.pagination?.onPageChange(i + 1)
                                        }}
                                    >
                                        {i + 1}
                                    </button>
                                )
                            }

                            return rows
                        })()}
                    </div>

                    <button
                        disabled={(props.pagination?.page ?? 1) >= (props.pagination?.count ?? 1) / (props.pagination?.pageSize ?? 1)}
                        onClick={(e) => {
                            onPageScroll(props.pagination ? props.pagination?.page + 1 : 0)
                            props.pagination?.onPageChange(props.pagination?.page + 1)
                        }}
                    >
                        <img src="/images/ev-database/ic_right_icon.svg" />
                    </button>
                </div>
            </div>
        </div>
    )
}
