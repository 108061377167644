import { Expose } from 'class-transformer'
import { IsDefined } from 'class-validator'

export class StripeErrorDTO {
    @Expose()
    @IsDefined()
    code!: number

    @Expose()
    @IsDefined()
    message!: string

    @Expose()
    @IsDefined()
    additionalData!: AdditionalDataDTO[]
}

export class AdditionalDataDTO {
    @Expose()
    @IsDefined()
    key!: string

    @Expose()
    value!: any
}
