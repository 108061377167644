import { store } from '../../redux'
import { ErrorDTO, EvDatabaseCarDTO } from '../../dto'
import { ErrorMessage, GetEvDatabaseCarSuccess, GetEvDatabaseCarFailed } from '../../redux/actions'
import { NetworkCall } from '../../utils'

export const getEvDatabaseCar = async (id: number) => {
    return await new NetworkCall('GET', `/1.0.0/ev-database/cars/${id}`)
        .then(200, EvDatabaseCarDTO, (responseBody: any) => {
            store.dispatch(GetEvDatabaseCarSuccess(responseBody))
        })
        .catch((response) => {
            store.dispatch(GetEvDatabaseCarFailed({}))
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
