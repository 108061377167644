import { store } from '../../redux'
import { ErrorDTO, EvDatabaseCarsDTO } from '../../dto'
import { ErrorMessage, EvDatabaseCarsFailed, EvDatabaseCarsSuccess } from '../../redux/actions'
import { NetworkCall } from '../../utils'

const queryBuilder = (): string => {
    let query = ``

    const make = store.getState().evDatabase.make !== undefined ? store.getState().evDatabase.make : undefined
    const model = store.getState().evDatabase.model !== undefined ? store.getState().evDatabase.model : undefined
    const minRange = store.getState().evDatabase.minRange !== undefined ? store.getState().evDatabase.minRange : undefined
    const maxRange = store.getState().evDatabase.maxRange !== undefined ? store.getState().evDatabase.maxRange : undefined
    const minCapacity = store.getState().evDatabase.minCapacity !== undefined ? store.getState().evDatabase.minCapacity : undefined
    const maxCapacity = store.getState().evDatabase.maxCapacity !== undefined ? store.getState().evDatabase.maxCapacity : undefined
    const chargePlug = store.getState().evDatabase.chargePlug !== undefined ? store.getState().evDatabase.chargePlug : undefined

    if (make) query += make !== undefined ? `&make=${make}` : ``
    if (model) query += model !== undefined ? `&model=${model}` : ``
    if (minRange) query += minRange !== undefined ? `&minRange=${minRange}` : ``
    if (maxRange) query += maxRange !== undefined ? `&maxRange=${maxRange}` : ``
    if (minCapacity) query += minCapacity !== undefined ? `&minCapacity=${minCapacity}` : ``
    if (maxCapacity) query += maxCapacity !== undefined ? `&maxCapacity=${maxCapacity}` : ``
    if (chargePlug) query += chargePlug !== undefined ? `&chargePlug=${chargePlug}` : ``

    return query
}

export const getEvDatabaseCars = async (reset: boolean = false) => {
    const page = reset ? 1 : store.getState().evDatabase.pagination.page
    const pageSize = store.getState().evDatabase.pagination.pageSize

    return await new NetworkCall('GET', `/1.0.0/ev-database/cars/?page=${page}&pageSize=${pageSize}${queryBuilder()}`)
        .then(200, EvDatabaseCarsDTO, (responseBody: any) => {
            store.dispatch(EvDatabaseCarsSuccess({ cars: responseBody.data, page: responseBody.page, pageSize: responseBody.pageSize, count: responseBody.count, reset: reset }))
        })
        .catch((response) => {
            store.dispatch(EvDatabaseCarsFailed({}))
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
