import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../redux'
import { UpdateMenu } from '../../../../redux/actions'
import { Menu } from '../../../../enum'
import './user-side-menu.scss'

interface UserSideMenuProps {}

export const UserSideMenu = (props: UserSideMenuProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const menu = useAppSelector((state) => state.base.menu)
    const user = useAppSelector((state) => state.login.user)

    return (
        <div className={`user-side-menu`}>
            <div className={`user-side-menu__profile-div`}>
                <img className={`profile-img`} alt="Profile icon" src="/images/ic_user.svg" />

                <div className={`username-div`}>
                    <label className={`username`}>{user && user.username ? user.username : ''}</label>
                    <label className={`address`}>{user && user.address ? user.address.postalCode + ' ' + user.address.city : ''}</label>
                </div>
            </div>

            <div className={`user-side-menu__divider-div`} />

            <ul className="user-side-menu__menu-div">
                <li
                    className={`menu-item`}
                    onClick={() => {
                        navigate('/my-profile')
                        dispatch(UpdateMenu({ menu: Menu.MyProfile }))
                    }}
                >
                    <label className={`title ${menu === Menu.MyProfile ? `active` : ``}`}>{t('header_profile_my_profile_button')}</label>
                </li>
                <li
                    className={`menu-item`}
                    onClick={() => {
                        navigate('/my-ev-garage')
                        dispatch(UpdateMenu({ menu: Menu.MyEVGarage }))
                    }}
                >
                    <label className={`title  ${menu === Menu.MyEVGarage ? `active` : ``}`}>{t('header_profile_my_ev_garage_button')}</label>
                </li>
                <li
                    className={`menu-item`}
                    onClick={() => {
                        navigate('/my-activity')
                        dispatch(UpdateMenu({ menu: Menu.MyActivity }))
                    }}
                >
                    <label className={`title  ${menu === Menu.MyActivity ? `active` : ``}`}>{t('header_profile_my_activity_button')}</label>
                </li>
                <li
                    className={`menu-item`}
                    onClick={() => {
                        navigate('/notification-settings')
                        dispatch(UpdateMenu({ menu: Menu.NotificationSettings }))
                    }}
                >
                    <label className={`title  ${menu === Menu.NotificationSettings ? `active` : ``}`}>{t('header_profile_notification_settings_button')}</label>
                </li>
            </ul>
        </div>
    )
}
