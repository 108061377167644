import { useTranslation } from 'react-i18next'
import { InputField, TextField } from '../../components/field'
import { useEffect, useState } from 'react'
import { Button } from '../../components'
import { validateEmail } from '../../utils/validate.util'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../redux'
import { getContactInfo, sendContactUsMessage } from '../../api'
import './contact-us.page.scss'

interface ContactUsPageProps {}

export const ContactUsPage = (props: ContactUsPageProps) => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()

    const { contactInfo } = useAppSelector((state) => state.contactUs)

    const [name, setName] = useState({
        error: null as unknown as string,
        value: '',
    })

    const [email, setEmail] = useState({
        error: null as unknown as string,
        value: '',
    })

    const [message, setMessage] = useState({
        error: '',
        value: '',
    })

    useEffect(() => {
        getContactInfo()

        if (name.error != null && name.error.length !== 0) {
            setName((prevName) => ({ ...prevName, value: prevName.value, error: t('error_contact_us_name_empty') }))
            return
        }
        if (email.error != null && email.error.length !== 0) {
            const emailValidation = validateEmail(email.value)
            if (emailValidation != null) {
                setEmail((prevEmail) => ({ ...prevEmail, value: prevEmail.value, error: t(emailValidation) }))
            }
            return
        }
        if (message.error != null && message.error.length !== 0) {
            setMessage((prevMessage) => ({ ...prevMessage, value: prevMessage.value, error: t('error_contact_us_message_empty') }))
            return
        }
    }, [i18n.language])

    const onSubmitClick = async () => {
        setName((prevName) => ({ ...prevName, value: prevName.value, error: '' }))
        setEmail((prevEmail) => ({ ...prevEmail, value: prevEmail.value, error: '' }))
        setMessage((prevMessage) => ({ ...prevMessage, value: prevMessage.value, error: '' }))
        if (name.value.length === 0) {
            setName((prevName) => ({ ...prevName, value: prevName.value, error: t('error_contact_us_name_empty') }))
            return
        }
        const emailValidation = validateEmail(email.value)
        if (emailValidation != null) {
            setEmail((prevEmail) => ({ ...prevEmail, value: prevEmail.value, error: t(emailValidation) }))
            return
        }
        if (message.value.length === 0) {
            setMessage((prevMessage) => ({ ...prevMessage, value: prevMessage.value, error: t('error_contact_us_message_empty') }))
            return
        }
        await sendContactUsMessage({
            name: name.value,
            message: message.value,
            email: email.value,
            onSuccess() {
                navigate('/')
            },
        })
    }

    return (
        <main className="page page-contact-us">
            <div className="main-div">
                <h2>{t('contact_us_title')}</h2>

                <div className="container-div">
                    <div className="contact-information-div">
                        <h3>{t('contact_us_information_title')}</h3>
                        <div className="company-data-div">
                            <div className="body-text">{t('contact_us_company_title')}</div>
                            <div className="company-location-div">
                                <span className="company-name">{contactInfo?.companyName}</span>
                                <span className="body-text">
                                    {contactInfo?.address?.address}, {contactInfo?.address?.postalCode} {contactInfo?.address?.city}
                                </span>
                                <span className="body-text">
                                    {t('contact_us_email_title')}{' '}
                                    <a className="mail-link" href={`mailto:${contactInfo?.companyEmail}`}>
                                        {contactInfo?.companyEmail}
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="contact-faq-div">
                        <h3>{contactInfo?.title || ``}</h3>
                        <span className="body-text">{contactInfo?.description || ``}</span>
                    </div>

                    <div className="contact-form-container">
                        <h3>{t('contact_us_title')}</h3>

                        <form action="" className="contact-form">
                            <div className="contact-form-user-data">
                                <div className="input-style">
                                    <InputField title={t('contact_us_name_desc')} field={name} onChange={(e) => setName((prevName) => ({ ...prevName, value: e.target.value }))} type="text" />
                                </div>
                                <div className="input-style">
                                    <InputField title={t('signup_email_title')} field={email} onChange={(e) => setEmail((prevName) => ({ ...prevName, value: e.target.value }))} type="email" />
                                </div>
                            </div>
                            <div className="contact-form-message">
                                <TextField title={t('contact_us_message_title')} rows={7} field={message} onChange={(e) => setMessage((prevName) => ({ ...prevName, value: e.target.value }))} />
                            </div>

                            <div className="contact-form-button">
                                <Button title={t('button_submit')} onClick={onSubmitClick} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    )
}
