import { Modal } from '../modal'
import { useTranslation } from 'react-i18next'
import { IconButton } from '../../buttons'
import { Link } from 'react-router-dom'
import './reserve-price.modal.scss'

interface ReservePriceModallProps {
    onClose: () => void
}

export const ReservePriceModal = (props: ReservePriceModallProps) => {
    const { t, i18n } = useTranslation()

    return (
        <Modal title={t('modal_what_is_reserve_price_title')} onCloseClick={props.onClose}>
            <div className="reserve-price-modal">
                <label className="reserve-price-modal__description">{t('modal_what_is_reserve_price_top_desc')}</label>
                <label className="reserve-price-modal__description">{t('modal_what_is_reserve_price_middle_desc')}</label>
                <div className="reserve-price-modal__description" dangerouslySetInnerHTML={{ __html: t('modal_what_is_reserve_price_bottom_desc') }}></div>

                <Link to={`/how-it-works/${i18n.language}`} target="_blank">
                    <IconButton title={t('modal_what_is_reserve_price_learn_more_button')} icon="/images/ic_arrow_right_rounded_black.svg" />
                </Link>
            </div>
        </Modal>
    )
}
