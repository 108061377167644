import { createAction } from '@reduxjs/toolkit'

interface Payload {
    make: string[]
    model: string[]
    year: number[]
    minMileage: number
    maxMileage: number
    count: number | null
}

export const FiltersSuccess = createAction<Payload>('CARS__FILTERS_SUCCESS')
