import { Expose, Type } from 'class-transformer'
import { IsDefined, ValidateNested } from 'class-validator'
import { Auction } from '../../entity'

export class AuctionBidResponseDTO {
    @Expose()
    @IsDefined()
    userId!: number

    @Expose()
    @ValidateNested()
    @Type(() => Auction)
    auction!: Auction
}
