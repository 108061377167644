import { useTranslation } from 'react-i18next'
import { Car } from '../../../../../entity'
import { Blurhash } from 'react-blurhash'
import { useEffect, useState } from 'react'
import { Battery } from '../../../../../components'
import { CarStatusEnum } from '../../../../../enum'
import { DeleteReservePriceModal, EditReservePriceModal, UploadImagesModal } from '../../../../../components/modals'
import { useNavigate } from 'react-router-dom'
import './cars-auctioned-element.scss'

interface CarsAuctionedElementProps {
    car: Car
}

export const CarsAuctionedElement = (props: CarsAuctionedElementProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [imageLoaded, setImageLoaded] = useState(false)

    const [reservePriceModalVisible, setReservePriceModalVisible] = useState(false)
    const [deleteReservePriceModalVisible, setDeleteReservePriceModalVisible] = useState(false)
    const [uploadImagesModalVisible, setUploadImagesModalVisible] = useState(false)

    const [actionEndsInOneDay, setActionEndsInOneDay] = useState(false)

    useEffect(() => {
        const timeDifferenceInMs = new Date(props.car.auctions[0].endDate).getTime() - new Date().getTime()
        const days = Math.floor(timeDifferenceInMs / (24 * 60 * 60 * 1000))

        if (days < 1) {
            setActionEndsInOneDay(true)
        }
    }, [])

    return (
        <li key={props.car.id} className={`cars-auctioned-element`}>
            <div className="cars-auctioned-element__image-div">
                {props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 && <Blurhash className={`blurhash-img ${imageLoaded ? `hidden` : ``}`} hash={props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 ? props.car.mediaFiles[0].blurHash : ''} width={'100%'} height={'100%'} resolutionX={32} resolutionY={32} punch={1} />}

                <img
                    alt="Car"
                    className="car-img"
                    src={props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 ? props.car.mediaFiles[0].thumbnail : '/images/ic_no_image.svg'}
                    onLoad={() => {
                        setImageLoaded(true)
                    }}
                />

                <p className={`status ${props.car.status === CarStatusEnum.Approved ? `` : `hidden`} `}>{t('my_ev_garage_live_auction_title')}</p>

                <div className="add-images-div" onClick={() => setUploadImagesModalVisible(!uploadImagesModalVisible)}>
                    <img alt="Add icon" className="add-img" src="/images/ic_plus.svg" />

                    <p className={`title`}>{t('my_ev_garage_add_images_title')}</p>
                </div>
            </div>

            <div className="cars-auctioned-element__information-div">
                <div className={`name-div`} onClick={(e) => navigate(`/auction/${props.car.id}`)}>
                    <h3 className="name">
                        {props.car?.manufacturingYear} {props.car?.make} {props.car?.model}
                    </h3>
                    <label className="address">
                        {props.car?.address?.postalCode} {props.car?.address?.city}
                    </label>
                </div>

                <div className="price-div">
                    <div className={`bid-div`}>
                        <p className="sub-title">{t('car_card_current_bid_title')}</p>
                        <div className="price-div">
                            <h3 className={`price ${actionEndsInOneDay ? `primary` : ``}`}> {props.car.auctions[0].currentPrice.toLocaleString('de-CH', { minimumFractionDigits: 0 })}</h3>

                            <label className={`currency ${actionEndsInOneDay ? `primary` : ``}`}>{t('car_card_chf_title')}</label>

                            <label className={`currency `}>{'/ ' + props.car.auctions[0].bidCount + ' ' + t('auction_bids_title')}</label>
                        </div>
                    </div>

                    {props.car.reservePrice && (
                        <div className="reserve-price-div">
                            <p className={`sub-title `}>{t('my_ev_garage_edit_reserve_price_title')}</p>

                            <div
                                className="reserve-price-button"
                                onClick={() => {
                                    setReservePriceModalVisible(!reservePriceModalVisible)
                                }}
                            >
                                <img alt="Edit icon" className="edit-img" src="/images/ic_edit_white.svg" />
                                <h3 className={`price `}>{props.car.reservePrice.toLocaleString('de-CH', { minimumFractionDigits: 0 })} </h3>
                                <p className={`currency `}> {t('car_card_chf_title')}</p>
                            </div>
                        </div>
                    )}
                    <div className="time-left-div">
                        <p className={`sub-title ${new Date(props.car.auctions[0].endDate).getTime() - new Date().getTime() < 0 ? 'inactive' : ''}`}>{t('car_card_time_left_title')}</p>

                        <Battery type={'normal'} auction={props.car.auctions[0]} />
                    </div>
                </div>

                <div className="comments-div">
                    <img alt="Comment icon" className="action-img" src="/images/ic_comments.svg" />
                    <label className="label">{props.car.commentCount ?? 0}</label>
                </div>
            </div>

            {reservePriceModalVisible ? (
                <EditReservePriceModal
                    id={props.car.id}
                    currentPrice={props.car.reservePrice ?? 0}
                    onClose={() => {
                        setReservePriceModalVisible(!reservePriceModalVisible)
                    }}
                    onDeleteClick={() => {
                        setReservePriceModalVisible(!reservePriceModalVisible)

                        setDeleteReservePriceModalVisible(!deleteReservePriceModalVisible)
                    }}
                />
            ) : null}

            {deleteReservePriceModalVisible ? (
                <DeleteReservePriceModal
                    id={props.car.id}
                    onClose={() => {
                        setDeleteReservePriceModalVisible(!deleteReservePriceModalVisible)
                    }}
                />
            ) : null}

            {uploadImagesModalVisible ? (
                <UploadImagesModal
                    id={props.car.id}
                    mediaFileCount={props.car.mediaFileCount ?? 0}
                    onClose={() => {
                        setUploadImagesModalVisible(!uploadImagesModalVisible)
                    }}
                />
            ) : null}
        </li>
    )
}
