import { useEffect, useRef, useState, MouseEvent } from 'react'
import './viewpager.scss'

interface ViewPagerProps {
    cards: React.ReactElement[]
}

export const ViewPager = (props: ViewPagerProps) => {
    const cardsRef = useRef<HTMLDivElement>(null)

    const [state, setState] = useState({
        currentIndex: 0,
    })

    const onScroll = () => {
        if (cardsRef.current) {
            const scrollLeft = cardsRef.current.scrollLeft
            const scrollWidth = cardsRef.current.scrollWidth
            const clientWidth = Math.round(cardsRef.current.clientWidth / 3)

            const scrollPercentage = (100 * scrollLeft) / (scrollWidth - clientWidth)
            const onItemPercent = 100 / props.cards.length

            var index = Math.floor((scrollPercentage + onItemPercent) / onItemPercent)
            if (index > props.cards.length) {
                index = props.cards.length
            }

            setState((prevState) => ({ ...prevState, currentIndex: index - 1 }))
        }
    }

    const onDotClick = (position: number) => {
        if (cardsRef.current) {
            cardsRef.current.removeEventListener('scroll', onScroll)

            cardsRef.current.scrollTo((cardsRef.current.clientWidth / 3) * position, 0)
            setState((prevState) => ({ ...prevState, currentIndex: position }))

            cardsRef.current.addEventListener('scroll', onScroll)
        }
    }

    useEffect(() => {
        if (cardsRef.current) {
            cardsRef.current.addEventListener('scroll', onScroll)

            //cardsRef.current.scrollTo(0, 0)
        }
        return () => {
            if (cardsRef.current) {
                cardsRef.current.removeEventListener('scroll', onScroll)
            }
        }
    }, [])

    const onPageLeftClick = () => {
        if (cardsRef.current && state.currentIndex >= 0) {
            onDotClick(state.currentIndex - 1)
        }
    }

    const onPageRightClick = () => {
        if (cardsRef.current && state.currentIndex <= props.cards.length) {
            onDotClick(state.currentIndex + 1)
        }
    }

    let isDown = false
    let startX = 0
    let scrollLeft = 0

    const onMouseDown = (event: MouseEvent) => {
        event.preventDefault()

        isDown = true
        cardsRef.current?.classList.add('active')

        startX = event.pageX - cardsRef.current!!.offsetLeft
        scrollLeft = cardsRef.current!!.scrollLeft
    }

    useEffect(() => {
        cardsRef.current!.scrollLeft = 0;
    }, [cardsRef])

    const onMouseLeave = (event: MouseEvent) => {
        event.preventDefault()
        isDown = false

        cardsRef.current?.classList.remove('active')
    }

    const onMouseUp = (event: MouseEvent) => {
        event.preventDefault()

        isDown = false
        cardsRef.current?.classList.remove('active')
    }

    const onMouseMove = (event: MouseEvent) => {
        if (!isDown) return
        event.preventDefault()

        const x = event.pageX - cardsRef.current!!.offsetLeft
        const walk = (x - startX) * 3 //scroll-fast
        cardsRef.current!!.scrollLeft = scrollLeft - walk
    }

    return (
        <div className="viewpager">
            <div className="viewpager__cards" ref={cardsRef} onMouseDown={onMouseDown} onMouseLeave={onMouseLeave} onMouseMove={onMouseMove} onMouseUp={onMouseUp}>
                {props.cards.map((card, index) => (
                    <div className={`card-item`}>{card}</div>
                ))}
            </div>

            <div className="viewpager__pager">
                <img alt="Left icon" className="pager-img" src="/images/ic_arrow_left_black.svg" onClick={onPageLeftClick} />
                <img alt="Right icon" className="pager-img" src="/images/ic_arrow_right_black.svg" onClick={onPageRightClick} />
            </div>
        </div>
    )
}
