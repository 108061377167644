import { createAction } from '@reduxjs/toolkit'

interface Payload {
    key:
        | 'country'
        | 'postalCode'
        | 'city'
        | 'manufacturingYear'
        | 'make'
        | 'makeOtherEnabled'
        | 'model'
        | 'modelOtherEnabled'
        | 'variant'
        | 'vin'
        | 'batteryCapacity'
        | 'manufacturerEstimatedRange'
        | 'horsepower'
        | 'mileage'
        | 'batteryStatus'
        | 'drivetrain'
        | 'chargingConnectorAc'
        | 'chargingConnectorAcOtherName'
        | 'chargingConnectorDc'
        | 'chargingConnectorDcOtherName'
        | 'maximumAcCharging'
        | 'maximumDcCharging'
        | 'mfkDateYear'
        | 'mfkDateMonth'
        | 'validWarrantyYear'
        | 'validWarrantyMonth'
        | 'hasReservePrice'
        | 'hasReservePriceLocal'
        | 'reservePrice'
        | 'exteriorColour'
        | 'interiorColour'
        | 'headUpDisplay'
        | 'laneAssist'
        | 'threeSixtydegreeCamera'
        | 'parkingAssist'
        | 'rearViewCamera'
        | 'autoParking'
        | 'cruiseControl'
        | 'adaptiveCruiseControl'
        | 'parkingSensors'
        | 'centralDisplay'
        | 'navigationSystem'
        | 'appleCarplay'
        | 'androidAuto'
        | 'bluetoothInterface'
        | 'dabRadio'
        | 'wirelessPhoneCharger'
        | 'usbPorts'
        | 'soundSystem'
        | 'blindSpotMonitor'
        | 'abs'
        | 'esc'
        | 'tyrePressureMonitoringSystem'
        | 'autoEmergencyBreak'
        | 'driverAlertSystem'
        | 'isofix'
        | 'nightVision'
        | 'frontCollisionWarning'
        | 'crossTrafficWarning'
        | 'airbags'
        | 'heatPump'
        | 'rainSensor'
        | 'electricTrunkOpening'
        | 'electricSideMirrors'
        | 'heatedSideMirrors'
        | 'keylessEntry'
        | 'heatedSteeringWheel'
        | 'airConditioning'
        | 'electricSeats'
        | 'heatedSeats'
        | 'cooledSeats'
        | 'ledHeadLights'
        | 'ledRearLights'
        | 'xenonHeadLights'
        | 'xenonRearLights'
        | 'matrixHeadlights'
        | 'corneringLights'
        | 'fogLights'
        | 'ambientLighting'
        | 'tintedWindows'
        | 'skiRack'
        | 'towBar'
        | 'slidingRoof'
        | 'towBar'
        | 'panoramicRoof'
        | 'sportSuspension'
        | 'alloyWheels'
        | 'sportSeats'
        | 'seatMaterial'
        | 'otherEquipment'
        | 'ownersTake'
        | 'ownershipHistory'
        | 'serviceHistoryAndRepairs'
        | 'otherItemsIncluded'
        | 'modifications'
        | 'knownProblemsOrFlaws'
        | 'otherInformation'
        | 'terms'

    value: string | boolean | number | number[]
    error: string | null
}

export const UpdateCarForm = createAction<Payload>('CAR__UPDATE_CAR_FORM')
