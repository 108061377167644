import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../redux'
import { Menu } from '../../../enum'
import { UpdateMenu } from '../../../redux/actions'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../../api/users'
import { useEffect, useRef } from 'react'
import './user-menu.scss'

interface UserMenuProps {
    open: boolean
    onClose: () => void
}

export const UserMenu = (props: UserMenuProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const menu = useAppSelector((state) => state.base.menu)
    const user = useAppSelector((state) => state.login.user)

    const ref = useRef<HTMLDivElement>(null)

    function assertIsNode(e: EventTarget | null): asserts e is Node {
        if (!e || !('nodeType' in e)) {
            throw new Error(`Node expected`)
        }
    }

    useEffect(() => {
        const handleOutSideClick = (e: MouseEvent) => {
            assertIsNode(e.target)

            if (!ref.current?.contains(e.target) && (e.target as Element).className !== 'profile-div' && (e.target as Element).className !== 'profile-img' && (e.target as Element).className !== 'arrow-img') {
                props.onClose()
            }
        }

        if (props.open) {
            window.addEventListener('mousedown', handleOutSideClick)
        }
        return () => {
            window.removeEventListener('mousedown', handleOutSideClick)
        }
    }, [ref, props.open])

    return (
        <div className={`user-menu ${props.open ? `` : `hidden`}`} ref={ref}>
            <div className={`user-menu__profile-div`}>
                <img className={`profile-img`} alt="Profile icon" src="/images/ic_user.svg" />

                <div className={`username-div`}>
                    <label className={`username`}>{user && user.username ? user.username : ''}</label>
                    <label className={`address`}>{user && user.address ? user.address.postalCode + ' ' + user.address.city : ''}</label>
                </div>
            </div>

            <div className={`user-menu__divider-div`} />

            <ul className="user-menu__menu-div">
                <li
                    className={`menu-item`}
                    onClick={() => {
                        navigate('/my-profile')
                        dispatch(UpdateMenu({ menu: Menu.MyProfile }))
                        props.onClose()
                    }}
                >
                    <label className={`title ${menu === Menu.MyProfile ? `active` : ``}`}>{t('header_profile_my_profile_button')}</label>
                </li>
                <li
                    className={`menu-item`}
                    onClick={() => {
                        navigate('/my-ev-garage')
                        dispatch(UpdateMenu({ menu: Menu.MyEVGarage }))
                        props.onClose()
                    }}
                >
                    <label className={`title  ${menu === Menu.MyEVGarage ? `active` : ``}`}>{t('header_profile_my_ev_garage_button')}</label>
                </li>
                <li
                    className={`menu-item`}
                    onClick={() => {
                        navigate('/my-activity')
                        dispatch(UpdateMenu({ menu: Menu.MyActivity }))
                        props.onClose()
                    }}
                >
                    <label className={`title  ${menu === Menu.MyActivity ? `active` : ``}`}>{t('header_profile_my_activity_button')}</label>
                </li>
                <li
                    className={`menu-item`}
                    onClick={() => {
                        navigate('/notification-settings')
                        dispatch(UpdateMenu({ menu: Menu.NotificationSettings }))
                        props.onClose()
                    }}
                >
                    <label className={`title  ${menu === Menu.NotificationSettings ? `active` : ``}`}>{t('header_profile_notification_settings_button')}</label>
                </li>
                <li
                    className={`menu-item`}
                    onClick={() => {
                        dispatch(UpdateMenu({ menu: Menu.Auctions }))
                        logout()
                        navigate(`/`)
                        props.onClose()
                    }}
                >
                    <label className={`title`}>{t('header_profile_log_out_button')}</label>
                    <img className={`logout-img`} alt="Logout icon" src="/images/ic_logout.svg" />
                </li>
            </ul>
        </div>
    )
}
