import { useTranslation } from 'react-i18next'
import './steps-section-body.scss'
import { SectionBody } from '../section-body'
import { StepRow } from './step-row'
import { Step, StepAlign } from './step'

interface StepsSectionBodyProps {
    additionalClassNames?: string
}

export const StepsSectionBody = (props: StepsSectionBodyProps) => {
    const { t } = useTranslation()

    return (
        <SectionBody>
            <div className="steps-body-container">
                <div className="steps-body">
                    <StepRow>
                        <Step nr={'1'} title={t('how_it_works_steps_left1')} align={StepAlign.left}></Step>
                        <Step nr={'1'} title={t('how_it_works_steps_right1')} align={StepAlign.right}></Step>
                    </StepRow>
                    <StepRow>
                        <Step nr={'2'} title={t('how_it_works_steps_left2')} align={StepAlign.left}></Step>
                        <Step nr={'2'} title={t('how_it_works_steps_right2')} align={StepAlign.right}></Step>
                    </StepRow>
                    <StepRow>
                        <Step nr={'3'} title={t('how_it_works_steps_left3')} align={StepAlign.left}></Step>
                        <Step nr={'3'} title={t('how_it_works_steps_right3')} align={StepAlign.right}></Step>
                    </StepRow>
                    <StepRow>
                        <Step nr={'4'} title={t('how_it_works_steps_middle4')} align={StepAlign.middle}></Step>
                    </StepRow>
                    <StepRow>
                        <Step nr={'5'} title={t('how_it_works_steps_middle5')} align={StepAlign.middle}></Step>
                    </StepRow>
                </div>
            </div>
        </SectionBody>
    )
}
