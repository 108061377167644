import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { InputField, TextField } from '../../../../components/field'
import { UpdateCarForm } from '../../../../redux/actions'
import { useAppSelector } from '../../../../redux'
import { Checkbox, Dropdown, MultiSelectDropdown } from '../../../../components'
import { AirConditioningEnum, ElectricSeatsEnum, FrontAndRearEnum, SeatMaterialEnum } from '../../../../enum'
import { AirbagEnum } from '../../../../enum/airbag.enum'
import { USBPortEnum } from '../../../../enum/usb-ports.enum'
import './equipment.tab.scss'

interface EquipmentTabProps {}

export const EquipmentTab = (props: EquipmentTabProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { form } = useAppSelector((state) => state.car)

    const getFrontAndRearArray = () => {
        const items: [{ id: number; value: string }] = [{ id: -1, value: t('add_car_choose_title') }]
        items.push({ id: FrontAndRearEnum.Front, value: t('add_car_front_title') })
        items.push({ id: FrontAndRearEnum.Rear, value: t('add_car_rear_title') })
        items.push({ id: FrontAndRearEnum.FrontAndRear, value: t('add_car_front_and_rear_title') })
        items.shift()
        return items
    }

    const getAirbagArray = () => {
        const items: [{ id: number; value: string }] = [{ id: -1, value: t('add_car_choose_title') }]
        items.push({ id: AirbagEnum.Driver, value: t('add_car_airbag_driver_title') })
        items.push({ id: AirbagEnum.Front, value: t('add_car_airbag_front_title') })
        items.push({ id: AirbagEnum.SecondRow, value: t('add_car_airbag_second_row_title') })
        items.push({ id: AirbagEnum.ThirdRow, value: t('add_car_airbag_third_row_title') })
        items.push({ id: AirbagEnum.Knee, value: t('add_car_airbag_knee_title') })
        items.push({ id: AirbagEnum.Side, value: t('add_car_airbag_side_title') })
        items.push({ id: AirbagEnum.Curtain, value: t('add_car_airbag_curtain_title') })
        items.push({ id: AirbagEnum.RearCurtain, value: t('add_car_airbag_rear_curtain_title') })
        items.push({ id: AirbagEnum.Pedestrian, value: t('add_car_airbag_pedestrian_title') })
        items.push({ id: AirbagEnum.SeatBelt, value: t('add_car_airbag_seat_belt_title') })
        items.shift()
        return items
    }

    const getAirconditioningArray = () => {
        const items: [{ id: number; value: string }] = [{ id: -1, value: t('add_car_choose_title') }]
        items.push({ id: AirConditioningEnum.TwoZone, value: t('add_car_two_zone_title') })
        items.push({ id: AirConditioningEnum.ThreeZone, value: t('add_car_three_zone_title') })
        items.push({ id: AirConditioningEnum.FourZone, value: t('add_car_four_zone_title') })
        items.shift()
        return items
    }

    const getElectricSeatArray = () => {
        const items: [{ id: number; value: string }] = [{ id: -1, value: t('add_car_choose_title') }]
        items.push({ id: ElectricSeatsEnum.Driver, value: t('add_car_driver_title') })
        items.push({ id: ElectricSeatsEnum.Passenger, value: t('add_car_passenger_title') })
        items.push({ id: ElectricSeatsEnum.DriverAndPassenger, value: t('add_car_driver_and_passenger_title') })
        items.shift()
        return items
    }

    const getSeatMaterialArray = () => {
        const items: [{ id: number; value: string }] = [{ id: -1, value: t('add_car_choose_title') }]
        items.push({ id: SeatMaterialEnum.Leather, value: t('add_car_leather_title') })
        items.push({ id: SeatMaterialEnum.Alcantara, value: t('add_car_alcantara_title') })
        items.push({ id: SeatMaterialEnum.Suede, value: t('add_car_suede_title') })
        items.push({ id: SeatMaterialEnum.Vegan, value: t('add_car_vegan_title') })
        items.push({ id: SeatMaterialEnum.LeatherTissue, value: t('add_car_leather_tissue_title') })
        items.shift()
        return items
    }

    const getUSBPortsArray = () => {
        const items: [{ id: number; value: string }] = [{ id: -1, value: t('add_car_choose_title') }]
        items.push({ id: USBPortEnum.TypeA, value: t('add_car_usb_type_a_title') })
        items.push({ id: USBPortEnum.TypeC, value: t('add_car_usb_type_c_title') })
        items.push({ id: USBPortEnum.MultipleType, value: t('add_car_usb_multiple_type_title') })
        items.shift()
        return items
    }

    return (
        <main className="tab tab-equipment">
            <div className="tab-div">
                <h2>{t('add_car_title')}</h2>

                <h4>{t('add_car_equipment_title')}</h4>

                <div className="container-div">
                    <label>{t('add_car_equipment_desc')}</label>

                    <section className="row-div">
                        <InputField type="text" maxLength={50} description={t('add_car_color_desc')} field={form.exteriorColour} onChange={(e) => dispatch(UpdateCarForm({ key: 'exteriorColour', value: e.target.value, error: null }))} title={t('add_car_exterior_colour_title')} placeholder={t('')} />
                        <InputField type="text" maxLength={50} description={t('add_car_color_desc')} field={form.interiorColour} onChange={(e) => dispatch(UpdateCarForm({ key: 'interiorColour', value: e.target.value, error: null }))} title={t('add_car_interior_colour_title')} placeholder={t('')} />
                    </section>

                    <section className="section-div">
                        <h5>{t('add_car_driver_assistance_title')}</h5>

                        <div className="checkboxes-div">
                            <Checkbox error={''} checked={form.headUpDisplay.value} id="headUpDisplay" title={t('add_car_head_up_display_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'headUpDisplay', value: !form.headUpDisplay.value, error: null }))} />
                            <Checkbox error={''} checked={form.laneAssist.value} id="laneAssist" title={t('add_car_lane_assist_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'laneAssist', value: !form.laneAssist.value, error: null }))} />
                            <Checkbox error={''} checked={form.threeSixtydegreeCamera.value} id="threeSixtydegreeCamera" title={t('add_car_threesixty_degree_camera_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'threeSixtydegreeCamera', value: !form.threeSixtydegreeCamera.value, error: null }))} />
                            <Checkbox error={''} checked={form.parkingAssist.value} id="parkingAssist" title={t('add_car_parking_assist_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'parkingAssist', value: !form.parkingAssist.value, error: null }))} />
                            <Checkbox error={''} checked={form.rearViewCamera.value} id="rearViewCamera" title={t('add_car_rear_view_camera_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'rearViewCamera', value: !form.rearViewCamera.value, error: null }))} />
                            <Checkbox error={''} checked={form.autoParking.value} id="autoParking" title={t('add_car_auto_parking_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'autoParking', value: !form.autoParking.value, error: null }))} />
                            <Checkbox error={''} checked={form.cruiseControl.value} id="cruiseControl" title={t('add_car_cruise_control_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'cruiseControl', value: !form.cruiseControl.value, error: null }))} />
                            <Checkbox error={''} checked={form.adaptiveCruiseControl.value} id="adaptiveCruiseControl" title={t('add_car_adaptive_cruise_control_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'adaptiveCruiseControl', value: !form.adaptiveCruiseControl.value, error: null }))} />
                        </div>

                        <div className="parking-sensors-div">
                            <label>{t('add_car_parking_sensors_title')}</label>
                            <Dropdown
                                deleteEnabled={true}
                                selectItemTitle={t('add_car_choose_title')}
                                value={form.parkingSensors.value}
                                items={getFrontAndRearArray()}
                                error={''}
                                onChange={async (id: string) => {
                                    dispatch(UpdateCarForm({ key: 'parkingSensors', value: id, error: null }))
                                }}
                            />
                        </div>
                    </section>

                    <section className="section-div">
                        <h5>{t('add_car_infotainment_and_electronics_title')}</h5>

                        <div className="checkboxes-div">
                            <Checkbox error={''} checked={form.centralDisplay.value} id="centralDisplay" title={t('add_car_central_display_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'centralDisplay', value: !form.centralDisplay.value, error: null }))} />
                            <Checkbox error={''} checked={form.navigationSystem.value} id="navigationSystem" title={t('add_car_navigation_system_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'navigationSystem', value: !form.navigationSystem.value, error: null }))} />
                            <Checkbox error={''} checked={form.appleCarplay.value} id="appleCarplay" title={t('add_car_apple_carplay_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'appleCarplay', value: !form.appleCarplay.value, error: null }))} />
                            <Checkbox error={''} checked={form.androidAuto.value} id="androidAuto" title={t('add_car_android_auto_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'androidAuto', value: !form.androidAuto.value, error: null }))} />
                            <Checkbox error={''} checked={form.bluetoothInterface.value} id="bluetoothInterface" title={t('add_car_bluetooth_interface_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'bluetoothInterface', value: !form.bluetoothInterface.value, error: null }))} />
                            <Checkbox error={''} checked={form.dabRadio.value} id="dabRadio" title={t('add_car_dab_radio_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'dabRadio', value: !form.dabRadio.value, error: null }))} />
                            <Checkbox error={''} checked={form.wirelessPhoneCharger.value} id="wirelessPhoneCharger" title={t('add_car_wireless_phone_charger_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'wirelessPhoneCharger', value: !form.wirelessPhoneCharger.value, error: null }))} />
                            <Checkbox error={''} checked={form.soundSystem.value} id="soundSystem" title={t('add_car_sound_system_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'soundSystem', value: !form.soundSystem.value, error: null }))} />
                        </div>

                        <div className="usb-div">
                            <label>{t('add_car_usb_ports_title')}</label>
                            <Dropdown
                                deleteEnabled={true}
                                selectItemTitle={t('add_car_choose_title')}
                                value={form.usbPorts.value}
                                items={getUSBPortsArray()}
                                error={''}
                                onChange={async (id: string) => {
                                    dispatch(UpdateCarForm({ key: 'usbPorts', value: id, error: null }))
                                }}
                            />
                        </div>
                    </section>

                    <section className="section-div">
                        <h5>{t('add_car_safety_title')}</h5>

                        <div className="checkboxes-div">
                            <Checkbox error={''} checked={form.blindSpotMonitor.value} id="blindSpotMonitor" title={t('add_car_blind_spot_monitor_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'blindSpotMonitor', value: !form.blindSpotMonitor.value, error: null }))} />
                            <Checkbox error={''} checked={form.abs.value} id="abs" title={t('add_car_abs_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'abs', value: !form.abs.value, error: null }))} />
                            <Checkbox error={''} checked={form.esc.value} id="esc" title={t('add_car_esc_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'esc', value: !form.esc.value, error: null }))} />
                            <Checkbox error={''} checked={form.tyrePressureMonitoringSystem.value} id="tyrePressureMonitoringSystem" title={t('add_car_tyre_pressure_system_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'tyrePressureMonitoringSystem', value: !form.tyrePressureMonitoringSystem.value, error: null }))} />
                            <Checkbox error={''} checked={form.autoEmergencyBreak.value} id="autoEmergencyBreak" title={t('add_car_auto_emergency_break_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'autoEmergencyBreak', value: !form.autoEmergencyBreak.value, error: null }))} />
                            <Checkbox error={''} checked={form.driverAlertSystem.value} id="driverAlertSystem" title={t('add_car_driver_alert_system_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'driverAlertSystem', value: !form.driverAlertSystem.value, error: null }))} />
                            <Checkbox error={''} checked={form.isofix.value} id="isofix" title={t('add_car_isofix_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'isofix', value: !form.isofix.value, error: null }))} />
                            <Checkbox error={''} checked={form.nightVision.value} id="nightVision" title={t('add_car_night_vision_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'nightVision', value: !form.nightVision.value, error: null }))} />
                            <Checkbox error={''} checked={form.frontCollisionWarning.value} id="frontCollisionWarning" title={t('add_car_front_collision_warning_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'frontCollisionWarning', value: !form.frontCollisionWarning.value, error: null }))} />
                            <Checkbox error={''} checked={form.crossTrafficWarning.value} id="crossTrafficWarning" title={t('add_car_cross_traffic_warning_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'crossTrafficWarning', value: !form.crossTrafficWarning.value, error: null }))} />
                        </div>

                        <div className="airbags-div">
                            <div className="airbags-inner-div">
                                <label>{t('add_car_airbags_title')}</label>
                                <MultiSelectDropdown
                                    deleteEnabled={true}
                                    selectItemTitle={t('add_car_choose_title')}
                                    values={form.airbags.value}
                                    items={getAirbagArray()}
                                    error={''}
                                    onChange={(ids: number[]) => {
                                        dispatch(UpdateCarForm({ key: 'airbags', value: ids, error: null }))
                                    }}
                                />
                            </div>
                            <label>{t('add_car_multiple_title')}</label>
                        </div>
                    </section>

                    <section className="section-div">
                        <h5>{t('add_car_comfort_title')}</h5>

                        <div className="checkboxes-div">
                            <Checkbox error={''} checked={form.heatPump.value} id="heatPump" title={t('add_car_heat_pump_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'heatPump', value: !form.heatPump.value, error: null }))} />
                            <Checkbox error={''} checked={form.rainSensor.value} id="rainSensor" title={t('add_car_rain_sensor_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'rainSensor', value: !form.rainSensor.value, error: null }))} />
                            <Checkbox error={''} checked={form.electricTrunkOpening.value} id="electricTrunkOpening" title={t('add_car_electric_trunk_opening_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'electricTrunkOpening', value: !form.electricTrunkOpening.value, error: null }))} />
                            <Checkbox error={''} checked={form.electricSideMirrors.value} id="electricSideMirrors" title={t('add_car_electric_side_mirrors_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'electricSideMirrors', value: !form.electricSideMirrors.value, error: null }))} />
                            <Checkbox error={''} checked={form.heatedSideMirrors.value} id="heatedSideMirrors" title={t('add_car_heated_side_mirrors_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'heatedSideMirrors', value: !form.heatedSideMirrors.value, error: null }))} />
                            <Checkbox error={''} checked={form.keylessEntry.value} id="keylessEntry" title={t('add_car_keyless_entry_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'keylessEntry', value: !form.keylessEntry.value, error: null }))} />
                            <Checkbox error={''} checked={form.heatedSteeringWheel.value} id="heatedSteeringWheel" title={t('add_car_heated_steering_wheel_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'heatedSteeringWheel', value: !form.heatedSteeringWheel.value, error: null }))} />
                        </div>

                        <div className="air-conditioning-div">
                            <label>{t('add_car_air_conditioningl_title')}</label>
                            <Dropdown
                                deleteEnabled={true}
                                selectItemTitle={t('add_car_choose_title')}
                                value={form.airConditioning.value}
                                items={getAirconditioningArray()}
                                error={''}
                                onChange={async (id: string) => {
                                    dispatch(UpdateCarForm({ key: 'airConditioning', value: id, error: null }))
                                }}
                            />
                        </div>

                        <div className="seats-div">
                            <label>{t('add_car_electric_seats_title')}</label>
                            <Dropdown
                                deleteEnabled={true}
                                selectItemTitle={t('add_car_choose_title')}
                                value={form.electricSeats.value}
                                items={getElectricSeatArray()}
                                error={''}
                                onChange={async (id: string) => {
                                    dispatch(UpdateCarForm({ key: 'electricSeats', value: id, error: null }))
                                }}
                            />
                        </div>

                        <div className="seats-div">
                            <label>{t('add_car_heated_seats_title')}</label>
                            <Dropdown
                                deleteEnabled={true}
                                selectItemTitle={t('add_car_choose_title')}
                                value={form.heatedSeats.value}
                                items={getFrontAndRearArray()}
                                error={''}
                                onChange={async (id: string) => {
                                    dispatch(UpdateCarForm({ key: 'heatedSeats', value: id, error: null }))
                                }}
                            />
                        </div>

                        <div className="seats-div">
                            <label>{t('add_car_cooled_seats_title')}</label>
                            <Dropdown
                                deleteEnabled={true}
                                selectItemTitle={t('add_car_choose_title')}
                                value={form.cooledSeats.value}
                                items={getFrontAndRearArray()}
                                error={''}
                                onChange={async (id: string) => {
                                    dispatch(UpdateCarForm({ key: 'cooledSeats', value: id, error: null }))
                                }}
                            />
                        </div>
                    </section>

                    <section className="section-div">
                        <h5>{t('add_car_lights_title')}</h5>

                        <div className="checkboxes-div">
                            <Checkbox error={''} checked={form.ledHeadLights.value} id="ledHeadLights" title={t('add_car_led_headlights_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'ledHeadLights', value: !form.ledHeadLights.value, error: null }))} />
                            <Checkbox error={''} checked={form.ledRearLights.value} id="ledRearLights" title={t('add_car_led_rear_lights_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'ledRearLights', value: !form.ledRearLights.value, error: null }))} />
                            <Checkbox error={''} checked={form.xenonHeadLights.value} id="xenonHeadLights" title={t('add_car_xenon_headlights_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'xenonHeadLights', value: !form.xenonHeadLights.value, error: null }))} />
                            <Checkbox error={''} checked={form.xenonRearLights.value} id="xenonRearLights" title={t('add_car_xenon_rear_lights_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'xenonRearLights', value: !form.xenonRearLights.value, error: null }))} />
                            <Checkbox error={''} checked={form.matrixHeadlights.value} id="matrixHeadlights" title={t('add_car_matrix_headlights_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'matrixHeadlights', value: !form.matrixHeadlights.value, error: null }))} />
                            <Checkbox error={''} checked={form.corneringLights.value} id="corneringLights" title={t('add_car_cornering_lights_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'corneringLights', value: !form.corneringLights.value, error: null }))} />
                            <Checkbox error={''} checked={form.fogLights.value} id="fogLights" title={t('add_car_fog_lights_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'fogLights', value: !form.fogLights.value, error: null }))} />
                            <Checkbox error={''} checked={form.ambientLighting.value} id="ambientLighting" title={t('add_car_ambient_lighting_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'ambientLighting', value: !form.ambientLighting.value, error: null }))} />
                        </div>
                    </section>

                    <section className="section-div">
                        <h5>{t('add_car_extras_title')}</h5>

                        <div className="checkboxes-div">
                            <Checkbox error={''} checked={form.tintedWindows.value} id="tintedWindows" title={t('add_car_tinted_windows_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'tintedWindows', value: !form.tintedWindows.value, error: null }))} />
                            <Checkbox error={''} checked={form.skiRack.value} id="skiRack" title={t('add_car_ski_rack_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'skiRack', value: !form.skiRack.value, error: null }))} />
                            <Checkbox error={''} checked={form.towBar.value} id="towBar" title={t('add_car_tow_bar_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'towBar', value: !form.towBar.value, error: null }))} />
                            <Checkbox error={''} checked={form.slidingRoof.value} id="slidingRoof" title={t('add_car_sliding_roof_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'slidingRoof', value: !form.slidingRoof.value, error: null }))} />
                            <Checkbox error={''} checked={form.panoramicRoof.value} id="panoramicRoof" title={t('add_car_panoramic_roof_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'panoramicRoof', value: !form.panoramicRoof.value, error: null }))} />
                            <Checkbox error={''} checked={form.sportSuspension.value} id="sportSeats" title={t('add_car_sport_suspension_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'sportSuspension', value: !form.sportSuspension.value, error: null }))} />
                            <Checkbox error={''} checked={form.alloyWheels.value} id="alloyWheels" title={t('add_car_alloy_wheels_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'alloyWheels', value: !form.alloyWheels.value, error: null }))} />
                            <Checkbox error={''} checked={form.sportSeats.value} id="sportSeats" title={t('add_car_sport_seats_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'sportSeats', value: !form.sportSeats.value, error: null }))} />
                        </div>

                        <div className="seats-div">
                            <label>{t('add_car_seat_material_title')}</label>
                            <Dropdown
                                deleteEnabled={true}
                                selectItemTitle={t('add_car_choose_title')}
                                value={form.seatMaterial.value}
                                items={getSeatMaterialArray()}
                                error={''}
                                onChange={async (id: string) => {
                                    dispatch(UpdateCarForm({ key: 'seatMaterial', value: id, error: null }))
                                }}
                            />
                        </div>
                    </section>

                    <TextField field={form.otherEquipment} description={t('add_car_other_equipment_desc')} maxLength={300} title={t('add_car_other_equipment_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'otherEquipment', value: e.target.value, error: null }))} placeholder={t('add_car_feel_free_to_add_title')} />
                </div>
            </div>
        </main>
    )
}
