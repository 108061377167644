import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { EvDatabaseCar } from '../../entity'

export class EvDatabaseCarDTO {
    @Expose()
    @ValidateNested()
    @Type(() => EvDatabaseCar)
    car!: EvDatabaseCar
}
