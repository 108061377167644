import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { Make } from '../../entity'

export class MakesDTO {
    @Expose()
    @ValidateNested()
    @Type(() => Make)
    makes!: Make[]
}
