import { ChangeEvent, useState } from 'react'
import { Modal } from '../modal'
import { Button } from '../../buttons'
import { useTranslation } from 'react-i18next'
import { DragAnddrop } from '../../../pages/add-car/components/photos-tab/components/drag-and-drop/drag-and-drop'
import { updateReservePriceAndMediaFiles, uplpoadMediaFile } from '../../../api'
import { MediaFileTypeEnum } from '../../../enum'
import { MediaFile } from '../../../entity'
import { PhotoItem } from '../../../pages/add-car/components/photos-tab/components/photo-item/photo-item'
import { LinearProgress } from '../../progress'
import './upload-images.modal.scss'

interface UploadImagesModalModalProps {
    id: number
    mediaFileCount: number
    onClose: () => void
}

export const UploadImagesModal = (props: UploadImagesModalModalProps) => {
    const { t } = useTranslation()

    const [progressVisible, setProgressVisible] = useState(false)
    const [mediaFiles, setMediaFiles] = useState<MediaFile[]>([])
    const [mediaFileIds, setMediaFilesIds] = useState<number[]>([])

    const MAX_PHOTO_COUNT = 50

    const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files
        if (selectedFiles && selectedFiles.length > 0) {
            setProgressVisible(true)

            await uplpoadMediaFile({
                files: Array.from(selectedFiles),
                onSuccess(mediaFileArray: MediaFile[]) {
                    setMediaFiles([...mediaFiles, ...mediaFileArray])

                    mediaFileArray.map((mediaFile) => {
                        setMediaFilesIds((prevState) => [...prevState, mediaFile.id])
                    })

                    setProgressVisible(false)
                },
                onError() {
                    setProgressVisible(false)
                },
            })
        }
    }

    const onSubmitClick = async () => {
        if (mediaFiles.length !== 0 && mediaFiles.length + props.mediaFileCount <= 50) {
            await updateReservePriceAndMediaFiles({
                id: props.id,
                reservePrice: undefined,
                mediaFileIds: mediaFileIds,
                onSuccess() {
                    props.onClose()
                },
            })
        }
    }

    return (
        <Modal title={t('modal_upload_images_title')} onCloseClick={props.onClose}>
            <div className="upload-images-modal">
                <div className="containere-div">
                    <DragAnddrop
                        onFilesSelected={async (files: File[]) => {
                            setProgressVisible(true)

                            await uplpoadMediaFile({
                                files: files,
                                onSuccess(mediaFileArray: MediaFile[]) {
                                    setMediaFiles([...mediaFiles, ...mediaFileArray])

                                    mediaFileArray.map((mediaFile) => {
                                        setMediaFilesIds([...mediaFileIds, mediaFile.id])
                                    })

                                    setProgressVisible(false)
                                },
                                onError() {
                                    setProgressVisible(false)
                                },
                            })
                        }}
                        disabled={mediaFiles.filter((mediaFile) => mediaFile.type === MediaFileTypeEnum.Photo).length < MAX_PHOTO_COUNT}
                        innnerElement={
                            <div className={`upload-div`}>
                                <div className="drop-div">
                                    <img alt="Upload icon" className="upload-img" src="/images/ic_upload.svg" />
                                </div>
                                <label>{t('add_car_drop_images_title')}</label>
                                <label>{t('add_car_or_title')}</label>

                                <input type="file" hidden id="browse-photo" onChange={onFileChange} accept=".jpg, .jpeg, .png, .heic, .heif" multiple />

                                <label htmlFor="browse-photo" className="browse-button">
                                    {t('button_browse')}
                                </label>

                                <label className="type">{'JPG, JPEG, PNG, HEIC, HEIF'}</label>
                            </div>
                        }
                    />

                    {progressVisible && (
                        <div className={`progress-div`}>
                            <label>{t('add_car_uploading_title')}</label>
                            <LinearProgress percent={10} />
                        </div>
                    )}

                    {mediaFiles.length > 0 && mediaFiles.filter((mediaFile) => mediaFile.type === MediaFileTypeEnum.Photo) && (
                        <div className={`photos-div`}>
                            <div className="photos-grid">
                                {mediaFiles.map((mediaFile: MediaFile, index: number) =>
                                    mediaFile.type === MediaFileTypeEnum.Photo ? (
                                        <PhotoItem
                                            index={index}
                                            mediaFile={mediaFile}
                                            isVideo={true}
                                            onRemoveFileClick={(mediaFileId: number) => {
                                                setMediaFiles(mediaFiles.filter((mediaFile) => mediaFile.id !== mediaFileId))
                                                setMediaFilesIds(mediaFileIds.filter((mId) => mId !== mediaFileId))
                                            }}
                                        />
                                    ) : null
                                )}
                            </div>

                            <div className="image-count">
                                <p>
                                    {t('add_car_uploaded_images_title')} {mediaFiles.filter((mediaFile) => mediaFile.type === MediaFileTypeEnum.Photo).length + props.mediaFileCount}/{MAX_PHOTO_COUNT}
                                </p>
                            </div>
                        </div>
                    )}
                </div>

                <div className="button-div">
                    <Button default={true} title={t('button_cancel')} onClick={props.onClose} />
                    <Button title={t('button_submit')} onClick={onSubmitClick} />
                </div>
            </div>
        </Modal>
    )
}
