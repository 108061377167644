import { createReducer } from '@reduxjs/toolkit'
import { CarsState } from '../../states'
import { AuctionsTab } from '../../../enum'
import { CarsFailed, CarsSuccess, CarsPagination, FiltersFailed, FiltersSuccess, UpdateCarsParams } from '../../actions'

const initialState: CarsState = {
    cars: [],
    isLoading: false,
    pagination: {
        page: 1,
        pageSize: 6,
        count: 0,
    },
    search: '',
    make: null,
    model: null,
    startYear: null,
    endYear: null,
    minMileage: null,
    maxMileage: null,
    batteryCapacity: null,
    tab: AuctionsTab.EndingSoon,

    filters: {
        makeArray: [],
        modelArray: [],
        yearArray: [],
        minMileageFilter: 0,
        maxMileageFilter: 0,
        count: null,
    },
}

export const carsReducer = createReducer(initialState, (builder) => {
    builder.addCase(CarsPagination, (state, action) => {
        state.isLoading = true
        state.pagination.page = state.pagination.page + 1
    })
    builder.addCase(CarsSuccess, (state, action) => {
        state.isLoading = false

        if (action.payload.reset) {
            state.cars = []
        }
        state.cars = [...state.cars, ...action.payload.cars]
        state.pagination.page = action.payload.page
        state.pagination.pageSize = action.payload.pageSize
        state.pagination.count = action.payload.count
    })
    builder.addCase(CarsFailed, (state) => {
        state.isLoading = false

        // state.form.email.error = ''
        // state.form.password.error = t('login-page--invalid-username-or-password')
    })
    builder.addCase(UpdateCarsParams, (state, action) => {
        // state.cars = []
        // state.pagination.page = 1

        if (action.payload.tab !== undefined) state.tab = action.payload.tab
        if (action.payload.search !== undefined) state.search = action.payload.search
        if (action.payload.make !== undefined) state.make = action.payload.make
        if (action.payload.model !== undefined) state.model = action.payload.model
        if (action.payload.startYear !== undefined) state.startYear = action.payload.startYear
        if (action.payload.endYear !== undefined) state.endYear = action.payload.endYear
        if (action.payload.minMileage !== undefined) state.minMileage = action.payload.minMileage
        if (action.payload.maxMileage !== undefined) state.maxMileage = action.payload.maxMileage
        if (action.payload.batteryCapacity !== undefined) state.batteryCapacity = action.payload.batteryCapacity
    })

    builder.addCase(FiltersSuccess, (state, action) => {
        state.filters.makeArray = action.payload.make
        state.filters.modelArray = action.payload.model
        state.filters.yearArray = action.payload.year
        state.filters.minMileageFilter = action.payload.minMileage
        state.filters.maxMileageFilter = action.payload.maxMileage
        state.filters.count = action.payload.count
    })

    builder.addCase(FiltersFailed, (state, action) => {})
})
