import { createAction } from '@reduxjs/toolkit'
import { Auction } from '../../../entity'

interface Payload {
    auctions: Auction[]
    page: number
    pageSize: number
    count: number
    reset: boolean
}

export const CarsWonSuccess = createAction<Payload>('USER__CARS_WON_SUCCESS')
