import { createAction } from '@reduxjs/toolkit'

interface Payload {
    make: string[]
    model: string[]
    minRange: number
    maxRange: number
    minBatteryCapacity: number
    maxBatteryCapacity: number
    chargePlug: string[]
}

export const EvDatabaseFiltersSuccess = createAction<Payload>('EV_DATABASE_CARS__FILTERS_SUCCESS')
