import { useTranslation } from 'react-i18next'
import { Toggle } from '../../components'
import { useDispatch } from 'react-redux'
import { ShowToast } from '../../redux/actions'
import { useAppSelector } from '../../redux'
import { UserSideMenu } from '../my-profile/components/user-side-menu/user-side-menu'
import { modifyNotificationSettings } from '../../api/users'
import './notification-settings.page.scss'

interface NotificationSettingsPageProps {}

export const NotificationSettingsPage = (props: NotificationSettingsPageProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const notificationSettings = useAppSelector((state) => state.user.notificationSettings)

    const onSaveClick = async (oneDayBeforeAuctionEnds: boolean, oneHourBeforeAuctionEnds: boolean, newBids: boolean, replyToMyComment: boolean, questionsAnswered: boolean, auctionResults: boolean, reservePriceLowered: boolean, reservePriceDeleted: boolean) => {
        await modifyNotificationSettings({
            oneDayBeforeAuctionEnds: oneDayBeforeAuctionEnds,
            oneHourBeforeAuctionEnds: oneHourBeforeAuctionEnds,
            newBids: newBids,
            replyToMyComment: replyToMyComment,
            questionsAnswered: questionsAnswered,
            auctionResults: auctionResults,
            reservePriceLowered: reservePriceLowered,
            reservePriceDeleted: reservePriceDeleted,
            onSuccess() {
                dispatch(ShowToast({ message: 'toast_notification_settings_success' }))
            },
        })
    }

    return (
        <main className="page page-notification-setttings">
            <div className="main-div">
                <UserSideMenu />
                <div className="settings-div">
                    <h2>{t('header_profile_notification_settings_button')} </h2>

                    <div className="container-div">
                        <h4>{t('notifications_settings_subtitle')} </h4>

                        <div className="item-div">
                            <Toggle
                                enabled={notificationSettings.oneDayBeforeAuctionEnds}
                                onChange={(enabled: boolean) => {
                                    onSaveClick(enabled, notificationSettings.oneHourBeforeAuctionEnds, notificationSettings.newBids, notificationSettings.replyToMyComment, notificationSettings.questionsAnswered, notificationSettings.auctionResults, notificationSettings.reservePriceLowered, notificationSettings.reservePriceDeleted)
                                }}
                            />
                            <label className="body-text">{t('notifications_settings_one_day_before_auction_ends_title')} </label>
                        </div>

                        <div className="item-div">
                            <Toggle
                                enabled={notificationSettings.oneHourBeforeAuctionEnds}
                                onChange={(enabled: boolean) => {
                                    onSaveClick(notificationSettings.oneDayBeforeAuctionEnds, enabled, notificationSettings.newBids, notificationSettings.replyToMyComment, notificationSettings.questionsAnswered, notificationSettings.auctionResults, notificationSettings.reservePriceLowered, notificationSettings.reservePriceDeleted)
                                }}
                            />
                            <label className="body-text">{t('notifications_settings_one_hour_before_auction_ends_title')} </label>
                        </div>
                        <div className="item-div">
                            <Toggle
                                enabled={notificationSettings.newBids}
                                onChange={(enabled: boolean) => {
                                    onSaveClick(notificationSettings.oneDayBeforeAuctionEnds, notificationSettings.oneHourBeforeAuctionEnds, enabled, notificationSettings.replyToMyComment, notificationSettings.questionsAnswered, notificationSettings.auctionResults, notificationSettings.reservePriceLowered, notificationSettings.reservePriceDeleted)
                                }}
                            />
                            <label className="body-text">{t('notifications_settings_when_there_are_new_bids_title')} </label>
                        </div>
                        <div className="item-div">
                            <Toggle
                                enabled={notificationSettings.replyToMyComment}
                                onChange={(enabled: boolean) => {
                                    onSaveClick(notificationSettings.oneDayBeforeAuctionEnds, notificationSettings.oneHourBeforeAuctionEnds, notificationSettings.newBids, enabled, notificationSettings.questionsAnswered, notificationSettings.auctionResults, notificationSettings.reservePriceLowered, notificationSettings.reservePriceDeleted)
                                }}
                            />
                            <label className="body-text">{t('notifications_settings_when_there_is_a_reply_to_my_comment_title')} </label>
                        </div>
                        <div className="item-div">
                            <Toggle
                                enabled={notificationSettings.questionsAnswered}
                                onChange={(enabled: boolean) => {
                                    onSaveClick(notificationSettings.oneDayBeforeAuctionEnds, notificationSettings.oneHourBeforeAuctionEnds, notificationSettings.newBids, notificationSettings.replyToMyComment, enabled, notificationSettings.auctionResults, notificationSettings.reservePriceLowered, notificationSettings.reservePriceDeleted)
                                }}
                            />
                            <label className="body-text">{t('notifications_settings_when_my_questions_are_answered_title')} </label>
                        </div>
                        <div className="item-div">
                            <Toggle
                                enabled={notificationSettings.auctionResults}
                                onChange={(enabled: boolean) => {
                                    onSaveClick(notificationSettings.oneDayBeforeAuctionEnds, notificationSettings.oneHourBeforeAuctionEnds, notificationSettings.newBids, notificationSettings.replyToMyComment, notificationSettings.questionsAnswered, enabled, notificationSettings.reservePriceLowered, notificationSettings.reservePriceDeleted)
                                }}
                            />
                            <label className="body-text">{t('notifications_settings_with_the_auction_results_title')} </label>
                        </div>
                        <div className="item-div">
                            <Toggle
                                enabled={notificationSettings.reservePriceLowered}
                                onChange={(enabled: boolean) => {
                                    onSaveClick(notificationSettings.oneDayBeforeAuctionEnds, notificationSettings.oneHourBeforeAuctionEnds, notificationSettings.newBids, notificationSettings.replyToMyComment, notificationSettings.questionsAnswered, notificationSettings.auctionResults, enabled, notificationSettings.reservePriceDeleted)
                                }}
                            />
                            <label className="body-text">{t('notifications_settings_reserve_price_lowered_title')} </label>
                        </div>
                        <div className="item-div">
                            <Toggle
                                enabled={notificationSettings.reservePriceDeleted}
                                onChange={(enabled: boolean) => {
                                    onSaveClick(notificationSettings.oneDayBeforeAuctionEnds, notificationSettings.oneHourBeforeAuctionEnds, notificationSettings.newBids, notificationSettings.replyToMyComment, notificationSettings.questionsAnswered, notificationSettings.auctionResults, notificationSettings.reservePriceLowered, enabled)
                                }}
                            />
                            <label className="body-text">{t('notifications_settings_reserve_price_deleted_title')} </label>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
