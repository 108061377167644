import { Children, PropsWithChildren } from 'react'
import './step-row.scss'

interface StepRowProps {
    additionalClassNames?: string
}

export const StepRow = (props: PropsWithChildren<StepRowProps>) => {
    return <div className={'step-row'}>{props.children && props.children}</div>
}
