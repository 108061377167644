import { createReducer } from '@reduxjs/toolkit'
import { FaqsState } from '../../states'
import { GetFaqsSuccess } from '../../actions'
import { UpdateCurrentSection } from '../../actions/faqs/update-current-section.action'

const initialState: FaqsState = {
    faqs: [],
    currentSection: 0,
}

export const faqsReducer = createReducer(initialState, (builder) => {
    builder.addCase(GetFaqsSuccess, (state, action) => {
        state.faqs = action.payload.faqs
    })
    builder.addCase(UpdateCurrentSection, (state, action) => {
        state.currentSection = action.payload.currentSection
    })
})
