import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../redux'
import { CommentHistoryElement } from './comment-history-element'
import { Button, LoadMoreButton } from '../../../../components'
import { CommentHistoryPagination } from '../../../../redux/actions'
import { commentHistory } from '../../../../api'
import './comment-history.tab.scss'

interface CommentHistoryTabProps {}

export const CommentHistoryTab = (props: CommentHistoryTabProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { t } = useTranslation()
    const { cars, pagination } = useAppSelector((state) => state.user.commentHistory)

    const onLoadMoreClick = async () => {
        dispatch(CommentHistoryPagination({}))
        await commentHistory(null)
    }

    return (
        <main className="tab tab-comment-history">
            <div className="tab-div">
                <div className="inner-container-div">
                    <label className={`title`}>{t('auction_comments_title') + ' (' + pagination.count + ')'}</label>
                    <div className="divider" />
                    <ul className={`comment-list ${pagination.count == 0 ? `hidden` : ``}`}>
                        {cars.map((car, index) => (
                            <CommentHistoryElement car={car} />
                        ))}
                    </ul>

                    <div className={`empty-list-div ${pagination.count != 0 ? `hidden` : ``}`}>
                        <label>{t('my_activity_comment_history_empty_title')}</label>

                        <Button
                            title={t('button_go_to_auctions')}
                            onClick={async () => {
                                navigate('/')
                            }}
                        />
                    </div>
                </div>

                <div className={`bottom-div ${pagination.count <= pagination.pageSize * pagination.page ? `hidden` : ``}`}>
                    <LoadMoreButton onClick={onLoadMoreClick} />
                </div>
            </div>
        </main>
    )
}
