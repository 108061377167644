import { useTranslation } from 'react-i18next'
import { Document } from '../../../../entity'
import './document-element.scss'

interface DocumentElementProps {
    document: Document
}

export const DocumentElement = (props: DocumentElementProps) => {
    const { t } = useTranslation()

    return (
        <div className="document-element">
            <img alt="Document icon" className="document-img" src="/images/ic_document.svg" />
            <label className="name">{props.document.name}</label>

            <a className="download" href={props.document.url} target="_blank" download>
                {t('button_download')}
            </a>
        </div>
    )
}
