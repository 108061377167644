import { Expose, Type } from 'class-transformer'
import { IsDefined, IsString, ValidateNested } from 'class-validator'

export class Make {
    @Expose()
    @IsDefined()
    @IsString()
    name!: string

    @Expose()
    @IsDefined()
    models!: string[]
}
