import { createReducer } from '@reduxjs/toolkit'
import { NotificationsState } from '../../states'
import { ListNotifications } from '../../actions'
import { NewNotification } from '../../actions/notification'

const initialState: NotificationsState = {
    isNewNotification: false,
    notifications: [],
    pagination: {
        page: 1,
        pageSize: 10,
        count: 0,
    },
}

export const notificationReducer = createReducer(initialState, (builder) => {
    builder.addCase(NewNotification, (state, action) => {
        state.isNewNotification = action.payload.isNew
    })

    builder.addCase(ListNotifications, (state, action) => {
        if (action.payload.page === 1) {
            state.notifications = action.payload.data
        } else {
            state.notifications.push(...action.payload.data)
        }
        state.pagination.page = action.payload.page
        state.pagination.pageSize = action.payload.pageSize
        state.pagination.count = action.payload.count
        state.isNewNotification = false
    })
})
