import { createAction } from '@reduxjs/toolkit'

interface Payload {
    search?: string | null
    tab?: 'endingSoon' | 'newAuction' | 'noReserve' | 'closeToMe' | 'pastAutions' | 'favourite'
    make?: string | null
    model?: string | null
    startYear?: string | null
    endYear?: string | null
    minMileage?: string | null
    maxMileage?: string | null
    batteryCapacity?: number | null
}

export const UpdateCarsParams = createAction<Payload>('CARS__CARS_UPDATE_PARAMS')
