import { Expose, Type } from 'class-transformer'
import { IsDefined, IsNumber, ValidateNested } from 'class-validator'
import { Car } from '../../entity'

export class CarsBoughtDTO {
    @Expose()
    @ValidateNested()
    @Type(() => Car)
    reservePriceNotMetData!: Car[]

    @Expose()
    @ValidateNested()
    @Type(() => Car)
    bidOnData!: Car[]

    @Expose()
    @IsDefined()
    @IsNumber()
    page!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    pageSize!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    bidOnCount!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    notMetCount!: number
}
