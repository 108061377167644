import { ContactInfoDTO, ErrorDTO } from '../../dto'
import { store } from '../../redux'
import { ErrorMessage, GetContactInfoSuccess } from '../../redux/actions'
import { NetworkCall } from '../../utils'

export const getContactInfo = async () => {
    return await new NetworkCall('GET', `/1.0.0/contact-us`)
        .then(200, ContactInfoDTO, (responseBody: any) => {
            store.dispatch(GetContactInfoSuccess(responseBody))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
