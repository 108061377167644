import { store } from '../../redux'
import { EvDatabseFilterDTO } from '../../dto'
import { NetworkCall } from '../../utils'
import { EvDatabaseFiltersFailed, EvDatabaseFiltersSuccess } from '../../redux/actions'

const queryBuilder = (): string => {
    let query = ``

    const make = store.getState().evDatabase.make !== undefined ? store.getState().evDatabase.make : undefined
    const model = store.getState().evDatabase.model !== undefined ? store.getState().evDatabase.model : undefined
    const minRange = store.getState().evDatabase.minRange !== undefined ? store.getState().evDatabase.minRange : undefined
    const maxRange = store.getState().evDatabase.maxRange !== undefined ? store.getState().evDatabase.maxRange : undefined
    const minCapacity = store.getState().evDatabase.minCapacity !== undefined ? store.getState().evDatabase.minCapacity : undefined
    const maxCapacity = store.getState().evDatabase.maxCapacity !== undefined ? store.getState().evDatabase.maxCapacity : undefined
    const chargePlug = store.getState().evDatabase.chargePlug !== undefined ? store.getState().evDatabase.chargePlug : undefined

    if (make) query += make !== undefined ? (query.length === 0 ? `?` : `&`) + `make=${make}` : ``
    if (model) query += model !== undefined ? (query.length === 0 ? `?` : `&`) + `model=${model}` : ``
    if (minRange) query += minRange !== undefined ? (query.length === 0 ? `?` : `&`) + `minRange=${minRange}` : ``
    if (maxRange) query += maxRange !== undefined ? (query.length === 0 ? `?` : `&`) + `maxRange=${maxRange}` : ``
    if (minCapacity) query += minCapacity !== undefined ? (query.length === 0 ? `?` : `&`) + `minMileage=${minCapacity}` : ``
    if (maxCapacity) query += maxCapacity !== undefined ? (query.length === 0 ? `?` : `&`) + `maxCapacity=${maxCapacity}` : ``
    if (chargePlug) query += chargePlug !== undefined ? (query.length === 0 ? `?` : `&`) + `chargePlug=${chargePlug}` : ``

    return query
}

export const getEvDatabaseFilterData = async () => {
    return await new NetworkCall('GET', `/1.0.0/ev-database/filters${queryBuilder()}`)
        .then(200, EvDatabseFilterDTO, (responseBody: any) => {
            store.dispatch(EvDatabaseFiltersSuccess(responseBody))
        })
        .catch((response) => {
            store.dispatch(EvDatabaseFiltersFailed({}))
        })
        .call()
}
