import { store } from '../../redux'
import { CarsDTO, ErrorDTO } from '../../dto'
import { CarsFailed, CarsSuccess, ErrorMessage } from '../../redux/actions'
import { NetworkCall } from '../../utils'

const queryBuilder = (): string => {
    let query = ``

    const make = store.getState().cars.make !== undefined ? store.getState().cars.make : undefined
    const model = store.getState().cars.model !== undefined ? store.getState().cars.model : undefined
    const startYear = store.getState().cars.startYear !== undefined ? store.getState().cars.startYear : undefined
    const endYear = store.getState().cars.endYear !== undefined ? store.getState().cars.endYear : undefined
    const minMileage = store.getState().cars.minMileage
    const maxMileage = store.getState().cars.maxMileage
    const batteryCapacity = store.getState().cars.batteryCapacity !== undefined ? store.getState().cars.batteryCapacity : undefined
    const search = store.getState().cars.search

    if (make) query += make !== undefined ? `&make=${make}` : ``
    if (model) query += model !== undefined ? `&model=${model}` : ``
    if (startYear) query += startYear !== undefined ? `&startYear=${startYear}` : ``
    if (endYear) query += endYear !== undefined ? `&endYear=${endYear}` : ``
    if (minMileage) query += minMileage !== undefined ? `&minMileage=${minMileage}` : ``
    if (maxMileage) query += maxMileage !== undefined ? `&maxMileage=${maxMileage}` : ``
    if (batteryCapacity) query += batteryCapacity !== undefined ? `&batteryCapacity=${batteryCapacity}` : ``
    if (search !== undefined && search !== '') query += `&search=${search}`

    return query
}

export const getCars = async (reset: boolean = false) => {
    const page = reset ? 1 : store.getState().cars.pagination.page
    const pageSize = store.getState().cars.pagination.pageSize
    const tab = store.getState().cars.tab

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('GET', `/1.0.0/cars/?page=${page}&pageSize=${pageSize}&tab=${tab}${queryBuilder()}`)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(200, CarsDTO, (responseBody) => {
            store.dispatch(CarsSuccess({ cars: responseBody.data, page: responseBody.page, pageSize: responseBody.pageSize, count: responseBody.count, reset: reset }))
        })
        .catch((response) => {
            store.dispatch(CarsFailed({}))
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
