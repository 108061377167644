import { ErrorDTO, UserDTO } from '../../dto'
import { store } from '../../redux'
import { ErrorMessage, GetUserFailed, GetUserSuccess } from '../../redux/actions'
import { NetworkCall } from '../../utils'

export const getUser = async (id: number) => {
    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('GET', `/1.0.0/users/${id}`)
        .setHeader('Authorization', `Bearer ${token}`)
        .then(200, UserDTO, (responseBody) => {
            store.dispatch(GetUserSuccess(responseBody))
        })
        .catch((response) => {
            store.dispatch(GetUserFailed({}))
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
