import { useEffect, useRef, useState } from 'react'
import { Modal } from '../modal'
import { Button } from '../../buttons'
import { useTranslation } from 'react-i18next'
import { feeAuthorization, getCarsBought, payBuyersFee } from '../../../api'
import { Car } from '../../../entity'
import { Blurhash } from 'react-blurhash'
import { CarStatusEnum } from '../../../enum'
import { useAppSelector } from '../../../redux'
import './pay-buyers-fee.modal.scss'

interface PayBuyersFeeModalProps {
    car: Car
    onClose: () => void
}

export const PayBuyersFeeModal = (props: PayBuyersFeeModalProps) => {
    const { t } = useTranslation()
    const [imageLoaded, setImageLoaded] = useState(false)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)
    const user = useAppSelector((state) => state.login.user)

    const iframeContainerRef = useRef<HTMLDivElement>(null)
    const iframeRef = useRef<HTMLIFrameElement>(null)

    const [iframeUrl, setIframeUrl] = useState('')
    const [paymentIntentId, setPaymentIntentId] = useState('')
    const [buyersFee, setBuyersFee] = useState(0)
    const [amount, setAmount] = useState(0)

    const [timesRefreshed, setTimesRefreshed] = useState(0)

    useEffect(() => {
        console.log('useEffect', timesRefreshed)

        const interval = setInterval(() => {
            let timeLeft = Math.floor((props.car.updated + 24 * 60 * 60 * 1000 - new Date().getTime()) / 1000)
            setSeconds(timeLeft % 60)
            timeLeft = Math.floor(timeLeft / 60)
            setMinutes(timeLeft % 60)
            timeLeft = Math.floor(timeLeft / 60)
            setHours(timeLeft)
            if (timeLeft <= 0) {
                clearInterval(interval)
            }
        }, 1000)
    }, [])

    const onPayClick = async () => {
        await payBuyersFee({
            auctionId: props.car.auctions[0].id,
            onSuccess() {
                getCarsBought(true)
                props.onClose()
            },
            onErrror(error) {
                //  props.onError(error)

                error.additionalData.map((data) => {
                    if (data.key === 'url') {
                        setIframeUrl(data.value)
                    }
                    if (data.key === 'paymentIntentId') {
                        setPaymentIntentId(data.value)
                    }
                    if (data.key === 'amount') {
                        setAmount(data.value)
                    }
                    if (data.key === 'hold') {
                        setBuyersFee(data.value)
                    }
                })
            },
        })
    }

    const onLoad = async () => {
        if (timesRefreshed === 1) {
            await feeAuthorization({
                auctionId: props.car.auctions[0].id,
                userId: user?.id ?? 0,
                amount: amount,
                buyersFee: buyersFee,
                paymentIntentId: paymentIntentId,
                onSuccess() {
                    getCarsBought(true)

                    props.onClose()
                },
            })

            setIframeUrl('')
        }

        setTimesRefreshed(timesRefreshed + 1)
    }

    return (
        <Modal title={t('modal_pay_buyers_fee_title')} onCloseClick={props.onClose}>
            <div className="pay-buyers-fee-modal">
                <label className="pay-buyers-fee-modal__description">{t('modal_pay_buyers_fee_description')}</label>

                <div className="car-div">
                    <div className="image-div">
                        {props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 && <Blurhash className={`blurhash-img ${imageLoaded ? `hidden` : ``}`} hash={props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 ? props.car.mediaFiles[0].blurHash : ''} width={'100%'} height={'100%'} resolutionX={32} resolutionY={32} punch={1} />}

                        <img
                            alt="Car"
                            className="car-img"
                            src={props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 ? props.car.mediaFiles[0].thumbnail : '/images/ic_no_image.svg'}
                            onLoad={() => {
                                setImageLoaded(true)
                            }}
                        />
                    </div>

                    <div className="information-div">
                        <div className={`name-div`}>
                            <h3 className="name">
                                {props.car?.manufacturingYear} {props.car?.make} {props.car?.model}
                            </h3>
                            <label className="address">
                                {props.car?.address?.postalCode} {props.car?.address?.city}
                            </label>
                        </div>

                        <div className="price-div">
                            <div className={`bid-div`}>
                                <p className="sub-title">{t('car_card_final_price_title')}</p>
                                <div className="price-div">
                                    <h3 className={`price `}> {props.car.auctions[0].currentPrice.toLocaleString('de-CH', { minimumFractionDigits: 0 })}</h3>
                                    <label className={`currency `}>{t('car_card_chf_title')}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="divider-div" />

                <div className="pay-buyers-fee-modal__button-div">
                    {props.car.updated + 24 * 60 * 60 * 1000 - new Date().getTime() > 0 && props.car.status === CarStatusEnum.Sold ? (
                        <div className="time-div">
                            <label className={`time-title`}> {t('car_card_time_left_title')}</label>
                            <label className={`time`}>{`${hours.toString().padStart(2, `0`)}:${minutes.toString().padStart(2, `0`)}:${seconds.toString().padStart(2, `0`)}`}</label>
                        </div>
                    ) : (
                        <div className="time-div">
                            <label className={`time-title`}> {t('car_card_time_left_title')}</label>
                            <label className={`time`}>{`00:00:00`}</label>
                        </div>
                    )}

                    <div className="price-div">
                        <div className={`bid-div`}>
                            <p className="sub-title">{t('my_ev_garage_buyers_fee_title')}</p>
                            <div className="price-div">
                                <h3 className={`price primary ${props.car.status === CarStatusEnum.Unpaid || props.car.status === CarStatusEnum.Overdue ? `red` : ``}`}> {props.car.auctions[0].finalBuyersFee?.toLocaleString('de-CH', { minimumFractionDigits: 0 })}</h3>
                                <label className={`currency primary ${props.car.status === CarStatusEnum.Unpaid || props.car.status === CarStatusEnum.Overdue ? `red` : ``}`}>{t('car_card_chf_title')}</label>
                            </div>
                        </div>
                    </div>

                    {props.car.status === CarStatusEnum.Sold ? (
                        <Button title={t('my_ev_garage_pay_now_button').replace('{amount}', '' + props.car.auctions[0].finalBuyersFee?.toLocaleString('de-CH', { minimumFractionDigits: 0 }))} onClick={onPayClick} />
                    ) : (
                        <Button type="red" title={t('my_ev_garage_pay_now_button').replace('{amount}', '' + props.car.auctions[0].finalBuyersFee?.toLocaleString('de-CH', { minimumFractionDigits: 0 }))} onClick={onPayClick} />
                    )}
                </div>

                {iframeUrl.length !== 0 ? (
                    <>
                        <div className="iframe-container" ref={iframeContainerRef}>
                            <iframe ref={iframeRef} onLoad={onLoad} src={iframeUrl} frameBorder={'0'} title={'redirect_iframe'} />
                        </div>
                    </>
                ) : null}
            </div>
        </Modal>
    )
}
