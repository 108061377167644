import { createReducer } from '@reduxjs/toolkit'
import { UserState } from '../../states'
import {
    BidHistoryFailed,
    BidHistoryPagination,
    BidHistorySuccess,
    CarsAuctionedFailed,
    CarsAuctionedPagination,
    CarsAuctionedSuccess,
    CarsBoughtFailed,
    CarsBoughtPagination,
    CarsBoughtSuccess,
    CarsSoldFailed,
    CarsSoldPagination,
    CarsSoldSuccess,
    CarsWonFailed,
    CarsWonPagination,
    CarsWonSuccess,
    ClearUserProfile,
    CommentHistoryFailed,
    CommentHistoryPagination,
    CommentHistorySuccess,
    GetUserFailed,
    GetUserSuccess,
    LoginSuccess,
    SetUnpaidCars,
    UpdateUserFailed,
    UpdateUserForm,
    UpdateUserModalForm,
    UpdateUserSuccess,
} from '../../actions'
import { RoleEnum } from '../../../enum'
import { UpdateUserNotificationSettings } from '../../actions/user/update-user-notification-settings.action'

const initialState: UserState = {
    form: {
        role: { error: null, value: RoleEnum.Private },
        firstName: { error: null, value: `` },
        lastName: { error: null, value: `` },
        username: { error: null, value: `` },
        companyName: { error: null, value: `` },
        uidNumber: { error: null, value: `` },
        vatNumber: { error: null, value: `` },
        email: { error: null, value: `` },
        english: { error: null, value: false },
        german: { error: null, value: false },
        french: { error: null, value: false },
        italian: { error: null, value: false },
        newsletter: { error: null, value: false },
        country: { error: null, value: `` },
        address: { error: null, value: `` },
        city: { error: null, value: `` },
        postalCode: { error: null, value: `` },
        phoneNumber: { error: null, value: `` },
        termsVersion: { error: null, value: 0 },
        privacyVersion: { error: null, value: 0 },
        cardId: { error: null, value: `` },
    },

    modalForm: {
        country: { error: null, value: `` },
        address: { error: null, value: `` },
        city: { error: null, value: `` },
        postalCode: { error: null, value: `` },
        phoneNumber: { error: null, value: `` },
        cardId: { error: null, value: `` },
    },

    notificationSettings: {
        oneDayBeforeAuctionEnds: false,
        oneHourBeforeAuctionEnds: true,
        newBids: true,
        replyToMyComment: true,
        questionsAnswered: true,
        auctionResults: true,
        reservePriceLowered: true,
        reservePriceDeleted: true,
    },
    bidHistory: {
        auctions: [],
        isLoading: false,
        pagination: {
            page: 1,
            pageSize: 10,
            count: 0,
        },
    },
    carsWon: {
        auctions: [],
        isLoading: false,
        pagination: {
            page: 1,
            pageSize: 10,
            count: 0,
        },
    },
    commentHistory: {
        cars: [],
        isLoading: false,
        pagination: {
            page: 1,
            pageSize: 10,
            count: 0,
        },
    },
    carsAuctioned: {
        cars: [],
        isLoading: false,
        pagination: {
            page: 1,
            pageSize: 12,
            notMetCount: 0,
            draftCount: 0,
            approvedCount: 0,
        },
    },
    carsSold: {
        cars: [],
        isLoading: false,
        pagination: {
            page: 1,
            pageSize: 12,
            didNotSellCount: 0,
            soldCount: 0,
        },
    },
    carsBought: {
        reservePriceNotMetCars: [],
        bidOnCars: [],

        isLoading: false,
        pagination: {
            page: 1,
            pageSize: 12,
            bidOnCount: 0,
            notMetCount: 0,
        },
    },

    userDetail: {
        user: null,
    },

    unpaidCars: [],
}

export const userReducer = createReducer(initialState, (builder) => {
    builder.addCase(UpdateUserSuccess, (state, action) => {
        //state.user = action.payload.user
    })
    builder.addCase(UpdateUserFailed, (state) => {
        // state.form.email.error = ''
        // state.form.password.error = t('login-page--invalid-username-or-password')
    })
    builder.addCase(UpdateUserForm, (state, action) => {
        ;(state.form[action.payload.key] as any) = {
            ...state.form[action.payload.key],
            value: action.payload.value,
            error: action.payload.error,
        }
    })

    builder.addCase(UpdateUserModalForm, (state, action) => {
        ;(state.modalForm[action.payload.key] as any) = {
            ...state.modalForm[action.payload.key],
            value: action.payload.value,
            error: action.payload.error,
        }
    })

    builder.addCase(LoginSuccess, (state, action) => {
        state.form.role.value = action.payload.user.role ?? -1
        state.form.username.value = action.payload.user.username
        state.form.email.value = action.payload.user.email
        state.form.firstName.value = action.payload.user.firstName ?? ''
        state.form.lastName.value = action.payload.user.lastName ?? ''
        state.form.companyName.value = action.payload.user.companyName ?? ''
        state.form.uidNumber.value = action.payload.user.uidNumber ?? ''
        state.form.vatNumber.value = action.payload.user.vatNumber ?? ''
        state.form.english.value = action.payload.user.languages!!.find((lng) => lng.code === 'en') !== undefined
        state.form.german.value = action.payload.user.languages!!.find((lng) => lng.code === 'de') !== undefined
        state.form.italian.value = action.payload.user.languages!!.find((lng) => lng.code === 'it') !== undefined
        state.form.french.value = action.payload.user.languages!!.find((lng) => lng.code === 'fr') !== undefined
        state.form.newsletter.value = action.payload.user.newsletter
        state.form.country.value = action.payload.user.address?.country ?? ''
        state.form.address.value = action.payload.user.address?.address ?? ''
        state.form.city.value = action.payload.user.address?.city ?? ''
        state.form.postalCode.value = action.payload.user.address?.postalCode?.toString() ?? ''
        state.form.phoneNumber.value = action.payload.user.phoneNumber != null ? action.payload.user.phoneNumber.replace('+41', '') : ''
        state.form.vatNumber.value = action.payload.user.vatNumber ?? ''
        state.form.termsVersion.value = action.payload.user.termsVersion ?? -1
        state.form.privacyVersion.value = action.payload.user.privacyVersion ?? -1
        state.form.cardId.value = action.payload.user.stripeCardId ?? ''
    })
    builder.addCase(UpdateUserNotificationSettings, (state, action) => {
        if (action.payload.oneDayBeforeAuctionEnds !== undefined) {
            state.notificationSettings.oneDayBeforeAuctionEnds = action.payload.oneDayBeforeAuctionEnds
        }
        if (action.payload.oneHourBeforeAuctionEnds !== undefined) {
            state.notificationSettings.oneHourBeforeAuctionEnds = action.payload.oneHourBeforeAuctionEnds
        }
        if (action.payload.newBids !== undefined) {
            state.notificationSettings.newBids = action.payload.newBids
        }
        if (action.payload.replyToMyComment !== undefined) {
            state.notificationSettings.replyToMyComment = action.payload.replyToMyComment
        }
        if (action.payload.questionsAnswered !== undefined) {
            state.notificationSettings.questionsAnswered = action.payload.questionsAnswered
        }
        if (action.payload.auctionResults !== undefined) {
            state.notificationSettings.auctionResults = action.payload.auctionResults
        }
        if (action.payload.reservePriceDeleted !== undefined) {
            state.notificationSettings.reservePriceDeleted = action.payload.reservePriceDeleted
        }
        if (action.payload.reservePriceLowered !== undefined) {
            state.notificationSettings.reservePriceLowered = action.payload.reservePriceLowered
        }
    })

    builder.addCase(BidHistoryPagination, (state, action) => {
        state.bidHistory.isLoading = true
        state.bidHistory.pagination.page = state.bidHistory.pagination.page + 1
    })
    builder.addCase(BidHistorySuccess, (state, action) => {
        state.bidHistory.isLoading = false

        if (action.payload.reset) {
            state.bidHistory.auctions = []
        }
        state.bidHistory.auctions = [...state.bidHistory.auctions, ...action.payload.auctions]
        state.bidHistory.pagination.page = action.payload.page
        state.bidHistory.pagination.pageSize = action.payload.pageSize
        state.bidHistory.pagination.count = action.payload.count
    })
    builder.addCase(BidHistoryFailed, (state) => {
        state.bidHistory.isLoading = false
    })

    builder.addCase(CarsWonPagination, (state, action) => {
        state.carsWon.isLoading = true
        state.carsWon.pagination.page = state.carsWon.pagination.page + 1
    })
    builder.addCase(CarsWonSuccess, (state, action) => {
        state.carsWon.isLoading = false

        if (action.payload.reset) {
            state.carsWon.auctions = []
        }
        state.carsWon.auctions = [...state.carsWon.auctions, ...action.payload.auctions]
        state.carsWon.pagination.page = action.payload.page
        state.carsWon.pagination.pageSize = action.payload.pageSize
        state.carsWon.pagination.count = action.payload.count
    })
    builder.addCase(CarsWonFailed, (state) => {
        state.carsWon.isLoading = false
    })

    builder.addCase(CommentHistoryPagination, (state, action) => {
        state.commentHistory.isLoading = true
        state.commentHistory.pagination.page = state.commentHistory.pagination.page + 1
    })
    builder.addCase(CommentHistorySuccess, (state, action) => {
        state.commentHistory.isLoading = false

        if (action.payload.reset) {
            state.commentHistory.cars = []
        }
        state.commentHistory.cars = [...state.commentHistory.cars, ...action.payload.cars]
        state.commentHistory.pagination.page = action.payload.page
        state.commentHistory.pagination.pageSize = action.payload.pageSize
        state.commentHistory.pagination.count = action.payload.count
    })
    builder.addCase(CommentHistoryFailed, (state) => {
        state.commentHistory.isLoading = false
    })

    builder.addCase(CarsAuctionedPagination, (state, action) => {
        state.carsAuctioned.isLoading = true
        state.carsAuctioned.pagination.page = state.carsAuctioned.pagination.page + 1
    })
    builder.addCase(CarsAuctionedSuccess, (state, action) => {
        state.carsAuctioned.isLoading = false

        if (action.payload.reset) {
            state.carsAuctioned.cars = []
        }
        state.carsAuctioned.cars = [...state.carsAuctioned.cars, ...action.payload.cars]
        state.carsAuctioned.pagination.page = action.payload.page
        state.carsAuctioned.pagination.pageSize = action.payload.pageSize
        state.carsAuctioned.pagination.draftCount = action.payload.draftCount
        state.carsAuctioned.pagination.approvedCount = action.payload.approvedCount
        state.carsAuctioned.pagination.notMetCount = action.payload.notMetCount
    })
    builder.addCase(CarsAuctionedFailed, (state) => {
        state.carsAuctioned.isLoading = false
    })

    builder.addCase(CarsSoldPagination, (state, action) => {
        state.carsSold.isLoading = true
        state.carsSold.pagination.page = state.carsSold.pagination.page + 1
    })
    builder.addCase(CarsSoldSuccess, (state, action) => {
        state.carsSold.isLoading = false

        if (action.payload.reset) {
            state.carsSold.cars = []
        }
        state.carsSold.cars = [...state.carsSold.cars, ...action.payload.cars]
        state.carsSold.pagination.page = action.payload.page
        state.carsSold.pagination.pageSize = action.payload.pageSize
        state.carsSold.pagination.soldCount = action.payload.soldCount
        state.carsSold.pagination.didNotSellCount = action.payload.didNotSellCount
    })
    builder.addCase(CarsSoldFailed, (state) => {
        state.carsSold.isLoading = false
    })

    builder.addCase(CarsBoughtPagination, (state, action) => {
        state.carsBought.isLoading = true
        state.carsBought.pagination.page = state.carsBought.pagination.page + 1
    })
    builder.addCase(CarsBoughtSuccess, (state, action) => {
        state.carsBought.isLoading = false

        if (action.payload.reset) {
            state.carsBought.reservePriceNotMetCars = []
            state.carsBought.bidOnCars = []
        }
        state.carsBought.reservePriceNotMetCars = [...state.carsBought.reservePriceNotMetCars, ...action.payload.reservePriceNotMetData]
        state.carsBought.bidOnCars = [...state.carsBought.bidOnCars, ...action.payload.bidOnData]

        state.carsBought.pagination.page = action.payload.page
        state.carsBought.pagination.pageSize = action.payload.pageSize
        state.carsBought.pagination.notMetCount = action.payload.notMetCount
        state.carsBought.pagination.bidOnCount = action.payload.bidOnCount
    })
    builder.addCase(CarsBoughtFailed, (state) => {
        state.carsBought.isLoading = false
    })

    builder.addCase(GetUserSuccess, (state, action) => {
        state.userDetail.user = action.payload.user
    })
    builder.addCase(GetUserFailed, (state) => {})
    builder.addCase(SetUnpaidCars, (state, action) => {
        state.unpaidCars = action.payload.cars
    })

    builder.addCase(ClearUserProfile, (state, action) => {
        state.userDetail.user = null

        state.bidHistory.auctions = []
        state.bidHistory.pagination.count = 0

        state.commentHistory.cars = []
        state.commentHistory.pagination.count = 0

        state.carsWon.auctions = []
        state.carsWon.pagination.count = 0
    })
})
