import { useTranslation } from 'react-i18next'
import { TabView } from '../../components'
import { UserSideMenu } from '../my-profile/components/user-side-menu/user-side-menu'
import { TabViewPage } from '../../components/tabview/tabview-page'
import { CommentHistoryTab } from './components/comment-history-tab'
import { BidHistoryTab } from './components/bid-history-tab'
import './my-activity.page.scss'

interface MyActivityPageProps {}

export const MyActivityPage = (props: MyActivityPageProps) => {
    const { t } = useTranslation()

    return (
        <main className="page page-my-activity">
            <div className="main-div">
                <UserSideMenu />
                <div className="my-activity-div">
                    <h2>{t('header_profile_my_activity_button')} </h2>

                    <div className="container-div">
                        <TabView tabs={{ bid: t('my_activity_bid_history_title'), comment: t('my_activity_comment_history_title') }}>
                            <TabViewPage key="bid">
                                <BidHistoryTab />
                            </TabViewPage>

                            <TabViewPage key="comment">
                                <CommentHistoryTab />
                            </TabViewPage>
                        </TabView>
                    </div>
                </div>
            </div>
        </main>
    )
}
