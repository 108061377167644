import { useCallback, useEffect, useState } from 'react'
import './linear.progress.scss'

interface LinearProgressProps {
    percent: number
}

export const LinearProgress = (props: LinearProgressProps) => {
    const [currentValue, setCurrentValue] = useState(0)

    const increment = useCallback(() => {
        if (currentValue === 100) {
            setCurrentValue(0)
        } else {
            setCurrentValue((v) => v + 5)
        }
    }, [setCurrentValue, currentValue])

    useEffect(() => {
        const r = setInterval(() => {
            increment()
        }, 100)

        return () => {
            clearInterval(r)
        }
    }, [increment])

    return (
        <div className={'linear-progress'}>
            <div className={'emptyProgressBar'} style={{ width: '100%' }}>
                <div
                    className={'fillingProgressBar'}
                    style={{
                        left: 0,
                        width: currentValue + '%',
                        transition: '0.2s',
                    }}
                />
            </div>
        </div>
    )
}
