import './circle.progress.scss'

const cleanPercentage = (percentage: number) => {
    const tooLow = !Number.isFinite(+percentage) || percentage < 0
    const tooHigh = percentage > 100
    return tooLow ? 0 : tooHigh ? 100 : +percentage
}

interface CircleProps {
    colour: string
    pct: number
}

const Circle = (props: CircleProps) => {
    const r = 27
    const circ = 2 * Math.PI * r
    const strokePct = ((100 - props.pct) * circ) / 100
    return (
        <circle
            r={r}
            cx={30}
            cy={30}
            fill="transparent"
            stroke={strokePct !== circ ? props.colour : ''} // remove colour as 0% sets full circumference
            strokeWidth={'5'}
            strokeDasharray={circ}
            strokeDashoffset={props.pct ? strokePct : 0}
            strokeLinecap="square"
        ></circle>
    )
}

interface TextProps {
    percentage: number
}

const Text = (props: TextProps) => {
    return (
        <text x="50%" y="50%" dominantBaseline="central" textAnchor="middle">
            {props.percentage.toFixed(0)}%
        </text>
    )
}

interface CircleProgressProps {
    percentage: number
    colour: string
}

export const CircleProgress = (props: CircleProgressProps) => {
    const pct = cleanPercentage(props.percentage)
    return (
        <svg className="circle-progress" width={60} height={60}>
            <g transform={`rotate(-90 ${'30 30'})`}>
                <Circle colour="lightgrey" pct={100} />
                <Circle colour={props.colour} pct={pct} />
            </g>
            <Text percentage={pct} />
        </svg>
    )
}
