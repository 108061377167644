import { UserDTO } from '../../dto'
import { store } from '../../redux'
import { LoginSuccess } from '../../redux/actions'
import { Logout } from '../../redux/actions/login'
import { NetworkCall } from '../../utils'

export const me = async (token: string) => {
    return await new NetworkCall('GET', `/1.0.0/users/me`)
        .setHeader('Authorization', `Bearer ${token}`)
        .then(200, UserDTO, (responseBody) => {
            store.dispatch(LoginSuccess({ user: responseBody.user, session: { token: token } }))
        })
        .catch(401, (response) => {
            store.dispatch(Logout({}))
        })
        .catch( (response) => {
            
        })
        .call()
}
