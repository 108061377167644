import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../redux'
import { BidHistoryElement } from './bid-history-element'
import { Button, LoadMoreButton } from '../../../../components'
import { BidHistoryPagination } from '../../../../redux/actions'
import { bidHistory } from '../../../../api'
import './bid-history.tab.scss'

interface BidHistoryTabProps {}

export const BidHistoryTab = (props: BidHistoryTabProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { auctions, pagination } = useAppSelector((state) => state.user.bidHistory)

    const onLoadMoreClick = async () => {
        dispatch(BidHistoryPagination({}))
        await bidHistory(null)
    }

    return (
        <main className="tab tab-bid-history">
            <div className="tab-div">
                <div className="inner-container-div">
                    <label className={`title`}>{t('my_activity_live_auctions_title') + ' (' + pagination.count + ')'}</label>
                    <div className="divider" />
                    <ul className={`history-list ${pagination.count == 0 ? `hidden` : ``}`}>
                        {auctions.map((auction, index) => (
                            <BidHistoryElement auction={auction} />
                        ))}
                    </ul>

                    <div className={`empty-list-div ${pagination.count != 0 ? `hidden` : ``}`}>
                        <label>{t('my_activity_bid_history_empty_title')}</label>

                        <Button
                            title={t('button_go_to_auctions')}
                            onClick={async () => {
                                navigate('/')
                            }}
                        />
                    </div>
                </div>

                <div className={`bottom-div ${pagination.count <= pagination.pageSize * pagination.page ? `hidden` : ``}`}>
                    <LoadMoreButton onClick={onLoadMoreClick} />
                </div>
            </div>
        </main>
    )
}
