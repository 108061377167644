import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../redux'
import { getImpressum } from '../../api'
import { useEffect } from 'react'
import './impressum.page.scss'

export const ImpressumPage = () => {
    const { t, i18n } = useTranslation()

    const { impressum } = useAppSelector((state) => state.impressum)

    useEffect(() => {
        getImpressum()
    }, [i18n.language])

    return (
        <main className="page page-impressum">
            <div className="main-div">
                <h2>{t('impressum_title')}</h2>
                <div className="container-div">{impressum?.contentHtml && <div className="content" dangerouslySetInnerHTML={{ __html: impressum?.contentHtml }} />}</div>
            </div>
        </main>
    )
}
