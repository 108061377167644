import { PropsWithChildren } from 'react'
import { GuidePageEnum } from '../../../enum/guide-page-enum'
import { GuideSubmenu } from './guide-submenu'
import { useTranslation } from 'react-i18next'
import './guide-base.page.scss'

interface GuideBasePageProps {
    page: GuidePageEnum
    additionalClassNames?: string
}

export const GuideBasePage = (props: PropsWithChildren<GuideBasePageProps>) => {
    const { t } = useTranslation()

    const _getPageTitle = () => {
        switch (props.page) {
            case GuidePageEnum.PhotoGuide:
                return t('guide_photo_guide_title')
            case GuidePageEnum.VideoGuide:
                return t('guide_video_guide_title')
            default:
                return t('guide_photo_checklist_guide_title')
        }
    }

    const _getPageClassName = () => ('page page-guide ' + props.additionalClassNames ?? '').trim()

    return (
        <main className={_getPageClassName()}>
            <div className="main-div">
                <GuideSubmenu activePage={props.page} isTop={false} />
                <div className="content-with-top-menu-div">
                    <div className="page-title">
                        <h2>{_getPageTitle()}</h2>
                    </div>
                    <GuideSubmenu activePage={props.page} isTop={true} />
                    <div className="content-div">{props.children}</div>
                </div>
            </div>
        </main>
    )
}
