import { Expose } from 'class-transformer'
import { IsBoolean, IsDefined, IsNumber, IsOptional, IsString, ValidateIf } from 'class-validator'
import { User } from './user.entity'

export class Comment {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsString()
    comment!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    likeCount!: number

    @Expose()
    @IsDefined()
    @IsBoolean()
    @ValidateIf((object, value) => value !== undefined)
    liked!: boolean | undefined

    @IsOptional()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null)
    parentId!: number | null

    parentUsername!: string | null

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== undefined)
    children!: Comment[] | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number

    @Expose()
    user!: User
}
