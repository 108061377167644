import { t } from 'i18next'

export const handleServerErrors = (code: number) => {
    switch (code) {
        case 1:
            return t(`error_internal_server_error`)
        case 13:
            return t(`error_invalid_query_params`)
        case 14:
            return t(`error_invalid_body_params`)
        case 15:
            return t(`error_invalid_header_params`)
        case 16:
            return t(`error_invalid_token`)
        case 20:
            return t(`error_unauthorized`)
        case 30:
            return t(`error_forbidden`)
        case 40:
            return t(`error_too_many_request`)
        case 50:
            return t(`error_not_found`)
        case 100001:
            return t(`error_user_not_found`)
        case 100002:
            return t(`error_wrong_password`)
        case 100003:
            return t(`error_user_already_exists`)
        case 100004:
            return t(`error_username_already_exist`)
        case 100005:
            return t(`error_email_not_verified`)
        case 100006:
            return t(`error_auction_in_progress`)
        case 100007:
            return t(`error_bid_in_live_action`)
        case 100008:
            return t(`error_payment_required`)
        case 100009:
            return t(`error_user_not_winner_of_auction`)
        case 100011:
            return t(`error_user_blacklisted`)
        case 200001:
            return t(`error_auction_not_found`)
        case 200002:
            return t(`error_bid_amount_lower_than_price`)
        case 200003:
            return t(`error_seller_can_not_bid`)
        case 200004:
            return t(`error_auction_expired`)
        case 200005:
            return t(`error_buyers_fee_already_paid`)
        case 300001:
            return t(`error_document_not_found`)
        case 400001:
            return t(`error_media_file_not_found`)
        case 400002:
            return t(`error_photo_limit_reached`)
        case 500001:
            return t(`error_car_not_found`)
        case 500002:
            return t(`error_car_can_not_be_modified`)
        case 500003:
            return t(`error_reserve_price_can_only_be_lowered`)
        case 600001:
            return t(`error_file_type_is_incorrect`)
        case 600002:
            return t(`error_uploaded_object_should_be_a_file`)
        case 600003:
            return t(`error_file_size_is_too_large`)
        case 600004:
            return t(`error_invalid_codec_name`)
        case 600005:
            return t(`error_video_too_long`)
        case 600006:
            return t(`error_media_file_count`)
        case 600007:
            return t(`error_add_car_maximum_file_size`)
        case 700001:
            return t(`error_comment_not_found`)
        case 800001:
            return t(`error_question_not_found`)
        case 900001:
            return t(`error_answer_not_found`)
        case 900002:
            return t(`error_only_seller_can_answer_this_question`)
        case 900003:
            return t(`error_answer_already_exists`)
        case 110001:
            return t(`error_terms_not_found`)
        case 120001:
            return t(`error_privacy_not_found`)
        case 130001:
            return t(`error_contact_not_found`)
        case 140001:
            return t(`error_impressum_not_found`)
        case 150001:
            return t(`error_wrong_address`)
        case 150002:
            return t(`error_missing_address_components`)
        case 190001:
            return t(`error_payment_failed`)
        case 190002:
            return t(`error_invoice_could_not_be_created`)
        case 190003:
            return t(`error_buyers_fee_could_not_be_deducted`)
        case 190004:
            return t(`error_card_update_failed`)
        default:
            return null
    }
}
