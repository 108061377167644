import { Modal } from '../modal'
import { useTranslation } from 'react-i18next'
import { Button } from '../../buttons'
import './no-reserve-price.modal.scss'

interface NoReservePriceModalProps {
    onClose: () => void
    onOk: () => void
}

export const NoReservePriceModal = (props: NoReservePriceModalProps) => {
    const { t } = useTranslation()

    return (
        <Modal title={t('modal_no_reserve_price_title')} onCloseClick={props.onClose}>
            <div className="no-reserve-price-modal">
                <label className="no-reserve-price-modal__description">{t('modal_no_reserve_price_desc')}</label>

                <div className="button-div">
                    <Button default={true} title={t('modal_no_reserve_price_no_button')} onClick={props.onClose} />
                    <Button title={t('modal_no_reserve_price_yes_button')} onClick={props.onOk} />
                </div>
            </div>
        </Modal>
    )
}
