import { Expose } from 'class-transformer'
import { IsDefined } from 'class-validator'

export class ErrorDTO {
    @Expose()
    @IsDefined()
    code!: number

    @Expose()
    @IsDefined()
    message!: string
}
