import { GuidePageEnum } from '../../../enum/guide-page-enum'
import { GuideBasePage } from '../guide/guide-base.page'
import { Section } from '../components/section'
import { useTranslation } from 'react-i18next'

export const VideoGuidePage = () => {
    const { t } = useTranslation()

    return (
        <GuideBasePage page={GuidePageEnum.VideoGuide} additionalClassNames="page-video-guide">
            <Section>
                <Section title={t('guide_video_guide_section_title')} isSub={true}>
                    <div>
                        <p dangerouslySetInnerHTML={{ __html: t('guide_video_guide_section_body') }}></p>
                        <p dangerouslySetInnerHTML={{ __html: t('guide_video_guide_section_capture') }}></p>
                    </div>
                </Section>
                <Section title={t('guide_video_guide_exterior_title')} isSub={true}>
                    <ul className="left-indent">
                        <li dangerouslySetInnerHTML={{ __html: t('guide_video_guide_exterior_item1') }}></li>
                        <li dangerouslySetInnerHTML={{ __html: t('guide_video_guide_exterior_item2') }}></li>
                        <li dangerouslySetInnerHTML={{ __html: t('guide_video_guide_exterior_item3') }}></li>
                        <li dangerouslySetInnerHTML={{ __html: t('guide_video_guide_exterior_item4') }}></li>
                    </ul>
                </Section>
                <Section title={t('guide_video_guide_interior_title')} isSub={true}>
                    <div>
                        <ul className="left-indent">
                            <li dangerouslySetInnerHTML={{ __html: t('guide_video_guide_interior_item1') }}></li>
                            <li dangerouslySetInnerHTML={{ __html: t('guide_video_guide_interior_item2') }}></li>
                            <li dangerouslySetInnerHTML={{ __html: t('guide_video_guide_interior_item3') }}></li>
                            <li dangerouslySetInnerHTML={{ __html: t('guide_video_guide_interior_item4') }}></li>
                            <li dangerouslySetInnerHTML={{ __html: t('guide_video_guide_interior_item5') }}></li>
                        </ul>
                        <p dangerouslySetInnerHTML={{ __html: t('guide_video_guide_note') }}></p>
                    </div>
                </Section>
            </Section>
        </GuideBasePage>
    )
}
