import { PropsWithChildren } from 'react'

interface SectionTitleProps {
    additionalClassNames?: string
}

export const SectionTitle = (props: PropsWithChildren<SectionTitleProps>) => {
    const _getClassName = () => ['section-title', props.additionalClassNames ?? ''].join(' ').trim()

    return <>{props.children && <div className={_getClassName()}>{props.children}</div>}</>
}
