import { store } from '../../redux'
import { ErrorDTO, LoginDTO } from '../../dto'
import { LoginSuccess, LoginFailed, ErrorMessage, ShowToast, ShowAcceptTermsModal, UpdateUserForm } from '../../redux/actions'
import { NetworkCall } from '../../utils'
import { getCars } from '../cars'
import { User } from '../../entity'

interface LoginProps {
    onSuccess: (user: User) => void
    onErrror: (code: number) => void
}

export const login = async (props: LoginProps) => {
    const pushToken = sessionStorage.getItem('pushToken')
    const requestBody = {
        email: store.getState().login.form.email.value,
        password: store.getState().login.form.password.value,
        pushToken: pushToken === null || pushToken.startsWith('blocked') ? null : pushToken,
    }

    return await new NetworkCall('POST', `/1.0.0/users/login`, requestBody)
        .then(201, LoginDTO, async (responseBody) => {
            localStorage.setItem(`showFloatingButton`, `true`)

            if (store.getState().login.form.rememberMe.value) {
                localStorage.setItem('token', responseBody.session.token)
            } else {
                sessionStorage.setItem('token', responseBody.session.token)
            }

            const user = responseBody.user

            props.onSuccess(responseBody.user)

            store.dispatch(LoginSuccess(responseBody))

            store.dispatch(ShowToast({ message: 'toast_login_success' }))

            const termsResponse = await fetch(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/terms`)
            if (termsResponse.status === 200) {
                const responseBody = await termsResponse.json()
                const terms = responseBody.terms
                localStorage.setItem(`termsVersion`, terms.version)
                if (user.termsVersion && user.termsVersion < terms.version) {
                    localStorage.setItem(`termsChanged`, `true`)
                    store.dispatch(ShowAcceptTermsModal())
                }
            }

            const privacyResponse = await fetch(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/privacy`)
            if (privacyResponse.status === 200) {
                const responseBody = await privacyResponse.json()
                const privacy = responseBody.privacy
                store.dispatch(UpdateUserForm({ key: `privacyVersion`, value: privacy.version, error: null }))
                localStorage.setItem(`privacyVersion`, privacy.version)
                if (user.privacyVersion && user.privacyVersion < privacy.version) {
                    localStorage.setItem(`termsChanged`, `true`)
                    store.dispatch(ShowAcceptTermsModal())
                }
            }

            getCars(true)
        })
        .catch((response) => {
            store.dispatch(LoginFailed({}))

            if ((response as ErrorDTO).code !== 100005) {
                store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
            }
            props.onErrror((response as ErrorDTO).code)
        })
        .call()
}
