import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { UpdateCarForm } from '../../../../redux/actions'
import { useAppSelector } from '../../../../redux'
import { useState } from 'react'
import { InputField } from '../../../../components/field'
import { IconButton } from '../../../../components'
import { ReservePriceModal } from '../../../../components/modals'
import './reserve-price.tab.scss'

interface ReservePriceTabProps {}

export const ReservePriceTab = (props: ReservePriceTabProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { form } = useAppSelector((state) => state.car)
    const [reservePriceEnabled, setReservePriceEnabled] = useState<boolean | number>(form.hasReservePriceLocal.value)
    const [reservePriceModalVisible, setReservePriceModalVisible] = useState(false)

    return (
        <main className="tab tab-reserve-price">
            <div className="tab-div">
                <h2>{t('add_car_title')}</h2>

                <h4>{t('add_car_reserve_price_title')}</h4>

                <div className="container-div">
                    <label>{t('add_car_reserve_price_subtitle')}</label>

                    <div className="reserve-price-type-div">
                        <div className="type-div">
                            <label
                                className={`type ${reservePriceEnabled !== -1 && !reservePriceEnabled ? 'active' : ''} `}
                                onClick={() => {
                                    setReservePriceEnabled(false)
                                    dispatch(UpdateCarForm({ key: 'hasReservePrice', value: true, error: null }))
                                    dispatch(UpdateCarForm({ key: 'hasReservePriceLocal', value: false, error: null }))
                                    dispatch(UpdateCarForm({ key: 'reservePrice', value: '', error: null }))
                                }}
                            >
                                {t('add_car_no_title')}
                            </label>

                            <label
                                className={`type ${reservePriceEnabled !== -1 && reservePriceEnabled ? 'active' : ''} `}
                                onClick={() => {
                                    setReservePriceEnabled(true)
                                    dispatch(UpdateCarForm({ key: 'hasReservePrice', value: true, error: null }))
                                    dispatch(UpdateCarForm({ key: 'hasReservePriceLocal', value: true, error: null }))
                                }}
                            >
                                {t('add_car_yes_title')}
                            </label>
                        </div>

                        <IconButton
                            title={t('add_car_rwhat_is_eserve_price_title')}
                            icon="/images/ic_question_info.svg"
                            onClick={() => {
                                setReservePriceModalVisible(!reservePriceModalVisible)
                            }}
                        />
                    </div>
                    <div className={`price-div ${reservePriceEnabled !== -1 && reservePriceEnabled ? '' : 'hidden'}`}>
                        <InputField
                            type="text"
                            maxLength={11}
                            field={form.reservePrice}
                            placeholder={''}
                            title={t('add_car_reserve_price_required_title')}
                            onChange={(event) => {
                                let userInput: string = event.target.value.replace(/[^0-9]/g, '')
                                if (userInput.length === 0) {
                                    dispatch(UpdateCarForm({ key: 'reservePrice', value: '', error: null }))
                                }
                                if (userInput !== '') {
                                    let userAmount = parseInt(userInput)
                                    dispatch(UpdateCarForm({ key: 'reservePrice', value: userAmount.toLocaleString('de-CH', { minimumFractionDigits: 0 }), error: null }))
                                }
                            }}
                        />
                        <label>{t('car_card_chf_title')}</label>
                    </div>

                    <label>{t('add_car_reserve_price_desc')}</label>
                </div>
            </div>

            {reservePriceModalVisible ? (
                <ReservePriceModal
                    onClose={() => {
                        setReservePriceModalVisible(!reservePriceModalVisible)
                    }}
                />
            ) : null}
        </main>
    )
}
