import { useTranslation } from 'react-i18next'
import { Button } from '../../components'
import { useDispatch } from 'react-redux'
import { ShowLoginModal } from '../../redux/actions'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import './email-verification.page.scss'

interface EmailVerificationPageProps {}

export const EmailVerificationPage = (props: EmailVerificationPageProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const verificationSuccess = useLoaderData()

    useEffect(() => {
        if (verificationSuccess != null && !verificationSuccess) {
            navigate('/')
        }
    }, [verificationSuccess])

    return (
        <main className="page page-email-verification">
            <div className="main-div">
                <img alt="Check icon" className="arrow-img" src="/images/ic_check.svg" />

                <h2>{t('email_verification_title')} </h2>

                <label className="body-text">{verificationSuccess ? t('email_verification_succes_title') : t('email_verification_verify_title')} </label>

                <Button title={t('button_log_in')} onClick={() => dispatch(ShowLoginModal({ visible: true, carId: null }))} />
            </div>
        </main>
    )
}
