import { createReducer } from '@reduxjs/toolkit'
import { SignUpState } from '../../states'
import { SignUpFailed, SignUpSuccess, UpdateSignUpForm } from '../../actions'

const initialState: SignUpState = {
    user: null,
    form: {
        username: { error: null, value: `` },
        email: { error: null, value: `` },
        password: { error: null, value: `` },
        confirm: { error: null, value: false },
        english: { error: null, value: false },
        german: { error: null, value: false },
        french: { error: null, value: false },
        italian: { error: null, value: false },
        terms: { error: null, value: false },
        newsletter: { error: null, value: false },
    },
}

export const signUpReducer = createReducer(initialState, (builder) => {
    builder.addCase(SignUpSuccess, (state, action) => {
        // state.user = action.payload.user

        state.form.username.value = ''
        state.form.email.value = ''
        state.form.password.value = ''
        state.form.confirm.value = false
        state.form.english.value = false
        state.form.german.value = false
        state.form.french.value = false
        state.form.italian.value = false
        state.form.terms.value = false
        state.form.newsletter.value = false
    })
    builder.addCase(SignUpFailed, (state) => {
        // state.form.email.error = ''
        // state.form.password.error = t('login-page--invalid-username-or-password')
    })
    builder.addCase(UpdateSignUpForm, (state, action) => {
        ;(state.form[action.payload.key] as any) = {
            ...state.form[action.payload.key],
            value: action.payload.value,
            error: action.payload.error,
        }
        //Object.keys(state.form).forEach((key) => ((state.form as any)[key].error = null))
    })
})
