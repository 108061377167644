import { createBrowserRouter } from 'react-router-dom'
import { DefaultLayout } from '../components/layouts'
import { AuctionsPage, AuctionPage, SignUpPage, UserProfilePage, EmailVerificationPage, EvDatabaseDetailPage, MyEvGaragePage, MyProfilePage, HowItWorksPage, EvDatabasePage, AboutUsPage, TermsPage, ImpressumPage, PrivacyPage, AddCarPage, ContactUsPage, FaqsPage, ResetPasswordPage, ErrorPage, NotificationSettingsPage, WhyGoElectricPage, MyActivityPage } from '../pages'
import { auctionsPageLoader, auctionPageLoader, addCarPageLoader, emailVerificationPageLoader, myEvGaragePageLoader, resetPasswordPageLoader, notificationSettingsPageLoader, termsPageLoader, impressumPageLoader, privacyPageLoader, contactUsPageLoader, myActivityPageLoader, faqsPageLoader, userProfilePageLoader, evDatabasePageLoader, myProfilePageLoader } from '../loaders'
import { PhotoChecklistPage, PhotoGuidePage, VideoGuidePage } from '../pages/guide'
import { RequireAuth } from '../components'

export const router = createBrowserRouter([
    {
        children: [
            {
                element: <AuctionsPage />,
                loader: auctionsPageLoader,
                errorElement: <ErrorPage />,
                path: '',
            },
            {
                element: <AuctionPage />,
                loader: auctionPageLoader,
                path: 'auction/:id',
            },
            {
                element: (
                    <RequireAuth>
                        <AddCarPage />
                    </RequireAuth>
                ),
                loader: addCarPageLoader,
                path: 'add-car',
                //shouldRevalidate: () => true,
            },
            {
                element: <HowItWorksPage />,
                // path: 'how-it-works',
                path: 'how-it-works/:lng',
            },

            {
                element: <HowItWorksPage />,
                // path: 'how-it-works',
                path: 'how-it-works',
            },
            {
                element: <EvDatabasePage />,
                loader: evDatabasePageLoader,
                path: 'electric-car-database',
            },
            {
                element: <EvDatabaseDetailPage />,
                loader: evDatabasePageLoader,
                path: 'electric-car-database/:id',
            },
            {
                element: <AboutUsPage />,
                path: 'why-choose',
            },
            {
                element: <SignUpPage />,
                path: 'sign-up',
            },
            {
                element: <ResetPasswordPage />,
                loader: resetPasswordPageLoader,
                path: 'reset-password',
            },
            {
                element: <EmailVerificationPage />,
                loader: emailVerificationPageLoader,
                path: 'email-verification',
            },
            {
                element: <UserProfilePage />,
                loader: userProfilePageLoader,
                path: 'user/:id',
            },
            {
                element: (
                    <RequireAuth>
                        <MyProfilePage />
                    </RequireAuth>
                ),
                loader: myProfilePageLoader,
                path: 'my-profile',
            },
            {
                element: (
                    <RequireAuth>
                        <MyEvGaragePage />
                    </RequireAuth>
                ),
                loader: myEvGaragePageLoader,
                path: 'my-ev-garage',
            },
            {
                element: (
                    <RequireAuth>
                        <MyActivityPage />
                    </RequireAuth>
                ),
                loader: myActivityPageLoader,
                path: 'my-activity',
            },
            {
                element: (
                    <RequireAuth>
                        <NotificationSettingsPage />
                    </RequireAuth>
                ),
                loader: notificationSettingsPageLoader,
                path: 'notification-settings',
            },
            {
                element: <TermsPage />,
                loader: termsPageLoader,
                path: 'terms',
            },
            {
                element: <ImpressumPage />,
                loader: impressumPageLoader,
                path: 'impressum',
            },
            {
                element: <TermsPage />,
                path: 'cookies',
            },
            {
                element: <PrivacyPage />,
                loader: privacyPageLoader,
                path: 'privacy-policy',
            },
            {
                element: <PhotoGuidePage />,
                path: 'guide',
            },
            {
                element: <PhotoGuidePage />,
                path: 'photo-guide',
            },
            {
                element: <VideoGuidePage />,
                path: 'video-guide',
            },
            {
                element: <PhotoChecklistPage />,
                path: 'photo-checklist',
            },
            {
                element: <WhyGoElectricPage />,
                path: 'why-go-electric',
            },
            {
                element: <FaqsPage />,
                loader: faqsPageLoader,
                path: 'faqs',
            },
            {
                element: <ContactUsPage />,
                loader: contactUsPageLoader,
                path: 'contact-us',
            },
        ],
        element: <DefaultLayout />,
        errorElement: <ErrorPage />,
        path: '/',
    },
])
