import { store } from '../../redux'
import { ErrorDTO, ListNotificationsDTO } from '../../dto'
import { ErrorMessage, ListNotifications } from '../../redux/actions'
import { NetworkCall } from '../../utils'

export const listNotifications = async ( page: number = 1 ) => {
    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')
    if (token === null) {
        return
    }

    return await new NetworkCall('GET', `/1.0.0/notifications?page=${page}`)
        .setHeader( `Authorization`, `Bearer ${ token }` )
        .then(200, ListNotificationsDTO, (responseBody) => {
            store.dispatch(ListNotifications(responseBody))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
