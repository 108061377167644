import { ChangeEvent, MouseEventHandler, useState } from 'react'
import { Modal } from '../modal'
import { Button, IconButton } from '../../buttons'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '../../checkbox'
import useTimeout from '../../../utils/timeout.util'
import { isMultipleOf, roundUp } from '../../../utils/math.util'
import './bid.modal.scss'

interface BidModalProps {
    currentPrice: number
    firstBid: boolean
    onClose: () => void
    onHowBiddingWorksClick: MouseEventHandler
    onBuyersFeeClick: MouseEventHandler
    onBid: (value: number) => void
}

export const BidModal = (props: BidModalProps) => {
    const { t } = useTranslation()

    const [termsError, setTermsError] = useState('')
    const [terms, setTerms] = useState(false)

    const [amountError, setAmountError] = useState('')
    const [formattedAmount, setFormattedAmount] = useState('0')
    const [amount, setAmount] = useState(0)
    const [totalToPay, setTotalToPay] = useState(0)
    const [payToSeller, setPayToSeller] = useState(0)
    const [buyersFee, setBuyersFee] = useState(0)

    const { clear, reset } = useTimeout(() => {
        setFormattedAmount(amount.toLocaleString('de-CH', { minimumFractionDigits: 0 }))
    }, 1500)

    const onBidClick = async () => {
        setTermsError('')
        setAmountError('')

        if (!terms) {
            setTermsError(t('error_signup_terms'))
            return
        }

        if (formattedAmount.length !== 0 && amount <= props.currentPrice) {
            setAmountError(t('error_bid_amount_lower_empty'))
        } else if (amount > 300000) {
            setAmountError(t('error_bid_amount_verify'))
        } else {
            props.onBid(amount)
        }
    }

    const onBidChange = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        setTermsError('')
        setAmountError('')

        reset()

        let userInput: string = event.target.value.replace(/[^0-9]/g, '')

        if (userInput === '') {
            setFormattedAmount('0')
            setAmount(0)
            setTotalToPay(0)
            setPayToSeller(0)
            setBuyersFee(0)

            clear()
        } else {
            let userAmount = parseInt(userInput)

            setFormattedAmount(userAmount.toLocaleString('de-CH', { minimumFractionDigits: 0 }))

            const checkedAmount = checkAmount(userAmount)
            setAmount(checkedAmount)

            setTotalToPay(checkedAmount + getBuyersFee(checkedAmount))
            setPayToSeller(checkedAmount)
            setBuyersFee(getBuyersFee(checkedAmount))
        }
    }

    const checkAmount = (amount: number): number => {
        if (amount < 10000 && !isMultipleOf(amount, 100)) {
            return roundUp(amount, 100)
        }
        if (amount >= 10000 && amount < 40000 && !isMultipleOf(amount, 250)) {
            return roundUp(amount, 250)
        }
        if (amount >= 40000 && !isMultipleOf(amount, 400)) {
            return roundUp(amount, 400)
        }

        return amount
    }

    const getBuyersFee = (amount: number): number => {
        const calculatedBuyersFee = amount * 0.019
        let buyersFee = 0

        if (calculatedBuyersFee < 250) {
            buyersFee = 250
        } else if (calculatedBuyersFee > 1450) {
            buyersFee = 1450
        } else {
            buyersFee = calculatedBuyersFee
        }

        return buyersFee
    }

    const getStep = () => {
        if (props.currentPrice < 10000) {
            return 100
        }
        if (props.currentPrice >= 10000 && props.currentPrice < 40000) {
            return 250
        }
        if (props.currentPrice >= 40000) {
            return 400
        }
    }

    return (
        <Modal title={props.firstBid ? t('modal_bid_first_title') : t('modal_bid_title')} onCloseClick={props.onClose}>
            <div className="bid-modal">
                <div className={`first-bid-desc-div ${props.firstBid ? `` : `hidden`}`}>
                    <h3 className="sub-title">{t('modal_bid_first_bid_sub_title')}</h3>
                    <label className="description">{t('modal_bid_first_bid_first_desc')}</label>
                    <label className="description">{t('modal_bid_first_bid_second_desc')}</label>
                    <label className="description">{t('modal_bid_first_bid_third_desc')}</label>
                    <IconButton reverse={true} title={t('auction_how_bidding_works_button')} icon="/images/ic_info.svg" onClick={props.onHowBiddingWorksClick} />
                </div>

                <div className="amount-div">
                    <div className={`input-div`}>
                        <div className={`field`}>
                            <h4 className="body-text">{t('modal_bid_bid_placeholder')}</h4>

                            <input onChange={onBidChange} id={'bid_value'} required={true} type="text" value={formattedAmount} step={getStep()} min="0" max={'999999999'} maxLength={11} />
                            <div className={`error-div ${amountError != null && amountError.length > 0 ? `` : `hidden`}`}>
                                <img alt="Error icon" className="error-img" src="/images/ic_error.svg" />

                                <div className={`message`}>{amountError}</div>
                            </div>
                        </div>

                        <h3 className="currency-title">{t('car_card_chf_title')}</h3>
                    </div>
                    <div className="fee-div">
                        <div className="item-div">
                            <label className="title">{t('modal_bid_total_to_pay_title')}</label>
                            <label className="value">
                                {totalToPay.toLocaleString('de-CH', { minimumFractionDigits: 0 })} {t('car_card_chf_title')}
                            </label>
                        </div>
                        <div className="item-divider" />
                        <div className="item-div">
                            <label className="title">{t('modal_bid_pay_to_seller_title')}</label>
                            <label className="value">
                                {payToSeller.toLocaleString('de-CH', { minimumFractionDigits: 0 })} {t('car_card_chf_title')}
                            </label>
                        </div>
                        <div className="item-div click" onClick={props.onBuyersFeeClick}>
                            <label className="title">{t('modal_bid_buyers_fee_title')}</label>
                            <label className="value">
                                {buyersFee.toLocaleString('de-CH', { minimumFractionDigits: 0 })} {t('car_card_chf_title')}
                            </label>
                        </div>
                    </div>
                </div>

                <div className="terms-div">
                    <Checkbox
                        checked={terms}
                        titleElement={
                            <label className="small-text">
                                {t('modal_bid_terms_first_part_title')}&nbsp;
                                <a href="/terms" target="_blank">
                                    {t('modal_bid_terms_second_part_title')}
                                </a>
                                <br />
                                {t('modal_bid_terms_third_title')}&nbsp;
                                <a href="/privacy-policy" target="_blank">
                                    {t('modal_bid_terms_fourth_title')}
                                </a>
                            </label>
                        }
                        error={termsError}
                        id="terms"
                        onChange={(e) => setTerms(!terms)}
                    />
                </div>

                <div className="button-div">
                    <Button default={true} title={t('button_cancel')} onClick={props.onClose} />
                    <Button title={t('button_place_bid')} onClick={onBidClick} />
                </div>
            </div>
        </Modal>
    )
}
