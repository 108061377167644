import { createReducer } from "@reduxjs/toolkit";
import { AboutUsSection } from "../../../enum/about-us-section.enum";
import { AboutUsState } from "../../states";
import { UpdateCurrentSection } from "../../actions/about-us/update-current-section.action";

const initialState: AboutUsState = {
    currentSection: AboutUsSection.WhyChoose
}

export const aboutUsReducer = createReducer(initialState, (builder) => {
    builder.addCase(UpdateCurrentSection, (state, action) => {
        state.currentSection = action.payload.currentSection
    })
})