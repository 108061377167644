import { Expose } from 'class-transformer'
import { Session, User } from '../../entity'
import { IsDefined } from 'class-validator'

export class LoginDTO {
    @Expose()
    @IsDefined()
    user!: User

    @Expose()
    @IsDefined()
    session!: Session
}
