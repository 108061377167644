export const formatTimeLeft = (timestamp: number, t: (str: string) => string) => {
    let days = 0
    let hours = 0
    let minutes = 0
    let seconds = Math.floor((new Date().getTime() - timestamp) / 1000)
    let d = new Date(timestamp)

    if (seconds < 60) {
        return `${t(seconds > 1 ? 'seconds_ago' : 'second_ago')}`.replace('{number}', '' + seconds)
    }
    minutes = Math.floor(seconds / 60)
    if (minutes < 60) {
        return `${t(minutes > 1 ? 'minutes_ago' : 'minute_ago')}`.replace('{number}', '' + minutes)
    }
    hours = Math.floor(minutes / 60)
    if (hours < 24) {
        return `${t(hours > 1 ? 'hours_ago' : 'hour_ago')}`.replace('{number}', '' + hours)
    }
    days = Math.floor(hours / 24)
    if (hours < 30) {
        return `${t(days > 1 ? 'days_ago' : 'day_ago')}`.replace('{number}', '' + days)
    }

    return `${t(`month-${d.getMonth() + 1}`)} ${d.getDate()}, ${d.getFullYear()}`
}

export const formatTimeLeftWithoutDay = (timestamp: number, t: (str: string) => string) => {
    let d = new Date(timestamp)
    return `${t(`month-${d.getMonth() + 1}`)} ${d.getFullYear()}`
}

// en
// June 7, 2024 at 9:10 AM

// de
// 7. Juni 2024 um 09:10

// fr
// 7 juin 2024 à 09:10
