import { store } from '../../redux'
import { DefaultDTO, ErrorDTO } from '../../dto'
import { ErrorMessage, ShowToast } from '../../redux/actions'
import { NetworkCall } from '../../utils'
import { getQuestions } from './get-questions.api'

interface CreateQuestionProps {
    carId: number
    question: string
    onSuccess: () => void
}

export const createQuestion = async (props: CreateQuestionProps) => {
    const requestBody = {
        carId: props.carId,
        question: props.question,
    }

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('POST', `/1.0.0/questions`, requestBody)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(201, DefaultDTO, (responseBody) => {
            props.onSuccess()

            getQuestions(props.carId, true)

            store.dispatch(ShowToast({ message: 'toast_create_question_success' }))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
