import { Navigate } from 'react-router-dom'

interface RequireAuthProps {
    children: JSX.Element
}

export const RequireAuth = (props: RequireAuthProps) => {
    // const authenticatedUser = useSelector((state: State) => state.login.user)
    // if (authenticatedUser === null || authenticatedUser === undefined) {

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')
    if (token === null) {
        return <Navigate to={`/`} />
    }
    return props.children
}
