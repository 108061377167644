export enum CarStatusEnum {
    Draft = 0,
    Pending = 1,
    Approved = 2,
    Deleted = 3,
    Archived = 4,
    Sold = 5,
    ReservePriceNotMetSeller = 6,
    ReservePriceNotMetBuyer = 7,
    Paid = 8,
    Unpaid = 9,
    Overdue = 10,
    DidNotSell = 11,
}
