import { ChangeEvent, useRef, useState } from 'react'
import './input.field.scss'
import '../field.scss'

interface InputFieldProps {
    field: {
        disabled?: boolean
        error: string | null
        name?: string
        required?: boolean
        value: string
    }
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    title?: string
    placeholder?: string
    description?: string
    showEditIcon?: boolean
    type: 'email' | 'text' | 'number'
    min?: number
    max?: number
    maxLength?: number
    infoText?: string
}

export const InputField = (props: InputFieldProps) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [showInfo, setShowInfo] = useState(false)

    const onShowInfoClick = () => {
        setShowInfo(!showInfo)
    }

    return (
        <div className={`field field--input ${props.field.error != null && props.field.error.length > 0 ? ` field--error` : ``}`}>
            <p className="body-text">{props.title}</p>

            {props.showEditIcon && <img alt="Edit icon" className={`edit-img ${props.title != null && props.title.length > 0 ? `` : `top-margin`}`} src="/images/ic_edit_gray.svg" />}

            {props.infoText && <img alt="Info icon" className={`info-img ${props.title != null && props.title.length > 0 ? `` : `top-margin`}`} src="/images/ic_info_gray.svg" onClick={onShowInfoClick} />}

            {showInfo && <div className={`info-popup`}>{props.infoText}</div>}

            <input ref={inputRef} onChange={props.onChange} disabled={props.field?.disabled === true} id={props.field?.name} min={props.min} max={props.max} maxLength={props.maxLength} name={props.field?.name} placeholder={props.placeholder} required={props.field?.required !== false} type={props.type} value={props.field?.value} />

            <div className={`${props.field.error != null && props.field.error.length > 0 ? `field__error-div` : `field--hidden`}`}>
                <img alt="Error icon" className="error-img" src="/images/ic_error.svg" />

                <div className={`message`}>{props.field.error}</div>
            </div>

            {props.description && <p className="desc-text">{props.description} </p>}
        </div>
    )
}
