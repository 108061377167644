import { useState } from 'react'
import { Modal } from '../modal'
import { Button } from '../../buttons'
import { TextField } from '../../field'
import { useTranslation } from 'react-i18next'
import { createReport } from '../../../api'
import { ReportTypeEnum } from '../../../enum'
import './report.modal.scss'

interface ReportModalProps {
    id: number
    type: number
    onClose: () => void
}

export const ReportModal = (props: ReportModalProps) => {
    const { t } = useTranslation()

    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    const [selectedItem, setSelectedItem] = useState(-1)

    const getItems = () => {
        var cards: React.ReactElement[] = []
        cards.push(
            <div className={`item-div ${selectedItem === 0 ? `selected` : ``}`} onClick={() => onItemClick(0, t('modal_report_spam_title'))}>
                {selectedItem === 0 && <img className={`check-img`} alt="Check icon" src={`/images/ic_check_primary.svg`} />}

                <label className={`item ${selectedItem === 0 ? `selected` : ``}`}>{t('modal_report_spam_title')}</label>
            </div>
        )
        cards.push(
            <div className={`item-div ${selectedItem === 1 ? `selected` : ``}`} onClick={() => onItemClick(1, t('modal_report_child_endangerment_title'))}>
                {selectedItem === 1 && <img className={`check-img`} alt="Check icon" src={`/images/ic_check_primary.svg`} />}

                <label className={`item ${selectedItem === 1 ? `selected` : ``}`}>{t('modal_report_child_endangerment_title')}</label>
            </div>
        )
        cards.push(
            <div className={`item-div ${selectedItem === 2 ? `selected` : ``}`} onClick={() => onItemClick(2, t('modal_report_threat_title'))}>
                {selectedItem === 2 && <img className={`check-img`} alt="Check icon" src={`/images/ic_check_primary.svg`} />}

                <label className={`item ${selectedItem === 2 ? `selected` : ``}`}>{t('modal_report_threat_title')}</label>
            </div>
        )
        cards.push(
            <div className={`item-div ${selectedItem === 3 ? `selected` : ``}`} onClick={() => onItemClick(3, t('modal_report_harassment_title'))}>
                {selectedItem === 3 && <img className={`check-img`} alt="Check icon" src={`/images/ic_check_primary.svg`} />}

                <label className={`item ${selectedItem === 3 ? `selected` : ``}`}>{t('modal_report_harassment_title')}</label>
            </div>
        )
        cards.push(
            <div className={`item-div ${selectedItem === 4 ? `selected` : ``}`} onClick={() => onItemClick(4, t('modal_report_hate_speech_title'))}>
                {selectedItem === 4 && <img className={`check-img`} alt="Check icon" src={`/images/ic_check_primary.svg`} />}

                <label className={`item ${selectedItem === 4 ? `selected` : ``}`}>{t('modal_report_hate_speech_title')}</label>
            </div>
        )
        cards.push(
            <div className={`item-div ${selectedItem === 5 ? `selected` : ``}`} onClick={() => onItemClick(5, t('modal_report_violent_content_title'))}>
                {selectedItem === 5 && <img className={`check-img`} alt="Check icon" src={`/images/ic_check_primary.svg`} />}
                <label className={`item ${selectedItem === 5 ? `selected` : ``}`}>{t('modal_report_violent_content_title')}</label>{' '}
            </div>
        )
        cards.push(
            <div className={`item-div ${selectedItem === 6 ? `selected` : ``}`} onClick={() => onItemClick(6, t('modal_report_harmful_content_title'))}>
                {selectedItem === 6 && <img className={`check-img`} alt="Check icon" src={`/images/ic_check_primary.svg`} />}
                <label className={`item ${selectedItem === 6 ? `selected` : ``}`}>{t('modal_report_harmful_content_title')}</label>{' '}
            </div>
        )
        cards.push(
            <div className={`item-div ${selectedItem === 7 ? `selected` : ``}`} onClick={() => onItemClick(7, t('modal_report_links_title'))}>
                {selectedItem === 7 && <img className={`check-img`} alt="Check icon" src={`/images/ic_check_primary.svg`} />}
                <label className={`item ${selectedItem === 7 ? `selected` : ``}`}>{t('modal_report_links_title')}</label>{' '}
            </div>
        )

        return cards
    }

    const onItemClick = async (id: number, message: string) => {
        setError('')

        if (id === selectedItem) {
            setSelectedItem(-1)
            setMessage('')
        } else {
            setSelectedItem(id)
            setMessage(message)
        }
    }

    const onReportClick = async () => {
        if (message.length !== 0) {
            await createReport({
                id: props.id,
                type: props.type,
                message: message,
                onSuccess() {
                    props.onClose()
                },
            })
        } else {
            setError(t('error_report_empty'))
        }
    }

    return (
        <Modal title={props.type === ReportTypeEnum.Car ? t('modal_report_car_ad_title') : props.type === ReportTypeEnum.Comment ? t('car_information_report_comment_button') : t('car_information_report_question_button')} onCloseClick={props.onClose}>
            <div className="report-modal">
                <label className="report-modal__description">{t('modal_report_select_title')}</label>

                <div className="report-modal__select-div">{getItems()}</div>

                <div className="report-modal__other-div">
                    <label className="other">{t('modal_report_other_title')}</label>

                    <TextField
                        field={{ error: error, value: message }}
                        onChange={(e) => {
                            setError('')
                            setSelectedItem(-1)
                            setMessage(e.target.value)
                        }}
                        placeholder={''}
                    />
                </div>

                <div className="report-modal__button-div">
                    <Button default={true} title={t('button_cancel')} onClick={props.onClose} />
                    <Button title={t('button_submit')} onClick={onReportClick} />
                </div>
            </div>
        </Modal>
    )
}
