export const createFormattedDateString = (timestamp: number): string => {
    const date = new Date(timestamp)

    const dateString = date.getFullYear() + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.' + date.getDate().toString().padStart(2, '0') + '.'

    return dateString
}

export function numberWithSpaces(x: number) {
    return x
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export function getLocale() {
    const language = localStorage.getItem('language')
    if (language === null) {
        return 'en'
    }

    return language
}

//const formattedDateTime = new Date().toLocaleString('de-CH', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })
