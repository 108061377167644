import { useTranslation } from 'react-i18next'
import { RefObject, useState } from 'react'
import { TextField } from '../../../../../components/field'
import { Car, Comment } from '../../../../../entity'
import { CommentElement } from './comment-element'
import { LoadMoreButton } from '../../../../../components'
import { useDispatch } from 'react-redux'
import { CommentsPagination, ShowLoginModal } from '../../../../../redux/actions'
import { getComments } from '../../../../../api'
import { useAppSelector } from '../../../../../redux'
import { createComment } from '../../../../../api/comments/create-comment.api'
import { CarStatusEnum } from '../../../../../enum'
import './comments.scss'

interface CommentsProps {
    car: Car
    comments: Comment[]
    commentRef: RefObject<HTMLDivElement>

    onReportClick: (id: number) => void
}

export const Comments = (props: CommentsProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [message, setMessage] = useState('')
    const [tab, setTab] = useState<'newestFirst' | 'sellerComments'>('newestFirst')

    const [parentId, setParentId] = useState<number | null>(null)
    const [username, setUsername] = useState<string | null>(null)

    const user = useAppSelector((state) => state.login.user)
    const pagination = useAppSelector((state) => state.comments.pagination)

    const onSendClick = async () => {
        if (user) {
            if (message.length !== 0) {
                await createComment({
                    carId: props.car.id,
                    comment: message,
                    parentId: parentId ? parentId : undefined,
                    onSuccess() {
                        setMessage('')
                        setParentId(null)
                        setUsername(null)
                    },
                })
            }
        } else {
            dispatch(ShowLoginModal({ visible: true, carId: null }))
        }
    }

    const onReplyClick = async (id: number, username: string) => {
        if (user) {
            setParentId(id)
            setUsername(username)

            props.commentRef.current?.scrollIntoView({ behavior: 'smooth' })
        } else {
            dispatch(ShowLoginModal({ visible: true, carId: null }))
        }
    }

    const onDeleteReplyClick = async () => {
        setParentId(null)
        setUsername(null)
    }

    const onLoadMoreClick = async () => {
        dispatch(CommentsPagination({}))
        await getComments(props.car.id, tab)
    }

    const getCommentArray = () => {
        const comments: Comment[] = []

        props.comments.map((comment: Comment) => {
            comments.push(comment)

            comments.push(...getCommentChildren(comment))
        })

        return comments
    }

    const getCommentChildren = (parent: Comment) => {
        const comments: Comment[] = []

        if (parent.children) {
            parent.children.map((comment: Comment) => {
                comment = { ...comment, parentUsername: parent.user.username }

                comments.push(comment)

                comments.push(...getCommentChildren(comment))
            })
        }

        return comments
    }

    return (
        <div className="comments">
            {props.car.status === CarStatusEnum.Approved && (
                <>
                    <div className="textfield-div">
                        <div className={`reply-div ${username ? `` : `hidden`}`}>
                            <p className={`title `}>
                                {t('car_information_reply_to_title')}
                                {username}
                            </p>

                            <img alt="Close icon" className="close-img" src="/images/ic_close.svg" onClick={onDeleteReplyClick} />
                        </div>
                        <TextField customStyle={{ paddingRight: '40px', width: 'calc(100% - 14px - 14px - 26px)' }} field={{ error: '', value: message }} maxLength={300} onChange={(e) => setMessage(e.target.value)} placeholder={t('car_information_add_comment_placeholder')} />
                        <img alt="Send icon" className="send-img" src="/images/ic_send.svg" onClick={onSendClick} />
                    </div>
                    <div className="divider" />
                </>
            )}
            <div className="order-div">
                <label
                    className={`title ${tab === 'newestFirst' ? `active` : ``}`}
                    onClick={async (e) => {
                        setTab('newestFirst')
                        await getComments(props.car.id, 'newestFirst', true)
                    }}
                >
                    {t('car_information_newest_first_button')}
                </label>
                <label
                    className={`title ${tab === 'sellerComments' ? `active` : ``}`}
                    onClick={async (e) => {
                        setTab('sellerComments')
                        await getComments(props.car.id, 'sellerComments', true)
                    }}
                >
                    {t('car_information_seller_comments_button')}
                </label>
            </div>

            <ul className="list">
                {getCommentArray().map((comment) => (
                    <CommentElement comment={comment} hideReply={props.car.status !== CarStatusEnum.Approved} onReportClick={() => props.onReportClick(comment.id)} onReplyClick={(id, username) => onReplyClick(id, username)} />
                ))}
            </ul>

            <label className={`no-item-title ${pagination.count === 0 ? `` : `hidden`}`}> {t('car_information_no_comments_title')}</label>

            <div className={`bottom-div ${pagination.count <= pagination.pageSize * pagination.page ? `hidden` : ``}`}>
                <LoadMoreButton onClick={onLoadMoreClick} />
            </div>
        </div>
    )
}
