import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../redux'
import { createRef } from 'react'
import { Submenu } from './components/submenu'
import { FaqItem } from './components/faq-item'
import { Section } from './components/section'
import './faqs.page.scss'

interface FaqsPageProps {}

export const FaqsPage = (props: FaqsPageProps) => {
    const { t, i18n } = useTranslation()

    const { faqs } = useAppSelector((state) => state.faqs)
    const currentSection = useAppSelector((state) => state.faqs.currentSection)

    const filteredFaqs = faqs.filter((faq) => i18n.language.includes(faq.language ? faq.language.code : 'en')).sort((x) => x.order)

    const menuItems = filteredFaqs.map((x) => {
        return { title: x.title }
    })

    const refs = menuItems.map(() => createRef<HTMLDivElement | null>())

    return (
        <main className="page page-faqs">
            <div className="main-div">
                <div className="submenu-div-container">
                    <Submenu refs={refs} menuItems={menuItems} />
                </div>
                <div className="content-div">
                    <Section title={t('faqs_title')}></Section>
                    {filteredFaqs.map((header, index) => (
                        <Section key={header.id} title={header.title} ref={refs[index]} additionalClassNames="faq-section">
                            <div>
                                {header.faqItems?.map((question) => (
                                    <FaqItem key={`${header.id}_${question.id}`} title={question.question} body={question.answer} isCollapse={true}></FaqItem>
                                ))}
                            </div>
                        </Section>
                    ))}
                </div>
            </div>
        </main>
    )
}
