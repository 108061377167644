export enum Menu {
    Auctions = 0,
    HowItWorks = 1,
    EVDatabase = 2,
    AboutUs = 3,
    Contacts = 4,

    Terms = 5,
    Impressum = 6,
    Cookies = 7,
    Privacy = 8,

    MyProfile = 10,
    MyEVGarage = 11,
    MyActivity = 12,
    NotificationSettings = 13,

    FAQ = 14,
    Guide = 15,
    Links = 16,
    WhyGoElectric = 17,

    SellACar = 18,

    HowItWorks_HowItWorks = 19,
    HowItWorks_BuyingElectricCar = 20,
    HowItWorks_SellingElectricCar = 21,
    HowItWorks_ConcludingTheSale = 22,
    HowItWorks_WhatIsReservePrice = 23,
    HowItWorks_BatteryStateOfHealth = 24,

    AboutUs_WhyChooseLotNumberOne = 25,
    AboutUs_AboutUs = 26,
    AboutUs_YourExclusiveBenefits = 27,
}
