import { store } from '../../redux'
import { DefaultDTO, ErrorDTO } from '../../dto'
import { ErrorMessage, ShowToast } from '../../redux/actions'
import { NetworkCall } from '../../utils'

interface ReauctionProps {
    id: number
    onSuccess: () => void
}

export const reauction = async (props: ReauctionProps) => {
    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('POST', `/1.0.0/cars/${props.id}/reauction`)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(201, DefaultDTO, (responseBody) => {
            props.onSuccess()

            store.dispatch(ShowToast({ message: 'toast_reauction_success' }))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
