import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router-dom'
import { useAppSelector } from '../../redux'
import { CommentHistoryElement } from '../my-activity/components/comment-history-tab/comment-history-element'
import { LoadMoreButton } from '../../components'
import { BidHistoryPagination, CommentHistoryPagination } from '../../redux/actions'
import { useDispatch } from 'react-redux'
import { bidHistory, commentHistory } from '../../api'
import { BidHistoryElement } from '../my-activity/components/bid-history-tab/bid-history-element'
import { useEffect, useState } from 'react'
import { MessageModal } from '../../components/modals'
import { formatTimeLeftWithoutDay } from '../../utils/format-time-left'
import './user-profile.page.scss'

interface UserProfilePageProps {}

export const UserProfilePage = (props: UserProfilePageProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const userId = useLoaderData()

    const loggedInUser = useAppSelector((state) => state.login.user)

    const { user } = useAppSelector((state) => state.user.userDetail)
    const commentHistoryCars = useAppSelector((state) => state.user.commentHistory.cars)
    const commentHistoryCarsCount = useAppSelector((state) => state.user.commentHistory.pagination.count)

    const bidHistoryAuctions = useAppSelector((state) => state.user.bidHistory.auctions)
    const bidHistoryAuctionsCount = useAppSelector((state) => state.user.bidHistory.pagination.count)

    const carsWonAuctions = useAppSelector((state) => state.user.carsWon.auctions)
    const carsWonAuctionsCount = useAppSelector((state) => state.user.carsWon.pagination.count)

    const [messageModalVisibility, setMessageModalVisibility] = useState(false)

    useEffect(() => {
        if (!loggedInUser) {
            setMessageModalVisibility(!messageModalVisibility)
        }
    }, [loggedInUser])

    const onLoadMoreBidHistoryClick = async () => {
        dispatch(BidHistoryPagination({}))
        await bidHistory(userId as number)
    }

    const onLoadMoreCommentHistoryClick = async () => {
        dispatch(CommentHistoryPagination({}))
        await commentHistory(userId as number)
    }

    return (
        <main className="page page-user-profile">
            <div className="main-div">
                <div className="user-div">
                    <div className="profile-div">
                        <div className="profile-name-div">
                            <h2 className="name">
                                {/* //{user?.firstName != null ? user?.firstName : user?.companyName ?? ''} {user?.lastName != null ? user?.lastName : ''} */}
                                {user?.username}
                            </h2>

                            <label className="joined">{t('user_profile_joined_title') + ' ' + formatTimeLeftWithoutDay(user?.created ?? 0, t)}</label>
                        </div>

                        <div className="languages-div">
                            {user!!.languages?.filter((lng) => lng.code === 'fr').length !== 0 && <img alt="FR flag icon" className="flag-img" src="/images/ic_flag_fr.svg" />}
                            {user!!.languages?.filter((lng) => lng.code === 'de').length !== 0 && <img alt="DE flag icon" className="flag-img" src="/images/ic_flag_de.svg" />}
                            {user!!.languages?.filter((lng) => lng.code === 'en').length !== 0 && <img alt="EN flag icon" className="flag-img" src="/images/ic_flag_en.svg" />}
                            {user!!.languages?.filter((lng) => lng.code === 'it').length !== 0 && <img alt="IT flag icon" className="flag-img" src="/images/ic_flag_it.svg" />}
                        </div>
                    </div>

                    <div className="num-div">
                        <div className="item-div">
                            <h3 className="number">{bidHistoryAuctionsCount}</h3>
                            <label className="title">{t('user_profile_cars_bid_on_title')}</label>
                        </div>

                        <div className="divider-div" />

                        <div className="item-div">
                            <h3 className="number">{carsWonAuctionsCount}</h3>
                            <label className="title">{t('user_profile_cars_won_title')}</label>
                        </div>

                        <div className="divider-div" />

                        <div className="item-div">
                            <h3 className="number">{commentHistoryCarsCount}</h3>
                            <label className="title">{t('user_profile_comments_made_title')}</label>
                        </div>
                    </div>
                </div>

                <div className="inner-container-div">
                    <label className={`title`}>{t('user_profile_cars_bid_on_title') + ' (' + bidHistoryAuctionsCount + ')'}</label>

                    <div className="divider" />

                    <ul className={`history-list ${bidHistoryAuctionsCount !== 0 ? `` : `hidden`}`}>
                        {bidHistoryAuctions.map((auction, index) => (
                            <BidHistoryElement auction={auction} />
                        ))}
                    </ul>

                    <div className={`empty-list-div ${bidHistoryAuctionsCount !== 0 ? `hidden` : ``}`}>
                        <label>{t('my_activity_bid_history_empty_title')}</label>
                    </div>
                </div>

                <div className={`bottom-div ${bidHistoryAuctionsCount < 10 ? `hidden` : ``}`}>
                    <LoadMoreButton onClick={onLoadMoreBidHistoryClick} />
                </div>

                <div className="inner-container-div">
                    <label className={`title`}>{t('auction_comments_title') + ' (' + commentHistoryCarsCount + ')'}</label>

                    <div className="divider" />

                    <ul className={`history-list ${commentHistoryCarsCount !== 0 ? `` : `hidden`}`}>
                        {commentHistoryCars.map((car) => (
                            <CommentHistoryElement car={car} />
                        ))}
                    </ul>

                    <div className={`empty-list-div ${commentHistoryCarsCount !== 0 ? `hidden` : ``}`}>
                        <label>{t('my_activity_comment_history_empty_title')}</label>
                    </div>
                </div>

                <div className={`bottom-div ${commentHistoryCarsCount < 10 ? `hidden` : ``}`}>
                    <LoadMoreButton onClick={onLoadMoreCommentHistoryClick} />
                </div>
            </div>

            {messageModalVisibility ? <MessageModal showClose={false} message={t('modal_user_profile_login_message')} title={t('modal_user_profile_login_title')} onCloseClick={() => setMessageModalVisibility(!messageModalVisibility)} /> : null}
        </main>
    )
}
