import { Expose, Type } from "class-transformer";
import {IsDefined, IsNumber, ValidateNested} from "class-validator";
import { Notification } from '../../entity';

export class ListNotificationsDTO {
    @Expose()
    @IsDefined()
    @ValidateNested()
    @Type( () => Notification)
    data!: Notification[];

    @Expose()
    @IsDefined()
    @IsNumber()
    page!: number;

    @Expose()
    @IsDefined()
    @IsNumber()
    pageSize!: number;

    @Expose()
    @IsDefined()
    @IsNumber()
    count!: number;

}