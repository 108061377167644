import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../redux'
import { Section } from '../ev-database/components/section'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { getEvDatabaseCar } from '../../api'
import { FunctionComponent } from 'react'
import { SectionTitle } from '../ev-database/components/section-title'
import { SectionBody } from '../ev-database/components/section-body'
import './ev-database-detail.page.scss'

interface EvDatabaseDetailPageProps {}

export const EvDatabaseDetailPage = (props: EvDatabaseDetailPageProps) => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation()

    useEffect(() => {
        getEvDatabaseCar(Number(id))
    }, [id])

    const { car } = useAppSelector((state) => state.evDatabase)

    type ContentDivBoxProps = {
        image: string
        title: String
        value: number | null | undefined
        unit: String
    }

    const ContentDivBox: FunctionComponent<ContentDivBoxProps> = ({ image, title, value, unit }) => {
        return (
            <div className="content-div-box">
                <img src={image} width={'36px'}></img>
                <div className="content-div-box-body">
                    <div>{title}</div>
                    <h4>
                        {value} {unit}
                    </h4>
                </div>
            </div>
        )
    }

    type DetailRowProps = {
        title: String
        value: String | null | undefined
        boolValue?: boolean | null | undefined
        unit: String
        isEven?: boolean
        isInvertEvenOnSmallScreen?: boolean
    }

    const DetailRow: FunctionComponent<DetailRowProps> = ({ title, value, unit, isEven, boolValue, isInvertEvenOnSmallScreen }) => {
        const getAdditionalClass = () => {
            let additionalClass = ''
            if (isEven) {
                additionalClass = ' even'
            } else {
                additionalClass = ' odd'
            }
            if (isInvertEvenOnSmallScreen) {
                additionalClass += '-inverted'
            }

            return additionalClass
        }

        return (
            <div className={'detail-row' + getAdditionalClass()}>
                <div className="opacity">{title}</div>
                {(boolValue === null || boolValue === undefined) && (
                    <div className="value-unit">
                        {value === null || value === undefined ? '-' : value} {unit}
                    </div>
                )}
                {boolValue !== null && boolValue !== undefined && boolValue && <img src="/images/ev-database/ic_yes.svg"></img>}
                {boolValue !== null && boolValue !== undefined && !boolValue && <img src="/images/ev-database/ic_no.svg"></img>}
            </div>
        )
    }

    return (
        <main className="page page-ev-database-detail">
            <div className="main-div">
                <div className="main-div__back" onClick={() => navigate(-1)}>
                    <img className="link-img" alt="" src="/images/ev-database/ic_back.svg" />
                </div>
                <Section>
                    <SectionTitle>
                        <div className="content-title">
                            <h2>{`${car?.make} ${car?.model} ${car?.modelVersion ? car?.modelVersion : ''}`}</h2>
                            <div className="content-title__back" onClick={() => navigate(-1)}>
                                <img className="link-img" alt="" src="/images/ev-database/ic_back.svg" />
                                <span className="link-text">{t('ev_database_detail_back_to_list')}</span>
                            </div>
                        </div>
                    </SectionTitle>
                    <SectionBody>
                        <div className="content-div-boxes">
                            <ContentDivBox image="/images/ic_battery_capacity.svg" title={t('ev_database_detail_battery_capacity')} value={car?.batteryCapacity} unit={'kWh'} />
                            <ContentDivBox image="/images/ic_range.svg" title={t('ev_database_detail_range_wltp')} value={car?.rangeWLTP} unit={'km'} />
                            <ContentDivBox image="/images/ic_efficiency.svg" title={t('ev_database_detail_efficiency')} value={car?.efficiency} unit={'Wh/km'} />
                        </div>
                        <div className="separator"></div>
                        <h4>{t('ev_database_detail_real_range')}</h4>
                        <div className="detail-groups">
                            <div className="detail-group">
                                <div className="detail-rows">
                                    <DetailRow title={t('ev_database_detail_real_range_city_cold')} value={car?.realRangeCityCold?.toString()} unit={'km'} />
                                    <DetailRow title={t('ev_database_detail_real_range_highway_cold')} value={car?.realRangeHighwayCold?.toString()} unit={'km'} isEven={true} />
                                    <DetailRow title={t('ev_database_detail_real_range_combined_cold')} value={car?.realRangeCombinedCold?.toString()} unit={'km'} />
                                </div>
                            </div>
                            <div className="detail-group">
                                <div className="detail-rows">
                                    <DetailRow title={t('ev_database_detail_real_range_city_mild')} value={car?.realRangeCityMild?.toString()} unit={'km'} isInvertEvenOnSmallScreen={true} />
                                    <DetailRow title={t('ev_database_detail_real_range_highway_mild')} value={car?.realRangeHighwayMild?.toString()} unit={'km'} isEven={true} isInvertEvenOnSmallScreen={true} />
                                    <DetailRow title={t('ev_database_detail_real_range_combined_mild')} value={car?.realRangeCombinedMild?.toString()} unit={'km'} isInvertEvenOnSmallScreen={true} />
                                </div>
                            </div>
                        </div>
                        <h4>{t('ev_database_detail_performance')}</h4>
                        <div className="detail-groups">
                            <div className="detail-group">
                                <div className="detail-rows">
                                    <DetailRow title={t('ev_database_detail_performance')} value={car?.acceleration?.toString()} unit={'sec'} />
                                    <DetailRow title={t('ev_database_detail_top_speed')} value={car?.topSpeed?.toString()} unit={'km/h'} isEven={true} />
                                    <DetailRow title={t('ev_database_detail_electric_range')} value={car?.electricRange?.toString()} unit={'km'} />
                                </div>
                            </div>
                            <div className="detail-group">
                                <div className="detail-rows">
                                    <DetailRow title={t('ev_database_detail_total_power')} value={car?.totalPower !== null && car?.totalPower !== undefined ? `${car!.totalPower!.toString()}` : null} unit={car?.totalPower !== null && car?.totalPower !== undefined ? `kW (${(car!.totalPower! * 1.36).toFixed(2)} PS)` : ''} isInvertEvenOnSmallScreen={true} />
                                    <DetailRow title={t('ev_database_detail_total_torque')} value={car?.totalTorque?.toString()} unit={'Nm'} isEven={true} isInvertEvenOnSmallScreen={true} />
                                    <DetailRow title={t('ev_database_detail_drive')} value={car?.drive} unit={''} isInvertEvenOnSmallScreen={true} />
                                </div>
                            </div>
                        </div>
                        <h4>{t('ev_database_detail_battery')}</h4>
                        <div className="detail-groups">
                            <div className="detail-group">
                                <div className="detail-rows">
                                    <DetailRow title={t('ev_database_detail_battery_left1')} value={car?.batteryNominalCapacity?.toString()} unit={'kWh'} />
                                    <DetailRow title={t('ev_database_detail_battery_left2')} value={car?.batteryUsableCapacity?.toString()} unit={'kWh'} isEven={true} />
                                    <DetailRow title={t('ev_database_detail_battery_left3')} value={car?.batteryType?.toString()} unit={''} />
                                    <DetailRow title={t('ev_database_detail_battery_left4')} value={car?.batteryCells?.toString()} unit={''} isEven={true} />
                                    <DetailRow title={t('ev_database_detail_battery_left5')} value={car?.batteryArchitecture?.toString()} unit={''} />
                                </div>
                            </div>
                            <div className="detail-group">
                                <div className="detail-rows">
                                    <DetailRow title={t('ev_database_detail_battery_right1')} value={car?.batteryCathodeMaterial?.toString()} unit={''} isInvertEvenOnSmallScreen={true} />
                                    <DetailRow title={t('ev_database_detail_battery_right2')} value={car?.batteryPackConfiguration?.toString()} unit={''} isEven={true} isInvertEvenOnSmallScreen={true} />
                                    <DetailRow title={t('ev_database_detail_battery_right3')} value={car?.batteryNominalVoltage?.toString()} unit={'V'} isInvertEvenOnSmallScreen={true} />
                                </div>
                            </div>
                        </div>
                        <h4>{t('ev_database_detail_charging')}</h4>
                        <div className="detail-groups">
                            <div className="detail-group">
                                <div className="detail-rows">
                                    <DetailRow title={t('ev_database_detail_charging_left1')} value={car?.chargePort?.toString()} unit={''} />
                                    <DetailRow title={t('ev_database_detail_charging_left2')} value={car?.chargePortPrimaryLocation?.toString()} unit={''} isEven={true} />
                                    <DetailRow title={t('ev_database_detail_charging_left3')} value={car?.chargePortSecondaryLocation?.toString()} unit={''} />
                                    <DetailRow title={t('ev_database_detail_charging_left4')} value={car?.chargePower?.toString()} unit={'kW AC'} isEven={true} />
                                    <DetailRow title={t('ev_database_detail_charging_left5')} value={car?.chargeTime?.toString()} unit={''} />
                                    <DetailRow title={t('ev_database_detail_charging_left6')} value={car?.chargeSpeed?.toString()} unit={'km/h'} isEven={true} />
                                </div>
                            </div>
                            <div className="detail-group">
                                <div className="detail-rows">
                                    <DetailRow title={t('ev_database_detail_charging_right1')} value={car?.fastChargePort?.toString()} unit={''} />
                                    <DetailRow title={t('ev_database_detail_charging_right2')} value={car?.fastChargePortLocation?.toString()} unit={''} isEven={true} />
                                    <DetailRow title={t('ev_database_detail_charging_right3')} value={car?.fastChargePowerMax?.toString()} unit={'kW DC'} />
                                    <DetailRow title={t('ev_database_detail_charging_right4')} value={car?.fastChargePower?.toString()} unit={'kW DC'} isEven={true} />
                                    <DetailRow title={t('ev_database_detail_charging_right5')} value={car?.fastChargeTime?.toString()} unit={'min'} />
                                    <DetailRow title={t('ev_database_detail_charging_right6')} value={car?.fastChargeSpeed?.toString()} unit={'km/h'} isEven={true} />
                                    <DetailRow title={t('ev_database_detail_charging_right7')} value={car?.autoChargeSupported?.toString()} unit={''} boolValue={car?.autoChargeSupported} />
                                </div>
                            </div>
                        </div>
                        <h4>{t('ev_database_detail_energy_consumption')}</h4>
                        <div className="detail-groups">
                            <div className="detail-group">
                                <div className="detail-rows">
                                    <DetailRow title={t('ev_database_detail_energy_consumption_left1')} value={car?.vehicleConsumption?.toString()} unit={'Wh/km'} />
                                    <DetailRow title={t('ev_database_detail_energy_consumption_left2')} value={car?.co2Emission?.toString()} unit={'g/km'} isEven={true} />
                                </div>
                            </div>
                            <div className="detail-group">
                                <div className="detail-rows">
                                    <DetailRow title={t('ev_database_detail_energy_consumption_right1')} value={car?.fuelEquivalent?.toString()} unit={'l/100km'} />
                                </div>
                            </div>
                        </div>
                        <h4>{t('ev_database_detail_dimensions')}</h4>
                        <div className="detail-groups">
                            <div className="detail-group">
                                <div className="detail-rows">
                                    <DetailRow title={t('ev_database_detail_dimensions_left1')} value={car?.length?.toString()} unit={'mm'} />
                                    <DetailRow title={t('ev_database_detail_dimensions_left2')} value={car?.width?.toString()} unit={'mm'} isEven={true} />
                                    <DetailRow title={t('ev_database_detail_dimensions_left3')} value={car?.widthWithMirrors?.toString()} unit={'mm'} />
                                    <DetailRow title={t('ev_database_detail_dimensions_left4')} value={car?.height?.toString()} unit={'mm'} isEven={true} />
                                    <DetailRow title={t('ev_database_detail_dimensions_left5')} value={car?.wheelbase?.toString()} unit={'mm'} />
                                    <DetailRow title={t('ev_database_detail_dimensions_left6')} value={car?.weightUnladen?.toString()} unit={'kg'} isEven={true} />
                                    <DetailRow title={t('ev_database_detail_dimensions_left7')} value={car?.grossVehicleWeight?.toString()} unit={'kg'} />
                                    <DetailRow title={t('ev_database_detail_dimensions_left8')} value={car?.maxPayload?.toString()} unit={'kg'} isEven={true} />
                                </div>
                            </div>
                            <div className="detail-group">
                                <div className="detail-rows">
                                    <DetailRow title={t('ev_database_detail_dimensions_right1')} value={car?.cargoVolume?.toString()} unit={'L'} />
                                    <DetailRow title={t('ev_database_detail_dimensions_right2')} value={car?.cargoVolumeMax?.toString()} unit={''} isEven={true} />
                                    <DetailRow title={t('ev_database_detail_dimensions_right3')} value={car?.cargoVolumeFrunk?.toString()} unit={'L'} />
                                    <DetailRow title={t('ev_database_detail_dimensions_right4')} value={car?.roofLoad?.toString()} unit={'kg'} isEven={true} />
                                    <DetailRow title={t('ev_database_detail_dimensions_right5')} value={car?.towHitchPossible?.toString()} unit={''} boolValue={car?.towHitchPossible} />
                                    <DetailRow title={t('ev_database_detail_dimensions_right6')} value={car?.towingWeightUnbraked?.toString()} unit={'kg'} isEven={true} />
                                    <DetailRow title={t('ev_database_detail_dimensions_right7')} value={car?.towingWeightBraked?.toString()} unit={'kg'} />
                                    <DetailRow title={t('ev_database_detail_dimensions_right8')} value={car?.verticalLoadMax?.toString()} unit={'kg'} isEven={true} />
                                </div>
                            </div>
                        </div>
                        <label>{t('ev_database_detail_source')}</label>
                    </SectionBody>
                </Section>
            </div>
        </main>
    )
}
