import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, ValidateIf } from 'class-validator'
import { User } from './user.entity'

export class Bid {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    amount!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    buyersFee!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== undefined)
    payToSeller!: number | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    user!: User | null
}

