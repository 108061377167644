export const monthToUpper = (str: string): string => {
    const monthNames: {[index: string]: string} = {
        janvier: 'Janvier',
        février: 'Février',
        mars: 'Mars',
        avril: 'Avril',
        mai: 'Mai',
        juin: 'Juin',
        juillet: 'Juillet',
        août: 'Août',
        septembre: 'Septembre',
        octobre: 'Octobre',
        november: 'November',
        décembre: 'Décembre'
    }
    for (const monthName of Object.keys(monthNames)) {
        str = str.replaceAll(monthName, monthNames[monthName]);
    }
    return str;
}