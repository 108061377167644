import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { store, useAppSelector } from '../../../../redux'
import { Button, Dropdown, RangeSlider } from '../../../../components'
import { getEvDatabaseCars, getEvDatabaseFilterData } from '../../../../api'
import { UpdateEvDatabaseCarsParams } from '../../../../redux/actions'

import './filter.scss'

interface FilterProps {
    isInModal: boolean
    onClose: () => void
}

export const Filter = (props: FilterProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const minRangeInitValue = 0
    const maxRangeInitValue = 1000
    const minCapacityInitValue = 1
    const maxCapacityInitValue = 200

    const { make, model, minRange, maxRange, minCapacity, maxCapacity, chargePlug } = useAppSelector((state) => state.evDatabase)
    const makeArray = useAppSelector((state) => state.evDatabase.filters.make)
    const modelArray = useAppSelector((state) => state.evDatabase.filters.model)
    const chargePlugArray = useAppSelector((state) => state.evDatabase.filters.chargePlug)

    const onClearAllClick = async () => {
        dispatch(UpdateEvDatabaseCarsParams({ make: null, model: null, minRange: minRangeInitValue, maxRange: maxRangeInitValue, minCapacity: minCapacityInitValue, maxCapacity: maxCapacityInitValue, chargePlug: null }))
        await getEvDatabaseFilterData()
        await getEvDatabaseCars(true)
    }

    const onViewResultClick = async () => {
        await getEvDatabaseCars(true)
    }

    const getMakeArray = () => {
        const items: [{ id: string; value: string }] = [{ id: '', value: t('modal_report_select_title') }]
        makeArray.map((item, index) => items.push({ id: item, value: item }))
        items.shift()
        return items
    }

    const getModelArray = () => {
        const items: [{ id: string; value: string }] = [{ id: '', value: t('modal_report_select_title') }]
        modelArray.map((item, index) => items.push({ id: item, value: item }))
        items.shift()
        return items
    }

    const getChargePlugArray = () => {
        const items: [{ id: string; value: string }] = [{ id: '', value: t('modal_report_select_title') }]
        chargePlugArray.map((item, index) => items.push({ id: item, value: item }))
        items.shift()
        return items
    }

    return (
        <div className="ev-filter">
            <div className="ev-filter__title">
                <div className="ev-filter__title__row">
                    {<div className="ev-filter__title__row__text">{!props.isInModal ? t('filter_filter_by') : ''}</div>}
                    <div className="ev-filter__title__row__link" onClick={onClearAllClick}>
                        {t('filter_clear_all')}
                    </div>
                </div>
            </div>
            <Dropdown
                deleteEnabled={false}
                selectItemTitle={t('filter_make')}
                value={make}
                items={getMakeArray()}
                error={''}
                onChange={async (id: string) => {
                    dispatch(UpdateEvDatabaseCarsParams({ make: id }))
                    await getEvDatabaseFilterData()
                }}
            />

            <Dropdown
                deleteEnabled={false}
                selectItemTitle={t('filter_model')}
                value={model}
                items={getModelArray()}
                error={''}
                onChange={async (id: string) => {
                    dispatch(UpdateEvDatabaseCarsParams({ model: id }))
                    await getEvDatabaseFilterData()
                }}
            />

            <div className="ev-filter__item__slider">
                <div className="ev-filter__item__slider__title">{t('filter_range')}</div>
                <RangeSlider
                    min={minRangeInitValue}
                    max={maxRangeInitValue}
                    unit="km"
                    onChange={async (id: string, value: number) => {
                        if (id === 'min') {
                            dispatch(UpdateEvDatabaseCarsParams({ minRange: value }))
                        } else if (id === 'max') {
                            dispatch(UpdateEvDatabaseCarsParams({ maxRange: value }))
                        }
                    }}
                    minVal={minRange ?? minRangeInitValue}
                    maxVal={maxRange ?? maxRangeInitValue}
                ></RangeSlider>
            </div>
            <div className="ev-filter__item__slider">
                <div className="ev-filter__item__slider__title">{t('filter_battery_capacity')}</div>
                <RangeSlider
                    min={minCapacityInitValue}
                    max={maxCapacityInitValue}
                    unit="kWh"
                    onChange={async (id: string, value: number) => {
                        if (id === 'min') {
                            dispatch(UpdateEvDatabaseCarsParams({ minCapacity: value }))
                        } else if (id === 'max') {
                            dispatch(UpdateEvDatabaseCarsParams({ maxCapacity: value }))
                        }
                    }}
                    minVal={minCapacity ?? minCapacityInitValue}
                    maxVal={maxCapacity ?? maxCapacityInitValue}
                ></RangeSlider>
            </div>

            <div className="ev-filter__item">
                <div className="ev-filter__item__title">{t('filter_charge_plug')}</div>
                <Dropdown
                    deleteEnabled={false}
                    selectItemTitle={t('filter_all_type')}
                    value={chargePlug}
                    items={getChargePlugArray()}
                    error={''}
                    onChange={async (id: string) => {
                        dispatch(UpdateEvDatabaseCarsParams({ chargePlug: id }))
                        await getEvDatabaseFilterData()
                    }}
                />
            </div>
            <div className="ev-filter__button">
                <Button
                    title={t('ev_database_filter_button_title')}
                    onClick={(e) => {
                        let rec = store.getState().evDatabase
                        dispatch(UpdateEvDatabaseCarsParams({ make: rec.make, model: rec.model, minRange: rec.minRange, maxRange: rec.maxRange, minCapacity: rec.minCapacity, maxCapacity: rec.maxCapacity, chargePlug: rec.chargePlug }))
                        props.onClose()
                        onViewResultClick()
                    }}
                />
            </div>
        </div>
    )
}
