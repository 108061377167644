import { Expose } from 'class-transformer'
import { IsBoolean, IsDefined, IsIn, IsNumber, IsOptional, IsString, ValidateIf } from 'class-validator'
import { Address, Language, NotificationSettings } from './../entity'
import { RoleEnum } from '../enum'

export class User {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null)
    firstName!: string | null

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null)
    lastName!: string | null

    @Expose()
    @IsDefined()
    @IsString()
    username!: string

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null)
    companyName!: string | null

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null)
    uidNumber!: string | null

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null)
    vatNumber!: string | null

    @Expose()
    @IsDefined()
    @IsString()
    email!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    @IsIn(
        Object.keys(RoleEnum)
            .map((key: string) => parseInt(key))
            .filter((key: number) => !isNaN(key))
    )
    @ValidateIf((object, value) => value !== null)
    role!: number | null

    @Expose()
    @IsDefined()
    @IsBoolean()
    newsletter!: boolean

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null)
    phoneNumber!: string | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null)
    termsVersion!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null)
    privacyVersion!: number | null

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null)
    stripeCustomerId!: string | null

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null)
    stripeCardId!: string | null

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    languages?: Language[] | null

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    address!: Address | null

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    notificationSettings?: NotificationSettings | null

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number
}


