import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { TextField } from '../../../../components/field'
import { useAppSelector } from '../../../../redux'
import { ErrorMessage, UpdateCarForm } from '../../../../redux/actions'
import { DragAnddrop } from '../photos-tab/components/drag-and-drop/drag-and-drop'
import { uplpoadDocument } from '../../../../api'
import { ChangeEvent, useEffect, useState } from 'react'
import { Document } from '../../../../entity'
import { DocumentItem } from './document-item/document-item'
import { LinearProgress } from '../../../../components'
import './additional.tab.scss'
import '../tab.scss'

interface AdditionalTabProps {}

export const AdditionalTab = (props: AdditionalTabProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { form } = useAppSelector((state) => state.car)
    const { documents } = useAppSelector((state) => state.car.form)
    const [progressVisible, setProgressVisible] = useState(false)

    const MAX_FILE_SIZE = 1024 * 10 // 10MB
    const MAX_DOCUMENT_COUNT = 10

    const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files
        if (selectedFiles && selectedFiles.length > 0) {
            Array.from(selectedFiles).map((selectedFile) => {
                const fileSizeKiloBytes = selectedFile.size / 1024
                if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                    dispatch(ErrorMessage({ title: '', message: t('error_add_car_maximum_file_size'), code: 600007 }))
                    return
                }
            })

            setProgressVisible(true)

            await uplpoadDocument({
                files: Array.from(selectedFiles),
                onSuccess() {
                    setProgressVisible(false)
                },
                onError() {
                    setProgressVisible(false)
                },
            })
        }
    }

    return (
        <main className="tab tab-additional">
            <div className="tab-div">
                <h2>{t('add_car_title')}</h2>

                <h4>{t('add_car_additional_information_title')}</h4>

                <div className="container-div">
                    <label>{t('add_car_additional_info_desc')}</label>

                    <TextField title={t('add_car_owners_take_title')} description={t('add_car_ownership_history_desc')} maxLength={500} field={form.ownersTake} onChange={(e) => dispatch(UpdateCarForm({ key: 'ownersTake', value: e.target.value, error: null }))} placeholder={t('add_car_tell_your_audience_title')} />

                    <TextField title={t('add_car_ownership_history_title')} description={t('add_car_ownership_history_desc')} maxLength={500} field={form.ownershipHistory} onChange={(e) => dispatch(UpdateCarForm({ key: 'ownershipHistory', value: e.target.value, error: null }))} placeholder={t('add_car_ownership_history_placeholder')} />

                    <TextField title={t('add_car_service_history_and_repairs_title')} description={t('add_car_ownership_history_desc')} maxLength={500} field={form.serviceHistoryAndRepairs} onChange={(e) => dispatch(UpdateCarForm({ key: 'serviceHistoryAndRepairs', value: e.target.value, error: null }))} placeholder={t('add_car_service_history_and_repairs_placeholder')} />

                    <TextField title={t('add_car_other_items_included_title')} description={t('add_car_ownership_history_desc')} maxLength={500} field={form.otherItemsIncluded} onChange={(e) => dispatch(UpdateCarForm({ key: 'otherItemsIncluded', value: e.target.value, error: null }))} placeholder={t('add_car_other_items_included_placeholder')} />

                    <div className="documents-title-div">
                        <div className="documents-sub-title-div">
                            <h5>{t('add_car_upload_documents_title')}</h5>
                            <label>{t('add_car_please_redsact_title')}</label>
                        </div>

                        <label>{t('add_car_upload_documents_placeholder')}</label>
                    </div>

                    <DragAnddrop
                        onFilesSelected={async (files: File[]) => {
                            files.map((selectedFile) => {
                                const fileSizeKiloBytes = selectedFile.size / 1024
                                if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                                    dispatch(ErrorMessage({ title: '', message: t('error_add_car_maximum_file_size'), code: 600007 }))
                                    return
                                }
                            })
                            setProgressVisible(true)

                            await uplpoadDocument({
                                files: files,
                                onSuccess() {
                                    setProgressVisible(false)
                                },
                                onError() {
                                    setProgressVisible(false)
                                },
                            })
                        }}
                        disabled={documents.length < MAX_DOCUMENT_COUNT}
                        innnerElement={
                            <div className="upload-div">
                                <div className="drop-div">
                                    <img alt="Upload icon" className="upload-img" src="/images/ic_upload.svg" />
                                </div>
                                <label>{t('add_car_drop_your_file_here_title')}</label>
                                <label>{t('add_car_or_title')}</label>

                                <input type="file" hidden id="browse-file" onChange={onFileChange} accept=".pdf, .jpg, .jpeg" />

                                <label htmlFor="browse-file" className="browse-button">
                                    {t('button_browse')}
                                </label>

                                <label className="type">{'PDF, JPG (maximum file size: 10 MB)'}</label>
                            </div>
                        }
                    />

                    {documents.length > 0 && (
                        <div className={`documents-div`}>
                            <h5>{t('add_car_upload_documents_title')}</h5>

                            <div className="documents-list">
                                {documents.map((document: Document, index: number) => (
                                    <DocumentItem document={document} />
                                ))}
                            </div>
                        </div>
                    )}

                    {documents.length > 0 && (
                        <div className="image-count">
                            <p>
                                {t('add_car_uploaded_document_title')} {documents.length}/{MAX_DOCUMENT_COUNT}
                            </p>
                        </div>
                    )}

                    {progressVisible && (
                        <div className={`progress-div`}>
                            <label>{t('add_car_uploading_title')}</label>
                            <LinearProgress percent={10} />
                        </div>
                    )}

                    <TextField title={t('add_car_modifications_title')} description={t('add_car_ownership_history_desc')} maxLength={500} field={form.modifications} onChange={(e) => dispatch(UpdateCarForm({ key: 'modifications', value: e.target.value, error: null }))} placeholder={''} />

                    <TextField title={t('add_car_known_problems_or_flaws_title')} description={t('add_car_ownership_history_desc')} maxLength={500} field={form.knownProblemsOrFlaws} onChange={(e) => dispatch(UpdateCarForm({ key: 'knownProblemsOrFlaws', value: e.target.value, error: null }))} placeholder={''} />

                    <TextField title={t('car_information_other_information_title')} description={t('add_car_ownership_history_desc')} maxLength={500} field={form.otherInformation} onChange={(e) => dispatch(UpdateCarForm({ key: 'otherInformation', value: e.target.value, error: null }))} placeholder={t('add_car_other_information_placholder')} />
                </div>
            </div>
        </main>
    )
}
