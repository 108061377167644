import { useTranslation } from 'react-i18next'
import './highlights.scss'

interface HighlightsProps {}

export const Highlights = (props: HighlightsProps) => {
    const { t } = useTranslation()

    return (
        <section className={`highlights`}>
            <div className="highlights__item">
                <h2 className={`title`}> {t('highlight_first_title')}</h2>
                <label className={`body_text`}> {t('highlight_first_desc')}</label>
            </div>

            <div className="highlights__divider" />

            <div className="highlights__item">
                <h2 className={`title`}> {t('highlight_second_title')}</h2>
                <label className={`body_text`}> {t('highlight_second_desc')}</label>
            </div>

            <div className="highlights__divider" />

            <div className="highlights__item">
                <h2 className={`title`}> {t('highlight_third_title')}</h2>
                <label className={`body_text`}> {t('highlight_third_desc')}</label>
            </div>
        </section>
    )
}
