import { LoaderFunctionArgs } from 'react-router-dom'
import { bidHistory, carsWon, commentHistory, getUser } from '../api'
import { store } from '../redux'
import { ClearUserProfile } from '../redux/actions'

export const userProfilePageLoader = async (args: LoaderFunctionArgs) => {
    store.dispatch(ClearUserProfile({}))

    const userId = parseInt(args.params.id as string)
    await getUser(userId)

    await bidHistory(userId, true)
    await commentHistory(userId, true)
    await carsWon(userId, true)

    return userId
}
