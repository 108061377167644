import { useTranslation } from 'react-i18next'
import { Checkbox } from '../../checkbox'
import { useDispatch } from 'react-redux'
import { HideAcceptTermsModal } from '../../../redux/actions'
import { Button } from '../../buttons'
import { Modal } from '../modal'
import { useState } from 'react'
import { modifyTermsAndPrivacyVersion } from '../../../api/users'
import { Link } from 'react-router-dom'
import './accept-terms.modal.scss'

export const AcceptTermsModal = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [accepted, setAccepted] = useState(false)
    const [error, setError] = useState('')

    const onClose = async () => {
        if (accepted === false) {
            setError(t('modal_accept_terms_error'))
            return
        }
        localStorage.removeItem(`termsChanged`)
        await modifyTermsAndPrivacyVersion()
        dispatch(HideAcceptTermsModal())
    }

    return (
        <Modal className="modal--accept-terms" title={t('modal_accept_terms_title')} showClose={false}>
            <div className="accept-terms-modal">
                <div className="accept-terms-modal__description">
                    {t('modal_accept_terms_description')}
                    <br />
                </div>
                <Link to="/terms" onClick={() => dispatch(HideAcceptTermsModal())}>
                    {t('modal_accept_terms_terms')}
                </Link>
                <Link to="/privacy-policy" onClick={() => dispatch(HideAcceptTermsModal())}>
                    {t('modal_accept_terms_privacy')}
                </Link>
                <div className="accept-terms-modal__accept-div">
                    <Checkbox error={error} title={t('modal_accept_terms_accepted')} checked={accepted} id="accept" onChange={(e) => setAccepted(!accepted)} />
                </div>

                <div className="accept-terms-modal__button-div">
                    <Button title={t('button_continue')} onClick={onClose} />
                </div>
            </div>
        </Modal>
    )
}
