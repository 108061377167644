import { Children, PropsWithChildren } from 'react'
import './card-row.scss'

interface CardRowProps {
    additionalClassNames?: string
}

export const CardRow = (props: PropsWithChildren<CardRowProps>) => {
    const _getColsClassName = () => {
        switch (Children.count(props.children)) {
            case 1:
                return 'one-col'
            case 2:
                return 'two-cols'
            case 3:
                return 'three-cols'
            default:
                throw new Error(`${Children.count(props.children)} is an unsupported card column count.`)
        }
    }

    const _getCardRowClassNames = () => ['card-row', _getColsClassName(), props.additionalClassNames ?? ''].join(' ').trim()

    return <>{props.children && <div className={_getCardRowClassNames()}>{props.children}</div>}</>
}
