import { createAction } from '@reduxjs/toolkit'
import { Car } from '../../../entity'

interface Payload {
    cars: Car[]
    page: number
    pageSize: number
    didNotSellCount: number
    soldCount: number
    reset: boolean
}

export const CarsSoldSuccess = createAction<Payload>('CARS__CARS_SOLD_SUCCESS')
