import { store } from '../../redux'
import { ErrorDTO } from '../../dto'
import { ErrorMessage } from '../../redux/actions'
import { NetworkCall } from '../../utils'

interface TranslateProps {
    text: string
    language: string
    onSuccess: (text: string) => void
}

export const translate = async (props: TranslateProps) => {
    const requestBody = {
        text: props.text,
    }

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('POST', `/1.0.0/translate`, requestBody)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .setHeader('Language', props.language)
        .then(201, (responseBody) => {
            props.onSuccess(responseBody.text as string)
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
