import { useState } from 'react'
import { Modal } from '../modal'
import { Button } from '../../buttons'
import { InputField } from '../../field'
import { useTranslation } from 'react-i18next'
import { deleteUser } from '../../../api'
import './delete-account.modal.scss'

interface DeleteAccountModalProps {
    onClose: () => void
    onDelete: () => void
}

export const DeleteAccountModal = (props: DeleteAccountModalProps) => {
    const { t } = useTranslation()

    const [error, setError] = useState('')
    const [key, setKey] = useState('')

    const onDeleteClick = async () => {
        setError('')

        if (key.length === 0 || key !== 'DELETE') {
            setError(t('error_delete_account'))
        } else {
            await deleteUser({
                onSuccess() {
                    props.onDelete()
                },
            })
        }
    }

    return (
        <Modal title={t('modal_delete_account_title')} onCloseClick={props.onClose}>
            <div className="delete-account-modal">
                <div className="">
                    <h3 className="sub-title">{t('modal_delete_account_sub_title')}</h3>

                    <label className="description">{t('modal_delete_account_description')}</label>
                </div>

                <InputField
                    type="text"
                    field={{ error: error, value: key }}
                    onChange={(e) => {
                        setError('')
                        setKey(e.target.value)
                    }}
                    title={t('modal_delete_account_hint')}
                    placeholder={''}
                />

                <label className="small-text">{t('modal_delete_account_bottom_description')}</label>

                <div className="delete-account-modal__button-div">
                    <Button default={true} title={t('button_cancel')} onClick={props.onClose} />
                    <Button title={t('button_delete_account').toUpperCase()} onClick={onDeleteClick} />
                </div>
            </div>
        </Modal>
    )
}
