import { Expose } from 'class-transformer'
import { IsBoolean, IsDefined, IsIn, IsNumber, IsOptional, IsString, ValidateIf, isBoolean } from 'class-validator'
import { Auction, MediaFile, CarDetail, Comment, User, Address } from './../entity'
import { BatteryStatusEnum, CarStatusEnum, DrivetrainEnum } from '../enum'

export class Car {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsString()
    vin!: string

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null)
    variant!: string | null

    @Expose()
    @IsDefined()
    @IsString()
    make!: string

    @Expose()
    @IsDefined()
    @IsString()
    model!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    manufacturingYear!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    batteryCapacity!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    manufacturerEstimatedRange!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    horsepower!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    mileage!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    @IsIn(
        Object.keys(BatteryStatusEnum)
            .map((key: string) => parseInt(key))
            .filter((key: number) => !isNaN(key))
    )
    @ValidateIf((object, value) => value !== null)
    batteryStatus!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @IsIn(
        Object.keys(DrivetrainEnum)
            .map((key: string) => parseInt(key))
            .filter((key: number) => !isNaN(key))
    )
    drivetrain!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null)
    chargingConnectorAc!: number | null

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null)
    chargingConnectorAcOtherName!: string | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null)
    chargingConnectorDc!: number | null

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null)
    chargingConnectorDcOtherName!: string | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null)
    maximumAcCharging!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null)
    maximumDcCharging!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null)
    mfkDate!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null)
    validWarranty!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null)
    reservePrice?: number | null

    @Expose()
    @IsDefined()
    @IsBoolean()
    hasReservePrice!: boolean

    @Expose()
    @IsDefined()
    @IsNumber()
    views!: number

    @Expose()
    @IsDefined()
    @IsBoolean()
    @ValidateIf((object, value) => value !== undefined)
    favourite!: boolean | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @IsIn(
        Object.keys(CarStatusEnum)
            .map((key: string) => parseInt(key))
            .filter((key: number) => !isNaN(key))
    )
    status!: number

    @Expose()
    @ValidateIf((object, value) => value !== null)
    carDetail?: CarDetail | null

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    auctions!: Auction[]

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    mediaFiles?: MediaFile[] | null

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    comments!: Comment[] | null

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    user!: User | null

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    address!: Address | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== undefined)
    commentCount!: number | undefined

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== undefined)
    mediaFileCount!: number | undefined

    @Expose()
    @IsDefined()
    @IsBoolean()
    reservePriceLowered!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    reservePriceDeleted!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    isArchived!: boolean

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number
}
