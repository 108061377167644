import { createAction } from '@reduxjs/toolkit'
import { EvDatabaseCar } from '../../../entity/ev-database-car.entity'

interface Payload {
    cars: EvDatabaseCar[]
    page: number
    pageSize: number
    count: number
    reset: boolean
}

export const EvDatabaseCarsSuccess = createAction<Payload>('EV_DATABASE_CARS__EV_DATABASE_CARS_SUCCESS')
