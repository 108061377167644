import { store } from '../../redux'
import { DefaultDTO, ErrorDTO } from '../../dto'
import { ErrorMessage } from '../../redux/actions'
import { NetworkCall } from '../../utils'

interface FeeAuthorizationProps {
    auctionId: number
    userId: number
    amount: number
    buyersFee: number
    paymentIntentId: string
    onSuccess: () => void
}

export const feeAuthorization = async (props: FeeAuthorizationProps) => {
    const requestBody = {
        auctionId: props.auctionId,
        userId: props.userId,
        amount: props.amount,
        buyersFee: props.buyersFee,
        paymentIntentId: props.paymentIntentId,
    }

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('POST', `/1.0.0/users/fee/authorization`, requestBody)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(201, DefaultDTO, (responseBody) => {
            props.onSuccess()
        })
        .catch((response) => {
            const error = response as ErrorDTO

            store.dispatch(ErrorMessage({ title: '', message: error.message, code: error.code }))
        })
        .call()
}
