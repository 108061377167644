import { PropsWithChildren } from 'react'
import './step.scss'

export enum StepAlign {
    left,
    middle,
    right,
}

interface StepProps {
    nr: string
    title: string
    align: StepAlign
}

export const Step = (props: PropsWithChildren<StepProps>) => {
    const _getAlignClassName = () => {
        switch (props.align) {
            case StepAlign.left:
                return 'left'
            case StepAlign.right:
                return 'right'
            default:
                return 'middle'
        }
    }

    return (
        <div className="step">
            <div className={'step-body'}>
                <div className={'step-number ' + _getAlignClassName()}>
                    <h3 dangerouslySetInnerHTML={{ __html: props.nr }}></h3>
                </div>
                <div>
                    <p dangerouslySetInnerHTML={{ __html: props.title }}></p>
                </div>
            </div>
        </div>
    )
}
