import { ChangeEvent } from 'react'
import './phone.field.scss'
import '../field.scss'

interface PhoneFieldrops {
    field: {
        disabled?: boolean
        error: string | null
        name?: string
        required?: boolean
        value: string
    }
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    title?: string
    placeholder?: string
    area: string
    showEditIcon?: boolean
}

export const PhoneField = (props: PhoneFieldrops) => {
    return (
        <div className={`field field--phone ${props.field.error != null && props.field.error.length > 0 ? ` field--error` : ``}`}>
            <label className="body-text">{props.title}</label>

            <label className="area">{props.area}</label>

            {props.showEditIcon && <img alt="Edit icon" className={`edit-img ${props.title != null && props.title.length > 0 ? `` : `top-margin`}`} src="/images/ic_edit_gray.svg" />}

            <input onChange={props.onChange} disabled={props.field?.disabled === true} id={props.field?.name} maxLength={9} name={props.field?.name} placeholder={props.placeholder} required={props.field?.required !== false} type={'text'} value={props.field?.value} />

            <div className={`${props.field.error != null && props.field.error.length > 0 ? `field__error-div` : `field--hidden`}`}>
                <img alt="Error icon" className="error-img" src="/images/ic_error.svg" />

                <div className={`message`}>{props.field.error}</div>
            </div>
        </div>
    )
}
