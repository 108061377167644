import { useTranslation } from 'react-i18next'
import { Car } from '../../../../../entity'
import { Blurhash } from 'react-blurhash'
import { useState } from 'react'
import { CircleProgress, IconButton } from '../../../../../components'
import { CarStatusEnum } from '../../../../../enum'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { UpdateAddCarTab, UpdateCarForm, UpdateDocumentsAuction, UpdateIdCarForm, UpdateMediaFilesAuction } from '../../../../../redux/actions'
import './cars-draft-element.scss'

interface CarsDraftElementProps {
    car: Car
}

export const CarsDraftElement = (props: CarsDraftElementProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [imageLoaded, setImageLoaded] = useState(false)

    const preloadCar = () => {
        dispatch(UpdateIdCarForm({ id: props.car.id }))

        dispatch(UpdateCarForm({ key: 'country', value: props.car.address?.country ?? '', error: null }))
        dispatch(UpdateCarForm({ key: 'city', value: props.car.address?.city ?? '', error: null }))
        dispatch(UpdateCarForm({ key: 'postalCode', value: props.car.address?.postalCode ?? '', error: null }))
        dispatch(UpdateCarForm({ key: 'manufacturingYear', value: props.car.manufacturingYear, error: null }))
        dispatch(UpdateCarForm({ key: 'make', value: props.car.make, error: null }))
        dispatch(UpdateCarForm({ key: 'model', value: props.car.model, error: null }))
        dispatch(UpdateCarForm({ key: 'variant', value: props.car.variant ?? '', error: null }))
        dispatch(UpdateCarForm({ key: 'vin', value: props.car.vin, error: null }))
        dispatch(UpdateCarForm({ key: 'batteryCapacity', value: props.car.batteryCapacity.toString().replace('.', ','), error: null }))
        dispatch(UpdateCarForm({ key: 'manufacturerEstimatedRange', value: props.car.manufacturerEstimatedRange, error: null }))
        dispatch(UpdateCarForm({ key: 'horsepower', value: props.car.horsepower, error: null }))
        dispatch(UpdateCarForm({ key: 'mileage', value: props.car.mileage, error: null }))
        dispatch(UpdateCarForm({ key: 'batteryStatus', value: props.car.batteryStatus ?? -1, error: null }))
        dispatch(UpdateCarForm({ key: 'drivetrain', value: props.car.drivetrain ?? -1, error: null }))
        dispatch(UpdateCarForm({ key: 'chargingConnectorAc', value: props.car.chargingConnectorAc ?? '', error: null }))
        dispatch(UpdateCarForm({ key: 'chargingConnectorAcOtherName', value: props.car.chargingConnectorAcOtherName ?? '', error: null }))
        dispatch(UpdateCarForm({ key: 'chargingConnectorDc', value: props.car.chargingConnectorDc ?? '', error: null }))
        dispatch(UpdateCarForm({ key: 'chargingConnectorDcOtherName', value: props.car.chargingConnectorDcOtherName ?? '', error: null }))
        dispatch(UpdateCarForm({ key: 'maximumAcCharging', value: props.car.maximumAcCharging ? props.car.maximumAcCharging.toString().replace('.', ',') : '', error: null }))
        dispatch(UpdateCarForm({ key: 'maximumDcCharging', value: props.car.maximumDcCharging ? props.car.maximumDcCharging.toString().replace('.', ',') : '', error: null }))

        if (props.car.mfkDate != null) {
            const mfkDate = new Date(props.car.mfkDate)

            dispatch(UpdateCarForm({ key: 'mfkDateYear', value: '' + mfkDate.getUTCFullYear(), error: null }))
            dispatch(UpdateCarForm({ key: 'mfkDateMonth', value: '' + (mfkDate.getUTCMonth() + 1), error: null }))
        } else {
            dispatch(UpdateCarForm({ key: 'mfkDateMonth', value: '', error: null }))
            dispatch(UpdateCarForm({ key: 'mfkDateYear', value: '', error: null }))
        }

        if (props.car.validWarranty != null) {
            const validWarrantyDate = new Date(props.car.validWarranty)

            dispatch(UpdateCarForm({ key: 'validWarrantyYear', value: '' + validWarrantyDate.getUTCFullYear(), error: null }))
            dispatch(UpdateCarForm({ key: 'validWarrantyMonth', value: '' + (validWarrantyDate.getUTCMonth() + 1), error: null }))
        } else {
            dispatch(UpdateCarForm({ key: 'validWarrantyYear', value: '', error: null }))
            dispatch(UpdateCarForm({ key: 'validWarrantyMonth', value: '', error: null }))
        }

        dispatch(UpdateCarForm({ key: 'reservePrice', value: props.car.reservePrice ? props.car.reservePrice.toLocaleString('de-CH', { minimumFractionDigits: 0 }) : '', error: null }))
        dispatch(UpdateCarForm({ key: 'hasReservePriceLocal', value: props.car.hasReservePrice ? (props.car.reservePrice != null && props.car.reservePrice !== 0 ? true : false) : -1, error: null }))
        dispatch(UpdateCarForm({ key: 'hasReservePrice', value: props.car.hasReservePrice, error: null }))
        dispatch(UpdateCarForm({ key: 'exteriorColour', value: props.car.carDetail?.exteriorColour ?? '', error: null }))
        dispatch(UpdateCarForm({ key: 'interiorColour', value: props.car.carDetail?.interiorColour ?? '', error: null }))
        dispatch(UpdateCarForm({ key: 'headUpDisplay', value: props.car.carDetail?.headUpDisplay ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'threeSixtydegreeCamera', value: props.car.carDetail?.threeSixtydegreeCamera ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'parkingAssist', value: props.car.carDetail?.parkingAssist ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'rearViewCamera', value: props.car.carDetail?.rearViewCamera ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'autoParking', value: props.car.carDetail?.autoParking ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'cruiseControl', value: props.car.carDetail?.cruiseControl ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'adaptiveCruiseControl', value: props.car.carDetail?.adaptiveCruiseControl ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'parkingSensors', value: props.car.carDetail?.parkingSensors ?? -1, error: null }))
        dispatch(UpdateCarForm({ key: 'centralDisplay', value: props.car.carDetail?.centralDisplay ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'navigationSystem', value: props.car.carDetail?.navigationSystem ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'appleCarplay', value: props.car.carDetail?.appleCarplay ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'androidAuto', value: props.car.carDetail?.androidAuto ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'bluetoothInterface', value: props.car.carDetail?.bluetoothInterface ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'dabRadio', value: props.car.carDetail?.dabRadio ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'wirelessPhoneCharger', value: props.car.carDetail?.wirelessPhoneCharger ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'usbPorts', value: props.car.carDetail?.usbPorts ?? -1, error: null }))
        dispatch(UpdateCarForm({ key: 'soundSystem', value: props.car.carDetail?.soundSystem ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'blindSpotMonitor', value: props.car.carDetail?.blindSpotMonitor ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'abs', value: props.car.carDetail?.abs ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'esc', value: props.car.carDetail?.esc ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'tyrePressureMonitoringSystem', value: props.car.carDetail?.tyrePressureMonitoringSystem ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'autoEmergencyBreak', value: props.car.carDetail?.autoEmergencyBreak ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'driverAlertSystem', value: props.car.carDetail?.driverAlertSystem ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'isofix', value: props.car.carDetail?.isofix ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'nightVision', value: props.car.carDetail?.nightVision ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'frontCollisionWarning', value: props.car.carDetail?.frontCollisionWarning ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'crossTrafficWarning', value: props.car.carDetail?.crossTrafficWarning ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'airbags', value: props.car.carDetail?.airbags != null && props.car.carDetail?.airbags.length > 0 ? props.car.carDetail?.airbags : [], error: null }))
        dispatch(UpdateCarForm({ key: 'heatPump', value: props.car.carDetail?.heatPump ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'rainSensor', value: props.car.carDetail?.rainSensor ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'electricTrunkOpening', value: props.car.carDetail?.electricTrunkOpening ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'electricSideMirrors', value: props.car.carDetail?.electricSideMirrors ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'heatedSideMirrors', value: props.car.carDetail?.heatedSideMirrors ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'keylessEntry', value: props.car.carDetail?.keylessEntry ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'heatedSteeringWheel', value: props.car.carDetail?.heatedSteeringWheel ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'airConditioning', value: props.car.carDetail?.airConditioning ?? -1, error: null }))
        dispatch(UpdateCarForm({ key: 'electricSeats', value: props.car.carDetail?.electricSeats ?? -1, error: null }))
        dispatch(UpdateCarForm({ key: 'heatedSeats', value: props.car.carDetail?.heatedSeats ?? -1, error: null }))
        dispatch(UpdateCarForm({ key: 'cooledSeats', value: props.car.carDetail?.cooledSeats ?? -1, error: null }))
        dispatch(UpdateCarForm({ key: 'ledHeadLights', value: props.car.carDetail?.ledHeadLights ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'ledRearLights', value: props.car.carDetail?.ledRearLights ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'xenonHeadLights', value: props.car.carDetail?.xenonHeadLights ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'xenonRearLights', value: props.car.carDetail?.xenonRearLights ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'matrixHeadlights', value: props.car.carDetail?.matrixHeadlights ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'corneringLights', value: props.car.carDetail?.corneringLights ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'fogLights', value: props.car.carDetail?.fogLights ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'ambientLighting', value: props.car.carDetail?.ambientLighting ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'tintedWindows', value: props.car.carDetail?.tintedWindows ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'skiRack', value: props.car.carDetail?.skiRack ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'slidingRoof', value: props.car.carDetail?.slidingRoof ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'panoramicRoof', value: props.car.carDetail?.panoramicRoof ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'sportSuspension', value: props.car.carDetail?.sportSuspension ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'towBar', value: props.car.carDetail?.towBar ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'sportSeats', value: props.car.carDetail?.sportSeats ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'alloyWheels', value: props.car.carDetail?.alloyWheels ?? false, error: null }))
        dispatch(UpdateCarForm({ key: 'seatMaterial', value: props.car.carDetail?.seatMaterial ?? -1, error: null }))
        dispatch(UpdateCarForm({ key: 'otherEquipment', value: props.car.carDetail?.otherEquipment ?? '', error: null }))
        dispatch(UpdateCarForm({ key: 'ownersTake', value: props.car.carDetail?.ownersTake ?? '', error: null }))
        dispatch(UpdateCarForm({ key: 'ownershipHistory', value: props.car.carDetail?.ownershipHistory ?? '', error: null }))
        dispatch(UpdateCarForm({ key: 'serviceHistoryAndRepairs', value: props.car.carDetail?.serviceHistoryAndRepairs ?? '', error: null }))
        dispatch(UpdateCarForm({ key: 'otherItemsIncluded', value: props.car.carDetail?.otherItemsIncluded ?? '', error: null }))
        dispatch(UpdateCarForm({ key: 'modifications', value: props.car.carDetail?.modifications ?? '', error: null }))
        dispatch(UpdateCarForm({ key: 'knownProblemsOrFlaws', value: props.car.carDetail?.knownProblemsOrFlaws ?? '', error: null }))
        dispatch(UpdateCarForm({ key: 'otherInformation', value: props.car.carDetail?.otherInformation ?? '', error: null }))

        dispatch(UpdateMediaFilesAuction({ mediaFiles: props.car.mediaFiles ?? [] }))
        dispatch(UpdateDocumentsAuction({ documents: props.car.carDetail?.documents ?? [] }))
    }

    const getProgress = () => {
        const percent = 100 / 24
        let progress = 0

        if (props.car.address?.country.length !== 0) {
            progress += percent
        }

        if (props.car.address?.city.length !== 0) {
            progress += percent
        }

        if (props.car.address?.postalCode !== 0) {
            progress += percent
        }

        if (props.car.manufacturingYear !== 0) {
            progress += percent
        }

        if (props.car.make.length !== 0) {
            progress += percent
        }

        if (props.car.model.length !== 0) {
            progress += percent
        }

        if (props.car.batteryCapacity !== 0) {
            progress += percent
        }

        if (props.car.manufacturerEstimatedRange !== 0) {
            progress += percent
        }

        if (props.car.horsepower !== 0) {
            progress += percent
        }

        if (props.car.mileage !== 0) {
            progress += percent
        }

        if (props.car.batteryStatus !== -1) {
            progress += percent
        }

        if (props.car.drivetrain !== -1) {
            progress += percent
        }

        if (props.car.chargingConnectorAc !== -1) {
            progress += percent
        }

        if (props.car.chargingConnectorDc !== -1) {
            progress += percent
        }

        if (props.car.maximumAcCharging !== 0) {
            progress += percent
        }

        if (props.car.maximumDcCharging !== 0) {
            progress += percent
        }

        if (props.car.mfkDate !== 0) {
            progress += percent
        }

        if (props.car.mediaFiles != null && props.car.mediaFiles.length > 9) {
            progress += percent
        }

        if (props.car.carDetail != null && props.car.carDetail.exteriorColour.length !== 0) {
            progress += percent
        }

        if (props.car.carDetail != null && props.car.carDetail.interiorColour.length !== 0) {
            progress += percent
        }

        if (props.car.carDetail != null && props.car.carDetail.ownersTake.length !== 0) {
            progress += percent
        }

        if (props.car.carDetail != null && props.car.carDetail.ownershipHistory.length !== 0) {
            progress += percent
        }

        if (props.car.carDetail != null && props.car.carDetail.serviceHistoryAndRepairs.length !== 0) {
            progress += percent
        }

        if (props.car.carDetail != null && props.car.carDetail.knownProblemsOrFlaws.length !== 0) {
            progress += percent
        }

        return progress
    }

    return (
        <li key={props.car.id} className={`cars-draft-element`}>
            <div className="cars-draft-element__image-div">
                {props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 && <Blurhash className={`blurhash-img ${imageLoaded ? `hidden` : ``}`} hash={props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 ? props.car.mediaFiles[0].blurHash : ''} width={'100%'} height={'100%'} resolutionX={32} resolutionY={32} punch={1} />}

                <img
                    alt="Car"
                    className="car-img"
                    src={props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 ? props.car.mediaFiles[0].thumbnail : '/images/ic_no_image.svg'}
                    onLoad={() => {
                        setImageLoaded(true)
                    }}
                />
                <p className={`status ${props.car.status === CarStatusEnum.Pending ? `` : `hidden`} `}>{t('my_activity_in_review_title')}</p>
            </div>

            <div className="cars-draft-element__information-div">
                <div className={`name-div`}>
                    <div className={`name-inner-div`}>
                        <h3 className="name">
                            {props.car?.manufacturingYear} {props.car?.make} {props.car?.model}
                        </h3>
                        <label className="address">
                            {props.car?.address?.postalCode} {props.car?.address?.city}
                        </label>
                    </div>

                    {props.car.status === CarStatusEnum.Draft && (
                        <IconButton
                            title={t('button_edit_car_ad')}
                            icon="/images/ic_edit.svg"
                            onClick={() => {
                                dispatch(UpdateAddCarTab({ tab: 'cardetails' }))
                                preloadCar()
                                navigate('/add-car')
                            }}
                        />
                    )}
                </div>

                {props.car.status === CarStatusEnum.Draft && <CircleProgress percentage={getProgress()} colour={'#306ac0'} />}
            </div>
        </li>
    )
}
