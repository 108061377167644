import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, IsString, ValidateIf } from 'class-validator'
import { Address } from './address.enity'

export class ContactInfo {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsString()
    title!: string

    @Expose()
    @IsDefined()
    @IsString()
    description!: string

    @Expose()
    @IsDefined()
    @IsString()
    companyName!: string

    @Expose()
    @IsDefined()
    @IsString()
    companyEmail!: string

    @Expose()
    @ValidateIf((object, value) => value !== null)
    address?: Address | null

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number
}
