import { ChangeEvent, useRef, useState } from 'react'
import { validatePassword } from '../../../utils/validate.util'
import { useTranslation } from 'react-i18next'
import './password.field.scss'
import '../field.scss'

interface InputFieldProps {
    field: {
        disabled?: boolean
        error: string | null
        name?: string
        required?: boolean
        value: string
    }
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    title?: string
    placeholder?: string
    description?: string
    showStrongPassword: boolean
}

export const PasswordField = (props: InputFieldProps) => {
    const { t } = useTranslation()

    const inputRef = useRef<HTMLInputElement>(null)
    const [showPassword, setShowPassword] = useState(false)
    const [passPassword, setPassPassword] = useState(false)

    const onShowPasswordClick = () => {
        if (inputRef.current?.type === 'password') {
            inputRef.current!!.type = 'text'
        } else {
            inputRef.current!!.type = 'password'
        }
        setShowPassword(!showPassword)
    }

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const passwordValidation = validatePassword(event.target.value)
        setPassPassword(passwordValidation == null)

        props.onChange(event)
    }

    return (
        <div className={`field field--password ${passPassword && props.showStrongPassword ? ` pass` : ``} ${props.field.error != null && props.field.error.length > 0 ? ` field--error` : ``}`}>
            <p className="body-text">{props.title}</p>

            {passPassword && !props.field.disabled && props.showStrongPassword && <p className="body-text strong-text">{t('signup_password_strong_title')}</p>}

            <input ref={inputRef} onChange={onChange} disabled={props.field?.disabled === true} id={props.field?.name} name={props.field?.name} placeholder={props.placeholder} required={props.field?.required !== false} type={'password'} value={props.field?.value} />

            {!props.field.disabled && <img className={'eye-icon'} alt="Show password icon" src={`${!showPassword ? `/images/ic_eye.svg` : `/images/ic_eye_off.svg`}`} onClick={onShowPasswordClick} />}

            {passPassword && !props.field.disabled && props.showStrongPassword && <img className={'check-icon'} alt="Check icon" src={`/images/ic_check_circle.svg`} />}

            <div className={`${props.field.error != null && props.field.error.length > 0 ? `field__error-div` : `field--hidden`}`}>
                <img alt="Error icon" className="error-img" src="/images/ic_error.svg" />

                <div className={`message`}>{props.field.error}</div>
            </div>

            {props.description && <p className="desc-text">{props.description} </p>}
        </div>
    )
}
