import { MouseEventHandler, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Comment } from '../../../../../entity'
import { useTranslation } from 'react-i18next'
import { IconButton } from '../../../../../components'
import { likeComment, translate } from '../../../../../api'
import { useAppSelector } from '../../../../../redux'
import { ShowLoginModal } from '../../../../../redux/actions'
import { useNavigate } from 'react-router-dom'
import { formatTimeLeft } from '../../../../../utils'
import './comment-element.scss'

interface CommentElementProps {
    comment: Comment
    hideReply: boolean
    onReportClick: MouseEventHandler
    onReplyClick: (id: number, username: string) => void
}

export const CommentElement = (props: CommentElementProps) => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [likeCount, setLikeCount] = useState(props.comment.likeCount)
    const [liked, setLiked] = useState(props.comment.liked)
    const [translatedComment, setTranslatedComment] = useState('')

    const user = useAppSelector((state) => state.login.user)

    useEffect(() => {
        setTranslatedComment('')
    }, [props.comment])

    const onLikeClick = async () => {
        if (user) {
            if (user.id !== props.comment.user.id) {
                await likeComment({
                    id: props.comment.id,
                    onSuccess() {
                        if (!liked) {
                            setLikeCount(likeCount + 1)
                        } else {
                            setLikeCount(likeCount - 1)
                        }
                        setLiked(!liked)
                    },
                })
            }
        } else {
            dispatch(ShowLoginModal({ visible: true, carId: null }))
        }
    }

    const onTranslateClick = async () => {
        // if (user) {
        if (props.comment.comment.length != 0) {
            await translate({
                text: props.comment.comment,
                language: i18n.language.includes('de') ? 'de' : i18n.language.includes('fr') ? 'fr' : 'en',
                onSuccess(text: string) {
                    setTranslatedComment(text)
                },
            })
        }
        // } else {
        //     dispatch(ShowLoginModal({ visible: true, carId: null }))
        // }
    }

    return (
        <li key={props.comment.id} id={'' + props.comment.id} className={`comment-element ${props.comment.parentId != null ? `child` : ``}`}>
            <div className={`user-div ${props.comment.parentId != null ? `child` : ``}`}>
                <label
                    className="user"
                    onClick={() => {
                        navigate(`/user/${props.comment.user.id}`)
                    }}
                >
                    {props.comment.user.username}
                </label>
                <label className="date">{formatTimeLeft(props.comment.created!!, t)}</label>

                {/* <label className="date">{new Date(props.comment.created!!).toLocaleString('de-CH', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</label> */}
            </div>

            <div className="comment-div">
                <label className="comment" dangerouslySetInnerHTML={{ __html: (props.comment.parentUsername != null ? ` <b>Re: ` + props.comment.parentUsername + '</b>' : ``) + ' ' + (translatedComment != '' ? translatedComment : props.comment.comment) }}></label>

                <img alt="Translate icon" className="deepl-img" src="/images/ic_deepl.svg" onClick={onTranslateClick} />
            </div>

            <div className="action-div">
                <div className={`user-div ${props.comment.parentId != null ? `` : `child`}`}>
                    <label
                        className="user"
                        onClick={() => {
                            navigate(`/user/${props.comment.user.id}`)
                        }}
                    >
                        {props.comment.user.username}
                    </label>
                    <label className="date">{formatTimeLeft(props.comment.created!!, t)}</label>

                    {/* <label className="date">{new Date(props.comment.created!!).toLocaleString('de-CH', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</label> */}
                </div>

                {!props.hideReply && (
                    <div className={`like-button ${props.comment.parentId != null ? `child` : ``} ${likeCount === 0 ? `transparent` : ``}`} onClick={onLikeClick}>
                        <img alt="Like icon" src={`${liked ? `/images/ic_like_black.svg` : `/images/ic_like_gray.svg`}`} />
                        <label className={`title ${likeCount === 0 ? `hidden` : ``}`}>{likeCount}</label>
                    </div>
                )}

                <label className={`replies ${props.comment.parentId != null ? `child` : ``} ${props.comment.children != null && props.comment.children.length !== 0 ? `active` : ``} `}>
                    {props.comment.children ? props.comment.children.length : 0} {t('car_information_replies_title')}
                </label>

                {!props.hideReply && (
                    <>
                        <IconButton small={true} title={t('car_information_reply_button')} icon="/images/ic_reply.svg" onClick={() => props.onReplyClick(props.comment.id, props.comment.user.username)} />
                        <div className="report-button">
                            <IconButton small={true} title={t('car_information_report_comment_button')} icon="/images/ic_report.svg" onClick={props.onReportClick} />
                        </div>
                    </>
                )}
            </div>
        </li>
    )
}
