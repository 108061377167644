import { store } from '../../redux'
import { DefaultDTO, ErrorDTO } from '../../dto'
import { ErrorMessage, ShowToast } from '../../redux/actions'
import { NetworkCall } from '../../utils'

interface ChangePasswordProps {
    password: string
    newPassword: string
    onSuccess: () => void
}

export const changePassword = async (props: ChangePasswordProps) => {
    const requestBody = {
        password: props.password,
        newPassword: props.newPassword,
    }
    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('PATCH', `/1.0.0/users/change-password`, requestBody)
        .setHeader('Authorization', `Bearer ${token}`)
        .then(200, DefaultDTO, (responseBody) => {
            props.onSuccess()

            store.dispatch(ShowToast({ message: 'toast_change_password_success' }))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
