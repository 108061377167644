import { MouseEventHandler } from 'react'
import './button.scss'

interface ButtonProps {
    title: string
    type?: 'black' | 'gray' | 'red' | null
    fitContent?: boolean
    default?: boolean
    onClick?: MouseEventHandler
    htmlType?: 'button' | 'submit' | 'reset'
}

export const Button = (props: ButtonProps) => {
    return (
        <button className={`button${props.type === 'black' ? ` button--black` : ``}${props.type === 'gray' ? ` button--gray` : ``}${props.type === 'red' ? ` button--red` : ``}${props.default ? ` button--default` : ``}${props.fitContent ? ` fit-content` : ``}`} onClick={props.onClick} type={props.htmlType ?? 'button'}>
            <span dangerouslySetInnerHTML={{ __html: props.title }}></span>
            <img alt="Arrow icon" className={`button__arrow-img ${props.default ? `hide` : ``}`} src={`/images/${props.type === 'gray' ? `ic_arrow_right_rounded_black.svg` : `ic_arrow_right_rounded.svg`}`} />
        </button>
    )
}
