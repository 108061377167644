import { DefaultDTO, ErrorDTO } from '../../dto'
import { store } from '../../redux'
import { ErrorMessage, ShowToast } from '../../redux/actions'
import { NetworkCall } from '../../utils'

interface SendContactUsMessageProps {
    name: string
    email: string
    message: string
    onSuccess: () => void
}

export const sendContactUsMessage = async (props: SendContactUsMessageProps) => {
    const requestBody = {
        name: props.name,
        email: props.email,
        message: props.message,
    }

    return await new NetworkCall('POST', `/1.0.0/contact-us`, requestBody)
        .then(201, DefaultDTO, (responseBody) => {
            props.onSuccess()

            store.dispatch(ShowToast({ message: 'toast_contact_us_success' }))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
