import { createAction } from '@reduxjs/toolkit'
import { Bid } from '../../../entity'

interface Payload {
    bids: Bid[]
    page: number
    pageSize: number
    count: number
    reset: boolean
}

export const BidsSuccess = createAction<Payload>('BIDS__BIDS_SUCCESS')
