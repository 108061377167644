import { MouseEventHandler } from 'react'
import './icon.button.scss'

interface IconButtonProps {
    title: string
    icon: string
    small?: boolean
    reverse?: boolean
    hidden?: boolean
    withHoverAnim?: boolean
    onClick?: MouseEventHandler
}

export const IconButton = (props: IconButtonProps) => {
    return (
        <div className={`icon-button${props.withHoverAnim ? ` hover-anim` : ``}${props.small ? ` small` : ``}${props.reverse ? ` reverse` : ``}${props.hidden ? ` hidden` : ``}`} onClick={props.onClick}>
            <label className={`label`}>{props.title}</label>
            <img alt="Icon" className="icon" src={props.icon} />
        </div>
    )
}
