import { store } from '../../redux'
import { DefaultDTO, ErrorDTO } from '../../dto'
import { ErrorMessage } from '../../redux/actions'
import { NetworkCall } from '../../utils'

interface ApprovalCarProps {
    onSuccess: () => void
}

export const approvalCar = async (props: ApprovalCarProps) => {
    const carId = store.getState().car.form.id

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('POST', `/1.0.0/cars/${carId}/approval`)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(201, DefaultDTO, (responseBody) => {
            props.onSuccess()
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
