import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, IsString } from 'class-validator'

export class Address {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsString()
    country!: string

    @Expose()
    @IsDefined()
    @IsString()
    city!: string

    @Expose()
    @IsDefined()
    @IsString()
    address!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    postalCode!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    lat!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    lon!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number
}
