import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Submenu } from './components/submenu'
import { Section } from './components/section'
import { CardGrid } from './components/card-grid'
import { CardRow } from './components/card-row'
import { Card } from './components/card'
import { Button } from '../../components/buttons/button'
import { AboutUsSection } from '../../enum'
import { useAppSelector } from '../../redux'
import { useDispatch } from 'react-redux'
import { ShowLoginModal } from '../../redux/actions'
import './about-us.page.scss'

interface AboutUsPageProps {}

export const AboutUsPage = (props: AboutUsPageProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const currentSection = useAppSelector((state) => state.aboutUs.currentSection)
    const user = useAppSelector((state) => state.login.user)

    const _getIcon = (name: string) => `/images/about-us/${name}.svg`

    const firstSectionRef = useRef<HTMLDivElement | null>(null)
    const secondSectionRef = useRef<HTMLDivElement | null>(null)
    const thirdSectionRef = useRef<HTMLDivElement | null>(null)

    return (
        <main className="page page-about-us">
            <div className="main-div">
                <div className="submenu-div">
                    <Submenu refs={[firstSectionRef, secondSectionRef, thirdSectionRef]} />
                </div>
                <div className="content-div">
                    <Section title={t('about_us_why_choose_section_title')} ref={firstSectionRef} additionalClassNames={currentSection === AboutUsSection.WhyChoose ? '' : 'hiddenOnSmallScreen'}>
                        <CardGrid>
                            <CardRow additionalClassNames="why-choose">
                                <Card title={t('about_us_why_choose_section_electric_cars_card_title')} body={t('about_us_why_choose_section_electric_cars_card_body')} />
                                <Card title={t('about_us_why_choose_section_free_listing_card_title')} body={t('about_us_why_choose_section_free_listing_card_body')} />
                                <Card title={t('about_us_why_choose_section_daily_auctions_card_title')} body={t('about_us_why_choose_section_daily_auctions_card_body')} />
                            </CardRow>
                        </CardGrid>
                    </Section>

                    <Section title={t('about_us_about_us_section_title')} body={t('about_us_about_us_section_body')} ref={secondSectionRef} additionalClassNames={currentSection === AboutUsSection.AboutUs ? '' : 'hiddenOnSmallScreen'}></Section>

                    <Section title={t('about_us_benefits_section_title')} ref={thirdSectionRef} additionalClassNames={currentSection === AboutUsSection.Benefits ? '' : 'hiddenOnSmallScreen'}>
                        <CardGrid>
                            <CardRow>
                                <Card icon={_getIcon('ic_electric_cars')} title={t('about_us_benefits_section_electric_cars_card_title')} body={t('about_us_benefits_section_electric_cars_card_body')}></Card>
                                <Card icon={_getIcon('ic_fostering_transparency')} title={t('about_us_benefits_section_fostering_transparency_card_title')} body={t('about_us_benefits_section_fostering_transparency_card_body')}></Card>
                            </CardRow>
                            <CardRow>
                                <Card icon={_getIcon('ic_competitive_pricing')} title={t('about_us_benefits_section_competitive_pricing_card_title')} body={t('about_us_benefits_section_competitive_pricing_card_body')}></Card>
                                <Card icon={_getIcon('ic_daily_live_auctions')} title={t('about_us_benefits_section_daily_live_auctions_card_title')} body={t('about_us_benefits_section_daily_live_auctions_card_body')}></Card>
                            </CardRow>
                            <CardRow>
                                <Card icon={_getIcon('ic_education')} title={t('about_us_benefits_section_education_card_title')} body={t('about_us_benefits_section_education_card_body')}></Card>
                                <Card icon={_getIcon('ic_expert_support')} title={t('about_us_benefits_section_expert_support_card_title')} body={t('about_us_benefits_section_expert_support_card_body')}></Card>
                            </CardRow>
                        </CardGrid>
                    </Section>

                    <Section additionalClassNames="community">
                        <div className="community-content">
                            <h2 dangerouslySetInnerHTML={{ __html: t('about_us_community_title') }}></h2>
                            <div className="community-body" dangerouslySetInnerHTML={{ __html: t('about_us_community_body') }}></div>
                        </div>

                        {user == null && (
                            <Button
                                type={'black'}
                                title={t('about_us_community_login_button')}
                                onClick={() => {
                                    dispatch(ShowLoginModal({ visible: true, carId: null }))
                                }}
                            />
                        )}
                    </Section>
                </div>
            </div>
        </main>
    )
}
