import { useTranslation } from 'react-i18next'
import './steps-section-title.scss'
import { SectionTitle } from '../section-title'

interface StepsSectionTitleProps {
    additionalClassNames?: string
}

export const StepsSectionTitle = (props: StepsSectionTitleProps) => {
    const { t } = useTranslation()

    return (
        <SectionTitle>
            <div className="steps-title-container">
                <div className="steps-title">
                    <div className="simple-steps">
                        <h3>{t('how_it_works_steps_title_simple_steps')}</h3>
                    </div>
                    <div className="buy-or-sell">
                        <div className="buy-shell">
                            <h3>{t('how_it_works_steps_title_buy')}</h3>
                        </div>
                        <div className="or">
                            <p>{t('how_it_works_steps_title_or')}</p>
                        </div>
                        <div className="buy-shell">
                            <h3>{t('how_it_works_steps_title_sell')}</h3>
                        </div>
                    </div>
                    <div className="car">
                        <p>{t('how_it_works_steps_title_car')}</p>
                    </div>
                </div>
            </div>
        </SectionTitle>
    )
}
