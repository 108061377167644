import { MutableRefObject, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AboutUsSection } from '../../../enum'
import { UpdateCurrentSection } from '../../../redux/actions'
import { useAppSelector } from '../../../redux'
import { useWindowDimensions } from '../../../utils'
import { useTranslation } from 'react-i18next'
import './submenu.scss'

interface SubmenuProps {
    refs: MutableRefObject<HTMLDivElement | null>[]
}

export const Submenu = (props: SubmenuProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const currentSection = useAppSelector((state) => state.aboutUs.currentSection)
    const { width } = useWindowDimensions()

    useEffect(() => {
        if (width > 640) {
            setTimeout(() => {
                if (currentSection === AboutUsSection.WhyChoose) {
                    window.scrollTo(0, (props.refs[0].current?.offsetTop ?? 0) - 104)
                }
                if (currentSection === AboutUsSection.AboutUs) {
                    window.scrollTo(0, (props.refs[1].current?.offsetTop ?? 0) - 104)
                }
                if (currentSection === AboutUsSection.Benefits) {
                    window.scrollTo(0, (props.refs[2].current?.offsetTop ?? 0) - 104)
                }
            }, 250)
        }
    }, [currentSection, props.refs])

    return (
        <div className="about-us-submenu-div">
            <div
                dangerouslySetInnerHTML={{ __html: t('about_us_menu_why_choose') }}
                onClick={() => {
                    dispatch(UpdateCurrentSection({ currentSection: AboutUsSection.WhyChoose }))
                }}
                className={currentSection === AboutUsSection.WhyChoose ? 'submenu-item active' : 'submenu-item'}
            ></div>
            <div
                dangerouslySetInnerHTML={{ __html: t('about_us_menu_about_us') }}
                onClick={() => {
                    dispatch(UpdateCurrentSection({ currentSection: AboutUsSection.AboutUs }))
                }}
                className={currentSection === AboutUsSection.AboutUs ? 'submenu-item active' : 'submenu-item'}
            ></div>
            <div
                dangerouslySetInnerHTML={{ __html: t('about_us_menu_benefits') }}
                onClick={() => {
                    dispatch(UpdateCurrentSection({ currentSection: AboutUsSection.Benefits }))
                }}
                className={currentSection === AboutUsSection.Benefits ? 'submenu-item active' : 'submenu-item'}
            ></div>
        </div>
    )
}
