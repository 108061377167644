import { store } from '../../redux'
import { CommentsDTO } from '../../dto'
import { CommentsFailed, CommentsSuccess } from '../../redux/actions'
import { NetworkCall } from '../../utils'

export const getComments = async (carId: number, tab: 'newestFirst' | 'sellerComments', reset: boolean = false) => {
    const page = reset ? 1 : store.getState().comments.pagination.page
    const pageSize = store.getState().comments.pagination.pageSize

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('GET', `/1.0.0/comments?page=${page}&pageSize=${pageSize}&carId=${carId}&tab=${tab}`)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(200, CommentsDTO, (responseBody) => {
            store.dispatch(CommentsSuccess({ comments: responseBody.data, page: responseBody.page, pageSize: responseBody.pageSize, count: responseBody.count, reset: reset }))
        })
        .catch((response) => {
            store.dispatch(CommentsFailed({}))
        })
        .call()
}
