import { ErrorDTO, FaqsDTO } from '../../dto'
import { store } from '../../redux'
import { ErrorMessage, GetFaqsSuccess } from '../../redux/actions'
import { NetworkCall } from '../../utils'

export const getFaqs = async () => {
    return await new NetworkCall('GET', `/1.0.0/faqs`)
        .then(200, FaqsDTO, (responseBody) => {
            store.dispatch(GetFaqsSuccess(responseBody))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
