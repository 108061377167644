import { Section } from '../section'
import { StepsSectionBody } from './steps-section-body'
import { StepsSectionTitle } from './steps-section-title'
import './steps-section.scss'

interface StepsSectionProps {
    additionalClassNames?: string
}

export const StepsSection = (props: StepsSectionProps) => {
    const _getClassNames = (): string => ['steps-section', props.additionalClassNames ?? ''].join(' ').trim()

    return (
        <Section additionalClassNames={_getClassNames()}>
            <Section isSub={true} additionalClassNames="steps-section">
                <StepsSectionTitle></StepsSectionTitle>
                <StepsSectionBody></StepsSectionBody>
            </Section>
        </Section>
    )
}
