import { createReducer } from '@reduxjs/toolkit'
import { LoginState } from '../../states'
import { LoginFailed, LoginSuccess, UpdateLoginForm } from '../../actions'
import { Logout } from '../../actions/login'

const initialState: LoginState = {
    user: null,
    session: null,
    form: {
        email: { error: null, value: `` },
        password: { error: null, value: `` },
        rememberMe: { error: null, value: false },
    },
}

export const loginReducer = createReducer(initialState, (builder) => {
    builder.addCase(LoginSuccess, (state, action) => {
        state.user = action.payload.user
        state.session = action.payload.session

        state.form.email.value = ''
        state.form.password.value = ''
        state.form.rememberMe.value = false
    })
    builder.addCase(LoginFailed, (state) => {
        // state.form.email.error = ''
        // state.form.password.error = t('login-page--invalid-username-or-password')
    })
    builder.addCase(UpdateLoginForm, (state, action) => {
        ;(state.form[action.payload.key] as any) = {
            ...state.form[action.payload.key],
            value: action.payload.value,
            error: action.payload.error,
        }
        //Object.keys(state.form).forEach((key) => ((state.form as any)[key].error = null))
    })
    builder.addCase(Logout, (state, action) => {
        state.user = null
        state.session = null
        localStorage.removeItem('token')
        sessionStorage.removeItem('token')
    })
})
