import { createReducer } from '@reduxjs/toolkit'
import { EvDatabaseState } from '../../states'
import { EvDatabaseCarsPagination, EvDatabaseCarsSuccess, EvDatabaseCarsFailed, UpdateEvDatabaseCarsParams, EvDatabaseFiltersSuccess, EvDatabaseFiltersFailed, GetEvDatabaseCarSuccess, GetEvDatabaseCarFailed } from '../../actions'

const initialState: EvDatabaseState = {
    cars: [],
    isLoading: false,
    pagination: {
        page: 1,
        pageSize: 15,
        count: 0,
    },
    make: null,
    model: null,
    minRange: undefined,
    maxRange: undefined,
    minCapacity: undefined,
    maxCapacity: undefined,
    chargePlug: null,

    filters: {
        make: [],
        model: [],
        minRange: 0,
        maxRange: 0,
        minBatteryCapacity: 0,
        maxBatteryCapacity: 0,
        chargePlug: [],
    },

    car: null,
}

export const evDatabaseReducer = createReducer(initialState, (builder) => {
    builder.addCase(EvDatabaseCarsPagination, (state, action) => {
        state.isLoading = true
        state.pagination.page = action.payload.page
    })
    builder.addCase(EvDatabaseCarsSuccess, (state, action) => {
        state.isLoading = false

        state.cars = action.payload.cars
        state.pagination.page = action.payload.page
        state.pagination.pageSize = action.payload.pageSize
        state.pagination.count = action.payload.count
    })
    builder.addCase(EvDatabaseCarsFailed, (state) => {
        state.isLoading = false
    })
    builder.addCase(UpdateEvDatabaseCarsParams, (state, action) => {
        if (action.payload.make !== undefined) state.make = action.payload.make
        if (action.payload.model !== undefined) state.model = action.payload.model
        if (action.payload.minRange !== undefined) state.minRange = action.payload.minRange
        if (action.payload.maxRange !== undefined) state.maxRange = action.payload.maxRange
        if (action.payload.minCapacity !== undefined) state.minCapacity = action.payload.minCapacity
        if (action.payload.maxCapacity !== undefined) state.maxCapacity = action.payload.maxCapacity
        if (action.payload.chargePlug !== undefined) state.chargePlug = action.payload.chargePlug
    })

    builder.addCase(EvDatabaseFiltersSuccess, (state, action) => {
        state.filters.make = action.payload.make
        state.filters.model = action.payload.model
        state.filters.minRange = action.payload.minRange
        state.filters.maxRange = action.payload.maxRange
        state.filters.maxBatteryCapacity = action.payload.maxBatteryCapacity
        state.filters.minBatteryCapacity = action.payload.minBatteryCapacity
        state.filters.chargePlug = action.payload.chargePlug
    })

    builder.addCase(EvDatabaseFiltersFailed, (state, action) => {})

    builder.addCase(GetEvDatabaseCarSuccess, (state, action) => {
        state.car = action.payload.car
    })
    builder.addCase(GetEvDatabaseCarFailed, (state) => {})
})
