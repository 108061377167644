import { store } from '../../redux'
import { DefaultDTO, ErrorDTO } from '../../dto'
import { NetworkCall } from '../../utils'
import { ErrorMessage, ShowToast } from '../../redux/actions'

interface DeleteMediaFileProps {
    id: number
    onSuccess: () => void
}

export const deleteMediaFile = async (props: DeleteMediaFileProps) => {

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('DELETE', `/1.0.0/media-files/${props.id}`)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(200, DefaultDTO, (responseBody) => {
            props.onSuccess()

            store.dispatch(ShowToast({ message: 'toast_delete_file_success' }))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
