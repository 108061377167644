import { ChangeEvent, MouseEventHandler, useState } from 'react'
import { Modal } from '../modal'
import { Button, IconButton } from '../../buttons'
import { useTranslation } from 'react-i18next'
import useTimeout from '../../../utils/timeout.util'
import { getCarsAuctioned, updateReservePriceAndMediaFiles } from '../../../api'
import './edit-reserve-price.modal.scss'

interface EditReservePriceModalProps {
    id: number
    currentPrice: number
    onClose: () => void
    onDeleteClick: MouseEventHandler
}

export const EditReservePriceModal = (props: EditReservePriceModalProps) => {
    const { t } = useTranslation()

    const [amountError, setAmountError] = useState('')
    const [formattedAmount, setFormattedAmount] = useState('0')
    const [amount, setAmount] = useState(0)

    const { clear, reset } = useTimeout(() => setFormattedAmount(amount.toLocaleString('de-CH', { minimumFractionDigits: 0 })), 1500)

    const onSubmitClick = async () => {
        setAmountError('')

        if (formattedAmount === '0' && amount === 0) {
            setAmountError(t('error_reserve_price_empty'))
            return
        }

        if (formattedAmount.length !== 0 && amount >= props.currentPrice) {
            setAmountError(t('error_reserve_price_higher'))
            return
        }

        await updateReservePriceAndMediaFiles({
            id: props.id,
            reservePrice: amount,
            mediaFileIds: undefined,
            onSuccess() {
                getCarsAuctioned(true)

                props.onClose()
            },
        })
    }

    const onBidChange = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        setAmountError('')

        reset()

        let userInput: string = event.target.value.replace(/[^0-9]/g, '')

        if (userInput === '') {
            setFormattedAmount('0')
            setAmount(0)

            clear()
        } else {
            let userAmount = parseInt(userInput)

            setFormattedAmount(userAmount.toLocaleString('de-CH', { minimumFractionDigits: 0 }))
            setAmount(userAmount)
        }
    }

    return (
        <Modal title={t('modal_reserve_price_title')} onCloseClick={props.onClose}>
            <div className="edit-reserve-price-modal">
                <div className={`"edit-reserve-price-desc-div`}>
                    <label className="description">{t('modal_reserve_price_description')}</label>
                </div>

                <div className="amount-div">
                    <div className={`input-div`}>
                        <div className={`field`}>
                            <input onChange={onBidChange} id={'bid_value'} required={true} type="text" value={formattedAmount} min="0" max={'999999999'} maxLength={11} />
                            <div className={`error-div ${amountError != null && amountError.length > 0 ? `` : `hidden`}`}>
                                <img alt="Error icon" className="error-img" src="/images/ic_error.svg" />

                                <div className={`message`}>{amountError}</div>
                            </div>
                        </div>

                        <h3 className="currency-title">{t('car_card_chf_title')}</h3>

                        <IconButton title={t('button_delete_reserve_price')} icon="/images/ic_delete.svg" onClick={props.onDeleteClick} />
                    </div>
                </div>

                <div className="button-div">
                    <Button default={true} title={t('button_cancel')} onClick={props.onClose} />
                    <Button title={t('button_submit')} onClick={onSubmitClick} />
                </div>
            </div>
        </Modal>
    )
}
