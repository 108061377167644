import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { CarCard } from './components'
import { useTranslation } from 'react-i18next'
import { AuctionsTab } from '../../enum'
import { LoadMoreButton, SearchField } from '../../components'
import { Filter } from './components/filter'
import { useAppSelector } from '../../redux'
import { UpdateCarsParams } from '../../redux/actions/get-cars'
import { useDispatch } from 'react-redux'
import { filterData, getCars } from '../../api/cars'
import { CarsPagination } from '../../redux/actions/get-cars/cars-pagination.action'
import { useNavigate } from 'react-router-dom'
import './auctions.page.scss'

interface AuctionsPageProps {}

export const AuctionsPage = (props: AuctionsPageProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [filterIsHidden, setFilterIsHidden] = useState(true)

    const user = useAppSelector((state) => state.login.user)
    const { cars, isLoading, tab, search } = useAppSelector((state) => state.cars)
    const { page, pageSize, count } = useAppSelector((state) => state.cars.pagination)
    const filtersCount = useAppSelector((state) => state.cars.filters.count)

    const LOAD_MORE_COUNT = 66

    const observerTarget = useRef(null)

    const observer = new IntersectionObserver(
        (entries) => {
            if (entries[0].isIntersecting) {
                // 66 elemig ellapoz utána látható a load more gomb
                if (page * pageSize < LOAD_MORE_COUNT && page * pageSize <= count) {
                    dispatch(CarsPagination({}))
                    getCars()
                }

                // 66 elemnél a load more gomb lapoz egyet, és akkor újra lapozhat a végtelenségig, vagy a max elemszámig
                if (page * pageSize > LOAD_MORE_COUNT && page * pageSize <= count) {
                    dispatch(CarsPagination({}))
                    getCars()
                }
            }
        },
        { threshold: 0.0 }
    )

    useEffect(() => {
        if (observerTarget.current) {
            observer.observe(observerTarget.current)
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current)
            }
        }
    }, [observerTarget, page, count])

    const onChangeTab = async (tab: 'endingSoon' | 'newAuction' | 'noReserve' | 'closeToMe' | 'pastAutions' | 'favourite') => {
        dispatch(UpdateCarsParams({ tab: tab }))

        await getCars(true)
    }

    const onFilterClick = () => {
        setFilterIsHidden(!filterIsHidden)
    }

    const onSearchButtonClick = async (event: KeyboardEvent<HTMLInputElement>) => {
        event.preventDefault()

        await getCars(true)
    }

    const onSearchChange = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        dispatch(UpdateCarsParams({ search: event.target.value }))

        if (event.target.value.length === 0) {
            await getCars(true)
        }
    }

    return (
        <main className="page page-auctions">
            <div className="main-div">
                <h2 className="title-label">{t('auctions_live_auctions_title')} </h2>

                <div className="menu-div">
                    <div className="title-div">
                        <h2 className="label">{t('auctions_live_auctions_title')} </h2>

                        <div className="filter-button" onClick={() => onFilterClick()}>
                            <img alt="Filter icon" className="filter-img" src={filtersCount == null ? `/images/ic_filter.svg` : `/images/ic_filter_active.svg`} />

                            <label className={`title ${filtersCount == null ? `` : `primary`}`}>{t('auctions_filters_button')}</label>

                            <div className={`active ${filtersCount == null ? `hidden` : ``}`} />
                        </div>
                    </div>

                    <ul className={`menu-bar ${user ? `favourite` : ``}`}>
                        <li className="menu-bar-item" onClick={() => onChangeTab(AuctionsTab.EndingSoon)}>
                            <label className={`title ${AuctionsTab.EndingSoon === tab ? `active` : ``}`}>{t('auctions_ending_soon_button')}</label>

                            <div className={`indicator ${AuctionsTab.EndingSoon === tab ? `active` : ``}`} />
                        </li>

                        <li className="menu-bar-item" onClick={() => onChangeTab(AuctionsTab.NewAuctions)}>
                            <label className={`title ${AuctionsTab.NewAuctions === tab ? `active` : ``}`}>{t('auctions_new_auctions_button')}</label>

                            <div className={`indicator ${AuctionsTab.NewAuctions === tab ? `active` : ``}`} />
                        </li>

                        <li className="menu-bar-item" onClick={() => onChangeTab(AuctionsTab.NoReserve)}>
                            <label className={`title ${AuctionsTab.NoReserve === tab ? `active` : ``}`}>{t('auctions_no_reserve_buttone')}</label>

                            <div className={`indicator ${AuctionsTab.NoReserve === tab ? `active` : ``}`} />
                        </li>

                        <li
                            className={`menu-bar-item ${user?.address != null ? `` : `disabled`}`}
                            onClick={() => {
                                user?.address != null ? onChangeTab(AuctionsTab.CloseToMe) : navigate('/my-profile')
                            }}
                        >
                            <label className={`title ${AuctionsTab.CloseToMe === tab ? `active` : ``} ${user?.address != null ? `` : `disabled`}`}>{t('auctions_close_to_me_button')}</label>

                            <div className={`indicator ${AuctionsTab.CloseToMe === tab ? `active` : ``}`} />
                        </li>

                        <li className="menu-bar-item" onClick={() => onChangeTab(AuctionsTab.PastAuctions)}>
                            <label className={`title ${AuctionsTab.PastAuctions === tab ? `active` : ``}`}>{t('auctions_past_auctions_button')}</label>

                            <div className={`indicator ${AuctionsTab.PastAuctions === tab ? `active` : ``}`} />
                        </li>

                        <li className={`menu-bar-item ${user ? `favourite` : `hidden`}`} onClick={() => onChangeTab(AuctionsTab.Favourite)}>
                            <img alt="Favourite icon" className="favourite-img" src={`/images/${AuctionsTab.Favourite === tab ? `ic_favourite_selected.svg` : `ic_favourite.svg`}`} />
                            <div className={`indicator ${AuctionsTab.Favourite === tab ? `active` : ``}`} />
                        </li>
                    </ul>

                    <SearchField
                        closeAlwaysVisible={false}
                        value={search ?? ''}
                        onSearch={onSearchButtonClick}
                        onChange={onSearchChange}
                        placeholder={t('auctions_search_placeholder')}
                        onClose={async () => {
                            dispatch(UpdateCarsParams({ search: '' }))
                            await getCars(true)
                            await filterData()
                        }}
                    />

                    <div className="filter-button" onClick={() => onFilterClick()}>
                        <img alt="Filter icon" className="filter-img" src={filtersCount == null ? `/images/ic_filter.svg` : `/images/ic_filter_active.svg`} />

                        <label className={`title ${filtersCount == null ? `` : `primary`}`}>{t('auctions_filters_button')}</label>

                        <div className={`active ${filtersCount == null ? `hidden` : ``}`} />
                    </div>
                </div>

                <div className="cars-grid-div">
                    {cars.map((car, index) => (
                        <CarCard car={car} />
                    ))}
                </div>

                <div ref={observerTarget}></div>

                {isLoading && <p>{t('auctions_loading_title')}</p>}

                {cars.length === 0 && (
                    <div className="no-result-div">
                        <div className="text-div">
                            <h1 className="title">{t('auctions_no_result_title')}</h1>

                            {/* <h3 className="desc">{t('auctions_no_result_description')}</h3> */}
                        </div>

                        <img alt="Charger icon" className="charger-img" src="/images/ic_charger_image.svg" />
                    </div>
                )}

                <div className={`bottom-div ${page * pageSize !== LOAD_MORE_COUNT || page * pageSize >= count ? `hidden` : ``}`}>
                    {cars.length !== 0 && (
                        <LoadMoreButton
                            onClick={() => {
                                dispatch(CarsPagination({}))
                                getCars()
                            }}
                        />
                    )}
                </div>
            </div>

            <Filter
                isHidden={filterIsHidden}
                onCloseClick={() => {
                    setFilterIsHidden(!filterIsHidden)
                }}
            />
        </main>
    )
}
