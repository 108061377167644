import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Car } from '../../../../entity'
import { DocumentElement } from './document-element'
import { BatteryStatusEnum } from '../../../../enum/battery-status.enum'
import { AirConditioningEnum, ChargingConnectorAcTypeEnum, ChargingConnectorDcTypeEnum, DrivetrainEnum, ElectricSeatsEnum, FrontAndRearEnum, SeatMaterialEnum } from '../../../../enum'
import { AirbagEnum } from '../../../../enum/airbag.enum'
import { USBPortEnum } from '../../../../enum/usb-ports.enum'
import { translate } from '../../../../api'
import './car-informations.scss'
import { formatTimeLeftWithoutDay } from '../../../../utils/format-time-left'

interface CarInformationsProps {
    deepLEnabled: boolean
    car: Car
}

export const CarInformations = (props: CarInformationsProps) => {
    const { t, i18n } = useTranslation()

    const [translatedOwnershipHistory, setTranslatedOwnershipHistory] = useState('')
    const [translatedServiceHistoryAndRepairs, setTranslatedServiceHistoryAndRepairs] = useState('')
    const [translatedOtherEquipment, setTranslatedOtherEquipment] = useState('')
    const [translatedModifications, setTranslatedModifications] = useState('')
    const [translatedOtherItemsIncluded, setTranslatedOtherItemsIncluded] = useState('')
    const [translatedOtherInformation, setTranslatedOtherInformation] = useState('')
    const [translatedKnownProblems, setTranslatedKnownProblems] = useState('')

    const [carDetailsVisible, setDetailsVisible] = useState(false)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [otherEquipmentVisible, setOtherEquipmentVisible] = useState(false)
    const [ownershipHistoryVisible, setOwnershipHistoryVisible] = useState(false)
    const [serviceHistoryVisible, setServicepHistoryVisible] = useState(false)
    const [otherItemsIncludedInTheSaleVisible, setOtherItemsIncludedInTheSaleVisible] = useState(false)
    const [modificationVisible, setModificationVisible] = useState(false)
    const [knownProblemsVisible, setKnownProblemsVisible] = useState(false)
    const [uploadedDocumentsVisible, setUploadedDocumentsVisible] = useState(false)
    const [otherInformationVisible, setOtherInformationVisible] = useState(false)

    const onCarDetailsVisibilityClick = () => {
        setDetailsVisible(!carDetailsVisible)
    }

    const onEquipmentVisibilityClick = () => {
        setEquipmentVisible(!equipmentVisible)
    }

    const onOwnershipHistoryVisibilityClick = () => {
        setOwnershipHistoryVisible(!ownershipHistoryVisible)
    }

    const onOtherEquipmentVisibilityClick = () => {
        setOtherEquipmentVisible(!otherEquipmentVisible)
    }

    const onServiceHistoryVisibilityClick = () => {
        setServicepHistoryVisible(!serviceHistoryVisible)
    }

    const onOtherItemsIncludedInTheSaleVisibilityClick = () => {
        setOtherItemsIncludedInTheSaleVisible(!otherItemsIncludedInTheSaleVisible)
    }

    const onModificationsVisibilityClick = () => {
        setModificationVisible(!modificationVisible)
    }

    const onKnownProblemsVisibilityClick = () => {
        setKnownProblemsVisible(!knownProblemsVisible)
    }

    const onUploadedDocumentsVisibilityClick = () => {
        // setUploadedDocumentsVisible(!uploadedDocumentsVisible)
    }

    const onOtherInformationVisibilityClick = () => {
        setOtherInformationVisible(!otherInformationVisible)
    }

    const getUSBType = () => {
        let parkingSensors = ''

        if (props.car.carDetail?.usbPorts != null && props.car.carDetail?.usbPorts !== -1) {
            parkingSensors += t('add_car_usb_ports_title')
            parkingSensors += ' ('
            parkingSensors += props.car.carDetail?.usbPorts === USBPortEnum.TypeA ? t('add_car_usb_type_a_title') : props.car.carDetail?.usbPorts === USBPortEnum.TypeC ? t('add_car_usb_type_c_title') : t('add_car_usb_multiple_type_title')
            parkingSensors += ') • '
        }

        return parkingSensors
    }

    const getParkingSensors = () => {
        let parkingSensors = ''

        if (props.car.carDetail?.parkingSensors != null && props.car.carDetail?.parkingSensors !== -1) {
            parkingSensors += t('add_car_parking_sensors_title')
            parkingSensors += ' ('
            parkingSensors += props.car.carDetail?.parkingSensors === FrontAndRearEnum.Front ? t('add_car_front_title') : props.car.carDetail?.parkingSensors === FrontAndRearEnum.Rear ? t('add_car_rear_title') : t('add_car_front_and_rear_title')
            parkingSensors += ') • '
        }

        return parkingSensors
    }

    const getAirConditioning = () => {
        let airConditioning = ''

        if (props.car.carDetail?.airConditioning != null && props.car.carDetail?.airConditioning !== -1) {
            airConditioning += t('add_car_air_conditioningl_title')
            airConditioning += ' ('
            airConditioning += props.car.carDetail?.airConditioning === AirConditioningEnum.TwoZone ? t('add_car_two_zone_title') : props.car.carDetail?.airConditioning === AirConditioningEnum.ThreeZone ? t('add_car_three_zone_title') : t('add_car_four_zone_title')
            airConditioning += ') • '
        }

        return airConditioning
    }

    const getElectricSeats = () => {
        let electricSeats = ''

        if (props.car.carDetail?.electricSeats != null && props.car.carDetail?.electricSeats !== -1) {
            electricSeats += t('add_car_electric_seats_title')
            electricSeats += ' ('
            electricSeats += props.car.carDetail?.electricSeats === ElectricSeatsEnum.Driver ? t('add_car_driver_title') : props.car.carDetail?.electricSeats === ElectricSeatsEnum.Passenger ? t('add_car_passenger_title') : t('add_car_driver_and_passenger_title')
            electricSeats += ') • '
        }

        return electricSeats
    }

    const getCooledSeats = () => {
        let cooledSeats = ''

        if (props.car.carDetail?.cooledSeats != null && props.car.carDetail?.cooledSeats !== -1) {
            cooledSeats += t('add_car_cooled_seats_title')
            cooledSeats += ' ('
            cooledSeats += props.car.carDetail?.cooledSeats === FrontAndRearEnum.Front ? t('add_car_front_title') : props.car.carDetail?.cooledSeats === FrontAndRearEnum.Rear ? t('add_car_rear_title') : t('add_car_front_and_rear_title')
            cooledSeats += ') • '
        }

        return cooledSeats
    }

    const getHeatedSeats = () => {
        let heatedSeats = ''

        if (props.car.carDetail?.heatedSeats != null && props.car.carDetail?.heatedSeats !== -1) {
            heatedSeats += t('add_car_heated_seats_title')
            heatedSeats += ' ('
            heatedSeats += props.car.carDetail?.heatedSeats === FrontAndRearEnum.Front ? t('add_car_front_title') : props.car.carDetail?.heatedSeats === FrontAndRearEnum.Rear ? t('add_car_rear_title') : t('add_car_front_and_rear_title')
            heatedSeats += ')'
        }

        return heatedSeats
    }

    const getSeatMaterial = () => {
        let seatMaterial = ''

        if (props.car.carDetail?.seatMaterial != null && props.car.carDetail?.seatMaterial !== -1) {
            seatMaterial += t('add_car_seat_material_title')
            seatMaterial += ' ('
            seatMaterial +=
                props.car.carDetail?.seatMaterial === SeatMaterialEnum.Leather
                    ? t('add_car_leather_title')
                    : props.car.carDetail?.seatMaterial === SeatMaterialEnum.Alcantara
                    ? t('add_car_alcantara_title')
                    : props.car.carDetail?.seatMaterial === SeatMaterialEnum.Suede
                    ? t('add_car_suede_title')
                    : props.car.carDetail?.seatMaterial === SeatMaterialEnum.Vegan
                    ? t('add_car_vegan_title')
                    : t('add_car_leather_tissue_title')
            seatMaterial += ')'
        }

        return seatMaterial
    }

    const getAirbags = () => {
        let airbags = ''

        if (props.car.carDetail?.airbags != null && props.car.carDetail?.airbags.length !== 0) {
            airbags += t('add_car_airbags_title')
            airbags += ' ('

            props.car.carDetail?.airbags.map((id: number) => {
                if (id === AirbagEnum.Driver) {
                    airbags += t('add_car_airbag_driver_title')
                }
                if (id === AirbagEnum.Front) {
                    airbags += t('add_car_airbag_front_title')
                }
                if (id === AirbagEnum.SecondRow) {
                    airbags += t('add_car_airbag_second_row_title')
                }
                if (id === AirbagEnum.ThirdRow) {
                    airbags += t('add_car_airbag_third_row_title')
                }
                if (id === AirbagEnum.Knee) {
                    airbags += t('add_car_airbag_knee_title')
                }
                if (id === AirbagEnum.Side) {
                    airbags += t('add_car_airbag_side_title')
                }
                if (id === AirbagEnum.Curtain) {
                    airbags += t('add_car_airbag_curtain_title')
                }
                if (id === AirbagEnum.RearCurtain) {
                    airbags += t('add_car_airbag_rear_curtain_title')
                }
                if (id === AirbagEnum.Pedestrian) {
                    airbags += t('add_car_airbag_pedestrian_title')
                }
                if (id === AirbagEnum.SeatBelt) {
                    airbags += t('add_car_airbag_seat_belt_title')
                }
                airbags += ', '
            })

            airbags = airbags.substring(0, airbags.length - 2)
            airbags += ') • '
        }

        return airbags
    }

    const getDriverAssistValues = () => {
        let value = ''
        value += props.car.carDetail?.headUpDisplay ? t('add_car_head_up_display_title') + ' • ' : ''
        value += props.car.carDetail?.laneAssist ? t('add_car_lane_assist_title') + ' • ' : ''
        value += props.car.carDetail?.threeSixtydegreeCamera ? t('add_car_threesixty_degree_camera_title') + ' • ' : ''
        value += props.car.carDetail?.parkingAssist ? t('add_car_parking_assist_title') + ' • ' : ''
        value += props.car.carDetail?.rearViewCamera ? t('add_car_rear_view_camera_title') + ' • ' : ''
        value += getParkingSensors()
        value += props.car.carDetail?.autoParking ? t('add_car_auto_parking_title') + ' • ' : ''
        value += props.car.carDetail?.cruiseControl ? t('add_car_cruise_control_title') + ' • ' : ''
        value += props.car.carDetail?.adaptiveCruiseControl ? t('add_car_adaptive_cruise_control_title') + ' • ' : ''

        if (value.substring(value.length - 3, value.length).includes(' • ')) {
            value = value.substring(0, value.length - 3)
        }
        return value
    }

    const getInfotainmentValues = () => {
        let value = ''
        value += props.car.carDetail?.centralDisplay ? t('add_car_central_display_title') + ' • ' : ''
        value += props.car.carDetail?.navigationSystem ? t('add_car_navigation_system_title') + ' • ' : ''
        value += props.car.carDetail?.appleCarplay ? t('add_car_apple_carplay_title') + ' • ' : ''
        value += props.car.carDetail?.androidAuto ? t('add_car_android_auto_title') + ' • ' : ''
        value += props.car.carDetail?.bluetoothInterface ? t('add_car_bluetooth_interface_title') + ' • ' : ''
        value += props.car.carDetail?.dabRadio ? t('add_car_dab_radio_title') + ' • ' : ''
        value += props.car.carDetail?.wirelessPhoneCharger ? t('add_car_wireless_phone_charger_title') + ' • ' : ''
        value += getUSBType()
        value += props.car.carDetail?.soundSystem ? t('add_car_sound_system_title') + ' • ' : ''

        if (value.substring(value.length - 3, value.length).includes(' • ')) {
            value = value.substring(0, value.length - 3)
        }
        return value
    }

    const getSafetyValues = () => {
        let value = ''
        value += props.car.carDetail?.blindSpotMonitor ? t('add_car_blind_spot_monitor_title') + ' • ' : ''
        value += props.car.carDetail?.abs ? t('add_car_abs_title') + ' • ' : ''
        value += props.car.carDetail?.esc ? t('add_car_esc_title') + ' • ' : ''
        value += getAirbags()
        value += props.car.carDetail?.tyrePressureMonitoringSystem ? t('add_car_tyre_pressure_system_title') + ' • ' : ''
        value += props.car.carDetail?.autoEmergencyBreak ? t('add_car_auto_emergency_break_title') + ' • ' : ''
        value += props.car.carDetail?.driverAlertSystem ? t('add_car_driver_alert_system_title') + ' • ' : ''
        value += props.car.carDetail?.isofix ? t('add_car_isofix_title') + ' • ' : ''
        value += props.car.carDetail?.nightVision ? t('add_car_night_vision_title') + ' • ' : ''
        value += props.car.carDetail?.frontCollisionWarning ? t('add_car_front_collision_warning_title') + ' • ' : ''
        value += props.car.carDetail?.crossTrafficWarning ? t('add_car_cross_traffic_warning_title') + ' • ' : ''

        if (value.substring(value.length - 3, value.length).includes(' • ')) {
            value = value.substring(0, value.length - 3)
        }

        return value
    }

    const getComfortValues = () => {
        let value = ''
        value += props.car.carDetail?.heatPump ? t('add_car_heat_pump_title') + ' • ' : ''
        value += getAirConditioning()
        value += props.car.carDetail?.rainSensor ? t('add_car_rain_sensor_title') + ' • ' : ''
        value += props.car.carDetail?.electricTrunkOpening ? t('add_car_electric_trunk_opening_title') + ' • ' : ''
        value += props.car.carDetail?.electricSideMirrors ? t('add_car_electric_side_mirrors_title') + ' • ' : ''
        value += props.car.carDetail?.heatedSideMirrors ? t('add_car_heated_side_mirrors_title') + ' • ' : ''
        value += props.car.carDetail?.keylessEntry ? t('add_car_keyless_entry_title') + ' • ' : ''
        value += props.car.carDetail?.heatedSteeringWheel ? t('add_car_heated_steering_wheel_title') + ' • ' : ''
        value += getElectricSeats()
        value += getCooledSeats()
        value += getHeatedSeats()

        if (value.substring(value.length - 3, value.length).includes(' • ')) {
            value = value.substring(0, value.length - 3)
        }

        return value
    }

    const getLightValues = () => {
        let value = ''
        value += props.car.carDetail?.ledHeadLights ? t('add_car_led_headlights_title') + ' • ' : ''
        value += props.car.carDetail?.ledRearLights ? t('add_car_led_rear_lights_title') + ' • ' : ''
        value += props.car.carDetail?.xenonHeadLights ? t('add_car_xenon_headlights_title') + ' • ' : ''
        value += props.car.carDetail?.xenonRearLights ? t('add_car_xenon_rear_lights_title') + ' • ' : ''
        value += props.car.carDetail?.matrixHeadlights ? t('add_car_matrix_headlights_title') + ' • ' : ''
        value += props.car.carDetail?.corneringLights ? t('add_car_cornering_lights_title') + ' • ' : ''
        value += props.car.carDetail?.fogLights ? t('add_car_fog_lights_title') + ' • ' : ''
        value += props.car.carDetail?.ambientLighting ? t('add_car_ambient_lighting_title') + ' • ' : ''

        if (value.substring(value.length - 3, value.length).includes(' • ')) {
            value = value.substring(0, value.length - 3)
        }

        return value
    }

    const getExtrasValues = () => {
        let value = ''
        value += props.car.carDetail?.tintedWindows ? t('add_car_tinted_windows_title') + ' • ' : ''
        value += props.car.carDetail?.skiRack ? t('add_car_ski_rack_title') + ' • ' : ''
        value += props.car.carDetail?.towBar ? t('add_car_tow_bar_title') + ' • ' : ''
        value += props.car.carDetail?.slidingRoof ? t('add_car_sliding_roof_title') + ' • ' : ''
        value += props.car.carDetail?.panoramicRoof ? t('add_car_panoramic_roof_title') + ' • ' : ''
        value += props.car.carDetail?.sportSuspension ? t('add_car_sport_suspension_title') + ' • ' : ''
        value += props.car.carDetail?.alloyWheels ? t('add_car_alloy_wheels_title') + ' • ' : ''
        value += props.car.carDetail?.sportSeats ? t('add_car_sport_seats_title') + ' • ' : ''
        value += getSeatMaterial()

        if (value.substring(value.length - 3, value.length).includes(' • ')) {
            value = value.substring(0, value.length - 3)
        }

        return value
    }

    const onTranslateClick = async (type: 'knownProblems' | 'ownershipHistory' | 'serviceHistoryAndRepairs' | 'otherInformation' | 'otherItemsIncluded' | 'modifications' | 'otherEquipment') => {
        // if (user) {
        const text =
            type === 'ownershipHistory'
                ? props.car.carDetail?.ownershipHistory ?? ''
                : type === 'serviceHistoryAndRepairs'
                ? props.car.carDetail?.serviceHistoryAndRepairs ?? ''
                : type === 'otherInformation'
                ? props.car.carDetail?.otherInformation ?? ''
                : type === 'otherItemsIncluded'
                ? props.car.carDetail?.otherItemsIncluded ?? ''
                : type === 'modifications'
                ? props.car.carDetail?.modifications ?? ''
                : type === 'otherEquipment'
                ? props.car.carDetail?.otherEquipment ?? ''
                : type === 'knownProblems'
                ? props.car.carDetail?.knownProblemsOrFlaws ?? ''
                : ''

        if (text.length !== 0) {
            await translate({
                text: text,
                language: i18n.language.includes('de') ? 'de' : i18n.language.includes('fr') ? 'fr' : 'en',
                onSuccess(text: string) {
                    if (type === 'ownershipHistory') {
                        setTranslatedOwnershipHistory(text)
                    }

                    if (type === 'serviceHistoryAndRepairs') {
                        setTranslatedServiceHistoryAndRepairs(text)
                    }

                    if (type === 'otherInformation') {
                        setTranslatedOtherInformation(text)
                    }

                    if (type === 'otherItemsIncluded') {
                        setTranslatedOtherItemsIncluded(text)
                    }

                    if (type === 'modifications') {
                        setTranslatedModifications(text)
                    }

                    if (type === 'otherEquipment') {
                        setTranslatedOtherEquipment(text)
                    }

                    if (type === 'knownProblems') {
                        setTranslatedKnownProblems(text)
                    }
                },
            })
        }
        // } else {
        //     dispatch(ShowLoginModal({ visible: true }))
        // }
    }

    return (
        <div className="car-informations">
            <h2>{t('car_information_title')}</h2>

            <label>
                {props.car.manufacturingYear} {props.car.make} {props.car.model}
            </label>

            {/* car details */}
            <div className="section-div">
                <div className="header-div" onClick={onCarDetailsVisibilityClick}>
                    <label>{t('car_information_car_details_title')}</label>
                    <img className={`arrow ${carDetailsVisible ? 'rotate' : ''}`} alt="Arrow icon" src="/images/ic_arrow_down.svg" />
                </div>

                <div className={`container-div column ${carDetailsVisible ? `show` : `hide`}`}>
                    <div className={`car-detail-row-div`}>
                        <div className={`item-div`}>
                            <p className="title">{t('car_information_car_location_title')}</p>
                            <p className="value">
                                {props.car.address?.postalCode} {props.car.address?.city}
                            </p>
                        </div>

                        <div className={`item-div`}>
                            <p className="title">{t('car_information_manufacturing_year_title')}</p>
                            <p className="value">{props.car.manufacturingYear}</p>
                        </div>

                        <div className={`item-div`}>
                            <p className="title">{t('car_information_make_title')}</p>
                            <p className="value">{props.car.make}</p>
                        </div>
                    </div>

                    <div className={`car-detail-row-div`}>
                        <div className={`item-div`}>
                            <p className="title">{t('car_information_model_title')}</p>
                            <p className="value">{props.car.model}</p>
                        </div>

                        <div className={`item-div`}>
                            <p className="title">{t('add_car_variant_title')}</p>
                            <p className="value">{props.car.variant != null && props.car.variant !== '' ? props.car.variant : '-'}</p>
                        </div>

                        <div className={`item-div`}>
                            <p className="title">{t('car_information_battery_capacity_title')}</p>
                            <p className="value">
                                {props.car.batteryCapacity ? props.car.batteryCapacity.toString().replace('.', ',') : ''} {t('car_card_kWh_title')}
                            </p>
                        </div>
                    </div>

                    <div className={`car-detail-row-div`}>
                        <div className={`item-div`}>
                            <p className="title">{t('car_information_estimated_range_title')}</p>
                            <p className="value">
                                {props.car.manufacturerEstimatedRange} {t('car_card_km_title')}
                            </p>
                        </div>

                        <div className={`item-div`}>
                            <p className="title">{t('auction_horsepower_title')}</p>
                            <p className="value">
                                {props.car.horsepower} {t('auction_ps_title')}
                            </p>
                        </div>

                        <div className={`item-div`}>
                            <p className="title">{t('car_information_mileage_title')}</p>
                            <p className="value">
                                {props.car.mileage} {t('car_card_km_title')}
                            </p>
                        </div>
                    </div>

                    <div className={`car-detail-row-div`}>
                        <div className={`item-div`}>
                            <p className="title">{t('car_information_battery_status_title')}</p>
                            <p className="value">{props.car.batteryStatus === BatteryStatusEnum.Owned ? t('add_car_owned_title') : t('add_car_leased_title')} </p>
                        </div>

                        <div className={`item-div`}>
                            <p className="title">{t('car_information_drivetrain_title')}</p>
                            <p className="value">{props.car.drivetrain === DrivetrainEnum.AWD ? t('add_car_awd_title') : props.car.drivetrain === DrivetrainEnum.TWOWD ? t('add_car_twowd_title') : props.car.drivetrain === DrivetrainEnum.RWD ? t('add_car_rwd_title') : t('add_car_fwd_title')}</p>
                        </div>

                        <div className={`item-div`}>
                            <p className="title">{t('car_information_charging_connector_title')}</p>
                            <p className="value">
                                {t('add_car_ac_title')}
                                {': '}
                                {props.car.chargingConnectorAc === ChargingConnectorAcTypeEnum.Type1 ? t('add_car_type_one_title') : props.car.chargingConnectorAc === ChargingConnectorAcTypeEnum.Type2 ? t('add_car_type_two_title') : props.car.chargingConnectorAcOtherName}
                                {' / '}
                                {t('add_car_dc_title')}
                                {': '}
                                {props.car.chargingConnectorDc === ChargingConnectorDcTypeEnum.Ccs ? t('add_car_ccs_title') : props.car.chargingConnectorDc === ChargingConnectorDcTypeEnum.CHAdeMO ? t('add_car_chademo_title') : props.car.chargingConnectorDcOtherName}
                            </p>
                        </div>
                    </div>

                    <div className={`car-detail-row-div`}>
                        <div className={`item-div`}>
                            <p className="title">{t('car_information_maximum_ac_charging_title')}</p>
                            <p className="value">
                                {props.car.maximumAcCharging ? props.car.maximumAcCharging.toString().replace('.', ',') : ''} {t('car_card_kW_title')}
                            </p>
                        </div>

                        <div className={`item-div`}>
                            <p className="title">{t('car_information_maximum_dc_charging_title')}</p>
                            <p className="value">
                                {props.car.maximumDcCharging ? props.car.maximumDcCharging.toString().replace('.', ',') : ''} {t('car_card_kW_title')}
                            </p>
                        </div>

                        <div className={`item-div`}>
                            <p className="title">{t('car_information_mfk_date_title')}</p>
                            <p className="value">{props.car.mfkDate ? formatTimeLeftWithoutDay(props.car.mfkDate!!, t) : ''}</p>
                        </div>
                    </div>

                    <div className={`car-detail-row-div`}>
                        <div className={`item-div`}>
                            <p className="title">{t('car_information_warranty_until_title')}</p>
                            <p className="value">{props.car.validWarranty ? formatTimeLeftWithoutDay(props.car.validWarranty!!, t) : 'No'}</p>
                        </div>

                        <div className={`item-div`}>
                            <p className="title">{t('car_information_color_title')}</p>
                            <p className="value">{props.car.carDetail?.exteriorColour}</p>
                        </div>

                        <div className={`item-div`}>
                            <p className="title">{t('car_information_interior_title')}</p>
                            <p className="value">{props.car.carDetail?.interiorColour}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* equipment */}
            <div className="section-div">
                <div className="header-div" onClick={onEquipmentVisibilityClick}>
                    <label>{t('car_information_equipment_title')}</label>
                    <img className={`arrow ${equipmentVisible ? 'rotate' : ''}`} alt="Arrow icon" src="/images/ic_arrow_down.svg" />
                </div>

                <div className={`container-div column ${equipmentVisible ? `show` : `hide`}`}>
                    {/* drive assist */}
                    <div className={`row-div`}>
                        <div className={`title-div`}>
                            <img className={`title-img`} alt="Title icon" src="/images/ic_drive_assist.svg" />
                            <p className="title">{t('car_information_drive_assist_title')}</p>
                        </div>

                        <div className={`divider-div`} />

                        <div className={`values-div`}>
                            <p className="value">{getDriverAssistValues()}</p>
                        </div>
                    </div>

                    {/* infotainment and electronics */}
                    <div className={`row-div`}>
                        <div className={`title-div`}>
                            <img className={`title-img`} alt="Title icon" src="/images/ic_infotainment.svg" />
                            <p className="title">{t('car_information_infotainment_and_electronics_title')}</p>
                        </div>

                        <div className={`divider-div`} />

                        <div className={`values-div`}>
                            <p className="value">{getInfotainmentValues()}</p>
                        </div>
                    </div>

                    {/* safety */}
                    <div className={`row-div`}>
                        <div className={`title-div`}>
                            <img className={`title-img`} alt="Title icon" src="/images/ic_safety.svg" />
                            <p className="title">{t('car_information_safety_title')}</p>
                        </div>

                        <div className={`divider-div`} />

                        <div className={`values-div`}>
                            <p className="value">{getSafetyValues()}</p>
                        </div>
                    </div>

                    {/* comfort */}
                    <div className={`row-div`}>
                        <div className={`title-div`}>
                            <img className={`title-img`} alt="Title icon" src="/images/ic_comfort.svg" />
                            <p className="title">{t('car_information_comfort_title')}</p>
                        </div>

                        <div className={`divider-div`} />

                        <div className={`values-div`}>
                            <p className="value">{getComfortValues()}</p>
                        </div>
                    </div>

                    {/* lights */}
                    <div className={`row-div`}>
                        <div className={`title-div`}>
                            <img className={`title-img`} alt="Title icon" src="/images/ic_lights.svg" />
                            <p className="title">{t('car_information_lights_title')}</p>
                        </div>

                        <div className={`divider-div`} />

                        <div className={`values-div`}>
                            <div className={`values-div`}>
                                <p className="value">{getLightValues()}</p>
                            </div>
                        </div>
                    </div>

                    {/* extras */}
                    <div className={`row-div`}>
                        <div className={`title-div`}>
                            <img className={`title-img`} alt="Title icon" src="/images/ic_extras.svg" />
                            <p className="title">{t('car_information_extras_title')}</p>
                        </div>

                        <div className={`divider-div`} />

                        <div className={`values-div`}>
                            <div className={`values-div`}>
                                <p className="value">{getExtrasValues()}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* other equipment */}
            <div className="section-div">
                <div className="header-div" onClick={onOtherEquipmentVisibilityClick}>
                    <label>{t('add_car_other_equipment_title')}</label>
                    <img className={`arrow ${otherEquipmentVisible ? 'rotate' : ''}`} alt="Arrow icon" src="/images/ic_arrow_down.svg" />
                </div>

                <div className={`container-div ${otherEquipmentVisible ? `show` : `hide`}`}>
                    <div className={`column-div deepl-div`}>
                        <p className="description">{translatedOtherEquipment !== '' ? translatedOtherEquipment : props.car.carDetail?.otherEquipment}</p>
                        {props.deepLEnabled && <img alt="Translate icon" className="deepl-img" src="/images/ic_deepl.svg" onClick={() => onTranslateClick('otherEquipment')} />}
                    </div>
                </div>
            </div>

            {/* ownership historys */}
            <div className="section-div">
                <div className="header-div" onClick={onOwnershipHistoryVisibilityClick}>
                    <label>{t('car_information_ownership_history_title')}</label>
                    <img className={`arrow ${ownershipHistoryVisible ? 'rotate' : ''}`} alt="Arrow icon" src="/images/ic_arrow_down.svg" />
                </div>

                <div className={`container-div ${ownershipHistoryVisible ? `show` : `hide`}`}>
                    <div className={`column-div deepl-div`}>
                        <p className="description">{translatedOwnershipHistory !== '' ? translatedOwnershipHistory : props.car.carDetail?.ownershipHistory}</p>
                        {props.deepLEnabled && <img alt="Translate icon" className="deepl-img" src="/images/ic_deepl.svg" onClick={() => onTranslateClick('ownershipHistory')} />}
                    </div>
                </div>
            </div>

            {/* service historys */}
            <div className="section-div">
                <div className="header-div" onClick={onServiceHistoryVisibilityClick}>
                    <label>{t('car_information_service_history_title')}</label>
                    <img className={`arrow ${serviceHistoryVisible ? 'rotate' : ''}`} alt="Arrow icon" src="/images/ic_arrow_down.svg" />
                </div>

                <div className={`container-div ${serviceHistoryVisible ? `show` : `hide`}`}>
                    <div className={`column-div deepl-div`}>
                        <p className="description">{translatedServiceHistoryAndRepairs !== '' ? translatedServiceHistoryAndRepairs : props.car.carDetail?.serviceHistoryAndRepairs}</p>

                        {props.deepLEnabled && <img alt="Translate icon" className="deepl-img" src="/images/ic_deepl.svg" onClick={() => onTranslateClick('serviceHistoryAndRepairs')} />}
                    </div>
                </div>
            </div>

            {/* other items included in the sale */}
            <div className="section-div">
                <div className="header-div" onClick={onOtherItemsIncludedInTheSaleVisibilityClick}>
                    <label>{t('car_information_other_items_included_in_the_sale_title')}</label>
                    <img className={`arrow ${otherItemsIncludedInTheSaleVisible ? 'rotate' : ''}`} alt="Arrow icon" src="/images/ic_arrow_down.svg" />
                </div>

                <div className={`container-div ${otherItemsIncludedInTheSaleVisible ? `show` : `hide`}`}>
                    <div className={`column-div deepl-div`}>
                        <p className="description">{translatedOtherItemsIncluded !== '' ? translatedOtherItemsIncluded : props.car.carDetail?.otherItemsIncluded}</p>

                        {props.deepLEnabled && <img alt="Translate icon" className="deepl-img" src="/images/ic_deepl.svg" onClick={() => onTranslateClick('otherItemsIncluded')} />}
                    </div>
                </div>
            </div>

            {/* modifications */}
            <div className="section-div">
                <div className="header-div" onClick={onModificationsVisibilityClick}>
                    <label>{t('car_information_modifications_title')}</label>
                    <img className={`arrow ${modificationVisible ? 'rotate' : ''}`} alt="Arrow icon" src="/images/ic_arrow_down.svg" />
                </div>

                <div className={`container-div ${modificationVisible ? `show` : `hide`}`}>
                    <div className={`column-div deepl-div`}>
                        <p className="description">{translatedModifications !== '' ? translatedModifications : props.car.carDetail?.modifications}</p>

                        {props.deepLEnabled && <img alt="Translate icon" className="deepl-img" src="/images/ic_deepl.svg" onClick={() => onTranslateClick('modifications')} />}
                    </div>
                </div>
            </div>

            {/* known problems */}
            <div className="section-div">
                <div className="header-div" onClick={onKnownProblemsVisibilityClick}>
                    <label>{t('car_information_known_problems_or_flaws_title')}</label>
                    <img className={`arrow ${knownProblemsVisible ? 'rotate' : ''}`} alt="Arrow icon" src="/images/ic_arrow_down.svg" />
                </div>

                <div className={`container-div ${knownProblemsVisible ? `show` : `hide`}`}>
                    <div className={`column-div deepl-div`}>
                        <p className="description">{translatedKnownProblems !== '' ? translatedKnownProblems : props.car.carDetail?.knownProblemsOrFlaws}</p>
                        {props.deepLEnabled && <img alt="Translate icon" className="deepl-img" src="/images/ic_deepl.svg" onClick={() => onTranslateClick('knownProblems')} />}
                    </div>
                </div>
            </div>

            {/* uploaded documents */}
            <div className="section-div">
                <div className="header-div" onClick={onUploadedDocumentsVisibilityClick}>
                    <label>{t('car_information_uploaded_documents_title')}</label>
                    {/* <img className={`arrow ${uploadedDocumentsVisible ? 'rotate' : ''}`} alt="Arrow icon" src="/images/ic_arrow_down.svg" /> */}
                </div>

                <div className={`container-div`}>
                    <div className="ducuments-div">
                        {props.car.carDetail!!.documents!!.map((documents, index) => (
                            <DocumentElement document={documents} />
                        ))}
                    </div>
                </div>
            </div>

            {/* other information */}
            <div className="section-div">
                <div className="header-div" onClick={onOtherInformationVisibilityClick}>
                    <label>{t('car_information_other_information_title')}</label>
                    <img className={`arrow ${otherInformationVisible ? 'rotate' : ''}`} alt="Arrow icon" src="/images/ic_arrow_down.svg" />
                </div>

                <div className={`container-div ${otherInformationVisible ? `show` : `hide`}`}>
                    <div className={`column-div deepl-div`}>
                        <p className="description">{translatedOtherInformation !== '' ? translatedOtherInformation : props.car.carDetail?.otherInformation}</p>

                        {props.deepLEnabled && <img alt="Translate icon" className="deepl-img" src="/images/ic_deepl.svg" onClick={() => onTranslateClick('otherInformation')} />}
                    </div>
                </div>
            </div>
        </div>
    )
}
