import { useTranslation } from 'react-i18next'
import { Car } from '../../../../../entity'
import { Blurhash } from 'react-blurhash'
import { useState } from 'react'
import { Battery, Button } from '../../../../../components'
import { CarStatusEnum } from '../../../../../enum'
import { getCarsAuctioned, getCarsSold, reauction } from '../../../../../api'
import './cars-sold-element.scss'

interface CarsSoldElementProps {
    car: Car
    reauctionVisible: boolean
}

export const CarsSoldElement = (props: CarsSoldElementProps) => {
    const { t } = useTranslation()
    const [imageLoaded, setImageLoaded] = useState(false)

    const onReauctionClick = async () => {
        await reauction({
            id: props.car.id,
            onSuccess() {
                getCarsAuctioned(true)
                getCarsSold(true)
            },
        })
    }

    return (
        <li key={props.car.id} className={`cars-sold-element`}>
            <div className="cars-sold-element__image-div">
                {props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 && <Blurhash className={`blurhash-img ${imageLoaded ? `hidden` : ``}`} hash={props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 ? props.car.mediaFiles[0].blurHash : ''} width={'100%'} height={'100%'} resolutionX={32} resolutionY={32} punch={1} />}

                <img
                    alt="Car"
                    className="car-img"
                    src={props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 ? props.car.mediaFiles[0].thumbnail : '/images/ic_no_image.svg'}
                    onLoad={() => {
                        setImageLoaded(true)
                    }}
                />
            </div>

            <div className="cars-sold-element__information-div">
                <div className={`name-div`}>
                    <div className={`name-inner-div`}>
                        <h3 className="name">
                            {props.car?.manufacturingYear} {props.car?.make} {props.car?.model}
                        </h3>
                        <p className={`status ${props.car.status === CarStatusEnum.Paid ? `` : `hidden`} `}>{t('car_card_sold_title')} </p>
                        <p className={`status did-not-sell ${props.car.status === CarStatusEnum.DidNotSell ? `` : `hidden`} `}>{t('car_card_did_not_sell_title')} </p>
                        <p className={`status pending ${props.car.status === CarStatusEnum.Sold || props.car.status === CarStatusEnum.Unpaid || props.car.status === CarStatusEnum.Overdue ? `` : `hidden`} `}>{t('my_ev_garage_pending_title').toUpperCase()} </p>
                    </div>
                    <label className="address">
                        {props.car?.address?.postalCode} {props.car?.address?.city}
                    </label>
                </div>

                <div className="price-div">
                    <div className={`bid-div`}>
                        <p className="sub-title">{props.car.status === CarStatusEnum.Sold || props.car.status === CarStatusEnum.Paid || props.car.status === CarStatusEnum.Unpaid ? t('car_card_final_price_title') : t('my_ev_garage_highest_bid_title')}</p>

                        <div className="price-div">
                            <h3 className={`price`}> {props.car.auctions[0].currentPrice.toLocaleString('de-CH', { minimumFractionDigits: 0 })}</h3>
                            <label className={`currency `}>
                                {t('car_card_chf_title')} {'/ ' + props.car.auctions[0].bidCount + ' ' + t('auction_bids_title')}
                            </label>
                        </div>
                    </div>
                    <div className="time-left-div">
                        <p className={`sub-title `}>{t('car_card_end_date_title')}</p>
                        <Battery type={'normal'} auction={props.car.auctions[0]} />
                    </div>
                </div>

                <div className={`reauction-div ${props.reauctionVisible ? `` : `hidden`}`}>
                    <Button
                        title={t('button_reauction')}
                        onClick={() => {
                            onReauctionClick()
                        }}
                    />
                </div>
            </div>
        </li>
    )
}
