import { Modal } from '../modal'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ErrorMessage } from '../../../redux/actions'
import './message.modal.scss'

interface MessageModalProps {
    message: string
    title?: string
    showClose?: boolean
    onCloseClick?: () => void
}

export const MessageModal = (props: MessageModalProps) => {
    const dispatch = useDispatch()

    const { t } = useTranslation()

    return (
        <Modal title={props.title ? props.title : t('error_title')} showClose={props.showClose} onCloseClick={() => (props.onCloseClick ? props.onCloseClick() : dispatch(ErrorMessage({ title: null, message: null, code: null })))}>
            <div className="message-modal">
                <label className="message-modal__message">{props.message}</label>
            </div>
        </Modal>
    )
}
