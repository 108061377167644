import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { MediaFile } from '../../../../../../entity'
import { RemoveMediaFile } from '../../../../../../redux/actions'
import { deleteMediaFile } from '../../../../../../api'
import './photo-item.scss'
import { useEffect, useState } from 'react'

interface PhotoItemProps {
    index: number
    mediaFile: MediaFile
    isVideo: boolean
    onDragStart?: any
    onDragEnter?: any
    onDragEnd?: () => void
    onRemoveFileClick?: (mediaFileId: number) => void
}

export const PhotoItem = (props: PhotoItemProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [isAndroid, setIsAndroid] = useState(false)
    const [isFirstClick, setIsFirstClick] = useState(true)

    useEffect(() => {
        if (/Android/i.test(navigator.userAgent)) {
            setIsAndroid(true)
        } else {
            setIsAndroid(false)
        }
    }, [])

    const onRemoveFileClick = async () => {
        await deleteMediaFile({
            id: props.mediaFile.id,
            onSuccess() {
                if (props.onRemoveFileClick) {
                    props.onRemoveFileClick(props.mediaFile.id)
                } else {
                    dispatch(RemoveMediaFile({ mediaFileId: props.mediaFile.id }))
                }
            },
        })
    }

    const onLeftClick = async () => {
        props.onDragStart(props.index)
        props.onDragEnter(props.index - 1)
        if (props.onDragEnd) {
            props.onDragEnd()
            setIsFirstClick(true)
        }
    }

    const onRightClick = async () => {
        props.onDragStart(props.index)
        props.onDragEnter(props.index + 1)
        if (props.onDragEnd) {
            props.onDragEnd()
            setIsFirstClick(true)
        }
    }

    const dragStart = (e: any, position: number) => {
        props.onDragStart(position)
    }

    const dragEnter = (e: any, position: number) => {
        props.onDragEnter(position)
    }

    return (
        <div
            key={props.mediaFile.id}
            className="photo-item"
            draggable
            onDragStart={(e) => {
                if (props.onDragStart) {
                    dragStart(e, props.index)
                }
            }}
            onDragEnter={(e) => {
                if (props.onDragEnter) {
                    dragEnter(e, props.index)
                }
            }}
            onDragEnd={props.onDragEnd}
            onMouseEnter={() => {
                if (isAndroid) {
                    setIsFirstClick(false)
                }
            }}
            onMouseLeave={() => {
                if (isAndroid) {
                    setIsFirstClick(true)
                }
            }}
        >
            <div className="image-div">
                <img alt="Car" className="car-img" src={props.mediaFile.thumbnail} />

                {props.onDragStart && <img alt="Move icon" className={`move-img ${props.isVideo ? `hidden` : ``} ${isAndroid ? `hidden` : ``}`} src={`/images/ic_move.svg`} />}

                {props.onDragStart && (
                    <img
                        alt="left icon"
                        className={`left-img ${props.isVideo ? `hidden` : ``} ${isFirstClick ? `hidden` : `show`}`}
                        src={`/images/ic_arrow_left_black.svg`}
                        onClick={(e: any) => {
                            onLeftClick()
                        }}
                    />
                )}
                {props.onDragStart && (
                    <img
                        alt="Right icon"
                        className={`right-img ${props.isVideo ? `hidden` : ``}  ${isFirstClick ? `hidden` : `show`}`}
                        src={`/images/ic_arrow_right_black.svg`}
                        onClick={(e: any) => {
                            onRightClick()
                        }}
                    />
                )}

                <img
                    alt="Delete icon"
                    className={`delete-img ${props.isVideo ? `center` : ``} ${isAndroid ? `center` : ``}`}
                    src={`/images/ic_delete.svg`}
                    onClick={(e: any) => {
                        if (isAndroid) {
                            if (!isFirstClick) {
                                onRemoveFileClick()
                            }
                        } else {
                            onRemoveFileClick()
                        }
                    }}
                />

                <p className={`default ${props.index === 0 ? `` : `hidden`}  ${props.isVideo ? `hidden` : ``}`}>{t('add_car_default_title')}</p>
            </div>
        </div>
    )
}
