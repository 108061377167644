import { NetworkCall } from '../../utils'
import { store } from '../../redux'
import { Logout } from '../../redux/actions/login'
import { getCars } from '../cars'
import { UpdateCarsParams } from '../../redux/actions'

export const logout = async () => {
    return await new NetworkCall('POST', `/1.0.0/users/logout`)
        .setHeader('Authorization', `Bearer ${sessionStorage.getItem('token') ?? localStorage.getItem('token')}`)
        .then(201, (responseBody: any) => {
            store.dispatch(Logout({}))

            store.dispatch(UpdateCarsParams({ tab: 'endingSoon' }))

            getCars(true)
        })
        .catch((response) => {
            store.dispatch(Logout({}))

            getCars(true)
        })
        .call()
}
