import { ErrorDTO, NotificationSettingsDTO } from '../../dto'
import { store } from '../../redux'
import { ErrorMessage } from '../../redux/actions'
import { UpdateUserNotificationSettings } from '../../redux/actions/user/update-user-notification-settings.action'
import { NetworkCall } from '../../utils'

export const getNotificationSettings = async () => {
    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('GET', `/1.0.0/users/notification-settings`)
        .setHeader('Authorization', `Bearer ${token}`)
        .then(200, NotificationSettingsDTO, (responseBody) => {
            store.dispatch(UpdateUserNotificationSettings(responseBody.notificationSettings))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
