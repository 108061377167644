import { createAction } from '@reduxjs/toolkit'

interface Payload {
    oneDayBeforeAuctionEnds?: boolean
    oneHourBeforeAuctionEnds?: boolean
    newBids?: boolean
    replyToMyComment?: boolean
    questionsAnswered?: boolean
    auctionResults?: boolean
    reservePriceLowered?: boolean
    reservePriceDeleted?: boolean
}

export const UpdateUserNotificationSettings = createAction<Payload>('USER__UPDATE_USER_NOTIFICATION_SETTINGS')
