import { PropsWithChildren } from 'react'
import './why-do-quality-photos-item.scss'

interface WhyDoQualityPhotosItemProps {
    titleNumber: number
    titleText: string
    body: string
}

export const WhyDoQualityPhotosItem = (props: PropsWithChildren<WhyDoQualityPhotosItemProps>) => {
    return (
        <div className="why-do-quality-photos-item">
            <div className="title">
                <div className="title-badge">{props.titleNumber.toString() + '.'}</div>
                <div className="title-text" dangerouslySetInnerHTML={{ __html: props.titleText }}></div>
            </div>
            <div className="body" dangerouslySetInnerHTML={{ __html: props.body }}></div>
        </div>
    )
}
