import { createAction } from '@reduxjs/toolkit'

interface Payload {
    make?: string | null
    model?: string | null
    minRange?: number | undefined
    maxRange?: number | undefined
    minCapacity?: number | undefined
    maxCapacity?: number | undefined
    chargePlug?: string | null
}

export const UpdateEvDatabaseCarsParams = createAction<Payload>('EV_DATABASE_CARS__EV_DATABASE_CARS_UPDATE_PARAMS')
