import { useTranslation } from 'react-i18next'
import { Battery, Button } from '../../../../components'
import { Car } from '../../../../entity'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { favourite, getCars } from '../../../../api'
import { useAppSelector } from '../../../../redux'
import { AuctionsTab, CarStatusEnum } from '../../../../enum'
import { useDispatch } from 'react-redux'
import { ShowLoginModal } from '../../../../redux/actions'
import { Blurhash } from 'react-blurhash'
import './car-card.scss'

interface CarCardProps {
    car: Car
}

export const CarCard = (props: CarCardProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [touchMoved, setTouchMoved] = useState(false)

    const [imageLoaded, setImageLoaded] = useState(false)
    const [isFavourite, setIsFavourite] = useState(props.car.favourite)
    const [actionEndsInOneDay, setActionEndsInOneDay] = useState(false)
    const [actionEnded, setActionEnded] = useState(false)

    const endDateInMs = new Date(props.car.auctions[0].endDate).getTime()
    const [countDownTimeInMs, setCountDownTimeInMs] = useState(endDateInMs - new Date().getTime())

    const { tab } = useAppSelector((state) => state.cars)
    const { user } = useAppSelector((state) => state.login)

    useEffect(() => {
        //   setImageLoaded(false)
        setActionEnded(false)
        setIsFavourite(props.car.favourite)

        const timeDifferenceInMs = new Date(props.car.auctions[0].endDate).getTime() - new Date().getTime()
        const days = Math.floor(timeDifferenceInMs / (24 * 60 * 60 * 1000))

        if (timeDifferenceInMs < 0) {
            setActionEnded(true)
        } else if (days < 1) {
            setActionEndsInOneDay(true)

            const interval = setInterval(() => {
                setCountDownTimeInMs(endDateInMs - new Date().getTime())
            }, 1000)

            return () => clearInterval(interval)
        }
    }, [endDateInMs, props.car])

    const onTouchStart = async () => {
        setTouchMoved(false)
    }

    const onTouchMove = async () => {
        setTouchMoved(true)
    }

    const onTouchEnd = async (event: any) => {
        if (touchMoved === false) {
            onOpenOrFavouriteClick(event)
        }
    }

    const onClickHandler = async (event: any) => {
        event.stopPropagation()
        onOpenOrFavouriteClick(event)
    }

    const onOpenOrFavouriteClick = async (event: any) => {
        if (!props.car.isArchived) {
            const target = event.target as HTMLElement
            if (target.className.indexOf(`favourite-img`) === -1) {
                navigate(`/auction/${props.car.id}`)
            } else {
                if (user) {
                    await favourite({
                        id: props.car.id,
                        onSuccess() {
                            setIsFavourite(!isFavourite)

                            if (tab === AuctionsTab.Favourite) {
                                getCars(true)
                            }
                        },
                    })
                } else {
                    dispatch(ShowLoginModal({ visible: true, carId: null }))
                }
            }
        }
    }

    const getReservePriceTitle = () => {
        if (endDateInMs > new Date().getTime() && (props.car.reservePrice == null || props.car.reservePrice === 0) && props.car.status !== CarStatusEnum.Sold) {
            return t('car_card_no_reserve_title')
        } else if (props.car.reservePriceDeleted) {
            return t('car_card_reserve_price_deleted_title')
        } else if (props.car.reservePriceLowered) {
            return t('car_card_reserve_price_lowered_title')
        } else {
            return ''
        }
    }

    const getReservePriceTitleStyle = () => {
        if (endDateInMs < new Date().getTime()) {
            return `hidden`
        } else if (props.car.reservePrice == null || props.car.reservePrice === 0) {
            return ``
        } else if (props.car.reservePriceDeleted) {
            return 'reserve-price-deleted'
        } else if (props.car.reservePriceLowered) {
            return 'reserve-price-deleted'
        } else {
            return `hidden`
        }
    }

    return (
        <div key={props.car.id} className="car-card" onClick={(e) => onClickHandler(e)} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
            <div className="car-card__image-div">
                {props.car.mediaFiles != null && props.car.mediaFiles.length > 0 && <Blurhash className={`blurhash-img ${imageLoaded ? `hidden` : ``}`} hash={props.car.mediaFiles != null && props.car.mediaFiles.length > 0 ? props.car.mediaFiles[0].blurHash : ''} width={'100%'} height={'100%'} resolutionX={32} resolutionY={32} punch={1} />}

                <img
                    alt="Car"
                    className="car-img"
                    src={props.car.mediaFiles != null && props.car.mediaFiles.length > 0 ? props.car.mediaFiles[0].thumbnail : '/images/ic_no_image.svg'}
                    onLoad={() => {
                        setImageLoaded(true)
                    }}
                />

                <div className="car-overlay" />

                <img
                    alt="Favourite icon"
                    className={`favourite-img ${actionEnded ? `hidden` : ``}`}
                    onMouseOut={(e) => (e.currentTarget.src = `/images/${isFavourite ? `ic_favourite_selected.svg` : `ic_favourite.svg`}`)}
                    onMouseOver={(e) => (e.currentTarget.src = '/images/ic_favourite_selected.svg')}
                    src={`/images/${isFavourite ? `ic_favourite_selected.svg` : `ic_favourite.svg`}`}
                    onClick={(e) => onClickHandler(e)}
                />

                <p className={`no-reserve ${getReservePriceTitleStyle()}`}>{getReservePriceTitle()}</p>
                <p className={`status ${props.car.status === CarStatusEnum.Sold || props.car.status === CarStatusEnum.Paid || props.car.status === CarStatusEnum.Overdue || props.car.status === CarStatusEnum.Unpaid ? `` : `hidden`} `}>{t('car_card_sold_title')}</p>
                <p className={`status did-not-sell ${props.car.status === CarStatusEnum.DidNotSell ? `` : `hidden`} `}>{t('car_card_did_not_sell_title')} </p>
                <p className={`status pending ${props.car.status === CarStatusEnum.ReservePriceNotMetBuyer || props.car.status === CarStatusEnum.ReservePriceNotMetSeller ? `` : `hidden`} `}>{t('car_card_reserve_not_met_title')} </p>
            </div>

            <div className="car-card__content-div">
                <h3 className="car-card__title">
                    {props.car.manufacturingYear} {props.car.make} {props.car.model}
                </h3>
                <p className="sub-title">
                    {props.car.address?.postalCode} {props.car.address?.city}
                </p>

                <div className="car-card__milage-div">
                    <div className="milage-div">
                        <p className="sub-title">{t('car_card_mileage_title')}</p>
                        <label className="value">
                            {props.car.mileage.toLocaleString()} {t('car_card_km_title')}
                        </label>
                    </div>
                    <div className="divider" />

                    <div className="battery-div">
                        <p className="sub-title">{t('car_card_battery_capacity_title')}</p>
                        <label className="value">
                            {props.car.batteryCapacity.toString().replace('.', ',')} {t('car_card_kWh_title')}
                        </label>
                    </div>
                </div>

                <div className="car-card__price-div">
                    <div className={`bid-div ${actionEnded ? `action-ended` : ``}`}>
                        <p className="sub-title">{actionEnded ? (props.car.status === CarStatusEnum.Sold || props.car.status === CarStatusEnum.Paid || props.car.status === CarStatusEnum.Unpaid ? t('car_card_final_price_title') : t('my_ev_garage_highest_bid_title')) : t('car_card_current_bid_title')}</p>
                        <div className="price-div">
                            <h3 className={`price ${actionEndsInOneDay ? `primary` : ``} ${actionEnded ? `action-ended` : ``}`}>
                                {actionEnded
                                    ? props.car.status === CarStatusEnum.Sold || props.car.status === CarStatusEnum.Paid || props.car.status === CarStatusEnum.Unpaid
                                        ? props.car.auctions[0].currentPrice.toLocaleString('de-CH', { minimumFractionDigits: 0 })
                                        : props.car.auctions[0].highestBid?.toLocaleString('de-CH', { minimumFractionDigits: 0 })
                                    : props.car.auctions[0].currentPrice.toLocaleString('de-CH', { minimumFractionDigits: 0 })}
                            </h3>

                            <label className={`currency ${actionEndsInOneDay ? `primary` : ``} ${actionEnded ? `action-ended` : ``}`}>
                                {t('car_card_chf_title')} {actionEnded ? '/ ' + props.car.auctions[0].bidCount + ' ' + t('auction_bids_title') : ''}
                            </label>
                        </div>
                    </div>
                    <div className="time-left-div">
                        <p className={`sub-title ${actionEnded ? `` : `hide`}`}>{actionEnded ? t('car_card_end_date_title') : t('car_card_time_left_title')}</p>

                        <Battery type={'normal'} auction={props.car.auctions[0]} />
                    </div>
                </div>

                <div className={`button-div ${props.car.isArchived ? `action-ended` : ``}`}>
                    <Button type={actionEndsInOneDay ? null : 'black'} title={t('car_card_see_car_button')} onClick={() => navigate(`/auction/${props.car.id}`)} />
                </div>
            </div>
        </div>
    )
}
