import { BidHistoryDTO, ErrorDTO } from '../../dto'
import { store } from '../../redux'
import { BidHistoryFailed, BidHistorySuccess, ErrorMessage } from '../../redux/actions'
import { NetworkCall } from '../../utils'

export const bidHistory = async (userId: number | null, reset: boolean = false) => {
    const page = reset ? 1 : store.getState().user.bidHistory.pagination.page
    const pageSize = store.getState().user.bidHistory.pagination.pageSize

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')
    const id = userId ? userId : store.getState().login.user?.id

    return await new NetworkCall('GET', `/1.0.0/users/${id}/bid-history/?page=${page}&pageSize=${pageSize}`)
        .setHeader('Authorization', `Bearer ${token}`)
        .then(200, BidHistoryDTO, (responseBody) => {
            store.dispatch(BidHistorySuccess({ auctions: responseBody.data, page: responseBody.page, pageSize: responseBody.pageSize, count: responseBody.count, reset: reset }))
        })
        .catch((response) => {
            store.dispatch(BidHistoryFailed({}))

            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
