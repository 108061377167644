import { NetworkCall } from '../../utils'
import { store } from '../../redux'
import { Logout } from '../../redux/actions/login'
import { getCars } from '../cars'
import { ErrorMessage, ShowToast } from '../../redux/actions'
import { ErrorDTO } from '../../dto'

interface DeleteUserProps {
    onSuccess: () => void
}

export const deleteUser = async (props: DeleteUserProps) => {
    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')
    const id = store.getState().login.user?.id

    return await new NetworkCall('DELETE', `/1.0.0/users/${id}`)
        .setHeader('Authorization', `Bearer ${token}`)
        .then(200, (responseBody: any) => {
            store.dispatch(Logout({}))

            getCars(true)

            store.dispatch(ShowToast({ message: 'toast_delte_user_success' }))

            props.onSuccess()
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
