import { useTranslation } from 'react-i18next'
import { ChangeEvent, useState } from 'react'
import { DragAnddrop } from './components/drag-and-drop/drag-and-drop'
import { uplpoadMediaFile } from '../../../../api'
import { useAppSelector } from '../../../../redux'
import { MediaFile } from '../../../../entity'
import { PhotoItem } from './components/photo-item/photo-item'
import { MediaFileTypeEnum } from '../../../../enum'
import { useDispatch } from 'react-redux'
import { UpdateMediaFilesAuction } from '../../../../redux/actions'
import { LinearProgress } from '../../../../components'
import './photos.tab.scss'

interface PhotosTabProps {}

export const PhotosTab = (props: PhotosTabProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { mediaFiles } = useAppSelector((state) => state.car.form)
    const [progressVisible, setProgressVisible] = useState(false)

    const MAX_VIDEO_COUNT = 1
    const MAX_PHOTO_COUNT = 50

    const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files
        if (selectedFiles && selectedFiles.length > 0) {
            setProgressVisible(true)

            await uplpoadMediaFile({
                files: Array.from(selectedFiles),
                onSuccess() {
                    setProgressVisible(false)
                },
                onError() {
                    setProgressVisible(false)
                },
            })
        }
    }

    var dragItemPosition = -1
    var dragOverItemPosition = -1

    const onDragStart = (position: number) => {
        dragItemPosition = position
    }

    const onDragEnter = (position: number) => {
        dragOverItemPosition = position
    }

    const drop = () => {
        const copyListItems = [...mediaFiles!]

        const dragItemContent = copyListItems[dragItemPosition]

        copyListItems.splice(dragItemPosition, 1)
        copyListItems.splice(dragOverItemPosition, 0, dragItemContent)

        dragItemPosition = -1
        dragOverItemPosition = -1

        dispatch(UpdateMediaFilesAuction({ mediaFiles: copyListItems }))
    }

    return (
        <main className="tab tab-photos">
            <div className="tab-div">
                <h2>{t('add_car_title')}</h2>

                <h4>{t('add_car_photos_video_title')}</h4>

                <div className="container-div">
                    <label>{t('add_car_photos_video_desc')}</label>

                    <h5>{t('add_car_upload_images_title')}</h5>

                    <DragAnddrop
                        onFilesSelected={async (files: File[]) => {
                            setProgressVisible(true)
                            await uplpoadMediaFile({
                                files: files,
                                onSuccess() {
                                    setProgressVisible(false)
                                },
                                onError() {
                                    setProgressVisible(false)
                                },
                            })
                        }}
                        disabled={mediaFiles.filter((mediaFile) => mediaFile.type === MediaFileTypeEnum.Photo).length < MAX_PHOTO_COUNT}
                        innnerElement={
                            <div className={`upload-div`}>
                                <div className="drop-div">
                                    <img alt="Upload icon" className="upload-img" src="/images/ic_upload.svg" />
                                </div>
                                <label>{t('add_car_drop_images_title')}</label>
                                <label>{t('add_car_or_title')}</label>

                                <input type="file" hidden id="browse-photo" onChange={onFileChange} accept=".jpg, .jpeg, .png, .heic, .heif" multiple />

                                <label htmlFor="browse-photo" className="browse-button">
                                    {t('button_browse')}
                                </label>

                                <label className="type">{'JPG, JPEG, PNG, HEIC, HEIF (min 10)'}</label>
                            </div>
                        }
                    />

                    {mediaFiles.length > 0 && mediaFiles.filter((mediaFile) => mediaFile.type === MediaFileTypeEnum.Photo) && (
                        <div className={`photos-div`}>
                            <div className="photos-grid">{mediaFiles.map((mediaFile: MediaFile, index: number) => (mediaFile.type === MediaFileTypeEnum.Photo ? <PhotoItem index={index} mediaFile={mediaFile} isVideo={false} onDragEnter={onDragEnter} onDragStart={onDragStart} onDragEnd={drop} /> : null))}</div>

                            <div className="image-count">
                                <p>
                                    {t('add_car_uploaded_images_title')} {mediaFiles.filter((mediaFile) => mediaFile.type === MediaFileTypeEnum.Photo).length}/{MAX_PHOTO_COUNT}
                                </p>
                            </div>
                        </div>
                    )}

                    {progressVisible && (
                        <div className={`progress-div`}>
                            <label>{t('add_car_uploading_title')}</label>
                            <LinearProgress percent={10} />
                        </div>
                    )}

                    <h5>{t('add_car_upload_video_title')}</h5>

                    <DragAnddrop
                        onFilesSelected={async (files: File[]) => {
                            setProgressVisible(true)

                            await uplpoadMediaFile({
                                files: files,
                                onSuccess() {
                                    setProgressVisible(false)
                                },
                                onError() {
                                    setProgressVisible(false)
                                },
                            })
                        }}
                        disabled={mediaFiles.filter((mediaFile) => mediaFile.type === MediaFileTypeEnum.Video).length < MAX_VIDEO_COUNT}
                        innnerElement={
                            <div className="upload-div">
                                <div className="drop-div">
                                    <img alt="Upload icon" className="upload-img" src="/images/ic_upload.svg" />
                                </div>
                                <label>{t('add_car_drop_video_title')}</label>
                                <label>{t('add_car_or_title')}</label>

                                <input type="file" hidden id="browse-video" onChange={onFileChange} accept=".mp4" />

                                <label htmlFor="browse-video" className="browse-button">
                                    {t('button_browse')}
                                </label>

                                <label className="type">{'MP4 (max 300 MB)'}</label>
                            </div>
                        }
                    />

                    {mediaFiles.length > 0 && mediaFiles.filter((mediaFile) => mediaFile.type === MediaFileTypeEnum.Video) && (
                        <div className={`photos-div`}>
                            <div className="photos-grid">{mediaFiles.map((mediaFile: MediaFile, index: number) => (mediaFile.type === MediaFileTypeEnum.Video ? <PhotoItem index={index} isVideo={true} mediaFile={mediaFile} onDragEnter={onDragEnter} onDragStart={onDragStart} onDragEnd={drop} /> : null))}</div>

                            <div className="image-count">
                                <p>
                                    {t('add_car_uploaded_video_title')} {mediaFiles.filter((mediaFile) => mediaFile.type === MediaFileTypeEnum.Video).length}/{MAX_VIDEO_COUNT}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </main>
    )
}
