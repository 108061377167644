import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QuestionElement } from './question-element'
import { Car, Question } from '../../../../../entity'
import { LoadMoreButton } from '../../../../../components'
import { useAppSelector } from '../../../../../redux'
import { useDispatch } from 'react-redux'
import { QuestionsPagination, ShowLoginModal } from '../../../../../redux/actions'
import { createQuestion, createQuestionAnswer, getQuestions } from '../../../../../api'
import { TextField } from '../../../../../components/field'
import { CarStatusEnum } from '../../../../../enum'
import './questions.scss'

interface QuestionsProps {
    car: Car
    questions: Question[]
    onReportClick: (id: number, type: number) => void
}

export const Questions = (props: QuestionsProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [message, setMessage] = useState('')
    const [questionId, setQuestionId] = useState<number | null>(null)
    const [username, setUsername] = useState<string | null>(null)

    const user = useAppSelector((state) => state.login.user)
    const pagination = useAppSelector((state) => state.questions.pagination)

    const onSendClick = async () => {
        if (user) {
            if (message.length !== 0) {
                if (questionId) {
                    await createQuestionAnswer({
                        carId: props.car.id,
                        questionId: questionId,
                        answer: message,
                        onSuccess() {
                            setMessage('')
                            setQuestionId(null)
                            setUsername(null)
                        },
                    })
                } else {
                    await createQuestion({
                        carId: props.car.id,
                        question: message,
                        onSuccess() {
                            setMessage('')
                            setQuestionId(null)
                            setUsername(null)
                        },
                    })
                }
            }
        } else {
            dispatch(ShowLoginModal({ visible: true, carId: null }))
        }
    }

    const onReplyClick = async (id: number, username: string) => {
        if (user) {
            setQuestionId(id)
            setUsername(username)
        } else {
            dispatch(ShowLoginModal({ visible: true, carId: null }))
        }
    }

    const onDeleteReplyClick = async () => {
        setQuestionId(null)
        setUsername(null)
    }

    const onLoadMoreClick = async () => {
        dispatch(QuestionsPagination({}))
        await getQuestions(props.car.id)
    }

    return (
        <div className="questions">
            {props.car.status === CarStatusEnum.Approved && (
                <>
                    <div className="textfield-div">
                        <div className={`reply-div ${username ? `` : `hidden`}`}>
                            <p className={`title `}>
                                {t('car_information_reply_to_title')}
                                {username}
                            </p>

                            <img alt="Close icon" className="close-img" src="/images/ic_close.svg" onClick={onDeleteReplyClick} />
                        </div>
                        <TextField customStyle={{ paddingRight: '40px', width: 'calc(100% - 14px - 14px - 26px)' }} field={{ error: '', value: message }} maxLength={300} onChange={(e) => setMessage(e.target.value)} placeholder={username ? t('car_information_add_answer_placeholder') : t('car_information_add_question_placeholder')} />
                        <img alt="Send icon" className="send-img" src="/images/ic_send.svg" onClick={onSendClick} />
                    </div>
                    <div className="divider" />
                </>
            )}
            <ul className="list">
                {props.questions.map((question, index) => (
                    <QuestionElement question={question} hideReply={props.car.status !== CarStatusEnum.Approved} onReportClick={(id, type) => props.onReportClick(id, type)} onReplyClick={(id, username) => onReplyClick(id, username)} />
                ))}
            </ul>

            <label className={`no-item-title ${pagination.count === 0 ? `` : `hidden`}`}> {t('car_information_no_questions_title')}</label>

            <div className={`bottom-div ${pagination.count <= pagination.pageSize * pagination.page ? `hidden` : ``}`}>
                <LoadMoreButton onClick={onLoadMoreClick} />
            </div>
        </div>
    )
}
