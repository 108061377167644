import { store } from '../../redux'
import { DefaultDTO, ErrorDTO } from '../../dto'
import { ErrorMessage, ShowToast } from '../../redux/actions'
import { NetworkCall } from '../../utils'

interface CreateQuestionProps {
    id: number
    type: number
    message: string
    onSuccess: () => void
}

export const createReport = async (props: CreateQuestionProps) => {
    const requestBody = {
        id: props.id,
        type: props.type,
        message: props.message,
    }

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('POST', `/1.0.0/reports`, requestBody)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(201, DefaultDTO, (responseBody) => {
            props.onSuccess()

            store.dispatch(ShowToast({ message: 'toast_create_report_success' }))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
