import { Modal } from '../modal'
import { useTranslation } from 'react-i18next'
import './how-bidding-works.modal.scss'

interface HowBiddingWorksModalProps {
    onClose: () => void
}

export const HowBiddingWorksModal = (props: HowBiddingWorksModalProps) => {
    const { t } = useTranslation()

    return (
        <Modal title={t('modal_how_bidding_works_title')} onCloseClick={props.onClose}>
            <div className="how-bidding-works-modal">
                <label className="sub-title">{t('modal_how_bidding_works_live_title')}</label>
                <label className="description">{t('modal_how_bidding_works_live_description')}</label>

                <ul className="bullet-points">
                    <li className="description">{t('modal_how_bidding_works_first_bullet_point')}</li>
                    <li className="description">{t('modal_how_bidding_works_second_bullet_point')}</li>
                    <li className="description">{t('modal_how_bidding_works_third_bullet_point')}</li>
                    <li className="description">{t('modal_how_bidding_works_fourth_bullet_point')}</li>
                    <li className="description">{t('modal_how_bidding_works_fifth_bullet_point')}</li>
                    <li className="description">{t('modal_how_bidding_works_sixth_bullet_point')}</li>
                    <li className="description">{t('modal_how_bidding_works_seventh_bullet_point')}</li>
                    <li className="description">{t('modal_how_bidding_works_eighth_bullet_point')}</li>
                    {/* <li className="description">{t('modal_how_bidding_works_ninth_bullet_point')}</li> */}
                    <li className="description">{t('modal_how_bidding_works_tenth_bullet_point')}</li>
                    <li className="description">{t('modal_how_bidding_works_eleventh_bullet_point')}</li>
                    <li className="description">{t('modal_how_bidding_works_twelfth_bullet_point')}</li>
                </ul>

                <label className="description">{t('modal_how_bidding_works_more_title')}</label>
                <label className="description">{t('modal_how_bidding_works_good_luck_title')}</label>
                <div className="question-div">
                    <label className="sub-title">{t('modal_how_bidding_works_have_question_title')}</label>
                    <label className="description" dangerouslySetInnerHTML={{ __html: t('modal_how_bidding_works_email_us_title') }}></label>
                </div>
            </div>
        </Modal>
    )
}
