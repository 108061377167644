import { useTranslation } from 'react-i18next'
import { Comment } from '../../../../entity'
import './comment-history-comment-element.scss'
import { formatTimeLeft } from '../../../../utils'
import { useState } from 'react'
import { useAppSelector } from '../../../../redux'
import { translate } from '../../../../api'
import { useDispatch } from 'react-redux'
import { ShowLoginModal } from '../../../../redux/actions'

interface CommentHistoryCommentElementProps {
    comment: Comment
}

export const CommentHistoryCommentElement = (props: CommentHistoryCommentElementProps) => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()

    const [translatedComment, setTranslatedComment] = useState('')

    const user = useAppSelector((state) => state.login.user)

    const onTranslateClick = async () => {
        // if (user) {
        if (props.comment.comment.length !== 0) {
            await translate({
                text: props.comment.comment,
                language: i18n.language.includes('de') ? 'de' : i18n.language.includes('fr') ? 'fr' : 'en',
                onSuccess(text: string) {
                    setTranslatedComment(text)
                },
            })
        }
        // } else {
        //     dispatch(ShowLoginModal({ visible: true, carId: null }))
        // }
    }

    return (
        <li key={props.comment.id} className={`comment-history-comment-element ${props.comment.parentId != null ? `child` : ``}`}>
            <div className={`user-div ${props.comment.parentId != null ? `child` : ``}`}>
                <label className="user">{props.comment.user.username}</label>
                <label className="date">{formatTimeLeft(props.comment.created!!, t)}</label>
                {/* // <label className="date">{new Date(props.comment.created!!).toLocaleString('de-CH', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</label> */}
            </div>

            <div className="comment-div">
                <label className="comment">{translatedComment !== '' ? translatedComment : props.comment.comment}</label>

                <img alt="Translate icon" className="deepl-img" src="/images/ic_deepl.svg" onClick={onTranslateClick} />
            </div>

            <div className="action-div">
                <div className={`user-div ${props.comment.parentId != null ? `` : `child`}`}>
                    <label className="user">{props.comment.user.username}</label>
                    <label className="date">{formatTimeLeft(props.comment.created!!, t)}</label>

                    {/* <label className="date">{new Date(props.comment.created!!).toLocaleString('de-CH', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</label> */}
                </div>

                <label className={`replies ${props.comment.parentId != null ? `child` : ``} ${props.comment.children != null && props.comment.children.length != 0 ? `active` : ``} `}>
                    {props.comment.children ? props.comment.children.length : 0} {t('car_information_replies_title')}
                </label>
            </div>
        </li>
    )
}
