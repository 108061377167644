import { UserDTO } from '../../dto'
import { NetworkCall } from '../../utils'

export const modifyTermsAndPrivacyVersion = async () => {
    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')
    const requestBody = {
        termsVersion: parseInt(localStorage.getItem(`termsVersion`)!),
        privacyVersion: parseInt(localStorage.getItem(`privacyVersion`)!),
    }

    return await new NetworkCall('POST', `/1.0.0/users/document-versions`, requestBody)
        .setHeader(`Authorization`, `Bearer ${ token }`)
        .then(200, UserDTO, async () => {})
        .catch(() => {})
        .call()
}
