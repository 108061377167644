import { createReducer } from '@reduxjs/toolkit'
import { QuestionsSuccess, QuestionsPagination, QuestionsFailed } from '../../actions'
import { QuestionsState } from '../../states'

const initialState: QuestionsState = {
    questions: [],
    isLoading: false,
    pagination: {
        page: 1,
        pageSize: 10,
        count: 0,
    },
}

export const questionsReducer = createReducer(initialState, (builder) => {
    builder.addCase(QuestionsPagination, (state, action) => {
        state.isLoading = true
        state.pagination.page = state.pagination.page + 1
    })
    builder.addCase(QuestionsSuccess, (state, action) => {
        state.isLoading = false

        if (action.payload.reset) {
            state.questions = []
        }
        state.questions = [...state.questions, ...action.payload.questions]
        state.pagination.page = action.payload.page
        state.pagination.pageSize = action.payload.pageSize
        state.pagination.count = action.payload.count
    })
    builder.addCase(QuestionsFailed, (state) => {
        state.isLoading = false

        // state.form.email.error = ''
        // state.form.password.error = t('login-page--invalid-username-or-password')
    })
})
