import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { InputField } from '../../../../components/field'
import { useAppSelector } from '../../../../redux'
import { UpdateCarForm } from '../../../../redux/actions'
import { BatteryStatusEnum, ChargingConnectorAcTypeEnum, ChargingConnectorDcTypeEnum, DrivetrainEnum } from '../../../../enum'
import { Dropdown, IconButton } from '../../../../components'
import { useEffect, useRef, useState } from 'react'
import './car-details.tab.scss'
import '../tab.scss'

interface CarDetailsTabProps {}

export const CarDetailsTab = (props: CarDetailsTabProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { form } = useAppSelector((state) => state.car)
    const [warrantyEnabled, setWarrantyEnabled] = useState(form.validWarrantyYear.value !== '')

    const makes = useAppSelector((state) => state.car.makes)
    const [models, setModels] = useState<[{ id: string; value: string }] | []>([])

    const modelRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        refreshModelArray(form.make.value)
    }, [])

    const geMakeArray = () => {
        const items: [{ id: string; value: string }] = [{ id: '-1', value: t('modal_report_select_title') }]
        makes.map((make, index) => {
            items.push({ id: make.name, value: make.name })
        })

        items.shift()

        items.push({ id: '-2', value: t('add_car_other_title') })

        return items
    }

    const refreshModelArray = (selectedMake: string) => {
        const items: [{ id: string; value: string }] = [{ id: '-1', value: t('modal_report_select_title') }]
        makes.map((make, index) => {
            if (make.name === selectedMake) {
                make.models.map((model, index) => {
                    items.push({ id: model, value: model })
                })
            }
        })

        items.shift()

        items.push({ id: '-2', value: t('add_car_other_title') })

        setModels((current) => items)
    }

    const getDrivetrainArray = () => {
        const items: [{ id: number; value: string }] = [{ id: -1, value: t('modal_report_select_title') }]
        items.push({ id: DrivetrainEnum.AWD, value: t('add_car_awd_title') })
        items.push({ id: DrivetrainEnum.TWOWD, value: t('add_car_twowd_title') })
        items.push({ id: DrivetrainEnum.RWD, value: t('add_car_rwd_title') })
        items.push({ id: DrivetrainEnum.FWD, value: t('add_car_fwd_title') })
        items.shift()
        return items
    }

    const getMFKYearArray = () => {
        const items: [{ id: string; value: string }] = [{ id: '-1', value: t('modal_report_select_title') }]
        for (let i = 2000; i <= 2100; i++) {
            items.push({ id: i.toString(), value: i.toString() })
        }
        items.shift()
        return items
    }

    const getYearArray = () => {
        const items: [{ id: string; value: string }] = [{ id: '-1', value: t('modal_report_select_title') }]
        for (let i = 2024; i <= 2100; i++) {
            items.push({ id: i.toString(), value: i.toString() })
        }
        items.shift()
        return items
    }

    const getMonthArray = () => {
        const items: [{ id: string; value: string }] = [{ id: '-1', value: t('modal_report_select_title') }]
        for (let i = 0; i <= 11; i++) {
            items.push({ id: i.toString(), value: (i + 1).toString() })
        }
        items.shift()
        return items
    }

    return (
        <main className="tab tab-car-detail">
            <div className="tab-div">
                <h2>{t('add_car_title')}</h2>

                <h4>{t('add_car_car_location_title')}</h4>

                <div className="container-div row">
                    <div className="country-div">
                        <InputField type="text" infoText={t('info_text_country')} field={{ value: t('my_profile_switzlerland_title'), error: null, disabled: true }} placeholder={''} title={t('my_profile_country_title')} onChange={(e) => {}} />
                    </div>

                    <div className="postal-code-div">
                        <InputField
                            type="text"
                            field={form.postalCode}
                            min={0}
                            max={9999}
                            maxLength={4}
                            placeholder={''}
                            title={t('my_profile_postal_code_title')}
                            onChange={(event) => {
                                let userInput: string = event.target.value.replace(/[^0-9]/g, '')
                                if (userInput.length === 0) {
                                    dispatch(UpdateCarForm({ key: 'postalCode', value: '', error: null }))
                                }
                                if (userInput !== '') {
                                    dispatch(UpdateCarForm({ key: 'postalCode', value: userInput, error: null }))
                                }
                            }}
                        />
                    </div>

                    <div className="city-div">
                        <InputField type="text" field={form.city} placeholder={''} title={t('my_profile_city_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'city', value: e.target.value, error: null }))} />
                    </div>
                </div>

                <h4>{t('add_car_car_details_title')}</h4>

                <div className="container-div">
                    <div className="row-div">
                        <div className="manufacturing-div">
                            <InputField
                                type="text"
                                field={form.manufacturingYear}
                                placeholder={''}
                                maxLength={4}
                                title={t('add_car_manufacturing_year_title')}
                                onChange={(event) => {
                                    let userInput: string = event.target.value.replace(/[^0-9]/g, '')
                                    if (userInput.length === 0) {
                                        dispatch(UpdateCarForm({ key: 'manufacturingYear', value: '', error: null }))
                                    }
                                    if (userInput !== '') {
                                        dispatch(UpdateCarForm({ key: 'manufacturingYear', value: userInput, error: null }))
                                    }
                                }}
                            />
                        </div>

                        <div className="make-div" ref={modelRef}>
                            <label>{t('add_car_make_title')}</label>

                            <Dropdown
                                deleteEnabled={false}
                                selectItemTitle={t('modal_report_select_title')}
                                value={form.make.value}
                                items={geMakeArray()}
                                error={form.make.error ?? ''}
                                onChange={async (id: string) => {
                                    // window.scrollTo(0, modelRef.current?.offsetTop ?? 0)

                                    if (id === '-2') {
                                        dispatch(UpdateCarForm({ key: 'makeOtherEnabled', value: true, error: null }))
                                        dispatch(UpdateCarForm({ key: 'make', value: '', error: null }))
                                    } else {
                                        dispatch(UpdateCarForm({ key: 'makeOtherEnabled', value: false, error: null }))
                                        dispatch(UpdateCarForm({ key: 'make', value: id, error: null }))
                                    }
                                    refreshModelArray(id)
                                }}
                            />

                            {form.makeOtherEnabled.value && <InputField type="text" field={form.make} placeholder={''} title={t('')} onChange={(e) => dispatch(UpdateCarForm({ key: 'make', value: e.target.value, error: null }))} />}
                        </div>

                        <div className="model-div">
                            <label>{t('add_car_model_title')}</label>

                            <Dropdown
                                deleteEnabled={false}
                                selectItemTitle={t('modal_report_select_title')}
                                value={form.model.value}
                                items={models}
                                error={form.model.error ?? ''}
                                onChange={async (id: string) => {
                                    if (id === '-2') {
                                        dispatch(UpdateCarForm({ key: 'modelOtherEnabled', value: true, error: null }))
                                        dispatch(UpdateCarForm({ key: 'model', value: '', error: null }))
                                    } else {
                                        dispatch(UpdateCarForm({ key: 'modelOtherEnabled', value: false, error: null }))
                                        dispatch(UpdateCarForm({ key: 'model', value: id, error: null }))
                                    }
                                }}
                            />

                            {form.modelOtherEnabled.value && <InputField type="text" field={form.model} placeholder={''} title={t('')} onChange={(e) => dispatch(UpdateCarForm({ key: 'model', value: e.target.value, error: null }))} />}
                        </div>
                    </div>

                    <div className="variant-div">
                        <InputField type="text" field={form.variant} placeholder={''} title={t('add_car_variant_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'variant', value: e.target.value, error: null }))} />
                    </div>

                    <div className="vin-div">
                        <InputField type="text" maxLength={17} field={form.vin} placeholder={''} title={t('add_car_vin_title')} onChange={(e) => dispatch(UpdateCarForm({ key: 'vin', value: e.target.value, error: null }))} />
                        <IconButton reverse={true} small={true} title={t('add_car_vin_desc')} icon="/images/ic_info.svg" onClick={() => {}} />
                    </div>

                    <div className="row-div align-top ">
                        <div className="battery-div">
                            <InputField
                                type="text"
                                maxLength={10}
                                field={form.batteryCapacity}
                                placeholder={t('')}
                                title={t('add_car_battery_capacity_title')}
                                onChange={(event) => {
                                    let userInput: string = event.target.value.replace(/[^0-9,]/g, '')
                                    if (userInput.length === 0) {
                                        dispatch(UpdateCarForm({ key: 'batteryCapacity', value: '', error: null }))
                                    }
                                    if (userInput !== '') {
                                        dispatch(UpdateCarForm({ key: 'batteryCapacity', value: userInput, error: null }))
                                    }
                                }}
                            />
                            <label>{t('car_card_kWh_title')}</label>
                        </div>

                        <div className="range-div">
                            <InputField
                                type="text"
                                maxLength={16}
                                field={form.manufacturerEstimatedRange}
                                placeholder={t('')}
                                title={t('add_car_estimated_range_title')}
                                onChange={(event) => {
                                    let userInput: string = event.target.value.replace(/[^0-9]/g, '')
                                    if (userInput.length === 0) {
                                        dispatch(UpdateCarForm({ key: 'manufacturerEstimatedRange', value: '', error: null }))
                                    }
                                    if (userInput !== '') {
                                        dispatch(UpdateCarForm({ key: 'manufacturerEstimatedRange', value: userInput, error: null }))
                                    }
                                }}
                            />
                            <label>{t('car_card_km_title')}</label>
                        </div>

                        <div className="horsepower-div">
                            <InputField
                                type="text"
                                maxLength={10}
                                field={form.horsepower}
                                placeholder={t('')}
                                title={t('add_car_horsepower_title')}
                                onChange={(event) => {
                                    let userInput: string = event.target.value.replace(/[^0-9]/g, '')
                                    if (userInput.length === 0) {
                                        dispatch(UpdateCarForm({ key: 'horsepower', value: '', error: null }))
                                    }
                                    if (userInput !== '') {
                                        dispatch(UpdateCarForm({ key: 'horsepower', value: userInput, error: null }))
                                    }
                                }}
                            />
                            <label>{t('auction_ps_title')}</label>
                        </div>
                    </div>

                    <div className="mileage-div">
                        <InputField
                            type="text"
                            maxLength={16}
                            field={form.mileage}
                            placeholder={t('')}
                            title={t('add_car_mileage_title')}
                            onChange={(event) => {
                                let userInput: string = event.target.value.replace(/[^0-9]/g, '')
                                if (userInput.length === 0) {
                                    dispatch(UpdateCarForm({ key: 'mileage', value: '', error: null }))
                                }
                                if (userInput !== '') {
                                    dispatch(UpdateCarForm({ key: 'mileage', value: userInput, error: null }))
                                }
                            }}
                        />
                        <label>{t('car_card_km_title')}</label>
                    </div>

                    <div className="column-div battery-status-div">
                        <label>{t('add_car_battery_status_title')}</label>

                        <div className="type-div">
                            <label
                                className={`type ${form.batteryStatus.value === BatteryStatusEnum.Owned ? 'active' : ''} `}
                                onClick={() => {
                                    dispatch(UpdateCarForm({ key: 'batteryStatus', value: BatteryStatusEnum.Owned, error: null }))
                                }}
                            >
                                {t('add_car_owned_title')}
                            </label>

                            <label
                                className={`type ${form.batteryStatus.value === BatteryStatusEnum.Leased ? 'active' : ''} `}
                                onClick={() => {
                                    dispatch(UpdateCarForm({ key: 'batteryStatus', value: BatteryStatusEnum.Leased, error: null }))
                                }}
                            >
                                {t('add_car_leased_title')}
                            </label>
                        </div>
                    </div>

                    <div className="column-div drivetrain-status-div">
                        <label>{t('add_car_drivetrain_title')}</label>

                        <Dropdown
                            deleteEnabled={false}
                            selectItemTitle={t('modal_report_select_title')}
                            value={form.drivetrain.value}
                            items={getDrivetrainArray()}
                            error={''}
                            onChange={async (id: string) => {
                                dispatch(UpdateCarForm({ key: 'drivetrain', value: id, error: null }))
                            }}
                        />
                    </div>

                    <div className="charging-connector-div">
                        <label>{t('add_car_charging_connector_title')}</label>

                        <div className="connector-div">
                            <div className="ac-div">
                                <div className={`ac-type-div `}>
                                    <label>{t('add_car_ac_title')}</label>

                                    <div
                                        className={`type-div ${form.chargingConnectorAc.value === ChargingConnectorAcTypeEnum.Type1 ? 'active' : ''}`}
                                        onClick={() => {
                                            dispatch(UpdateCarForm({ key: 'chargingConnectorAc', value: ChargingConnectorAcTypeEnum.Type1, error: null }))
                                            dispatch(UpdateCarForm({ key: 'chargingConnectorAcOtherName', value: '', error: null }))
                                        }}
                                    >
                                        <div className={`check-box ${form.chargingConnectorAc.value === ChargingConnectorAcTypeEnum.Type1 ? 'active' : ''}`} />

                                        <label className="type">{t('add_car_type_one_title')}</label>
                                    </div>

                                    <div
                                        className={`type-div ${form.chargingConnectorAc.value === ChargingConnectorAcTypeEnum.Type2 ? 'active' : ''}`}
                                        onClick={() => {
                                            dispatch(UpdateCarForm({ key: 'chargingConnectorAc', value: ChargingConnectorAcTypeEnum.Type2, error: null }))
                                            dispatch(UpdateCarForm({ key: 'chargingConnectorAcOtherName', value: '', error: null }))
                                        }}
                                    >
                                        <div className={`check-box ${form.chargingConnectorAc.value === ChargingConnectorAcTypeEnum.Type2 ? 'active' : ''}`} />

                                        <label className="type">{t('add_car_type_two_title')}</label>
                                    </div>
                                </div>

                                <div className="other-div">
                                    <div className={`check-box ${form.chargingConnectorAc.value === ChargingConnectorAcTypeEnum.Other ? 'active' : ''}`} onClick={() => dispatch(UpdateCarForm({ key: 'chargingConnectorAc', value: ChargingConnectorAcTypeEnum.Other, error: null }))} />

                                    <InputField
                                        type="text"
                                        field={form.chargingConnectorAcOtherName}
                                        maxLength={20}
                                        placeholder={t('add_car_other_hint')}
                                        onChange={(e) => {
                                            dispatch(UpdateCarForm({ key: 'chargingConnectorAc', value: ChargingConnectorAcTypeEnum.Other, error: null }))
                                            dispatch(UpdateCarForm({ key: 'chargingConnectorAcOtherName', value: e.target.value, error: null }))
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="ac-div">
                                <div className={`ac-type-div`}>
                                    <label>{t('add_car_dc_title')}</label>

                                    <div
                                        className={`type-div ${form.chargingConnectorDc.value === ChargingConnectorDcTypeEnum.Ccs ? 'active' : ''}`}
                                        onClick={() => {
                                            dispatch(UpdateCarForm({ key: 'chargingConnectorDc', value: ChargingConnectorDcTypeEnum.Ccs, error: null }))
                                            dispatch(UpdateCarForm({ key: 'chargingConnectorDcOtherName', value: '', error: null }))
                                        }}
                                    >
                                        <div className={`check-box ${form.chargingConnectorDc.value === ChargingConnectorDcTypeEnum.Ccs ? 'active' : ''}`} />

                                        <label className="type">{t('add_car_ccs_title')}</label>
                                    </div>

                                    <div
                                        className={`type-div ${form.chargingConnectorDc.value === ChargingConnectorDcTypeEnum.CHAdeMO ? 'active' : ''}`}
                                        onClick={() => {
                                            dispatch(UpdateCarForm({ key: 'chargingConnectorDc', value: ChargingConnectorDcTypeEnum.CHAdeMO, error: null }))
                                            dispatch(UpdateCarForm({ key: 'chargingConnectorDcOtherName', value: '', error: null }))
                                        }}
                                    >
                                        <div className={`check-box ${form.chargingConnectorDc.value === ChargingConnectorDcTypeEnum.CHAdeMO ? 'active' : ''}`} />

                                        <label className="type">{t('add_car_chademo_title')}</label>
                                    </div>
                                </div>

                                <div className="other-div">
                                    <div className={`check-box ${form.chargingConnectorDc.value === ChargingConnectorDcTypeEnum.Other ? 'active' : ''}`} onClick={() => dispatch(UpdateCarForm({ key: 'chargingConnectorDc', value: ChargingConnectorDcTypeEnum.Other, error: null }))} />

                                    <InputField
                                        type="text"
                                        field={form.chargingConnectorDcOtherName}
                                        maxLength={20}
                                        placeholder={t('add_car_other_hint')}
                                        onChange={(e) => {
                                            dispatch(UpdateCarForm({ key: 'chargingConnectorDc', value: ChargingConnectorDcTypeEnum.Other, error: null }))
                                            dispatch(UpdateCarForm({ key: 'chargingConnectorDcOtherName', value: e.target.value, error: null }))
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row-div">
                        <div className="maximum-charging-div">
                            <InputField
                                type="text"
                                field={form.maximumAcCharging}
                                placeholder={''}
                                description={t('add_car_maximum_ac_charging_desc')}
                                maxLength={30}
                                title={t('add_car_maximum_ac_charging_title')}
                                onChange={(event) => {
                                    let userInput: string = event.target.value.replace(/[^0-9,]/g, '')

                                    if (userInput.length === 0) {
                                        dispatch(UpdateCarForm({ key: 'maximumAcCharging', value: '', error: null }))
                                    }
                                    if (userInput !== '') {
                                        dispatch(UpdateCarForm({ key: 'maximumAcCharging', value: userInput, error: null }))
                                    }
                                }}
                            />
                            <label>{t('car_card_kW_title')}</label>
                        </div>

                        <div className="maximum-charging-div">
                            <InputField
                                type="text"
                                maxLength={30}
                                field={form.maximumDcCharging}
                                description={t('add_car_maximum_ac_charging_desc')}
                                placeholder={''}
                                title={t('add_car_maximum_dc_charging_title')}
                                onChange={(event) => {
                                    let userInput: string = event.target.value.replace(/[^0-9,]/g, '')
                                    if (userInput.length === 0) {
                                        dispatch(UpdateCarForm({ key: 'maximumDcCharging', value: '', error: null }))
                                    }
                                    if (userInput !== '') {
                                        dispatch(UpdateCarForm({ key: 'maximumDcCharging', value: userInput, error: null }))
                                    }
                                }}
                            />
                            <label>{t('car_card_kW_title')}</label>
                        </div>
                    </div>

                    <div className="warranty-date-div">
                        <label>{t('add_car_mfk_date_title')}</label>

                        <Dropdown
                            deleteEnabled={false}
                            selectItemTitle={t('car_information_year_title')}
                            value={form.mfkDateYear.value}
                            items={getMFKYearArray()}
                            error={form.mfkDateYear.error ?? ''}
                            onChange={async (id: string) => {
                                dispatch(UpdateCarForm({ key: 'mfkDateYear', value: id, error: null }))
                            }}
                        />

                        <Dropdown
                            deleteEnabled={false}
                            selectItemTitle={t('car_information_month_title')}
                            value={form.mfkDateMonth.value}
                            items={getMonthArray()}
                            error={form.mfkDateMonth.error ?? ''}
                            onChange={async (id: string) => {
                                dispatch(UpdateCarForm({ key: 'mfkDateMonth', value: id, error: null }))
                            }}
                        />

                        {/* <InputField
                            type="text"
                            maxLength={4}
                            field={form.mfkDate}
                            placeholder={''}
                            title={t('add_car_mfk_date_title')}
                            onChange={(event) => {
                                let userInput: string = event.target.value.replace(/[^0-9]/g, '')
                                if (userInput.length === 0) {
                                    dispatch(UpdateCarForm({ key: 'mfkDate', value: '', error: null }))
                                }
                                if (userInput !== '') {
                                    dispatch(UpdateCarForm({ key: 'mfkDate', value: userInput, error: null }))
                                }
                            }}
                        /> */}
                    </div>
                </div>

                <h4>{t('add_car_warranty_title')}</h4>

                <div className="container-div warranty-div">
                    <label>{t('add_car_warranty_subtitle')}</label>

                    <div className="type-div">
                        <label
                            className={`type ${!warrantyEnabled ? 'active' : ''} `}
                            onClick={() => {
                                setWarrantyEnabled(false)
                                dispatch(UpdateCarForm({ key: 'validWarrantyYear', value: '', error: null }))
                                dispatch(UpdateCarForm({ key: 'validWarrantyMonth', value: '', error: null }))
                            }}
                        >
                            {t('add_car_no_title')}
                        </label>

                        <label
                            className={`type ${warrantyEnabled ? 'active' : ''} `}
                            onClick={() => {
                                setWarrantyEnabled(true)
                            }}
                        >
                            {t('add_car_yes_title')}
                        </label>
                    </div>

                    <div className={`warranty-date-div ${warrantyEnabled ? '' : 'hidden'}`}>
                        <label>{t('car_information_warranty_until_title')}</label>

                        <Dropdown
                            deleteEnabled={false}
                            selectItemTitle={t('car_information_year_title')}
                            value={form.validWarrantyYear.value}
                            items={getYearArray()}
                            error={form.validWarrantyYear.error ?? ''}
                            onChange={async (id: string) => {
                                dispatch(UpdateCarForm({ key: 'validWarrantyYear', value: id, error: null }))
                            }}
                        />

                        <Dropdown
                            deleteEnabled={false}
                            selectItemTitle={t('car_information_month_title')}
                            value={form.validWarrantyMonth.value}
                            items={getMonthArray()}
                            error={form.validWarrantyMonth.error ?? ''}
                            onChange={async (id: string) => {
                                dispatch(UpdateCarForm({ key: 'validWarrantyMonth', value: id, error: null }))
                            }}
                        />

                        {/* <InputField
                            type="text"
                            field={form.validWarrantyYear}
                            title={''}
                            maxLength={4}
                            placeholder={t('car_information_year_title')}
                            onChange={(event) => {
                                let userInput: string = event.target.value.replace(/[^0-9]/g, '')
                                if (userInput.length == 0) {
                                    dispatch(UpdateCarForm({ key: 'validWarrantyYear', value: '', error: null }))
                                }
                                if (userInput !== '') {
                                    dispatch(UpdateCarForm({ key: 'validWarrantyYear', value: userInput, error: null }))
                                }
                            }}
                        />
                        <InputField
                            type="text"
                            maxLength={2}
                            field={form.validWarrantyMonth}
                            title={''}
                            placeholder={t('car_information_month_title')}
                            onChange={(event) => {
                                let userInput: string = event.target.value.replace(/[^0-9]/g, '')
                                if (userInput.length == 0) {
                                    dispatch(UpdateCarForm({ key: 'validWarrantyMonth', value: '', error: null }))
                                }
                                if (userInput !== '') {
                                    dispatch(UpdateCarForm({ key: 'validWarrantyMonth', value: userInput, error: null }))
                                }
                            }}
                        /> */}
                    </div>
                </div>
            </div>
        </main>
    )
}
