import { LoaderFunctionArgs } from 'react-router-dom'
import { me } from '../api'

export const myProfilePageLoader = async (args: LoaderFunctionArgs) => {
    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')
    if (token !== null) {
        await me(token)
    }

    return null
}
