import { ErrorDTO, PrivacyDTO } from '../../dto'
import { store } from '../../redux'
import { ErrorMessage, GetPrivacySuccess } from '../../redux/actions'
import { NetworkCall } from '../../utils'

export const getPrivacyPolicy = async () => {
    return await new NetworkCall('GET', `/1.0.0/privacy`)
        .then(200, PrivacyDTO, (responseBody) => {
            store.dispatch(GetPrivacySuccess(responseBody))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
