import { store } from '../../redux'
import { CarDTO, ErrorDTO } from '../../dto'
import { GetCarSuccess, GetCarFailed, ErrorMessage } from '../../redux/actions'
import { NetworkCall } from '../../utils'

export const getCar = async (id: number) => {
    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('GET', `/1.0.0/cars/${id}`)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(200, CarDTO, (responseBody) => {
            store.dispatch(GetCarSuccess(responseBody))
        })
        .catch((response) => {
            store.dispatch(GetCarFailed({}))
            //car not found errorcode, ilyenkor fejlön a hoopsz kéernyő nem kell a hibaüzenet pluszban

            if ((response as ErrorDTO).code !== 500001) {
                store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
            }
        })
        .call()
}
