import { useTranslation } from 'react-i18next'
import { Filter } from './filter'
import { Modal } from '../../../../components/modals'

interface FilterModalProps {
    message: string
    onClose: () => void
}

export const FilterModal = (props: FilterModalProps) => {
    const { t } = useTranslation()

    return (
        <Modal
            title={t('filter_modal_filter_by')}
            onCloseClick={() => {
                props.onClose()
            }}
        >
            <Filter
                isInModal={true}
                onClose={async () => {
                    props.onClose()
                }}
            ></Filter>
        </Modal>
    )
}
