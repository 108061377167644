import { store } from '../../redux'
import { DefaultDTO, ErrorDTO } from '../../dto'
import { ErrorMessage, ShowToast } from '../../redux/actions'
import { NetworkCall } from '../../utils'

interface ReservePriceNotMetProps {
    id: number
    accept: boolean
    onSuccess: () => void
}

export const reservePriceNotMet = async (props: ReservePriceNotMetProps) => {
    const requestBody = {
        auctionId: props.id,
        accept: props.accept,
    }

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('POST', `/1.0.0/cars/reserve-price-not-met`, requestBody)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(201, DefaultDTO, (responseBody) => {
            props.onSuccess()

            store.dispatch(ShowToast({ message: 'toast_reserve_price_not_met_success' }))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
