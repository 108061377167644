import { NetworkCall } from "../../utils";

export const setPushToken = async ( pushToken: string ) => {
    const requestBody = {
        pushToken
    }

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('POST', `/1.0.0/users/set-push-token`, requestBody)
        .setHeader('Authorization', `Bearer ${ token }`)
        .then(200, () => {})
        .catch(() => {})
        .call()
}