import { store } from '../../redux'
import { ErrorDTO, NotificationSettingsDTO } from '../../dto'
import { ErrorMessage, UpdateUserFailed } from '../../redux/actions'
import { NetworkCall } from '../../utils'
import { UpdateUserNotificationSettings } from '../../redux/actions/user/update-user-notification-settings.action'

interface ModifyUserProps {
    oneDayBeforeAuctionEnds: boolean
    oneHourBeforeAuctionEnds: boolean
    newBids: boolean
    replyToMyComment: boolean
    questionsAnswered: boolean
    auctionResults: boolean
    reservePriceLowered: boolean
    reservePriceDeleted: boolean
    onSuccess: () => void
}

export const modifyNotificationSettings = async (props: ModifyUserProps) => {
    const requestBody = {
        oneDayBeforeAuctionEnds: props.oneDayBeforeAuctionEnds,
        oneHourBeforeAuctionEnds: props.oneHourBeforeAuctionEnds,
        newBids: props.newBids,
        replyToMyComment: props.replyToMyComment,
        questionsAnswered: props.questionsAnswered,
        auctionResults: props.auctionResults,
        reservePriceLowered: props.reservePriceLowered,
        reservePriceDeleted: props.reservePriceDeleted,
    }

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('PATCH', `/1.0.0/users/notification-settings`, requestBody)
        .setHeader('Authorization', `Bearer ${token}`)
        .then(200, NotificationSettingsDTO, (responseBody) => {
            store.dispatch(UpdateUserNotificationSettings(responseBody.notificationSettings))

            props.onSuccess()
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
            store.dispatch(UpdateUserFailed({}))
        })
        .call()
}
