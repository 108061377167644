import { PropsWithChildren } from 'react'
import './card-grid.scss'

interface CardGridProps {
    additionalClassNames?: string
}

export const CardGrid = (props: PropsWithChildren<CardGridProps>) => {
    return <>{props.children && <div className={'card-grid'}>{props.children}</div>}</>
}
