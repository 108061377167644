import React, { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from '../redux'
import { router } from './router'
import { me } from '../api'
import { useTranslation } from 'react-i18next'

export const App = () => {
    const { i18n } = useTranslation()

    useEffect(() => {
        const language = localStorage.getItem('language')
        if (language === null) {
            localStorage.setItem('language', i18n.language.includes('en') ? 'en' : i18n.language.includes('fr') ? 'fr' : i18n.language.includes('de') ? 'de' : 'en')
            i18n.changeLanguage('en')
        } else {
            if (language.includes('en')) {
                i18n.changeLanguage('en')
            }
            if (language.includes('fr')) {
                i18n.changeLanguage('fr')
            }
            if (language.includes('de')) {
                i18n.changeLanguage('de')
            }
        }

        const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')
        if (token !== null) {
            me(token)
        }
    }, [])

    return (
        <React.StrictMode>
            <Provider store={store}>
                <RouterProvider router={router} />
            </Provider>
        </React.StrictMode>
    )
}
