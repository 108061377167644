import { LoaderFunctionArgs } from 'react-router-dom'
import { getCarsAuctioned, getCarsBought, getCarsSold } from '../api'

export const myEvGaragePageLoader = async (args: LoaderFunctionArgs) => {
    await getCarsAuctioned(true)
    await getCarsSold(true)
    await getCarsBought(true)

    return null
}
