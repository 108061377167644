import { useState } from 'react'
import { Modal } from '../modal'
import { Button } from '../../buttons'
import { InputField } from '../../field'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '../../checkbox'
import { validateEmail } from '../../../utils/validate.util'
import { newsletterJoin } from '../../../api'
import './newsletter.modal.scss'

interface NewsletterModalProps {
    onClose: () => void
}

export const NewsletterModal = (props: NewsletterModalProps) => {
    const { t } = useTranslation()

    const [error, setError] = useState('')
    const [email, setEmail] = useState('')
    const [confirmAge, setConfirmAge] = useState(false)
    const [privacy, setPrivacy] = useState(false)
    const [ageError, setAgeError] = useState('')
    const [termsError, setTermsError] = useState('')

    const onJoinClick = async () => {
        setError('')
        setAgeError('')
        setTermsError('')

        const emailValidation = validateEmail(email)
        if (emailValidation != null) {
            setError(t(emailValidation))
            return
        }

        if (!confirmAge) {
            setAgeError(t('error_signup_confirm'))
            return
        }

        if (!privacy) {
            setTermsError(t('error_signup_privacy'))
            return
        }

        await newsletterJoin({
            email: email,
            onSuccess() {
                props.onClose()
            },
        })
    }

    return (
        <Modal title={t('modal_newsletter_title')} onCloseClick={props.onClose}>
            <div className="newsletter-modal">
                <label className="newsletter-modal__description">{t('modal_newsletter_description')}</label>

                <InputField
                    type="email"
                    field={{ error: error, value: email }}
                    onChange={(e) => {
                        setError('')
                        setEmail(e.target.value)
                    }}
                    title={t('signup_email_title')}
                    placeholder={''}
                />

                <div className="container-div">
                    <Checkbox error={ageError} titleElement={<label className="small-text">{t('modal_newsletter_confirm_title')} </label>} checked={confirmAge} id="age-cb" onChange={(e) => setConfirmAge(!confirmAge)} />

                    <Checkbox
                        error={termsError}
                        titleElement={
                            <label className="small-text">
                                {t('modal_newsletter_privacy_first_title')}{' '}
                                <a href="/privacy-policy" target="_blank">
                                    {t('modal_newsletter_privacy_second_title')}
                                </a>
                            </label>
                        }
                        checked={privacy}
                        id="terms-cb"
                        onChange={(e) => setPrivacy(!privacy)}
                    />
                </div>

                <div className="newsletter-modal__button-div">
                    <Button default={true} title={t('button_cancel')} onClick={props.onClose} />
                    <Button title={t('footer_join_button')} onClick={onJoinClick} />
                </div>
            </div>
        </Modal>
    )
}
