import { useEffect, useRef, useState } from 'react'
import './dropdown.scss'

interface DropdownProps {
    selectItemTitle: string
    disabled?: boolean
    value: any
    error: any
    items: DropdownItem[]
    onChange: any
    deleteEnabled: boolean
}

interface DropdownItem {
    id: number | string
    value: string
}

export const Dropdown = (props: DropdownProps) => {
    const [showItems, setShowItems] = useState(false)

    const onDropDownClick = () => {
        setShowItems(!showItems)
    }

    const onDeleteClick = () => {
        props.onChange(-1)
    }

    const onSelectItem = (item: DropdownItem) => {
        setShowItems(false)
        props.onChange(item.id)
    }

    const getValue = () => {
        const valueItem = props.items.find((x) => x.id === props.value)
        return valueItem !== undefined ? valueItem.value : props.selectItemTitle
    }

    const hasSelectedValue = () => {
        const valueItem = props.items.find((x) => x.id === props.value)
        return valueItem !== undefined
    }

    const onBlur = (event: React.FocusEvent<HTMLElement>) => {
        setShowItems(false)
    }

    const ref = useRef<HTMLDivElement>(null)

    function assertIsNode(e: EventTarget | null): asserts e is Node {
        if (!e || !('nodeType' in e)) {
            throw new Error(`Node expected`)
        }
    }

    useEffect(() => {
        const handleOutSideClick = ({ target }: MouseEvent) => {
            assertIsNode(target)

            if (!ref.current?.contains(target)) {
                setShowItems(!showItems)
            }
        }

        if (showItems) {
            window.addEventListener('mousedown', handleOutSideClick)
        }
        return () => {
            window.removeEventListener('mousedown', handleOutSideClick)
        }
    }, [ref, showItems])

    return (
        <div className={`dropdown-container`} ref={ref}>
            <div className={`dropdown${props.disabled === true ? ` disabled` : ``}`}>
                <div tabIndex={0} className={`dropdown-container`} onBlur={onBlur}>
                    <div className={`dropdown-selected-item${hasSelectedValue() ? '' : ' placeholder'}`} onClick={onDropDownClick} id={'dropdown'}>
                        {getValue()}
                    </div>

                    <img className={`dropdown-arrow ${showItems ? 'rotate' : ''}`} alt="Arrow icon" src="/images/ic_arrow_down.svg" onClick={onDropDownClick} />

                    <img className={`dropdown-delete simple ${hasSelectedValue() && props.deleteEnabled ? '' : 'hidden'}`} alt="Delete icon" src="/images/ic_delete.svg" onClick={onDeleteClick} />

                    <div style={{ display: showItems ? 'flex' : 'none' }} className={`dropdown-items`}>
                        {props.items.map((item) => (
                            <div className={`dropdown-items-item ${props.value === item.id ? 'selected' : ''}`} key={item.id + 'dropdown'} onClick={() => onSelectItem(item)}>
                                <span id={'dropdown'}>{item != null ? item.value : ''}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className={`error-field ${props.error != null && props.error.length > 0 ? `` : `hidden`}`}>
                <img alt="Error icon" className="error-img" src="/images/ic_error.svg" />

                <div className={`message`}>{props.error}</div>
            </div>
        </div>
    )
}
