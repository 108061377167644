import { createRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Checkbox } from '../../components'
import { InputField, PasswordField } from '../../components/field'
import { useAppSelector } from '../../redux'
import { useDispatch } from 'react-redux'
import { UpdateSignUpForm } from '../../redux/actions'
import { registration } from '../../api'
import { useNavigate } from 'react-router-dom'
import { validateEmail, validatePassword } from '../../utils/validate.util'
import './signup.page.scss'

interface SignUpPageProps {}

export const SignUpPage = (props: SignUpPageProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { form } = useAppSelector((state) => state.signUp)

    const onSigUpClick = async () => {
        dispatch(UpdateSignUpForm({ key: 'username', error: null, value: form.username.value }))
        dispatch(UpdateSignUpForm({ key: 'email', error: null, value: form.email.value }))
        dispatch(UpdateSignUpForm({ key: 'password', error: null, value: form.password.value }))
        dispatch(UpdateSignUpForm({ key: 'confirm', error: null, value: form.confirm.value }))
        dispatch(UpdateSignUpForm({ key: 'english', error: null, value: form.english.value }))
        dispatch(UpdateSignUpForm({ key: 'terms', error: null, value: form.terms.value }))

        if (form.username.value.length === 0) {
            dispatch(UpdateSignUpForm({ key: 'username', error: t('error_signup_username_empty'), value: '' }))
            return
        }

        const emailValidation = validateEmail(form.email.value)
        if (emailValidation != null) {
            dispatch(UpdateSignUpForm({ key: 'email', error: t(emailValidation), value: form.email.value }))
            return
        }

        const passwordValidation = validatePassword(form.password.value)
        if (passwordValidation != null) {
            dispatch(UpdateSignUpForm({ key: 'password', error: t(passwordValidation), value: form.password.value }))
            return
        }

        if (!form.confirm.value) {
            dispatch(UpdateSignUpForm({ key: 'confirm', error: t('error_signup_confirm'), value: false }))
            return
        }

        if (!form.english.value && !form.german.value && !form.italian.value && !form.french.value) {
            dispatch(UpdateSignUpForm({ key: 'english', error: t('error_signup_language'), value: false }))
            return
        }

        if (!form.terms.value) {
            dispatch(UpdateSignUpForm({ key: 'terms', error: t('error_signup_terms'), value: false }))
            return
        }

        // if (!formElement.current?.reportValidity()) {
        //     return
        // }

        if (await registration()) {
            navigate('/email-verification')
        }
    }

    return (
        <main className="page page-signup">
            <div className="main-div">
                <h2 className="label">{t('signup_title')} </h2>

                {/* <form action="" className="form-div" ref={formElement}> */}

                <div className="form-div">
                    <div className="user-information-div">
                        <h4 className="label">{t('signup_user_information_title')} </h4>

                        <InputField type="text" field={form.username} maxLength={50} placeholder={t('')} title={t('signup_username_title')} onChange={(e) => dispatch(UpdateSignUpForm({ key: 'username', value: e.target.value, error: null }))} />

                        <InputField type="email" field={form.email} placeholder={t('')} title={t('signup_email_title')} onChange={(e) => dispatch(UpdateSignUpForm({ key: 'email', value: e.target.value, error: null }))} />

                        <PasswordField showStrongPassword={true} field={form.password} placeholder={t('')} title={t('signup_password_title')} onChange={(e) => dispatch(UpdateSignUpForm({ key: 'password', value: e.target.value, error: null }))} />

                        <Checkbox error={form.confirm.error} checked={form.confirm.value} title={t('signup_confirm_title')} id="confirm" onChange={(e) => dispatch(UpdateSignUpForm({ key: 'confirm', value: !form.confirm.value, error: null }))} />
                    </div>
                    <div className="languages-div">
                        <h4 className="body-text">{t('signup_languages_spoken_title')} </h4>
                        <div className="items-div">
                            <Checkbox error={''} checked={form.english.value} title={t('signup_english_title')} id="en" onChange={(e) => dispatch(UpdateSignUpForm({ key: 'english', value: !form.english.value, error: null }))} />

                            <Checkbox error={''} checked={form.german.value} title={t('signup_german_title')} id="de" onChange={(e) => dispatch(UpdateSignUpForm({ key: 'german', value: !form.german.value, error: null }))} />

                            <Checkbox error={''} checked={form.french.value} title={t('signup_french_title')} id="fr" onChange={(e) => dispatch(UpdateSignUpForm({ key: 'french', value: !form.french.value, error: null }))} />

                            {/* <Checkbox error={form.english.error} checked={form.italian.value} title={t('signup_italian_title')} id="it" onChange={(e) => dispatch(UpdateSignUpForm({ key: 'italian', value: !form.italian.value, error: null }))} /> */}
                        </div>

                        <div className={`error-div ${form.english.error != null && form.english.error.length > 0 ? `` : `hidden`}`}>
                            <img alt="Error icon" className="error-img" src="/images/ic_error.svg" />

                            <div className={`message`}>{form.english.error}</div>
                        </div>
                    </div>
                    <Checkbox error={form.terms.error} checked={form.terms.value} id="terms" titleElement={<label className="small-text" dangerouslySetInnerHTML={{ __html: t('signup_terms_title') }}></label>} onChange={(e) => dispatch(UpdateSignUpForm({ key: 'terms', value: !form.terms.value, error: null }))} />
                    <Checkbox
                        error={''}
                        checked={form.newsletter.value}
                        titleElement={
                            <label className="small-text">
                                {t('signup_newsletter_first_part_title')}&nbsp;
                                <a href="/privacy-policy" target="_blank">
                                    {t('signup_newsletter_second_part_title')}
                                </a>
                            </label>
                        }
                        id="newsletter"
                        onChange={(e) => dispatch(UpdateSignUpForm({ key: 'newsletter', value: !form.newsletter.value, error: null }))}
                    />

                    <Button title={t('button_sign_up')} onClick={onSigUpClick} />
                </div>
            </div>
        </main>
    )
}
