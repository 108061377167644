import { Modal } from '../modal'
import { Button } from '../../buttons'
import { useTranslation } from 'react-i18next'
import { getCarsAuctioned, updateReservePriceAndMediaFiles } from '../../../api'
import './delete-reserve-price.modal.scss'

interface DeleteReservePriceModalProps {
    id: number
    onClose: () => void
}

export const DeleteReservePriceModal = (props: DeleteReservePriceModalProps) => {
    const { t } = useTranslation()

    const onDeleteClick = async () => {
        await updateReservePriceAndMediaFiles({
            id: props.id,
            reservePrice: null,
            mediaFileIds: undefined,
            onSuccess() {
                getCarsAuctioned(true)

                props.onClose()
            },
        })
    }

    return (
        <Modal title={t('modal_delete_reserve_price_title')} onCloseClick={props.onClose}>
            <div className="delete-reserve-price-modal">
                <div className={`"edit-reserve-price-desc-div`}>
                    <h3>{t('modal_delete_reserve_price_sub_title')}</h3>
                    <label className="description">{t('modal_delete_reserve_price_desc')}</label>
                </div>

                <div className="button-div">
                    <Button default={true} title={t('button_cancel')} onClick={props.onClose} />
                    <Button title={t('modal_delete_reserve_price_title')} onClick={onDeleteClick} />
                </div>
            </div>
        </Modal>
    )
}
