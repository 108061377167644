import { MouseEventHandler, useEffect } from 'react'
import { Modal } from '../modal'
import { Button, IconButton } from '../../buttons'
import { InputField, PhoneField } from '../../field'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../redux'
import { useDispatch } from 'react-redux'
import { UpdateUserForm, UpdateUserModalForm } from '../../../redux/actions'
import { modifyUser } from '../../../api'
import './register-to.modal.scss'

interface RegisterToModalProps {
    type: 'test-drive' | 'bid' | 'sell-a-car'
    onClose: () => void
    onSuccess: (type: string) => void
    onOkClick?: MouseEventHandler
    onHowBiddingWorksClick: MouseEventHandler
    onAddPaymentCardClick: MouseEventHandler
}

export const RegisterToModal = (props: RegisterToModalProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { modalForm, form } = useAppSelector((state) => state.user)

    useEffect(() => {
        dispatch(UpdateUserModalForm({ key: 'address', error: null, value: form.address.value }))
        dispatch(UpdateUserModalForm({ key: 'city', error: null, value: form.city.value }))
        dispatch(UpdateUserModalForm({ key: 'postalCode', error: null, value: form.postalCode.value }))
        dispatch(UpdateUserModalForm({ key: 'phoneNumber', error: null, value: form.phoneNumber.value }))
        dispatch(UpdateUserModalForm({ key: 'cardId', error: null, value: form.cardId.value }))
    }, [])

    const onSaveClick = async () => {
        dispatch(UpdateUserModalForm({ key: 'address', error: null, value: modalForm.address.value }))
        dispatch(UpdateUserModalForm({ key: 'city', error: null, value: modalForm.city.value }))
        dispatch(UpdateUserModalForm({ key: 'postalCode', error: null, value: modalForm.postalCode.value }))
        dispatch(UpdateUserModalForm({ key: 'phoneNumber', error: null, value: modalForm.phoneNumber.value }))
        dispatch(UpdateUserModalForm({ key: 'cardId', error: null, value: modalForm.cardId.value }))

        if (modalForm.address.value.length === 0) {
            dispatch(UpdateUserModalForm({ key: 'address', error: t('error_my_profile_address_empty'), value: '' }))
            return
        }

        if (modalForm.city.value.length === 0) {
            dispatch(UpdateUserModalForm({ key: 'city', error: t('error_my_profile_city_empty'), value: '' }))
            return
        }

        if (modalForm.postalCode.value.length === 0) {
            dispatch(UpdateUserModalForm({ key: 'postalCode', error: t('error_my_profile_postal_code_empty'), value: '' }))
            return
        }

        if (modalForm.phoneNumber.value.length === 0) {
            dispatch(UpdateUserModalForm({ key: 'phoneNumber', error: t('error_my_profile_phone_empty'), value: '' }))
            return
        }

        if (props.type !== 'sell-a-car') {
            if (modalForm.cardId.value.length === 0) {
                dispatch(UpdateUserModalForm({ key: 'cardId', error: t('error_my_profile_card_empty'), value: '' }))
                return
            }
        }

        dispatch(UpdateUserForm({ key: 'address', error: null, value: modalForm.address.value }))
        dispatch(UpdateUserForm({ key: 'city', error: null, value: modalForm.city.value }))
        dispatch(UpdateUserForm({ key: 'postalCode', error: null, value: modalForm.postalCode.value }))
        dispatch(UpdateUserForm({ key: 'phoneNumber', error: null, value: modalForm.phoneNumber.value }))
        dispatch(UpdateUserForm({ key: 'cardId', error: null, value: modalForm.cardId.value }))

        await modifyUser({
            onSuccess() {
                props.onSuccess(props.type)
            },
            onError() {},
        })
    }

    const onCloseClick = () => {
        dispatch(UpdateUserModalForm({ key: 'address', error: null, value: '' }))
        dispatch(UpdateUserModalForm({ key: 'city', error: null, value: '' }))
        dispatch(UpdateUserModalForm({ key: 'postalCode', error: null, value: '' }))
        dispatch(UpdateUserModalForm({ key: 'phoneNumber', error: null, value: '' }))
        dispatch(UpdateUserModalForm({ key: 'cardId', error: null, value: '' }))

        props.onClose()
    }

    return (
        <Modal title={props.type === 'test-drive' ? t('modal_test_drive_title') : props.type === 'sell-a-car' ? t('modal_register_to_sell_a_car_title') : t('modal_register_to_bid_title')} onCloseClick={onCloseClick}>
            <div className="register-to-modal">
                <div className={`register-to-modal__description-div`}>
                    <label className="description bold">{props.type === 'test-drive' ? t('modal_test_drive_desc') : props.type === 'sell-a-car' ? t('modal_register_to_sell_a_car_desc') : t('modal_register_to_bid_desc')}</label>

                    {props.type !== 'sell-a-car' && (
                        <>
                            <label className="description">{props.type === 'test-drive' ? t('modal_test_drive_auction_top_desc') : t('modal_register_to_bid_top_desc')}</label>
                            <label className="description">{props.type === 'test-drive' ? t('modal_test_drive_auction_bottom_desc') : ''}</label>
                            <IconButton reverse={true} title={t('auction_how_bidding_works_button')} icon="/images/ic_info.svg" onClick={props.onHowBiddingWorksClick} />
                        </>
                    )}
                </div>

                <div className="register-to-modal__address-div">
                    <h4>{t('my_profile_address_title')}</h4>

                    <div className="country-div">
                        <div className="country-div">
                            <InputField type="text" field={{ value: t('my_profile_switzlerland_title'), error: null, disabled: true }} placeholder={t('')} title={t('my_profile_country_title')} onChange={(e) => dispatch(UpdateUserModalForm({ key: 'country', value: e.target.value, error: null }))} />
                        </div>

                        <InputField type="text" field={modalForm.address} placeholder={t('')} title={t('my_profile_address_street_house_title')} onChange={(e) => dispatch(UpdateUserModalForm({ key: 'address', value: e.target.value, error: null }))} />
                    </div>

                    <div className="country-div">
                        <InputField type="text" field={modalForm.city} placeholder={t('')} title={t('my_profile_city_title')} onChange={(e) => dispatch(UpdateUserModalForm({ key: 'city', value: e.target.value, error: null }))} />

                        <div className="country-div">
                            <InputField
                                type="text"
                                field={modalForm.postalCode}
                                maxLength={4}
                                placeholder={t('')}
                                title={t('my_profile_postal_code_title')}
                                onChange={(event) => {
                                    let userInput: string = event.target.value.replace(/[^0-9]/g, '')
                                    if (userInput.length === 0) {
                                        dispatch(UpdateUserModalForm({ key: 'postalCode', value: '', error: null }))
                                    }
                                    if (userInput !== '') {
                                        dispatch(UpdateUserModalForm({ key: 'postalCode', value: userInput, error: null }))
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div className="phone-div">
                        <PhoneField
                            area="+41"
                            field={modalForm.phoneNumber}
                            placeholder={t('')}
                            title={t('my_profile_phone_number_title')}
                            onChange={(event: any) => {
                                let userInput: string = event.target.value.replace(/[^0-9]/g, '')
                                if (userInput.length === 0) {
                                    dispatch(UpdateUserModalForm({ key: 'phoneNumber', value: '', error: null }))
                                }
                                if (userInput !== '') {
                                    dispatch(UpdateUserModalForm({ key: 'phoneNumber', value: userInput, error: null }))
                                }
                            }}
                        />
                    </div>
                </div>

                {props.type !== 'sell-a-car' && (
                    <div className="register-to-modal__card-div">
                        <h4>{t('modal_test_drive_creait_card_title')}</h4>

                        {modalForm.cardId.value != null && modalForm.cardId.value !== '' && <label className="sucess-label">{t('my_profile_credit_card_added_successfully_title')} </label>}

                        {form.cardId.value == null || (form.cardId.value === '' && <Button type={'black'} title={t('button_add_payment_card')} onClick={props.onAddPaymentCardClick} />)}

                        <div className={`${modalForm.cardId.error != null && modalForm.cardId.error.length > 0 ? `field__error-div` : `field--hidden`}`}>
                            <img alt="Error icon" className="error-img" src="/images/ic_error.svg" />

                            <div className={`message`}>{modalForm.cardId.error}</div>
                        </div>
                    </div>
                )}

                <div className="register-to-modal__button-div">
                    <Button default={true} title={t('button_cancel')} onClick={onCloseClick} />

                    <Button title={props.type === 'test-drive' ? t('button_ask_for_test_drive') : props.type === 'sell-a-car' ? t('button_register_to_sell_a_car') : t('button_register_to_bid')} onClick={onSaveClick} />
                </div>
            </div>
        </Modal>
    )
}
