import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, IsString } from 'class-validator'
import { User } from './user.entity'

export class Answer {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsString()
    answer!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number

    @Expose()
    user!: User
}
