import { LoaderFunctionArgs } from 'react-router-dom'
import { NetworkCall } from '../utils'
import { store } from '../redux'
import { ErrorMessage } from '../redux/actions'
import { ErrorDTO } from '../dto'

export const emailVerificationPageLoader = async (args: LoaderFunctionArgs) => {
    return new Promise((resolve) => {
        const params = new URL(args.request.url).searchParams
        const token = params.get('token')
        //const email = params.get('email')
        //const lang = params.get('lang')

        if (token !== undefined && token !== null && token.length) {
            new NetworkCall('GET', `/1.0.0/users/validate-registration-email?token=${token}`)
                .then(200, (responseBody) => {
                    resolve(true)
                })
                .catch((response) => {
                    store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))

                    resolve(false)
                })
                .call()
        } else {
            resolve(null)
        }
    })
}
