import { Modal } from '../modal'
import { Button } from '../../buttons'
import { useTranslation } from 'react-i18next'
import './delete-card.modal.scss'

interface DeleteCardModalProps {
    onClose: () => void
    onDelete: () => void
}

export const DeleteCardModal = (props: DeleteCardModalProps) => {
    const { t } = useTranslation()

    const onDeleteClick = async () => {
        props.onDelete()
    }

    return (
        <Modal title={t('modal_delete_card_title')} onCloseClick={props.onClose}>
            <div className="delete-card-modal">
                <div className="">
                    <h3 className="sub-title">{t('modal_delete_card_sub_title')}</h3>

                    <label className="description">{t('modal_delete_card_description')}</label>
                </div>

                <label className="small-text">{t('modal_delete_card_bottom_description')}</label>

                <div className="delete-account-modal__button-div">
                    <Button default={true} title={t('button_cancel')} onClick={props.onClose} />
                    <Button title={t('button_remove_card').toUpperCase()} onClick={onDeleteClick} />
                </div>
            </div>
        </Modal>
    )
}
