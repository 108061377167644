import { useNavigate } from 'react-router-dom'
import { GuidePageEnum } from '../../../enum/guide-page-enum'
import { useTranslation } from 'react-i18next'
import './guide-submenu.scss'

interface GuideSubmenuProps {
    activePage: GuidePageEnum
    isTop: boolean
}

export const GuideSubmenu = (props: GuideSubmenuProps) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <>
            <div className={props.isTop ? 'submenu-top-div' : 'submenu-div'}>
                <div
                    className={`submenu-item ${props.activePage === GuidePageEnum.PhotoGuide ? 'active' : ''}`}
                    onClick={() => {
                        navigate('/photo-guide')
                    }}
                >
                    <span dangerouslySetInnerHTML={{ __html: t('guide_menu_photo_guide') }}></span>
                </div>
                <div
                    className={`submenu-item ${props.activePage === GuidePageEnum.VideoGuide ? 'active' : ''}`}
                    onClick={() => {
                        navigate('/video-guide')
                    }}
                >
                    <span dangerouslySetInnerHTML={{ __html: t('guide_menu_video_guide') }}></span>
                </div>
                <div
                    className={`submenu-item ${props.activePage === GuidePageEnum.PhotoChecklist ? 'active' : ''}`}
                    onClick={() => {
                        navigate('/photo-checklist')
                    }}
                >
                    <span dangerouslySetInnerHTML={{ __html: props.isTop ? t('guide_menu_photo_checklist_short') : t('guide_menu_photo_checklist') }}></span>
                </div>
            </div>
        </>
    )
}
