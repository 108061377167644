import { useTranslation } from 'react-i18next'
import { Notification } from '../../../entity'
import { NotificationTypeEnum } from '../../../enum'
import { useNavigate } from 'react-router-dom'
import { formatTimeLeft } from '../../../utils'
import './notification-element.scss'

interface NotificationElementProps {
    notification: Notification
    onClose: () => void
}

export const NotificationElement = (props: NotificationElementProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <li
            key={props.notification.id}
            className={`notification-element`}
            onClick={() => {
                navigate(props.notification.url)
                props.onClose()
            }}
        >
            <div className={`icon-div ${props.notification.type === NotificationTypeEnum.Bid ? `bid` : ``} ${props.notification.type === NotificationTypeEnum.Car ? `car` : ``}`}>
                <img className={`icon-img`} alt="Notification icon" src={props.notification.type === NotificationTypeEnum.Bid ? `/images/ic_bid.svg` : props.notification.type === NotificationTypeEnum.Comment ? `/images/ic_comments_white.svg` : props.notification.type === NotificationTypeEnum.Question ? `/images/ic_question.svg` : `/images/ic_car.svg`} />
            </div>
            <div className="message-div">
                <div className={`title-div`}>
                    <label className="title">{props.notification.title}</label>
                    <label className="date">{formatTimeLeft(props.notification.created, t)}</label>
                </div>

                <label className="message">{props.notification.message}</label>
            </div>
        </li>
    )
}
