import { ErrorDTO, ImpressumDTO } from '../../dto'
import { store } from '../../redux'
import { ErrorMessage, GetImpressumSuccess } from '../../redux/actions'
import { NetworkCall } from '../../utils'

export const getImpressum = async () => {
    return await new NetworkCall('GET', `/1.0.0/impressum`)
        .then(200, ImpressumDTO, (responseBody) => {
            store.dispatch(GetImpressumSuccess(responseBody))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
