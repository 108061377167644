import { Expose } from 'class-transformer'
import {IsBoolean, IsDefined, IsIn, IsNumber, IsOptional, IsString} from 'class-validator'
import { NotificationTypeEnum } from '../enum'

export class Notification {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    @IsIn(
        Object.keys(NotificationTypeEnum)
            .map((key: string) => parseInt(key))
            .filter((key: number) => !isNaN(key))
    )
    type!: number

    @Expose()
    @IsDefined()
    @IsString()
    title!: string

    @Expose()
    @IsDefined()
    @IsString()
    message!: string

    @Expose()
    @IsDefined()
    @IsBoolean()
    seen!: boolean

    @Expose()
    @IsDefined()
    @IsString()
    url!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number
}
