import { Expose, Type } from 'class-transformer'
import { IsDefined, ValidateNested } from 'class-validator'
import { ErrorDTO } from '../error.dto'

export class AuthenticationErrorDTO {
    @Expose()
    @IsDefined()
    userId!: number

    @Expose()
    @IsDefined()
    auctionId!: number

    @Expose()
    @IsDefined()
    paymentIntentId!: string

    @Expose()
    @IsDefined()
    amount!: number

    @Expose()
    @IsDefined()
    buyersFee!: number

    @Expose()
    @IsDefined()
    url!: string

    @Expose()
    @ValidateNested()
    @Type(() => ErrorDTO)
    error!: ErrorDTO
}
