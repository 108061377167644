import { store } from '../../redux'
import { ErrorDTO, MediaFilesDTO } from '../../dto'
import { NetworkCall } from '../../utils'
import { AddMediaFiles, ErrorMessage, ShowToast } from '../../redux/actions'
import { MediaFile } from '../../entity'

interface UplpoadMediaFileProps {
    files: File[]
    onSuccess: (mediaFiles: MediaFile[]) => void
    onError: () => void
}

export const uplpoadMediaFile = async (props: UplpoadMediaFileProps) => {
    let formData = new FormData()

    props.files.map((file: File) => {
        formData.append(`files`, file as Blob)
    })

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('POST', `/1.0.0/media-files`, formData)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(201, MediaFilesDTO, (responseBody) => {
            store.dispatch(AddMediaFiles({ mediaFiles: responseBody.mediaFiles }))

            props.onSuccess(responseBody.mediaFiles)

            store.dispatch(ShowToast({ message: 'toast_add_file_success' }))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))

            props.onError()
        })
        .call()
}
