import { Modal } from '../modal'
import { Button } from '../../buttons'
import { useTranslation } from 'react-i18next'
import { resendRegistrationEmail } from '../../../api'
import { useAppSelector } from '../../../redux'
import './resend-registration-email.modal.scss'

interface ResendRegistrationEmailModalProps {
    onClose: () => void
}

export const ResendRegistrationEmailModal = (props: ResendRegistrationEmailModalProps) => {
    const { t } = useTranslation()

    const { form } = useAppSelector((state) => state.login)

    const onResendClick = async () => {
        await resendRegistrationEmail({
            email: form.email.value,
            onSuccess() {
                props.onClose()
            },
        })
    }

    return (
        <Modal title={t('error_title')} onCloseClick={() => props.onClose()}>
            <div className="resend-registration-email-modal">
                <label className="resend-registration-email-modal__message">{t('error_email_not_verified')}</label>

                <div className="resend-registration-email-modal__button-div">
                    <Button default={true} title={t('button_cancel')} onClick={() => props.onClose()} />
                    <Button title={t('button_resend')} onClick={() => onResendClick()} />
                </div>
            </div>
        </Modal>
    )
}
