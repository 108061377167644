import { useTranslation } from 'react-i18next'
import { Battery, Button, IconButton } from '../../../../components'
import { MouseEventHandler, useEffect, useRef, useState } from 'react'
import { Car } from '../../../../entity'
import { useAppSelector } from '../../../../redux'
import { useDispatch } from 'react-redux'
import { favourite } from '../../../../api'
import { ShowLoginModal } from '../../../../redux/actions'
import { ShowToast } from '../../../../redux/actions/base/show-toast.action'
import { useNavigate } from 'react-router-dom'
import './sticky-auction.scss'
import { CarStatusEnum } from '../../../../enum'

interface StickyAuctionProps {
    car: Car
    onReportClick: MouseEventHandler
    onTestDriveClick: MouseEventHandler
    onBidClick: MouseEventHandler
    onHowBiddignWorksClick: MouseEventHandler
    onCommentsClick: MouseEventHandler
}

export const StickyAuction = (props: StickyAuctionProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const user = useAppSelector((state) => state.login.user)
    const commentCount = useAppSelector((state) => state.comments.pagination.count)
    const [isFavourite, setIsFavourite] = useState(props.car.favourite)

    const nameRef = useRef<HTMLHeadingElement>(null)

    useEffect(() => {
        if (nameRef && nameRef.current) {
            const name = props.car.manufacturingYear + ' ' + props.car.make + ' ' + props.car.model
            if (name.length < 25) {
                nameRef.current.style.fontSize = 26 + 'px'
            } else if (name.length < 32) {
                nameRef.current.style.fontSize = 22 + 'px'
            } else {
                nameRef.current.style.fontSize = 18 + 'px'
            }
        }
    }, [props.car])

    const onFavouriteClick = async () => {
        if (user) {
            await favourite({
                id: props.car.id,
                onSuccess() {
                    setIsFavourite(!isFavourite)
                },
            })
        } else {
            dispatch(ShowLoginModal({ visible: true, carId: null }))
        }
    }

    const getReservePriceTitle = () => {
        if (props.car.reservePrice == null || props.car.reservePrice === 0) {
            return t('car_card_no_reserve_title')
        } else if (props.car.reservePriceDeleted) {
            return t('car_card_reserve_price_deleted_title')
        } else if (props.car.reservePriceLowered) {
            return t('car_card_reserve_price_lowered_title')
        } else {
            return ''
        }
    }

    const getReservePriceTitleStyle = () => {
        if (props.car.reservePrice == null || props.car.reservePrice === 0) {
            return ``
        } else if (props.car.reservePriceDeleted) {
            return 'reserve-price-deleted'
        } else if (props.car.reservePriceLowered) {
            return 'reserve-price-deleted'
        } else {
            return `hidden`
        }
    }

    return (
        <div className={`sticky-auction ${props.car.status !== CarStatusEnum.Approved ? `auction-ended` : ``}`}>
            <div className="top-div">
                {props.car.status === CarStatusEnum.Approved && (
                    <>
                        <IconButton small={true} reverse={true} title={t('auction_add_to_favorites_title')} icon={`/images/${isFavourite ? `ic_favourite_selected.svg` : `ic_favourite.svg`}`} onClick={onFavouriteClick} />

                        <div className="small-divider-div" />

                        <IconButton small={true} reverse={true} title={t('auction_report_car_ad_title')} icon="/images/ic_report.svg" onClick={props.onReportClick} />

                        <div className="small-divider-div" />
                    </>
                )}

                <IconButton
                    small={true}
                    reverse={true}
                    title={t('auction_share_title')}
                    icon="/images/ic_share.svg"
                    onClick={() => {
                        if (navigator.share) {
                            navigator
                                .share({
                                    title: t('auction_share_title'),
                                    url: window.location.href,
                                })
                                .then(() => {
                                    navigator.clipboard.writeText(window.location.href)
                                })
                                .catch(console.error)
                        } else {
                            navigator.clipboard.writeText(window.location.href)
                            dispatch(ShowToast({ message: 'toast_link_copied_message' }))
                        }
                    }}
                />
            </div>

            <div className="name-div">
                <h2 ref={nameRef}>
                    {props.car.manufacturingYear} {props.car.make} {props.car.model}
                </h2>

                {props.car.status === CarStatusEnum.Approved && <p className={`no-reserve ${getReservePriceTitleStyle()}`}>{getReservePriceTitle()}</p>}
            </div>

            {props.car.status === CarStatusEnum.Approved && (
                <div className="battery-div">
                    <Battery type={'large'} auction={props.car.auctions[0]} onEnd={() => navigate(`/`)} />
                </div>
            )}

            {props.car.status !== CarStatusEnum.Approved && (
                <div className="new-price-div">
                    <div className={`bid-div`}>
                        <p className="sub-title">{props.car.status === CarStatusEnum.Sold || props.car.status === CarStatusEnum.Paid || props.car.status === CarStatusEnum.Unpaid ? t('car_card_final_price_title') : t('my_ev_garage_highest_bid_title')}</p>

                        <div className="price-div">
                            <h3 className={`price`}> {props.car.status === CarStatusEnum.Sold || props.car.status === CarStatusEnum.Paid || props.car.status === CarStatusEnum.Unpaid ? props.car.auctions[0].currentPrice.toLocaleString('de-CH', { minimumFractionDigits: 0 }) : props.car.auctions[0].highestBid?.toLocaleString('de-CH', { minimumFractionDigits: 0 })} </h3>
                            <label className={`currency `}>{t('car_card_chf_title')}</label>
                        </div>
                    </div>

                    <div className="time-left-div">
                        <p className={`sub-title inactive`}>{t('car_card_end_date_title')}</p>

                        <Battery type={'normal'} auction={props.car.auctions[0]} />
                    </div>
                </div>
            )}
            {props.car.status === CarStatusEnum.Approved && (
                <div className="bid-div">
                    <div className="current-bid-div">
                        <div className="bid-div">
                            <img alt="Bid icon" className="action-img" src="/images/ic_bid.svg" />
                            <label className="label">{t('auction_current_bid_title')}</label>
                        </div>

                        <div className="price-div">
                            <h1 className="price">{props.car.auctions[0].currentPrice.toLocaleString('de-CH', { minimumFractionDigits: 0 })}</h1>
                            <label className="currency">{t('car_card_chf_title')}</label>
                        </div>

                        <div className="battery-mobile-div">
                            <Battery type={'small'} auction={props.car.auctions[0]} />
                        </div>
                    </div>

                    <div className="bid-now-div">
                        <Button title={t('auction_bid_now_button')} onClick={props.onBidClick} />

                        <IconButton withHoverAnim={true} reverse={true} title={t('auction_how_bidding_works_button')} icon="/images/ic_info.svg" onClick={props.onHowBiddignWorksClick} />
                    </div>
                </div>
            )}

            <div className="divider" />

            <div className="statistics-div">
                <div className="item-div">
                    <label className="title">{t('auction_bids_title')}</label>
                    {/* <div className="value-div pointer" onClick={props.onCommentsClick}> */}

                    <div className="value-div ">
                        <img alt="Bid icon" className="action-img" src="/images/ic_bid.svg" />
                        <label className="label">{props.car.auctions!![0].bidCount ?? 0}</label>
                    </div>
                </div>

                <div className="item-div">
                    <label className="title">{t('auction_views_title')}</label>
                    <div className="value-div views">
                        <img alt="View icon" className="action-img" src="/images/ic_views.svg" />
                        <label className="label">{props.car.views}</label>
                    </div>
                </div>

                <div className="item-div">
                    <label className="title">{t('auction_comments_title')}</label>
                    <div className="value-div views pointer" onClick={props.onCommentsClick}>
                        <img alt="Comment icon" className="action-img" src="/images/ic_comments.svg" />
                        <label className="label">{commentCount}</label>
                    </div>
                </div>
            </div>

            <div className="divider" />

            <div className="seller-div">
                <div className="user-div">
                    <label className="type">
                        {t('auction_seller_title')} {props.car?.user?.firstName != null ? t('auction_private_title') : t('auction_dealer_title')}
                    </label>
                    <label
                        className="user"
                        onClick={() => {
                            if (user) {
                                navigate(`/user/${props.car?.user?.id}`)
                            } else {
                                dispatch(ShowLoginModal({ visible: true, carId: props.car ? props.car?.id : null }))
                            }
                        }}
                    >
                        {/* {props.car.user?.firstName != null ? props.car.user?.firstName : props.car.user?.companyName ?? ''} {props.car.user?.lastName != null ? props.car.user?.lastName : ''} */}
                        {props.car.user?.username}
                    </label>
                    <label className="address">
                        {props.car.user?.address?.postalCode} {props.car.user?.address?.city}
                    </label>
                </div>

                {props.car.status === CarStatusEnum.Approved && (
                    <div className="test-drive-div">
                        <IconButton withHoverAnim={true} title={t('auction_ask_for_test_drive_button')} icon="/images/ic_arrow_right_black.svg" onClick={props.onTestDriveClick} />
                    </div>
                )}
            </div>
        </div>
    )
}
