import { createReducer } from '@reduxjs/toolkit'
import { ImpressumState } from '../../states'
import { GetImpressumSuccess } from '../../actions'

const initialState: ImpressumState = {
    impressum: null,
}

export const impressumReducer = createReducer(initialState, (builder) => {
    builder.addCase(GetImpressumSuccess, (state, action) => {
        state.impressum = action.payload.impressum
    })
})
