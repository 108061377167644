import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, IsString, ValidateIf } from 'class-validator'
import { Answer } from './answer.entity'
import { User } from './user.entity'

export class Question {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsString()
    question!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    answer!: Answer | null

    @Expose()
    user!: User
}
