import { useTranslation } from 'react-i18next'
import { Bid } from '../../../../../entity'
import { useNavigate } from 'react-router-dom'
import './bid-element.scss'
import { getLocale } from '../../../../../utils/date.util'
import {monthToUpper} from "../../../../../utils";

interface BidElementProps {
    highest: boolean
    bid: Bid
}

export const BidElement = (props: BidElementProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <li key={props.bid.id} className={`bid-element`}>
            <div className={`bid-div`}>
                <label
                    className={`user ${props.highest ? `highest` : ``}`}
                    onClick={() => {
                        navigate(`/user/${props.bid.user?.id}`)
                    }}
                >
                    {props.bid.user?.username}
                </label>
                <label className={`amount ${props.highest ? `highest` : ``}`}>{props.bid.amount.toLocaleString('de-CH', { minimumFractionDigits: 0 })}</label>
                <label className={`date ${props.highest ? `highest` : ``}`}>{monthToUpper(new Date(props.bid.created).toLocaleString(getLocale(), { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }))}</label>
            </div>

            <div className="divider" />
        </li>
    )
}
