import { createReducer } from '@reduxjs/toolkit'
import { TermsState } from '../../states'
import { GetTermsSuccess } from '../../actions'

const initialState: TermsState = {
    terms: null,
}

export const termsReducer = createReducer(initialState, (builder) => {
    builder.addCase(GetTermsSuccess, (state, action) => {
        state.terms = action.payload.terms
    })
})
