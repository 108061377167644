import { CommentHistoryDTO, ErrorDTO } from '../../dto'
import { store } from '../../redux'
import { CommentHistoryFailed, CommentHistorySuccess, ErrorMessage } from '../../redux/actions'
import { NetworkCall } from '../../utils'

export const commentHistory = async (userId: number | null, reset: boolean = false) => {
    const page = reset ? 1 : store.getState().user.commentHistory.pagination.page
    const pageSize = store.getState().user.commentHistory.pagination.pageSize

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')
    const id = userId ? userId : store.getState().login.user?.id

    return await new NetworkCall('GET', `/1.0.0/users/${id}/comment-history/?page=${page}&pageSize=${pageSize}`)
        .setHeader('Authorization', `Bearer ${token}`)
        .then(200, CommentHistoryDTO, (responseBody) => {
            store.dispatch(CommentHistorySuccess({ cars: responseBody.data, page: responseBody.page, pageSize: responseBody.pageSize, count: responseBody.count, reset: reset }))
        })
        .catch((response) => {
            store.dispatch(CommentHistoryFailed({}))

            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
