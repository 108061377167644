import { useTranslation } from 'react-i18next'
import { AddCarTabView } from './components/add-car-tab-view'
import { AddCarTabViewPage } from './components/add-car-tab-view/add-car-tabview-page'
import { ReservePriceTab, PhotosTab, EquipmentTab, AdditionalTab, SummaryTab, CarDetailsTab, DoneTab } from './components'
import { useEffect } from 'react'
import { UpdateAddCarTab } from '../../redux/actions'
import { useDispatch } from 'react-redux'
import './add-car.page.scss'

interface AddCarPageProps {}

export const AddCarPage = (props: AddCarPageProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        //dispatch(UpdateAddCarTab({ tab: 'done' }))
        dispatch(UpdateAddCarTab({ tab: 'cardetails' }))
    }, [])

    return (
        <main className="page page-add-car">
            <div className="main-div">
                <AddCarTabView tabs={{ cardetails: t('add_car_car_details_title'), photos: t('add_car_photos_video_title'), equipment: t('add_car_equipment_title'), additional: t('add_car_additional_information_title'), reserveprice: t('add_car_reserve_price_title'), summary: t('add_car_summary_title'), done: '' }}>
                    <AddCarTabViewPage key="cardetails">
                        <CarDetailsTab />
                    </AddCarTabViewPage>

                    <AddCarTabViewPage key="photos">
                        <PhotosTab />
                    </AddCarTabViewPage>

                    <AddCarTabViewPage key="equipment">
                        <EquipmentTab />
                    </AddCarTabViewPage>

                    <AddCarTabViewPage key="additional">
                        <AdditionalTab />
                    </AddCarTabViewPage>

                    <AddCarTabViewPage key="reserveprice">
                        <ReservePriceTab />
                    </AddCarTabViewPage>

                    <AddCarTabViewPage key="summary">
                        <SummaryTab />
                    </AddCarTabViewPage>

                    <AddCarTabViewPage key="done">
                        <DoneTab />
                    </AddCarTabViewPage>
                </AddCarTabView>
            </div>
        </main>
    )
}
