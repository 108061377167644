import { Expose } from 'class-transformer'
import { IsBoolean, IsDefined, IsNumber, IsOptional } from 'class-validator'

export class NotificationSettings {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsBoolean()
    oneDayBeforeAuctionEnds!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    oneHourBeforeAuctionEnds!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    newBids!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    replyToMyComment!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    questionsAreAnswered!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    auctionResults!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    reservePriceLowered!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    reservePriceDeleted!: boolean

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number
}
