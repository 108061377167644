import { store } from '../../redux'
import { CarDTO, ErrorDTO } from '../../dto'
import { ErrorMessage, ShowToast, UpdateCarSuccess } from '../../redux/actions'
import { NetworkCall } from '../../utils'

interface CreateCarProps {
    showToast: boolean
    onSuccess: () => void
}

export const createCar = async (props: CreateCarProps) => {
    const carId = store.getState().car.form.id

    const carDetail = {
        exteriorColour: store.getState().car.form.exteriorColour.value,
        interiorColour: store.getState().car.form.interiorColour.value,
        headUpDisplay: store.getState().car.form.headUpDisplay.value,
        laneAssist: store.getState().car.form.laneAssist.value,
        threeSixtydegreeCamera: store.getState().car.form.threeSixtydegreeCamera.value,
        parkingAssist: store.getState().car.form.parkingAssist.value,
        rearViewCamera: store.getState().car.form.rearViewCamera.value,
        autoParking: store.getState().car.form.autoParking.value,
        cruiseControl: store.getState().car.form.cruiseControl.value,
        adaptiveCruiseControl: store.getState().car.form.adaptiveCruiseControl.value,
        parkingSensors: store.getState().car.form.parkingSensors.value,
        centralDisplay: store.getState().car.form.centralDisplay.value,
        navigationSystem: store.getState().car.form.navigationSystem.value,
        appleCarplay: store.getState().car.form.appleCarplay.value,
        androidAuto: store.getState().car.form.androidAuto.value,
        bluetoothInterface: store.getState().car.form.bluetoothInterface.value,
        dabRadio: store.getState().car.form.dabRadio.value,
        wirelessPhoneCharger: store.getState().car.form.wirelessPhoneCharger.value,
        usbPorts: store.getState().car.form.usbPorts.value,
        soundSystem: store.getState().car.form.soundSystem.value,
        blindSpotMonitor: store.getState().car.form.blindSpotMonitor.value,
        abs: store.getState().car.form.abs.value,
        esc: store.getState().car.form.esc.value,
        tyrePressureMonitoringSystem: store.getState().car.form.tyrePressureMonitoringSystem.value,
        autoEmergencyBreak: store.getState().car.form.autoEmergencyBreak.value,
        driverAlertSystem: store.getState().car.form.driverAlertSystem.value,
        isofix: store.getState().car.form.isofix.value,
        nightVision: store.getState().car.form.nightVision.value,
        frontCollisionWarning: store.getState().car.form.frontCollisionWarning.value,
        crossTrafficWarning: store.getState().car.form.crossTrafficWarning.value,
        airbags: store.getState().car.form.airbags.value,
        heatPump: store.getState().car.form.heatPump.value,
        rainSensor: store.getState().car.form.rainSensor.value,
        electricTrunkOpening: store.getState().car.form.electricTrunkOpening.value,
        electricSideMirrors: store.getState().car.form.electricSideMirrors.value,
        heatedSideMirrors: store.getState().car.form.heatedSideMirrors.value,
        keylessEntry: store.getState().car.form.keylessEntry.value,
        heatedSteeringWheel: store.getState().car.form.heatedSteeringWheel.value,
        airConditioning: store.getState().car.form.airConditioning.value !== -1 ? store.getState().car.form.airConditioning.value : null,
        electricSeats: store.getState().car.form.electricSeats.value !== -1 ? store.getState().car.form.electricSeats.value : null,
        heatedSeats: store.getState().car.form.heatedSeats.value !== -1 ? store.getState().car.form.heatedSeats.value : null,
        cooledSeats: store.getState().car.form.cooledSeats.value !== -1 ? store.getState().car.form.cooledSeats.value : null,
        ledHeadLights: store.getState().car.form.ledHeadLights.value,
        ledRearLights: store.getState().car.form.ledRearLights.value,
        xenonHeadLights: store.getState().car.form.xenonHeadLights.value,
        xenonRearLights: store.getState().car.form.xenonRearLights.value,
        matrixHeadlights: store.getState().car.form.matrixHeadlights.value,
        corneringLights: store.getState().car.form.corneringLights.value,
        fogLights: store.getState().car.form.fogLights.value,
        ambientLighting: store.getState().car.form.ambientLighting.value,
        tintedWindows: store.getState().car.form.tintedWindows.value,
        skiRack: store.getState().car.form.skiRack.value,
        towBar: store.getState().car.form.towBar.value,
        slidingRoof: store.getState().car.form.slidingRoof.value,
        panoramicRoof: store.getState().car.form.panoramicRoof.value,
        sportSuspension: store.getState().car.form.sportSuspension.value,
        alloyWheels: store.getState().car.form.alloyWheels.value,
        sportSeats: store.getState().car.form.sportSeats.value,
        seatMaterial: store.getState().car.form.seatMaterial.value !== -1 ? store.getState().car.form.seatMaterial.value : null,
        otherEquipment: store.getState().car.form.otherEquipment.value,
        ownersTake: store.getState().car.form.ownersTake.value,
        ownershipHistory: store.getState().car.form.ownershipHistory.value,
        serviceHistoryAndRepairs: store.getState().car.form.serviceHistoryAndRepairs.value,
        otherItemsIncluded: store.getState().car.form.otherItemsIncluded.value,
        knownProblemsOrFlaws: store.getState().car.form.knownProblemsOrFlaws.value,
        otherInformation: store.getState().car.form.otherInformation.value,
        modifications: store.getState().car.form.modifications.value,
    }

    var mfkDate = null
    if (store.getState().car.form.mfkDateYear.value != null && store.getState().car.form.mfkDateYear.value.length !== 0) {
        mfkDate = new Date(Number(store.getState().car.form.mfkDateYear.value), Number(store.getState().car.form.mfkDateMonth.value)).getTime()
    }

    var warrantyDate = null
    if (store.getState().car.form.validWarrantyYear.value != null && store.getState().car.form.validWarrantyYear.value.length !== 0) {
        warrantyDate = new Date(Number(store.getState().car.form.validWarrantyYear.value), Number(store.getState().car.form.validWarrantyMonth.value)).getTime()
    }

    var mediaFileIds: number[] = []
    if (store.getState().car.form.mediaFiles.length !== 0) {
        store.getState().car.form.mediaFiles.forEach((mediaFile) => {
            mediaFileIds.push(mediaFile.id)
        })
    }

    var documentIds: number[] = []
    if (store.getState().car.form.documents.length !== 0) {
        store.getState().car.form.documents.forEach((document) => {
            documentIds.push(document.id)
        })
    }

    const requestBody = {
        country: store.getState().car.form.country.value.length === 0 ? 'Switzerland' : store.getState().car.form.country.value,
        city: store.getState().car.form.city.value,
        postalCode: Number(store.getState().car.form.postalCode.value),
        vin: store.getState().car.form.vin.value,
        variant: store.getState().car.form.variant.value,
        make: store.getState().car.form.make.value,
        model: store.getState().car.form.model.value,
        manufacturingYear: Number(store.getState().car.form.manufacturingYear.value),
        batteryCapacity: Number(store.getState().car.form.batteryCapacity.value.replace(',', '.')),
        manufacturerEstimatedRange: Number(store.getState().car.form.manufacturerEstimatedRange.value),
        horsepower: Number(store.getState().car.form.horsepower.value),
        mileage: Number(store.getState().car.form.mileage.value),
        batteryStatus: store.getState().car.form.batteryStatus.value,
        drivetrain: store.getState().car.form.drivetrain.value,
        chargingConnectorAc: store.getState().car.form.chargingConnectorAc.value,
        chargingConnectorDc: store.getState().car.form.chargingConnectorDc.value,
        chargingConnectorAcOtherName: store.getState().car.form.chargingConnectorAcOtherName.value,
        chargingConnectorDcOtherName: store.getState().car.form.chargingConnectorDcOtherName.value,
        maximumAcCharging: Number(store.getState().car.form.maximumAcCharging.value.replace(',', '.')),
        maximumDcCharging: Number(store.getState().car.form.maximumDcCharging.value.replace(',', '.')),
        mfkDate: mfkDate,
        validWarranty: warrantyDate,
        reservePrice: store.getState().car.form.reservePrice.value === '' ? null : store.getState().car.form.reservePrice.value.toString().includes('’') ? Number(store.getState().car.form.reservePrice.value.toString().replace('’', '')) : Number(store.getState().car.form.reservePrice.value.toString()),
        hasReservePrice: store.getState().car.form.hasReservePrice.value,
        mediaFileIds: mediaFileIds,
        documentIds: documentIds,
        carDetail: carDetail,
    }

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall(carId != null ? 'PATCH' : 'POST', `/1.0.0/cars` + (carId != null ? `/` + carId : ''), requestBody)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(carId != null ? 200 : 201, CarDTO, (responseBody) => {
            store.dispatch(UpdateCarSuccess({ car: responseBody.car }))

            props.onSuccess()

            if (props.showToast) {
                store.dispatch(ShowToast({ message: 'toast_save_draft_success' }))
            }
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
