export enum AirbagEnum {
    Driver = 0,
    Front = 1,
    SecondRow = 2,
    ThirdRow = 3,
    Knee = 4,
    Side = 5,
    Curtain = 6,
    RearCurtain = 7,
    Pedestrian = 8,
    SeatBelt = 9,
}
