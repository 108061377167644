import { useTranslation } from 'react-i18next'
import { Button } from '../../../../components'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ResetCarForm } from '../../../../redux/actions'
import './done.tab.scss'
import '../tab.scss'

interface DoneTabProps {}

export const DoneTab = (props: DoneTabProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    return (
        <main className="tab tab-done">
            <div className="tab-div">
                <div className="container-div">
                    <img alt="Info icon" className="info-img" src="/images/ic_check.svg" />

                    <div className="text-div">
                        <h1>{t('add_car_all_done_title')}</h1>

                        <h2>{t('add_car_your_car_is_in_review_title')}</h2>

                        <label className="body-text">{t('add_car_your_car_is_in_review_desc')}</label>
                    </div>
                    <div className={`bottom-div`}>
                        <Button
                            title={t('button_sell_another_car')}
                            onClick={async () => {
                                dispatch(ResetCarForm({}))
                                window.location.reload()
                            }}
                        />
                        <Button
                            type={'black'}
                            title={t('button_go_to_auctions')}
                            onClick={async () => {
                                navigate('/')
                            }}
                        />
                    </div>
                </div>
            </div>
        </main>
    )
}
