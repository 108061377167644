import { createReducer } from '@reduxjs/toolkit'
import { CarState } from '../../states'
import { AddDocuments, AddMediaFiles, GetCarFailed, GetCarSuccess, RemoveDocument, RemoveMediaFile, ResetCarForm, UpdateAuction, UpdateCarForm, UpdateCarSuccess, UpdateIdCarForm, UpdateMediaFilesAuction } from '../../actions'
import { BatteryStatusEnum, ChargingConnectorAcTypeEnum, ChargingConnectorDcTypeEnum, DrivetrainEnum } from '../../../enum'
import { GetMakeAndModelsSuccess, UpdateAddCarTab, UpdateDocumentsAuction } from '../../actions/car'

const initialState: CarState = {
    car: null,
    form: {
        id: null,
        mediaFiles: [],
        documents: [],
        country: { error: null, value: '' },
        postalCode: { error: null, value: '' },
        city: { error: null, value: '' },
        manufacturingYear: { error: null, value: '' },
        make: { error: null, value: '' },
        makeOtherEnabled: { error: null, value: false },
        model: { error: null, value: '' },
        modelOtherEnabled: { error: null, value: false },
        variant: { error: null, value: '' },
        vin: { error: null, value: '' },
        batteryCapacity: { error: null, value: '' },
        manufacturerEstimatedRange: { error: null, value: '' },
        horsepower: { error: null, value: '' },
        mileage: { error: null, value: '' },
        batteryStatus: { error: null, value: BatteryStatusEnum.Owned },
        drivetrain: { error: null, value: 0 },
        chargingConnectorAc: { error: null, value: -1 },
        chargingConnectorAcOtherName: { error: null, value: '' },
        chargingConnectorDc: { error: null, value: -1 },
        chargingConnectorDcOtherName: { error: null, value: '' },
        maximumAcCharging: { error: null, value: '' },
        maximumDcCharging: { error: null, value: '' },
        mfkDateYear: { error: null, value: '' },
        mfkDateMonth: { error: null, value: '' },

        validWarrantyYear: { error: null, value: '' },
        validWarrantyMonth: { error: null, value: '' },
        reservePrice: { error: null, value: '' },
        hasReservePrice: { error: null, value: false },
        hasReservePriceLocal: { error: null, value: -1 },

        exteriorColour: { error: null, value: '' },
        interiorColour: { error: null, value: '' },
        headUpDisplay: { error: null, value: false },
        laneAssist: { error: null, value: false },
        threeSixtydegreeCamera: { error: null, value: false },
        parkingAssist: { error: null, value: false },
        rearViewCamera: { error: null, value: false },
        autoParking: { error: null, value: false },
        cruiseControl: { error: null, value: false },
        adaptiveCruiseControl: { error: null, value: false },
        parkingSensors: { error: null, value: -1 },
        centralDisplay: { error: null, value: false },
        navigationSystem: { error: null, value: false },
        appleCarplay: { error: null, value: false },
        androidAuto: { error: null, value: false },
        bluetoothInterface: { error: null, value: false },
        dabRadio: { error: null, value: false },
        wirelessPhoneCharger: { error: null, value: false },
        usbPorts: { error: null, value: -1 },
        soundSystem: { error: null, value: false },
        blindSpotMonitor: { error: null, value: false },
        abs: { error: null, value: false },
        esc: { error: null, value: false },
        tyrePressureMonitoringSystem: { error: null, value: false },
        autoEmergencyBreak: { error: null, value: false },
        driverAlertSystem: { error: null, value: false },
        isofix: { error: null, value: false },
        nightVision: { error: null, value: false },
        frontCollisionWarning: { error: null, value: false },
        crossTrafficWarning: { error: null, value: false },
        airbags: { error: null, value: [] },
        heatPump: { error: null, value: false },
        rainSensor: { error: null, value: false },
        electricTrunkOpening: { error: null, value: false },
        electricSideMirrors: { error: null, value: false },
        heatedSideMirrors: { error: null, value: false },
        keylessEntry: { error: null, value: false },
        heatedSteeringWheel: { error: null, value: false },
        airConditioning: { error: null, value: -1 },
        electricSeats: { error: null, value: -1 },
        heatedSeats: { error: null, value: -1 },
        cooledSeats: { error: null, value: -1 },
        ledHeadLights: { error: null, value: false },
        ledRearLights: { error: null, value: false },
        xenonHeadLights: { error: null, value: false },
        xenonRearLights: { error: null, value: false },
        matrixHeadlights: { error: null, value: false },
        corneringLights: { error: null, value: false },
        fogLights: { error: null, value: false },
        ambientLighting: { error: null, value: false },
        tintedWindows: { error: null, value: false },
        skiRack: { error: null, value: false },
        towBar: { error: null, value: false },
        slidingRoof: { error: null, value: false },
        panoramicRoof: { error: null, value: false },
        sportSuspension: { error: null, value: false },
        alloyWheels: { error: null, value: false },
        sportSeats: { error: null, value: false },
        seatMaterial: { error: null, value: -1 },
        otherEquipment: { error: null, value: '' },
        ownersTake: { error: null, value: '' },
        ownershipHistory: { error: null, value: '' },
        serviceHistoryAndRepairs: { error: null, value: '' },
        otherItemsIncluded: { error: null, value: '' },
        modifications: { error: null, value: '' },
        knownProblemsOrFlaws: { error: null, value: '' },
        otherInformation: { error: null, value: '' },

        terms: { error: null, value: false },
    },

    makes: [],

    tab: {
        activeTab: '',
    },
}

export const carReducer = createReducer(initialState, (builder) => {
    builder.addCase(GetCarSuccess, (state, action) => {
        state.car = action.payload.car
    })
    builder.addCase(GetCarFailed, (state) => {})

    builder.addCase(UpdateAuction, (state, action) => {
        if (!action.payload.sameUserId) {
            action.payload.auction.isFirstBid = state.car?.auctions[0].isFirstBid
        }

        state.car?.auctions.pop()
        state.car?.auctions.push(action.payload.auction)
    })

    builder.addCase(UpdateCarForm, (state, action) => {
        ;(state.form[action.payload.key] as any) = {
            ...state.form[action.payload.key],
            value: action.payload.value,
            error: action.payload.error,
        }
    })
    builder.addCase(UpdateIdCarForm, (state, action) => {
        state.form.id = action.payload.id
    })

    builder.addCase(AddMediaFiles, (state, action) => {
        state.form.mediaFiles.push(...action.payload.mediaFiles)
    })
    builder.addCase(RemoveMediaFile, (state, action) => {
        state.form.mediaFiles = state.form.mediaFiles.filter((mediaFile) => mediaFile.id !== action.payload.mediaFileId)
    })

    builder.addCase(GetMakeAndModelsSuccess, (state, action) => {
        state.makes = action.payload.makes
    })

    builder.addCase(AddDocuments, (state, action) => {
        state.form.documents.push(...action.payload.documents)
    })
    builder.addCase(RemoveDocument, (state, action) => {
        state.form.documents = state.form.documents.filter((document) => document.id !== action.payload.documentId)
    })

    builder.addCase(UpdateCarSuccess, (state, action) => {
        state.form.id = action.payload.car.id
        state.form.vin.value = action.payload.car.vin
        state.form.variant.value = action.payload.car.variant ?? ''
        state.form.make.value = action.payload.car.make
        state.form.model.value = action.payload.car.model
        state.form.manufacturingYear.value = '' + action.payload.car.manufacturingYear
        state.form.batteryCapacity.value = ('' + action.payload.car.batteryCapacity).replace('.', ',')
        state.form.manufacturerEstimatedRange.value = '' + action.payload.car.manufacturerEstimatedRange
        state.form.horsepower.value = '' + action.payload.car.horsepower
        state.form.mileage.value = '' + action.payload.car.mileage
        state.form.batteryStatus.value = action.payload.car.batteryStatus ?? BatteryStatusEnum.Owned
        state.form.drivetrain.value = action.payload.car.drivetrain ?? DrivetrainEnum.AWD
        state.form.chargingConnectorAc.value = action.payload.car.chargingConnectorAc ?? ChargingConnectorAcTypeEnum.Type1
        state.form.chargingConnectorDc.value = action.payload.car.chargingConnectorDc ?? ChargingConnectorDcTypeEnum.Ccs
        state.form.chargingConnectorAcOtherName.value = action.payload.car.chargingConnectorAcOtherName ?? ''
        state.form.chargingConnectorDcOtherName.value = action.payload.car.chargingConnectorDcOtherName ?? ''
        state.form.maximumAcCharging.value = ('' + action.payload.car.maximumAcCharging).replace('.', ',')
        state.form.maximumDcCharging.value = ('' + action.payload.car.maximumDcCharging).replace('.', ',')

        if (action.payload.car.mfkDate != null) {
            const mfkDate = new Date(action.payload.car.mfkDate)

            state.form.mfkDateYear.value = '' + mfkDate.getUTCFullYear()
            state.form.mfkDateMonth.value = '' + (mfkDate.getUTCMonth() + 1)
        } else {
            state.form.mfkDateYear.value = ''
            state.form.mfkDateMonth.value = ''
        }

        if (action.payload.car.validWarranty != null) {
            const validWarrantyDate = new Date(action.payload.car.validWarranty)

            state.form.validWarrantyYear.value = '' + validWarrantyDate.getUTCFullYear()
            state.form.validWarrantyMonth.value = '' + (validWarrantyDate.getUTCMonth() + 1)
        }

        state.form.reservePrice.value = action.payload.car.reservePrice ? '' + action.payload.car.reservePrice.toLocaleString('de-CH', { minimumFractionDigits: 0 }) : ''
        state.form.hasReservePrice.value = action.payload.car.reservePrice != null

        state.form.postalCode.value = '' + action.payload.car.address?.postalCode
        state.form.country.value = action.payload.car.address?.country ?? 'Switzerland'
        state.form.city.value = action.payload.car.address?.city ?? ''

        state.form.exteriorColour.value = action.payload.car.carDetail?.exteriorColour ?? ''
        state.form.interiorColour.value = action.payload.car.carDetail?.interiorColour ?? ''
        state.form.headUpDisplay.value = action.payload.car.carDetail?.headUpDisplay ?? false
        state.form.laneAssist.value = action.payload.car.carDetail?.laneAssist ?? false
        state.form.threeSixtydegreeCamera.value = action.payload.car.carDetail?.threeSixtydegreeCamera ?? false
        state.form.parkingAssist.value = action.payload.car.carDetail?.parkingAssist ?? false
        state.form.rearViewCamera.value = action.payload.car.carDetail?.rearViewCamera ?? false
        state.form.autoParking.value = action.payload.car.carDetail?.autoParking ?? false
        state.form.cruiseControl.value = action.payload.car.carDetail?.cruiseControl ?? false
        state.form.adaptiveCruiseControl.value = action.payload.car.carDetail?.adaptiveCruiseControl ?? false
        state.form.parkingSensors.value = action.payload.car.carDetail?.parkingSensors ?? -1

        state.form.centralDisplay.value = action.payload.car.carDetail?.centralDisplay ?? false
        state.form.navigationSystem.value = action.payload.car.carDetail?.navigationSystem ?? false
        state.form.appleCarplay.value = action.payload.car.carDetail?.appleCarplay ?? false
        state.form.androidAuto.value = action.payload.car.carDetail?.androidAuto ?? false
        state.form.bluetoothInterface.value = action.payload.car.carDetail?.bluetoothInterface ?? false
        state.form.dabRadio.value = action.payload.car.carDetail?.dabRadio ?? false
        state.form.wirelessPhoneCharger.value = action.payload.car.carDetail?.wirelessPhoneCharger ?? false
        state.form.usbPorts.value = action.payload.car.carDetail?.usbPorts ?? -1
        state.form.soundSystem.value = action.payload.car.carDetail?.soundSystem ?? false
        state.form.blindSpotMonitor.value = action.payload.car.carDetail?.blindSpotMonitor ?? false
        state.form.abs.value = action.payload.car.carDetail?.abs ?? false
        state.form.esc.value = action.payload.car.carDetail?.esc ?? false
        state.form.tyrePressureMonitoringSystem.value = action.payload.car.carDetail?.tyrePressureMonitoringSystem ?? false
        state.form.autoEmergencyBreak.value = action.payload.car.carDetail?.autoEmergencyBreak ?? false
        state.form.driverAlertSystem.value = action.payload.car.carDetail?.driverAlertSystem ?? false
        state.form.isofix.value = action.payload.car.carDetail?.isofix ?? false
        state.form.nightVision.value = action.payload.car.carDetail?.nightVision ?? false
        state.form.frontCollisionWarning.value = action.payload.car.carDetail?.frontCollisionWarning ?? false
        state.form.crossTrafficWarning.value = action.payload.car.carDetail?.crossTrafficWarning ?? false
        state.form.airbags.value = action.payload.car.carDetail?.airbags ?? []
        state.form.heatPump.value = action.payload.car.carDetail?.heatPump ?? false
        state.form.rainSensor.value = action.payload.car.carDetail?.rainSensor ?? false
        state.form.electricTrunkOpening.value = action.payload.car.carDetail?.electricTrunkOpening ?? false
        state.form.electricSideMirrors.value = action.payload.car.carDetail?.electricSideMirrors ?? false
        state.form.heatedSideMirrors.value = action.payload.car.carDetail?.heatedSideMirrors ?? false
        state.form.keylessEntry.value = action.payload.car.carDetail?.keylessEntry ?? false
        state.form.heatedSteeringWheel.value = action.payload.car.carDetail?.heatedSteeringWheel ?? false
        state.form.airConditioning.value = action.payload.car.carDetail?.airConditioning ?? -1
        state.form.electricSeats.value = action.payload.car.carDetail?.electricSeats ?? -1
        state.form.heatedSeats.value = action.payload.car.carDetail?.heatedSeats ?? -1
        state.form.cooledSeats.value = action.payload.car.carDetail?.cooledSeats ?? -1
        state.form.ledHeadLights.value = action.payload.car.carDetail?.ledHeadLights ?? false
        state.form.ledRearLights.value = action.payload.car.carDetail?.ledRearLights ?? false
        state.form.xenonHeadLights.value = action.payload.car.carDetail?.xenonHeadLights ?? false
        state.form.xenonRearLights.value = action.payload.car.carDetail?.xenonRearLights ?? false
        state.form.matrixHeadlights.value = action.payload.car.carDetail?.matrixHeadlights ?? false
        state.form.corneringLights.value = action.payload.car.carDetail?.corneringLights ?? false
        state.form.fogLights.value = action.payload.car.carDetail?.fogLights ?? false
        state.form.ambientLighting.value = action.payload.car.carDetail?.ambientLighting ?? false
        state.form.tintedWindows.value = action.payload.car.carDetail?.tintedWindows ?? false
        state.form.skiRack.value = action.payload.car.carDetail?.skiRack ?? false
        state.form.towBar.value = action.payload.car.carDetail?.towBar ?? false
        state.form.slidingRoof.value = action.payload.car.carDetail?.slidingRoof ?? false
        state.form.panoramicRoof.value = action.payload.car.carDetail?.panoramicRoof ?? false
        state.form.sportSuspension.value = action.payload.car.carDetail?.sportSuspension ?? false
        state.form.alloyWheels.value = action.payload.car.carDetail?.alloyWheels ?? false
        state.form.sportSeats.value = action.payload.car.carDetail?.sportSeats ?? false
        state.form.seatMaterial.value = action.payload.car.carDetail?.seatMaterial ?? -1
        state.form.otherEquipment.value = action.payload.car.carDetail?.otherEquipment ?? ''
        state.form.ownersTake.value = action.payload.car.carDetail?.ownersTake ?? ''
        state.form.ownershipHistory.value = action.payload.car.carDetail?.ownershipHistory ?? ''
        state.form.serviceHistoryAndRepairs.value = action.payload.car.carDetail?.serviceHistoryAndRepairs ?? ''
        state.form.otherItemsIncluded.value = action.payload.car.carDetail?.otherItemsIncluded ?? ''
        state.form.modifications.value = action.payload.car.carDetail?.modifications ?? ''
        state.form.knownProblemsOrFlaws.value = action.payload.car.carDetail?.knownProblemsOrFlaws ?? ''
        state.form.otherInformation.value = action.payload.car.carDetail?.otherInformation ?? ''

        state.form.documents = action.payload.car.carDetail?.documents ?? []
        state.form.mediaFiles = action.payload.car.mediaFiles ?? []
    })
    builder.addCase(UpdateMediaFilesAuction, (state, action) => {
        state.form.mediaFiles = action.payload.mediaFiles
    })

    builder.addCase(UpdateDocumentsAuction, (state, action) => {
        state.form.documents = action.payload.documents
    })

    builder.addCase(ResetCarForm, (state, action) => {
        state.form.id = null
        state.form.vin.value = ''
        state.form.variant.value = ''
        state.form.make.value = ''
        state.form.model.value = ''
        state.form.manufacturingYear.value = ''
        state.form.batteryCapacity.value = ''
        state.form.manufacturerEstimatedRange.value = ''
        state.form.horsepower.value = ''
        state.form.mileage.value = ''
        state.form.batteryStatus.value = BatteryStatusEnum.Owned
        state.form.drivetrain.value = DrivetrainEnum.AWD
        state.form.chargingConnectorAc.value = -1
        state.form.chargingConnectorDc.value = -1
        state.form.chargingConnectorAcOtherName.value = ''
        state.form.chargingConnectorDcOtherName.value = ''
        state.form.maximumAcCharging.value = ''
        state.form.maximumDcCharging.value = ''
        state.form.mfkDateYear.value = ''
        state.form.mfkDateMonth.value = ''

        state.form.validWarrantyYear.value = ''
        state.form.validWarrantyMonth.value = ''

        state.form.reservePrice.value = ''
        state.form.hasReservePrice.value = false
        state.form.hasReservePriceLocal.value = -1

        state.form.postalCode.value = ''
        state.form.country.value = 'Switzerland'
        state.form.city.value = ''

        state.form.exteriorColour.value = ''
        state.form.interiorColour.value = ''
        state.form.headUpDisplay.value = false
        state.form.laneAssist.value = false
        state.form.threeSixtydegreeCamera.value = false
        state.form.parkingAssist.value = false
        state.form.rearViewCamera.value = false
        state.form.autoParking.value = false
        state.form.cruiseControl.value = false
        state.form.adaptiveCruiseControl.value = false
        state.form.parkingSensors.value = -1

        state.form.centralDisplay.value = false
        state.form.navigationSystem.value = false
        state.form.appleCarplay.value = false
        state.form.androidAuto.value = false
        state.form.bluetoothInterface.value = false
        state.form.dabRadio.value = false
        state.form.wirelessPhoneCharger.value = false
        state.form.usbPorts.value = -1
        state.form.soundSystem.value = false
        state.form.blindSpotMonitor.value = false
        state.form.abs.value = false
        state.form.esc.value = false
        state.form.tyrePressureMonitoringSystem.value = false
        state.form.autoEmergencyBreak.value = false
        state.form.driverAlertSystem.value = false
        state.form.isofix.value = false
        state.form.nightVision.value = false
        state.form.frontCollisionWarning.value = false
        state.form.crossTrafficWarning.value = false
        state.form.airbags.value = []
        state.form.heatPump.value = false
        state.form.rainSensor.value = false
        state.form.electricTrunkOpening.value = false
        state.form.electricSideMirrors.value = false
        state.form.heatedSideMirrors.value = false
        state.form.keylessEntry.value = false
        state.form.heatedSteeringWheel.value = false
        state.form.airConditioning.value = -1
        state.form.electricSeats.value = -1
        state.form.heatedSeats.value = -1
        state.form.cooledSeats.value = -1
        state.form.ledHeadLights.value = false
        state.form.ledRearLights.value = false
        state.form.xenonHeadLights.value = false
        state.form.xenonRearLights.value = false
        state.form.matrixHeadlights.value = false
        state.form.corneringLights.value = false
        state.form.fogLights.value = false
        state.form.ambientLighting.value = false
        state.form.tintedWindows.value = false
        state.form.skiRack.value = false
        state.form.towBar.value = false
        state.form.slidingRoof.value = false
        state.form.panoramicRoof.value = false
        state.form.sportSuspension.value = false
        state.form.alloyWheels.value = false
        state.form.sportSeats.value = false
        state.form.seatMaterial.value = -1

        state.form.otherEquipment.value = ''
        state.form.ownersTake.value = ''
        state.form.ownershipHistory.value = ''
        state.form.serviceHistoryAndRepairs.value = ''
        state.form.otherItemsIncluded.value = ''
        state.form.modifications.value = ''
        state.form.knownProblemsOrFlaws.value = ''
        state.form.otherInformation.value = ''

        state.form.documents = []
        state.form.mediaFiles = []
        state.form.terms.value = false
    })

    builder.addCase(UpdateAddCarTab, (state, action) => {
        state.tab.activeTab = action.payload.tab
    })
})
