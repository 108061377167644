import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../redux'
import { CarsAuctionedElement } from './components/cars-auctioned-element'
import { CarStatusEnum } from '../../../../enum'
import { CarsAuctionedPagination } from '../../../../redux/actions'
import { getCarsAuctioned } from '../../../../api'
import { LoadMoreButton } from '../../../../components'
import { CarsDraftElement } from './components/cars-draft-element'
import { CarsReservePriceNotMetElement } from './components/cars-reserve-price-not-met-element'
import './cars-auctioned.tab.scss'

interface CarsAuctionedTabProps {}

export const CarsAuctionedTab = (props: CarsAuctionedTabProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { cars, pagination } = useAppSelector((state) => state.user.carsAuctioned)

    const onLoadMoreClick = async () => {
        dispatch(CarsAuctionedPagination({}))
        await getCarsAuctioned()
    }

    return (
        <main className="tab tab-cars-auctioned">
            <div className="tab-div">
                <div className="inner-container-div">
                    <label className={`title`}>{t('my_ev_garage_drafts_title') + ' (' + pagination.draftCount + ')'}</label>

                    <ul className={`car-list ${pagination.draftCount > 0 ? `` : `hidden`}`}>
                        <div className={`divider`} />

                        {cars
                            .filter((car) => car.status === CarStatusEnum.Draft || car.status === CarStatusEnum.Pending)
                            .map((car, index) => (
                                <CarsDraftElement car={car} />
                            ))}
                    </ul>
                </div>

                <div className="inner-container-div">
                    <label className={`title`}>{t('my_ev_garage_reserve_price_not_met_title') + ' (' + pagination.notMetCount + ')'}</label>

                    <ul className={`car-list ${pagination.notMetCount > 0 ? `` : `hidden`}`}>
                        <div className={`divider`} />

                        {cars
                            .filter((car) => car.status === CarStatusEnum.ReservePriceNotMetSeller || car.status === CarStatusEnum.ReservePriceNotMetBuyer)
                            .map((car, index) => (
                                <CarsReservePriceNotMetElement car={car} />
                            ))}
                    </ul>
                </div>

                <div className="inner-container-div">
                    <label className={`title`}>{t('my_ev_garage_cars_auctioned_title') + ' (' + pagination.approvedCount + ')'}</label>

                    <ul className={`car-list ${pagination.approvedCount > 0 ? `` : `hidden`}`}>
                        <div className={`divider`} />
                        {cars
                            .filter((car) => car.status === CarStatusEnum.Approved)
                            .map((car, index) => (
                                <CarsAuctionedElement car={car} />
                            ))}
                    </ul>
                </div>

                <div className={`bottom-div ${pagination.approvedCount + pagination.draftCount + pagination.notMetCount > 12 ? `` : `hidden`}`}>
                    <LoadMoreButton onClick={onLoadMoreClick} />
                </div>
            </div>
        </main>
    )
}
