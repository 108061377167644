import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Document } from '../../../../../entity'
import { deleteDocument } from '../../../../../api'
import { RemoveDocument } from '../../../../../redux/actions'
import './document-item.scss'

interface DocumentItemProps {
    document: Document
}

export const DocumentItem = (props: DocumentItemProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const onRemoveFileClick = async () => {
        await deleteDocument({ id: props.document.id, onSuccess() {
            dispatch(RemoveDocument({ documentId: props.document.id }))
        } })
    }

    return (
        <div key={props.document.id} className="document-item">
            <img alt="Document icon" className="document-img" src={`/images/ic_document.svg`} />
            <label>{props.document.name}</label>
            <img alt="Delete icon" className={`delete-img`} src={`/images/ic_delete.svg`} onClick={() => onRemoveFileClick()} />
        </div>
    )
}
