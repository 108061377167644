import { useState } from 'react'
import { Button } from '../../../../components'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../../redux'
import { useDispatch } from 'react-redux'
import { ErrorMessage, ResetCarForm, UpdateAddCarTab, UpdateCarForm } from '../../../../redux/actions'
import { approvalCar, createCar } from '../../../../api'
import { NoReservePriceModal } from '../../../../components/modals'
import './add-car-tabview.scss'

interface AddCarTabViewwProps {
    children: JSX.Element | JSX.Element[]
    tabs: { [key: string]: string }
}

export const AddCarTabView = (props: AddCarTabViewwProps) => {
    const dispatch = useDispatch()

    const children = Array.isArray(props.children) ? props.children : [props.children]
    const { t } = useTranslation()

    const { form } = useAppSelector((state) => state.car)
    const { activeTab } = useAppSelector((state) => state.car.tab)

    const [carDetailsTabValid, setCarDetailsTabValid] = useState(false)
    const [photosTabValid, setPhotosTabValid] = useState(false)
    const [equipmentTabValid, setEquipmentTabValid] = useState(false)
    const [additionalTabValid, setAdditionalTabValid] = useState(false)
    const [reservePriceTabValid, setReservePriceTabValid] = useState(false)
    const [summaryTabValid, setSummaryTabValid] = useState(false)

    const [noReservePriceModalVisible, setNoReservePriceModalVisible] = useState(false)

    const isTabValid = (index: number) => {
        switch (index) {
            case 0: {
                return carDetailsTabValid
            }
            case 1: {
                return photosTabValid
            }
            case 2: {
                return equipmentTabValid
            }
            case 3: {
                return additionalTabValid
            }
            case 4: {
                return reservePriceTabValid
            }
            case 5: {
                return summaryTabValid
            }
            default: {
                return false
            }
        }
    }

    const checkCarDetailValidity = () => {
        setCarDetailsTabValid(false)

        if (form.postalCode.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'postalCode', error: t('error_my_profile_postal_code_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })

            return false
        }

        if (form.city.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'city', error: t('error_my_profile_city_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })
            return false
        }

        if (form.manufacturingYear.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'manufacturingYear', error: t('error_add_car_manufacturing_year_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })

            return false
        }

        if (form.make.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'make', error: t('error_add_car_make_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })
            return false
        }

        if (form.model.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'model', error: t('error_add_car_model_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })
            return false
        }

        if (form.batteryCapacity.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'batteryCapacity', error: t('error_add_car_battery_capacity_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })
            return false
        }

        if (form.manufacturerEstimatedRange.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'manufacturerEstimatedRange', error: t('error_add_car_estimated_range_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })
            return false
        }

        if (form.horsepower.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'horsepower', error: t('error_add_car_horsepower_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })
            return false
        }

        if (form.mileage.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'mileage', error: t('error_add_car_mileage_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })
            return false
        }

        if (form.drivetrain.value === -1) {
            dispatch(UpdateCarForm({ key: 'drivetrain', error: t('error_add_car_drivetrain_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })
            return false
        }

        if (form.chargingConnectorAc.value === -1) {
            dispatch(UpdateCarForm({ key: 'chargingConnectorAcOtherName', error: t('error_add_charging_connector_ac_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })
            return false
        }

        if (form.chargingConnectorDc.value === -1) {
            dispatch(UpdateCarForm({ key: 'chargingConnectorDcOtherName', error: t('error_add_charging_connector_dc_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })
            return false
        }

        if (form.maximumAcCharging.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'maximumAcCharging', error: t('error_add_car_maximum_ac_charging_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })
            return false
        }

        if (form.maximumDcCharging.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'maximumDcCharging', error: t('error_add_car_maximum_dc_charging_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })
            return false
        }

        if (form.mfkDateYear.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'mfkDateYear', error: t('error_add_car_mfk_date_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })
            return false
        }

        if (form.mfkDateMonth.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'mfkDateMonth', error: t('error_add_car_mfk_date_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })
            return false
        }

        setCarDetailsTabValid(true)

        return true
    }

    const checkPhotosValidity = () => {
        setPhotosTabValid(false)

        if (form.mediaFiles.length === 0 || form.mediaFiles.length < 10) {
            dispatch(ErrorMessage({ title: '', message: 'Please upload minimum 10 images', code: 600006 }))
            document.documentElement.scrollTo({ top: 0, left: 0 })

            return false
        }

        setPhotosTabValid(true)

        return true
    }

    const checkEquipmentValidity = () => {
        setEquipmentTabValid(false)

        if (form.exteriorColour.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'exteriorColour', error: t('error_add_car_color_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })

            return false
        }

        if (form.interiorColour.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'interiorColour', error: t('error_add_car_color_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })

            return false
        }

        setEquipmentTabValid(true)

        return true
    }

    const checkAdditionalValidity = () => {
        setAdditionalTabValid(false)

        if (form.ownersTake.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'ownersTake', error: t('error_add_car_owners_take_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })

            return false
        }

        if (form.ownershipHistory.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'ownershipHistory', error: t('error_add_car_owners_history_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })

            return false
        }

        if (form.serviceHistoryAndRepairs.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'serviceHistoryAndRepairs', error: t('error_add_car_service_history_and_repairs_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })

            return false
        }

        if (form.knownProblemsOrFlaws.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'knownProblemsOrFlaws', error: t('error_add_car_known_problems_or_flaws_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })

            return false
        }

        setAdditionalTabValid(true)

        return true
    }

    const checkReservePriceValidity = () => {
        setReservePriceTabValid(false)

        if (form.hasReservePriceLocal.value === -1) {
            dispatch(UpdateCarForm({ key: 'reservePrice', error: t('error_add_car_reserve_price_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })

            return false
        }

        if (form.hasReservePriceLocal.value !== -1 && form.hasReservePriceLocal.value && form.reservePrice.value.length === 0) {
            dispatch(UpdateCarForm({ key: 'reservePrice', error: t('error_add_car_reserve_price_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })

            return false
        }

        setReservePriceTabValid(true)

        return true
    }

    const checkSummaryValidity = () => {
        setSummaryTabValid(false)

        if (!form.terms.value) {
            dispatch(UpdateCarForm({ key: 'terms', error: t('error_signup_terms'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })

            return false
        }

        setSummaryTabValid(true)

        return true
    }

    const saveDraft = async () => {
        await createCar({ showToast: true, onSuccess() {} })
    }

    const submitForApproval = async () => {
        await createCar({
            showToast: false,
            onSuccess() {
                approvalCar({
                    onSuccess() {
                        dispatch(ResetCarForm({}))

                        dispatch(UpdateAddCarTab({ tab: Object.keys(props.tabs)[6] }))
                    },
                })
            },
        })
    }

    const _getWideClassName = () => (Object.keys(props.tabs).indexOf(activeTab) === 5 || Object.keys(props.tabs).indexOf(activeTab) === 6 ? `wide` : ``)

    const _getDoneClassName = () => (Object.keys(props.tabs).indexOf(activeTab) === 6 ? `done` : ``)

    return (
        <div className="add-car-tab-view">
            <div className={`top-div ${_getWideClassName()}`}>
                <div className={`add-car-tab-view-pages ${_getWideClassName()}`}>{children.filter((child: JSX.Element) => child.key === activeTab)}</div>

                <div className={`progress-div ${_getWideClassName()}`}>
                    {Object.keys(props.tabs).map((key: string, index: number) =>
                        index < 6 ? (
                            <div
                                className={`tab${activeTab === key ? ` active` : ``}`}
                                key={key}
                                onClick={() => {
                                    if (isTabValid(index)) {
                                        dispatch(UpdateAddCarTab({ tab: key }))
                                    }
                                }}
                            >
                                <div
                                    className={`indicator-div`}
                                    key={key}
                                    onClick={() => {
                                        if (isTabValid(index)) {
                                            dispatch(UpdateAddCarTab({ tab: key }))
                                        }
                                    }}
                                >
                                    <div className={`indicator ${isTabValid(index) ? `valid` : ``}`}>
                                        <label className={`number ${isTabValid(index) && activeTab !== key ? `hidden` : ``}`}>{index + 1}</label>
                                        <img className={`check-img ${isTabValid(index) && activeTab !== key ? `` : `hidden`}`} alt="Check icon" src="/images/ic_check_white.svg" />
                                    </div>
                                    <div className={`line ${index === Object.keys(props.tabs).length - 2 ? `hidden` : ``}`} />
                                </div>

                                <label className={`title`}>{props.tabs[key]}</label>
                            </div>
                        ) : null
                    )}
                </div>

                <div className="info-div">
                    <img alt="Info icon" className="info-img" src="/images/ic_info_primary.svg" />
                    <label className={`small-text title`}>{t('add_car_first_description')}</label>
                    <label className={`small-text`}>{t('add_car_second_description')}</label>
                </div>
            </div>

            <div className={`bottom-div ${_getWideClassName()} ${_getDoneClassName()}`}>
                <button
                    className={`button button--black ${activeTab === 'cardetails' ? `hidden` : ``}`}
                    onClick={() => {
                        const currentIndex = Object.keys(props.tabs).indexOf(activeTab)
                        if (currentIndex > 0) {
                            dispatch(UpdateAddCarTab({ tab: Object.keys(props.tabs)[currentIndex - 1] }))
                            // @ts-ignore
                            document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' })
                        }
                    }}
                >
                    <img alt="Arrow icon" className={`button__arrow-img`} src={`/images/ic_arrow_left.svg`} />
                    {t('button_back')}
                </button>

                <button
                    className={`button button--white draft-button`}
                    onClick={(e) => {
                        const currentIndex = Object.keys(props.tabs).indexOf(activeTab)

                        switch (currentIndex) {
                            case 0: {
                                if (checkCarDetailValidity()) {
                                    saveDraft()
                                }
                                break
                            }
                            case 1: {
                                if (checkPhotosValidity()) {
                                    saveDraft()
                                }
                                break
                            }
                            case 2: {
                                if (checkEquipmentValidity()) {
                                    saveDraft()
                                }
                                break
                            }
                            case 3: {
                                if (checkAdditionalValidity()) {
                                    saveDraft()
                                }
                                break
                            }
                            case 4: {
                                if (checkReservePriceValidity()) {
                                    saveDraft()
                                }
                                break
                            }
                            case 5: {
                                saveDraft()

                                break
                            }
                            default: {
                                break
                            }
                        }
                    }}
                >
                    {t('button_save_draft')}
                    <img alt="Save icon" className={`button__arrow-img`} src={`/images/ic_save.svg`} />
                </button>

                <Button
                    title={Object.keys(props.tabs).indexOf(activeTab) === 4 ? t('button_view_summary') : Object.keys(props.tabs).indexOf(activeTab) === 5 ? t('button_submint_for_approval') : t('button_next')}
                    onClick={async () => {
                        const currentIndex = Object.keys(props.tabs).indexOf(activeTab)

                        if (currentIndex + 1 <= Object.keys(props.tabs).length) {
                            switch (currentIndex) {
                                case 0: {
                                    if (checkCarDetailValidity()) {
                                        dispatch(UpdateAddCarTab({ tab: Object.keys(props.tabs)[currentIndex + 1] }))
                                        // @ts-ignore
                                        document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' })
                                    }
                                    break
                                }
                                case 1: {
                                    if (checkPhotosValidity()) {
                                        dispatch(UpdateAddCarTab({ tab: Object.keys(props.tabs)[currentIndex + 1] }))
                                        // @ts-ignore
                                        document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' })
                                    }
                                    break
                                }
                                case 2: {
                                    if (checkEquipmentValidity()) {
                                        dispatch(UpdateAddCarTab({ tab: Object.keys(props.tabs)[currentIndex + 1] }))
                                        // @ts-ignore
                                        document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' })
                                    }
                                    break
                                }
                                case 3: {
                                    if (checkAdditionalValidity()) {
                                        dispatch(UpdateAddCarTab({ tab: Object.keys(props.tabs)[currentIndex + 1] }))
                                        // @ts-ignore
                                        document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' })
                                    }
                                    break
                                }
                                case 4: {
                                    if (checkReservePriceValidity()) {
                                        if (form.reservePrice.value === '' && !form.hasReservePriceLocal.value) {
                                            setNoReservePriceModalVisible(!noReservePriceModalVisible)
                                        } else {
                                            dispatch(UpdateAddCarTab({ tab: Object.keys(props.tabs)[currentIndex + 1] }))
                                            // @ts-ignore
                                            document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' })
                                        }
                                    }
                                    break
                                }
                                case 5: {
                                    if (checkSummaryValidity()) {
                                        submitForApproval()
                                    }
                                    break
                                }
                                default: {
                                    dispatch(UpdateAddCarTab({ tab: Object.keys(props.tabs)[currentIndex + 1] }))
                                    // @ts-ignore
                                    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' })

                                    break
                                }
                            }
                        }
                    }}
                />
            </div>

            {noReservePriceModalVisible ? (
                <NoReservePriceModal
                    onClose={() => {
                        setNoReservePriceModalVisible(!noReservePriceModalVisible)
                    }}
                    onOk={() => {
                        dispatch(UpdateAddCarTab({ tab: Object.keys(props.tabs)[5] }))

                        setNoReservePriceModalVisible(!noReservePriceModalVisible)
                    }}
                />
            ) : null}
        </div>
    )
}
