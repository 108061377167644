import { useTranslation } from 'react-i18next'
import { TabView } from '../../components'
import { UserSideMenu } from '../my-profile/components/user-side-menu/user-side-menu'
import { TabViewPage } from '../../components/tabview/tabview-page'
import { CarsAuctionedTab } from './components/car-auctioned-tab'
import { CarsSoldTab } from './components/cars-sold-tab'
import { CarsBoughtTab } from './components/cars-bought-tab/cars-bought.tab'
import './my-ev-garage.page.scss'

interface MyEvGaragePageProps {}

export const MyEvGaragePage = (props: MyEvGaragePageProps) => {
    const { t } = useTranslation()

    return (
        <main className="page page-my-ev-garage">
            <div className="main-div">
                <UserSideMenu />
                <div className="my-ev-garage-div">
                    <h2>{t('header_profile_my_ev_garage_button')} </h2>

                    <div className="container-div">
                        <TabView tabs={{ auctioned: t('my_ev_garage_cars_auctioned_title'), sold: t('my_ev_garage_cars_sold_title'), bought: t('my_ev_garage_cars_bought_bid_on_title') }}>
                            <TabViewPage key="auctioned">
                                <CarsAuctionedTab />
                            </TabViewPage>

                            <TabViewPage key="sold">
                                <CarsSoldTab />
                            </TabViewPage>

                            <TabViewPage key="bought">
                                <CarsBoughtTab />
                            </TabViewPage>
                        </TabView>
                    </div>
                </div>
            </div>
        </main>
    )
}
