import { createReducer } from '@reduxjs/toolkit'
import { ContactUsState } from '../../states'
import { GetContactInfoSuccess } from '../../actions'

const initialState: ContactUsState = {
    contactInfo: null,
}

export const contactUsReducer = createReducer(initialState, (builder) => {
    builder.addCase(GetContactInfoSuccess, (state, action) => {
        state.contactInfo = action.payload.contactInfo
    })
})
