import { useTranslation } from 'react-i18next'
import { Car } from '../../../../../entity'
import { Blurhash } from 'react-blurhash'
import { useState } from 'react'
import { Button } from '../../../../../components'
import { getCarsAuctioned, getCarsBought, getCarsSold, reservePriceNotMet } from '../../../../../api'
import { CarStatusEnum } from '../../../../../enum'
import './cars-reserve-price-not-met-element.scss'

interface CarsReservePriceNotMetElementProps {
    car: Car
}

export const CarsReservePriceNotMetElement = (props: CarsReservePriceNotMetElementProps) => {
    const { t } = useTranslation()
    const [imageLoaded, setImageLoaded] = useState(false)

    const onReservePriceNotMetClick = async (accept: boolean) => {
        await reservePriceNotMet({
            id: props.car.auctions[0].id,
            accept: accept,
            onSuccess() {
                if (accept) {
                    getCarsSold(true)
                } else {
                    getCarsAuctioned(true)
                }
                getCarsBought(true)
            },
        })
    }

    return (
        <li key={props.car.id} className={`cars-reserve-price-not-met-element`}>
            <div className="cars-reserve-price-not-met-element__image-div">
                {props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 && <Blurhash className={`blurhash-img ${imageLoaded ? `hidden` : ``}`} hash={props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 ? props.car.mediaFiles[0].blurHash : ''} width={'100%'} height={'100%'} resolutionX={32} resolutionY={32} punch={1} />}

                <img
                    alt="Car"
                    className="car-img"
                    src={props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 ? props.car.mediaFiles[0].thumbnail : '/images/ic_no_image.svg'}
                    onLoad={() => {
                        setImageLoaded(true)
                    }}
                />
            </div>

            <div className="cars-reserve-price-not-met-element__information-div">
                <div className={`name-div`}>
                    <div className={`name-inner-div`}>
                        <h3 className="name">
                            {props.car?.manufacturingYear} {props.car?.make} {props.car?.model}
                        </h3>
                        <label className="address">
                            {props.car?.address?.postalCode} {props.car?.address?.city}
                        </label>
                    </div>

                    <p className={`status overdue ${props.car.status === CarStatusEnum.ReservePriceNotMetSeller ? `` : `hidden`} `}>{t('my_ev_garage_pending_title').toUpperCase()}</p>
                </div>

                <div className="price-div">
                    <div className="bid-div">
                        <p className={`sub-title`}>{t('add_car_reserve_price_title')}</p>

                        <div className="price-div">
                            <h3 className={`price primary`}> {props.car.reservePrice?.toLocaleString('de-CH', { minimumFractionDigits: 0 })}</h3>
                            <label className={`currency primary`}>{t('car_card_chf_title')}</label>
                        </div>
                    </div>

                    <div className={`bid-div`}>
                        <p className="sub-title">{t('my_ev_garage_highest_bid_title')}</p>
                        <div className="price-div">
                            <h3 className={`price `}> {props.car.auctions[0].highestBid?.toLocaleString('de-CH', { minimumFractionDigits: 0 })}</h3>
                            <label className={`currency `}>{t('car_card_chf_title')}</label>
                        </div>
                    </div>

                    {props.car.status === CarStatusEnum.ReservePriceNotMetBuyer && (
                        <div className="buttons-div">
                            <Button
                                title={t('button_accept')}
                                onClick={() => {
                                    onReservePriceNotMetClick(true)
                                }}
                            />

                            <Button
                                type={'black'}
                                title={t('button_reject')}
                                onClick={() => {
                                    onReservePriceNotMetClick(false)
                                }}
                            />
                        </div>
                    )}
                </div>

                {props.car.status === CarStatusEnum.ReservePriceNotMetSeller ? <label className="desc">{t('my_ev_garage_pending_desc')}</label> : <label className="desc">{t('my_ev_garage_reserve_price_not_met_buyer_desc')}</label>}
            </div>
        </li>
    )
}
