import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from '../../components'

export const ErrorPage = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <div className="page page-error">
            <div className="error-div">
                <div className="text-div">
                    <h1 className="title">{t('error_page_title')}</h1>

                    <h3 className="desc">{t('error_page_description')}</h3>

                    <Button
                        title={t('button_go_to_auctions')}
                        onClick={() => {
                            navigate('/')
                        }}
                    />
                </div>

                <img alt="Charger icon" className="charger-img" src="/images/ic_charger_image.svg" />
            </div>
        </div>
    )
}
