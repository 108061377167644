import { ValidationError } from 'class-validator'
import { NetworkCallException } from '.'

export class UnexpectedResponseException extends NetworkCallException {
    message = 'UnexpectedResponseException'
    constructor(errors: ValidationError[]) {
        super()
        console.error(errors)
    }
}
