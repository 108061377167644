import { useTranslation } from 'react-i18next'
import { useEffect, useRef } from 'react'
import './language-menu.scss'

interface LanguageMenuProps {
    type: 'web' | 'mobile'
    open: boolean
    onItemClick: (lng: string) => void
    onClose: () => void
}

export const LanguageMenu = (props: LanguageMenuProps) => {
    const { t, i18n } = useTranslation()

    const ref = useRef<HTMLDivElement>(null)

    function assertIsNode(e: EventTarget | null): asserts e is Node {
        if (!e || !('nodeType' in e)) {
            throw new Error(`Node expected`)
        }
    }

    useEffect(() => {
        const handleOutSideClick = (e: MouseEvent) => {
            assertIsNode(e.target)

            if (!ref.current?.contains(e.target) && (e.target as Element).className !== 'language-div' && (e.target as Element).className !== 'arrow-img rotate' && (e.target as Element).className !== 'language') {
                props.onClose()
            }
        }

        if (props.open) {
            window.addEventListener('mousedown', handleOutSideClick)
        }
        return () => {
            window.removeEventListener('mousedown', handleOutSideClick)
        }
    }, [ref, props.open])

    return (
        <div className={`language-menu ${props.open ? `` : `hidden`} ${props.type === 'mobile' ? `mobile` : ``}`} ref={ref}>
            <ul className="floating-menu-bar">
                <li className={`floating-menu-bar-item`} onClick={() => props.onItemClick('en')}>
                    <label className={`floating-menu-bar-item-title ${i18n.language.includes('en') ? `active` : ``}`}>{t('header_langugage_en_short_button')}</label>
                </li>

                <li className={`floating-menu-bar-item`} onClick={() => props.onItemClick('de')}>
                    <label className={`floating-menu-bar-item-title  ${i18n.language.includes('de') ? `active` : ``}`}>{t('header_langugage_de_short_button')}</label>
                </li>

                <li className={`floating-menu-bar-item`} onClick={() => props.onItemClick('fr')}>
                    <label className={`floating-menu-bar-item-title  ${i18n.language.includes('fr') ? `active` : ``}`}>{t('header_langugage_fr_short_button')}</label>
                </li>
            </ul>
        </div>
    )
}
