import { store } from '../../redux'
import { BidsDTO } from '../../dto'
import { BidsFailed, BidsSuccess } from '../../redux/actions'
import { NetworkCall } from '../../utils'

export const getBids = async (auctionId: number, reset: boolean = false) => {
    const page = reset ? 1 : store.getState().bids.pagination.page
    const pageSize = store.getState().bids.pagination.pageSize

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('GET', `/1.0.0/auctions/${auctionId}/bids?page=${page}&pageSize=${pageSize}`)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(200, BidsDTO, (responseBody) => {
            store.dispatch(BidsSuccess({ bids: responseBody.data, page: responseBody.page, pageSize: responseBody.pageSize, count: responseBody.count, reset: reset }))
        })
        .catch((response) => {
            store.dispatch(BidsFailed({}))
        })
        .call()
}
