import { useTranslation } from 'react-i18next'
import './toast.scss'

interface ToastProps {
    message: string
}

export const Toast = (props: ToastProps) => {
    const { t } = useTranslation()

    return (
        <div className={`toast`}>
            <img alt="Check icon" className={`check-img`} src={`/images/ic_check_green.svg`} />

            <p className="message">{t(props.message)}</p>
        </div>
    )
}
