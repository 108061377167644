import { store } from '../../redux'
import { ErrorDTO, UserDTO } from '../../dto'
import { LoginSuccess, ErrorMessage, UpdateUserSuccess, UpdateUserFailed } from '../../redux/actions'
import { NetworkCall } from '../../utils'

interface ModifyUserProps {
    onSuccess: () => void

    onError: () => void
}

export const modifyUser = async (props: ModifyUserProps) => {
    const languages = []
    if (store.getState().user.form.english.value) {
        languages.push('en')
    }
    if (store.getState().user.form.german.value) {
        languages.push('de')
    }
    if (store.getState().user.form.italian.value) {
        languages.push('it')
    }
    if (store.getState().user.form.french.value) {
        languages.push('fr')
    }

    const requestBody = {
        firstName: store.getState().user.form.firstName.value.length === 0 ? undefined : store.getState().user.form.firstName.value,
        lastName: store.getState().user.form.lastName.value.length === 0 ? undefined : store.getState().user.form.lastName.value,
        email: store.getState().user.form.email.value,
        userName: store.getState().user.form.username.value,
        companyName: store.getState().user.form.companyName.value.length === 0 ? undefined : store.getState().user.form.companyName.value,
        uidNumber: store.getState().user.form.uidNumber.value.length === 0 ? undefined : store.getState().user.form.uidNumber.value,
        vatNumber: store.getState().user.form.vatNumber.value.length === 0 ? undefined : store.getState().user.form.vatNumber.value,
        languages: languages,
        role: store.getState().user.form.role.value,
        newsletter: store.getState().user.form.newsletter.value,
        country: store.getState().user.form.country.value.length === 0 ? 'Switzerland' : store.getState().user.form.country.value,
        address: store.getState().user.form.address.value,
        city: store.getState().user.form.city.value,
        postalCode: Number(store.getState().user.form.postalCode.value),
        phoneNumber: store.getState().user.form.phoneNumber.value.length === 0 ? '' : '+41' + store.getState().user.form.phoneNumber.value,
        termsVersion: store.getState().user.form.termsVersion.value === -1 ? undefined : store.getState().user.form.termsVersion.value,
        privacyVersion: store.getState().user.form.privacyVersion.value === -1 ? undefined : store.getState().user.form.privacyVersion.value,
        stripeTokenId: store.getState().user.form.cardId.value === '' ? null : store.getState().user.form.cardId.value,
    }

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')
    const id = store.getState().login.user?.id

    return await new NetworkCall('PATCH', `/1.0.0/users/${id}`, requestBody)
        .setHeader('Authorization', `Bearer ${token}`)

        .then(200, UserDTO, (responseBody) => {
            store.dispatch(LoginSuccess({ user: responseBody.user, session: { token: token ?? '' } }))
            store.dispatch(UpdateUserSuccess({ user: responseBody.user }))

            props.onSuccess()
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
            store.dispatch(UpdateUserFailed({}))

            props.onError()
        })
        .call()
}
