import { Dispatch, SetStateAction } from 'react'
import { Checkbox } from '../../../components'
import { useTranslation } from 'react-i18next'

interface PhotoChecklistCheckboxProps {
    id: string
    formData: Map<string, boolean>
    setFormData: Dispatch<SetStateAction<Map<string, boolean>>>
}

export const PhotoChecklistCheckbox = (props: PhotoChecklistCheckboxProps) => {
    const { t } = useTranslation()

    return (
        <Checkbox
            id={props.id}
            checked={props.formData.get(props.id) ?? false}
            onChange={(e) => {
                let newFormData = new Map<string, boolean>(props.formData)
                newFormData.set(props.id, e.target.checked)
                props.setFormData(newFormData)
            }}
            title={t(props.id)}
            error={''}
        ></Checkbox>
    )
}
