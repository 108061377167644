import { store } from '../../redux'
import { DefaultDTO, ErrorDTO } from '../../dto'
import { ErrorMessage, ShowToast } from '../../redux/actions'
import { NetworkCall } from '../../utils'
import { getQuestions } from './get-questions.api'

interface CreateQuestionAnswerProps {
    carId: number
    questionId: number
    answer: string
    onSuccess: () => void
}

export const createQuestionAnswer = async (props: CreateQuestionAnswerProps) => {
    const requestBody = {
        answer: props.answer,
    }

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('POST', `/1.0.0/questions/${props.questionId}/answer`, requestBody)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(201, DefaultDTO, (responseBody) => {
            props.onSuccess()

            getQuestions(props.carId, true)

            store.dispatch(ShowToast({ message: 'toast_create_answer_success' }))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
