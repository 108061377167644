import { ChangeEventHandler } from 'react'
import './checkbox.scss'

interface CheckboxProps {
    id: any
    checked: boolean
    title?: string
    titleElement?: JSX.Element
    error: string | null
    onChange: ChangeEventHandler<HTMLInputElement>
}

export const Checkbox = (props: CheckboxProps) => {
    return (
        <label className={`checkbox-container-div`} id={'checkbox'}>
            <div className="checkbox-div">
                <label className={`checkbox`}>
                    <input id={props.id} type="checkbox" onChange={props.onChange} defaultChecked={props.checked} />
                    <div className={`${props.checked ? 'checkbox--active' : 'checkbox--hidden '}`}>
                        <img className={`check-img`} alt="Check icon" src={`/images/ic_check_white.svg`} />
                    </div>
                </label>

                {props.titleElement ? props.titleElement : <label className="title">{props.title} </label>}
            </div>

            <div className={`error-div ${props.error != null && props.error.length > 0 ? `` : `hidden`}`}>
                <img alt="Error icon" className="error-img" src="/images/ic_error.svg" />

                <div className={`message`}>{props.error}</div>
            </div>
        </label>
    )
}
