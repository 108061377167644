import { Expose } from 'class-transformer'
import { IsBoolean, IsDefined, IsIn, IsNumber, IsOptional, IsString, ValidateIf } from 'class-validator'
import { Document } from './ducument.entity'
import { AirConditioningEnum, ElectricSeatsEnum, FrontAndRearEnum, SeatMaterialEnum } from '../enum'
import { USBPortEnum } from '../enum/usb-ports.enum'

export class CarDetail {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsString()
    exteriorColour!: string

    @Expose()
    @IsDefined()
    @IsString()
    interiorColour!: string

    @Expose()
    @IsDefined()
    @IsBoolean()
    headUpDisplay!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    laneAssist!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    threeSixtydegreeCamera!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    parkingAssist!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    rearViewCamera!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    autoParking!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    cruiseControl!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    adaptiveCruiseControl!: boolean

    @Expose()
    @IsDefined()
    @IsNumber()
    @IsIn(
        Object.keys(FrontAndRearEnum)
            .map((key: string) => parseInt(key))
            .filter((key: number) => !isNaN(key))
    )
    @ValidateIf((object, value) => value !== null)
    parkingSensors!: number | null

    @Expose()
    @IsDefined()
    @IsBoolean()
    centralDisplay!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    navigationSystem!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    appleCarplay!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    androidAuto!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    bluetoothInterface!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    dabRadio!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    wirelessPhoneCharger!: boolean

    @Expose()
    @IsDefined()
    @IsNumber()
    @IsIn(
        Object.keys(USBPortEnum)
            .map((key: string) => parseInt(key))
            .filter((key: number) => !isNaN(key))
    )
    @ValidateIf((object, value) => value !== null)
    usbPorts!: number | null

    @Expose()
    @IsDefined()
    @IsBoolean()
    soundSystem!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    blindSpotMonitor!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    abs!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    esc!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    tyrePressureMonitoringSystem!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    autoEmergencyBreak!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    driverAlertSystem!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    isofix!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    nightVision!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    frontCollisionWarning!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    crossTrafficWarning!: boolean

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    airbags?: number[] | null

    @Expose()
    @IsDefined()
    @IsBoolean()
    heatPump!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    rainSensor!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    electricTrunkOpening!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    electricSideMirrors!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    heatedSideMirrors!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    keylessEntry!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    heatedSteeringWheel!: boolean

    @Expose()
    @IsDefined()
    @IsNumber()
    @IsIn(
        Object.keys(AirConditioningEnum)
            .map((key: string) => parseInt(key))
            .filter((key: number) => !isNaN(key))
    )
    @ValidateIf((object, value) => value !== null)
    airConditioning!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @IsIn(
        Object.keys(ElectricSeatsEnum)
            .map((key: string) => parseInt(key))
            .filter((key: number) => !isNaN(key))
    )
    @ValidateIf((object, value) => value !== null)
    electricSeats!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @IsIn(
        Object.keys(FrontAndRearEnum)
            .map((key: string) => parseInt(key))
            .filter((key: number) => !isNaN(key))
    )
    @ValidateIf((object, value) => value !== null)
    heatedSeats!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @IsIn(
        Object.keys(FrontAndRearEnum)
            .map((key: string) => parseInt(key))
            .filter((key: number) => !isNaN(key))
    )
    @ValidateIf((object, value) => value !== null)
    cooledSeats!: number | null

    @Expose()
    @IsDefined()
    @IsBoolean()
    ledHeadLights!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    ledRearLights!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    xenonHeadLights!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    xenonRearLights!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    matrixHeadlights!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    corneringLights!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    fogLights!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    ambientLighting!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    tintedWindows!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    skiRack!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    towBar!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    slidingRoof!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    panoramicRoof!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    sportSuspension!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    alloyWheels!: boolean

    @Expose()
    @IsDefined()
    @IsBoolean()
    sportSeats!: boolean

    @Expose()
    @IsDefined()
    @IsNumber()
    @IsIn(
        Object.keys(SeatMaterialEnum)
            .map((key: string) => parseInt(key))
            .filter((key: number) => !isNaN(key))
    )
    @ValidateIf((object, value) => value !== null)
    seatMaterial!: number | null

    @Expose()
    @IsDefined()
    @IsString()
    otherEquipment!: string

    @Expose()
    @IsDefined()
    @IsString()
    ownersTake!: string

    @Expose()
    @IsDefined()
    @IsString()
    ownershipHistory!: string

    @Expose()
    @IsDefined()
    @IsString()
    serviceHistoryAndRepairs!: string

    @Expose()
    @IsDefined()
    @IsString()
    otherItemsIncluded!: string

    @Expose()
    @IsDefined()
    @IsString()
    modifications!: string

    @Expose()
    @IsDefined()
    @IsString()
    knownProblemsOrFlaws!: string

    @Expose()
    @IsDefined()
    @IsString()
    otherInformation!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    reservePrice!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    documents?: Document[] | null
}
