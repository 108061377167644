import { Expose, Type } from 'class-transformer'
import { IsDefined, ValidateNested } from 'class-validator'
import { Car } from '../../entity'

export class ListUnpaidCarsDTO {
    @Expose()
    @IsDefined()
    @ValidateNested()
    @Type(() => Car)
    cars!: Car[]
}
