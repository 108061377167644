import { LoaderFunctionArgs } from 'react-router-dom'
import { getCar, getComments, getQuestions } from '../api'
import { store } from '../redux'
import { CarStatusEnum } from '../enum'
import { redirect } from 'react-router-dom'

export const auctionPageLoader = async (args: LoaderFunctionArgs) => {
    const carId = parseInt(args.params.id as string)
    await getCar(carId)

    // if (store.getState().car.car?.status !== CarStatusEnum.Approved) {
    //     return redirect('/')
    // }

    await getComments(carId, 'newestFirst', true)
    await getQuestions(carId, true)

    return null
}
