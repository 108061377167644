import { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import './load-more.button.scss'

interface LoadMoreButtonProps {
    onClick?: MouseEventHandler
}

export const LoadMoreButton = (props: LoadMoreButtonProps) => {
    const { t } = useTranslation()

    return (
        <div className="load-more-button" onClick={props.onClick}>
            <label className="label">{t('car_information_load_more_button')}</label>
            <img alt="Arrow icon" className="arrow-img" src="/images/ic_arrow_down_orange.svg" />
        </div>
    )
}
