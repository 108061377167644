import ReactDOM from 'react-dom/client'
import 'reflect-metadata'

import { App } from './app/App'
import './assets/scss/index.scss'
import './app/i18n'
import { store } from './redux'
import { NewNotification } from './redux/actions/notification'
import { NetworkCall } from './utils'
import { Logout } from './redux/actions/login'

import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getMessaging, onMessage } from 'firebase/messaging'

function setInnerHeight() {
    document.body.setAttribute(`style`, `--inner-height: ${window.innerHeight}px`)
}
setInnerHeight()
window.onresize = setInnerHeight

if (navigator.serviceWorker) {
    navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then(() => {})
        .catch(() => {})

    const firebaseConfig = {
        // apiKey: 'AIzaSyDhvA22iog64xHdm644YZOM0VDvc5HC6KA',
        // authDomain: 'lno-dev.firebaseapp.com',
        // projectId: 'lno-dev',
        // storageBucket: 'lno-dev.appspot.com',
        // messagingSenderId: '524752065653',
        // appId: '1:524752065653:web:ec449a9da8f9ecfe5cd43c',
        // measurementId: 'G-SN8XZMEJXK',
        apiKey: 'AIzaSyAvBTHTFcheNf9--ZhX4cfnUTq6D6E5y5Q',
        authDomain: 'lot-number-one.firebaseapp.com',
        projectId: 'lot-number-one',
        storageBucket: 'lot-number-one.appspot.com',
        messagingSenderId: '829296557595',
        appId: '1:829296557595:web:1cc29cf60768aaff6082be',
        measurementId: 'G-JVFNQS5PDQ',
    }

    const app = initializeApp(firebaseConfig)
    const analytics = getAnalytics(app)

    const messaging = getMessaging()
    onMessage(messaging, (payload) => {
        store.dispatch(NewNotification({ isNew: true }))
    })
    navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data === 'NewNotification') {
            store.dispatch(NewNotification({ isNew: true }))
        }
    })
}

NetworkCall.catch(401, () => {
    if (store.getState().login.user) {
        store.dispatch(Logout({}))
        window.location.href = `/`
    }
})

ReactDOM.createRoot(document.querySelector(`#app`) as HTMLElement).render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
