import { NetworkCall } from '../../utils'
import { store } from "../../redux";
import { SetUnpaidCars } from "../../redux/actions";
import {ListUnpaidCarsDTO} from "../../dto";

export const listUnpaidCars = async () => {
    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('GET', `/1.0.0/users/popups/unpaid-auctions`)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(200, ListUnpaidCarsDTO, (responseBody) => {
            store.dispatch(SetUnpaidCars(responseBody))
        })
        .catch(() => {})
        .call()
}
