import { store } from '../../redux'
import { DefaultDTO, ErrorDTO } from '../../dto'
import { NetworkCall } from '../../utils'
import { ShowToast } from '../../redux/actions/base'

interface ForgotPasswordProps {
    email: string
    onSuccess: () => void
    onError: () => void
}

export const forgotPassword = async (props: ForgotPasswordProps) => {
    const requestBody = {
        email: props.email,
    }

    return await new NetworkCall('POST', `/1.0.0/users/forgot-password`, requestBody)
        .then(201, DefaultDTO, (responseBody) => {
            props.onSuccess()

            store.dispatch(ShowToast({ message: 'toast_forgot_password_success' }))
        })
        .catch((response) => {
            console.log((response as ErrorDTO).code)

            props.onError()

            store.dispatch(ShowToast({ message: 'toast_forgot_password_success' }))
        })
        .call()
}
