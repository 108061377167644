import { useState } from 'react'
import { Modal } from '../modal'
import { Button } from '../../buttons'
import { PasswordField } from '../../field'
import { useTranslation } from 'react-i18next'
import { validatePassword } from '../../../utils/validate.util'
import { changePassword } from '../../../api'
import './change-password.modal.scss'

interface ChangePasswordModalProps {
    onClose: () => void
}

export const ChangePasswordModal = (props: ChangePasswordModalProps) => {
    const { t } = useTranslation()

    const [oldPassword, setOldPassword] = useState('')
    const [oldPasswordError, setOldPasswordError] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordError, setNewPasswordError] = useState('')
    const [reNewPassword, setReNewPassword] = useState('')
    const [reNewPasswordError, setReNewPasswordError] = useState('')

    const onChangePasswordClick = async () => {
        setNewPasswordError('')
        setReNewPasswordError('')

        const oldPasswordValidation = validatePassword(oldPassword)
        if (oldPasswordValidation != null) {
            setOldPasswordError(t(oldPasswordValidation))
            return
        }

        const newPasswordValidation = validatePassword(newPassword)
        if (newPasswordValidation != null) {
            setNewPasswordError(t(newPasswordValidation))
            return
        }

        if (newPassword !== reNewPassword) {
            setReNewPasswordError(t('error_change_password_missmatch'))
            return
        }

        await changePassword({
            password: oldPassword,
            newPassword: newPassword,
            onSuccess() {
                props.onClose()
            },
        })
    }

    return (
        <Modal title={t('modal_change_password_title')} onCloseClick={props.onClose}>
            <div className="change-password-modal">
                <PasswordField
                    showStrongPassword={false}
                    field={{ error: oldPasswordError, value: oldPassword }}
                    onChange={(e: any) => {
                        setOldPasswordError('')
                        setOldPassword(e.target.value)
                    }}
                    title={t('modal_change_password_old_password_placeholder')}
                    placeholder={''}
                />

                <PasswordField
                    showStrongPassword={true}
                    field={{ error: newPasswordError, value: newPassword }}
                    onChange={(e) => {
                        setNewPasswordError('')
                        setNewPassword(e.target.value)
                    }}
                    title={t('modal_change_password_new_password_placeholder')}
                    placeholder={''}
                />

                <PasswordField
                    showStrongPassword={true}
                    field={{ error: reNewPasswordError, value: reNewPassword }}
                    onChange={(e: any) => {
                        setReNewPasswordError('')
                        setReNewPassword(e.target.value)
                    }}
                    title={t('modal_change_password_re_new_password_placeholder')}
                    placeholder={''}
                />

                <div className="change-password-modal__button-div">
                    <Button default={true} title={t('button_cancel')} onClick={props.onClose} />
                    <Button title={t('button_change_password')} onClick={onChangePasswordClick} />
                </div>
            </div>
        </Modal>
    )
}
