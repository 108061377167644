import { store } from '../../redux'
import { FilterDTO } from '../../dto'
import { NetworkCall } from '../../utils'
import { FiltersSuccess } from '../../redux/actions/get-cars/filters-success.action'
import { FiltersFailed } from '../../redux/actions/get-cars/filters-failed.action'

const queryBuilder = (): string => {
    let query = ``

    const tab = store.getState().cars.tab
    const search = store.getState().cars.search
    const make = store.getState().cars.make !== undefined ? store.getState().cars.make : undefined
    const model = store.getState().cars.model !== undefined ? store.getState().cars.model : undefined
    const startYear = store.getState().cars.startYear !== undefined ? store.getState().cars.startYear : undefined
    const endYear = store.getState().cars.endYear !== undefined ? store.getState().cars.endYear : undefined
    const minMileage = store.getState().cars.minMileage
    const maxMileage = store.getState().cars.maxMileage
    const batteryCapacity = store.getState().cars.batteryCapacity !== undefined ? store.getState().cars.batteryCapacity : undefined

    if (tab) query += tab !== undefined ? (query.length === 0 ? `?` : `&`) + `tab=${tab}` : ``
    if (search) query += search !== undefined && search !== '' ? (query.length === 0 ? `?` : `&`) + `search=${search}` : ``
    if (make) query += make !== undefined ? (query.length === 0 ? `?` : `&`) + `make=${make}` : ``
    if (model) query += model !== undefined ? (query.length === 0 ? `?` : `&`) + `model=${model}` : ``
    if (startYear) query += startYear !== undefined ? (query.length === 0 ? `?` : `&`) + `startYear=${startYear}` : ``
    if (endYear) query += endYear !== undefined ? (query.length === 0 ? `?` : `&`) + `endYear=${endYear}` : ``
    if (minMileage) query += minMileage !== undefined ? (query.length === 0 ? `?` : `&`) + `minMileage=${minMileage}` : ``
    if (maxMileage) query += maxMileage !== undefined ? (query.length === 0 ? `?` : `&`) + `maxMileage=${maxMileage}` : ``
    if (batteryCapacity) query += batteryCapacity !== undefined ? (query.length === 0 ? `?` : `&`) + `batteryCapacity=${batteryCapacity}` : ``

    return query
}

export const filterData = async () => {
    return await new NetworkCall('GET', `/1.0.0/cars/filter/data${queryBuilder()}`)
        .then(200, FilterDTO, (responseBody) => {
            store.dispatch(FiltersSuccess(responseBody))
        })
        .catch((response) => {
            store.dispatch(FiltersFailed({}))
        })
        .call()
}
