import { useTranslation } from 'react-i18next'
import { BidElement } from './bid-element'
import { LoadMoreButton } from '../../../../../components'
import { Bid } from '../../../../../entity'
import { useAppSelector } from '../../../../../redux'
import { useDispatch } from 'react-redux'
import { BidsPagination } from '../../../../../redux/actions'
import { getBids } from '../../../../../api'
import './bids.scss'
import { useEffect } from 'react'

interface BidsProps {
    auctionId: number
    bids: Bid[]
}

export const Bids = (props: BidsProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const pagination = useAppSelector((state) => state.bids.pagination)

    const onLoadMoreClick = async () => {
        dispatch(BidsPagination({}))
        await getBids(props.auctionId)
    }

    return (
        <div className="bids">
            <div className={`bid-title-div`}>
                <label className="title title--user">{t(`car_information_user_title`)}</label>
                <label className="title title--amount">{t(`car_information_bid_title`)}</label>
                <label className="title title--date right">{t(`car_information_date_and_time_title`)}</label>
            </div>
            <div className="divider" />

            <ul className="list">
                {props.bids.map((bid, index) => (
                    <BidElement highest={index == 0} bid={bid} />
                ))}
            </ul>

            <label className={`no-item-title ${pagination.count == 0 ? `` : `hidden`}`}> {t('car_information_no_bids_title')}</label>

            <div className={`bottom-div ${pagination.count <= pagination.pageSize * pagination.page ? `hidden` : ``}`}>
                <LoadMoreButton onClick={onLoadMoreClick} />
            </div>
        </div>
    )
}
