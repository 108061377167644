import { ErrorDTO, TermsDTO } from '../../dto'
import { store } from '../../redux'
import { ErrorMessage, GetTermsSuccess } from '../../redux/actions'
import { NetworkCall } from '../../utils'

export const getTermsAndConditions = async () => {
    return await new NetworkCall('GET', `/1.0.0/terms`)
        .then(200, TermsDTO, (responseBody) => {
            store.dispatch(GetTermsSuccess(responseBody))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
