import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Auction } from '../../entity'
import './battery.scss'

interface BatteryProps {
    auction: Auction
    type: 'small' | 'normal' | 'large'
    onEnd?: () => void
}

export const Battery = (props: BatteryProps) => {
    const { t } = useTranslation()

    const TOW_MIN_IN_MS = 120000

    const [actionEndsInTwelveHour, setActionEndsInTwelveHour] = useState(false)
    const [actionEnded, setActionEnded] = useState(false)

    const endDateInMs = new Date(props.auction.endDate).getTime()
    const [countDownTimeInMs, setCountDownTimeInMs] = useState(endDateInMs - new Date().getTime())

    useEffect(() => {
        const timeDifferenceInMs = endDateInMs - new Date().getTime()

        const days = Math.floor(timeDifferenceInMs / (24 * 60 * 60 * 1000))
        const daysMs = timeDifferenceInMs % (24 * 60 * 60 * 1000)
        const hours = Math.floor(daysMs / (60 * 60 * 1000))

        if (timeDifferenceInMs < 0) {
            setActionEnded(true)
        } else if (days < 1 && hours < 12) {
            setActionEndsInTwelveHour(true)

            const interval = setInterval(() => {
                setCountDownTimeInMs(endDateInMs - new Date().getTime())
                if (endDateInMs - new Date().getTime() < 0 && props.onEnd) {
                    props.onEnd()
                }
            }, 1000)

            return () => clearInterval(interval)
        } else if (days < 1 && hours < 24) {
            const interval = setInterval(() => {
                setCountDownTimeInMs(endDateInMs - new Date().getTime())
                if (endDateInMs - new Date().getTime() < 0 && props.onEnd) {
                    props.onEnd()
                }
            }, 1000)

            return () => clearInterval(interval)
        }
    }, [endDateInMs])

    const getTime = () => {
        if (endDateInMs < new Date().getTime()) {
            if (props.type === 'large') {
                return ''
            }
            //return new Date(props.auction.endDate).toLocaleString('de-CH', { year: 'numeric', month: 'short', day: 'numeric' })
            let d = new Date(props.auction.endDate)
            return `${t(`month-${d.getMonth() + 1}`)} ${d.getDate()}, ${d.getFullYear()}`
        }

        const days = Math.floor(countDownTimeInMs / (24 * 60 * 60 * 1000))
        const daysMs = countDownTimeInMs % (24 * 60 * 60 * 1000)
        const hours = Math.floor(daysMs / (60 * 60 * 1000))

        if (days < 1 && hours < 24) {
            const daysMs = countDownTimeInMs % (24 * 60 * 60 * 1000)
            const hours = Math.floor(daysMs / (60 * 60 * 1000))
            const hoursMs = countDownTimeInMs % (60 * 60 * 1000)
            const minutes = Math.floor(hoursMs / (60 * 1000))
            const minutesMs = countDownTimeInMs % (60 * 1000)
            const sec = Math.floor(minutesMs / 1000)
            return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (sec < 10 ? '0' + sec : sec)
        } else {
            return days + ' ' + t('car_card_day_title')
        }
    }

    const getPercent = () => {
        if (countDownTimeInMs < TOW_MIN_IN_MS) {
            const onePercent = TOW_MIN_IN_MS / 100
            return countDownTimeInMs / onePercent
        }
        return 100
    }

    const getOpacity = () => {
        if (countDownTimeInMs <= TOW_MIN_IN_MS) {
            return 0.2 + (0.8 - (countDownTimeInMs / TOW_MIN_IN_MS) * 0.8)
        }
        if (actionEndsInTwelveHour) {
            return 0.2
        }
        return 0.1
    }

    return (
        <div className={`battery ${props.type}`}>
            <div className={`battery__time-div ${props.type} ${actionEnded ? `action-ended` : ``}`}>
                <p className={`title ${props.type}`}>{t('car_card_time_left_title')}</p>
                <p className={`time ${props.type} ${actionEndsInTwelveHour ? `primary` : ``} ${actionEnded ? `action-ended` : ``}`}>{getTime()}</p>
            </div>

            <div className={`battery__progress ${props.type} ${actionEndsInTwelveHour ? `primary` : ``} ${actionEnded ? `hidden` : ``}`} style={{ maxWidth: `${getPercent()}%`, width: `${getPercent()}%`, opacity: getOpacity() }} />

            <img className={`battery__bg-img ${actionEnded ? `hidden` : ``}`} alt="Battery icon" src={`/images/${props.type === `large` ? `ic_battery_large.svg` : `${actionEndsInTwelveHour ? `ic_battery_primary.svg` : `ic_battery.svg`}`}`} />
        </div>
    )
}
