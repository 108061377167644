import { Expose, Type } from 'class-transformer'
import { IsDefined, IsNumber, ValidateNested } from 'class-validator'
import { EvDatabaseCar } from '../../entity'

export class EvDatabaseCarsDTO {
    @Expose()
    @ValidateNested()
    @Type(() => EvDatabaseCar)
    data!: EvDatabaseCar[]

    @Expose()
    @IsDefined()
    @IsNumber()
    page!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    pageSize!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    count!: number
}
