import { createAction } from '@reduxjs/toolkit'
import { Comment } from '../../../entity'

interface Payload {
    comments: Comment[]
    page: number
    pageSize: number
    count: number
    reset: boolean
}

export const CommentsSuccess = createAction<Payload>('COMMENTS__COMMENTS_SUCCESS')
