import { DragEvent } from 'react'
import './drag-and-drop.scss'

interface DragAnddropProps {
    onFilesSelected: (files: File[]) => void
    innnerElement: JSX.Element
    disabled: boolean
}

export const DragAnddrop = (props: DragAnddropProps) => {
    const onDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        const droppedFiles = event.dataTransfer.files
        if (droppedFiles.length > 0) {
            const newFiles = Array.from(droppedFiles)

            props.onFilesSelected(newFiles)
        }
    }

    return (
        <section className={`drag-and-drop ${props.disabled ? '' : 'disabled'}`} >
            <div className={`document-uploader`} onDrop={onDrop} onDragOver={(event) => event.preventDefault()}>
                {props.innnerElement}
            </div>
        </section>
    )
}
