import { useState } from 'react'
import { Modal } from '../modal'
import { Button } from '../../buttons'
import { InputField } from '../../field'
import { useTranslation } from 'react-i18next'
import { validateEmail } from '../../../utils/validate.util'
import { forgotPassword } from '../../../api'
import { useDispatch } from 'react-redux'
import { ShowLoginModal } from '../../../redux/actions'
import './forgot-password.modal.scss'

interface ForgotPasswordModalProps {
    onClose: () => void
}

export const ForgotPasswordModal = (props: ForgotPasswordModalProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [error, setError] = useState('')
    const [email, setEmail] = useState('')

    const onSubmitClick = async () => {
        setError('')

        const emailValidation = validateEmail(email)
        if (emailValidation != null) {
            setError(t(emailValidation))
            return
        }

        await forgotPassword({
            email: email,
            onSuccess() {
                props.onClose()

                dispatch(ShowLoginModal({ visible: true, carId: null }))
            },
            onError() {
                props.onClose()

                dispatch(ShowLoginModal({ visible: true, carId: null }))
            },
        })
    }

    return (
        <Modal title={t('modal_forgot_password_title')} onCloseClick={props.onClose}>
            <div className="forgot-password-modal">
                <InputField
                    type="email"
                    field={{ error: error, value: email }}
                    onChange={(e) => {
                        setError('')
                        setEmail(e.target.value)
                    }}
                    placeholder={t('')}
                    title={t('signup_email_title')}
                />

                <div className="login-modal__button-div">
                    <Button default={true} title={t('button_cancel')} onClick={props.onClose} />
                    <Button title={t('button_submit')} onClick={onSubmitClick} />
                </div>
            </div>
        </Modal>
    )
}
