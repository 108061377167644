import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import { HowItWorksSection, Menu } from '../../enum'
import { useDispatch } from 'react-redux'
import { LanguageMenu } from './language-menu/language-menu'
import { SearchField } from '../field'
import { useAppSelector } from '../../redux'
import { ResetCarForm, ShowLoginModal, UpdateAddCarTab, UpdateCarsParams, UpdateHowItWorksSection, UpdateMenu } from '../../redux/actions'
import { UserMenu } from './user-menu/user-menu'
import { getCars, listNotifications } from '../../api'
import { NotificationsMenu } from './notifications-menu/notifications-menu'
import { HamburgerMenu } from './hamburger-menu/hamburger-menu'
import { RegisterToModal } from '../modals'
import { hasUnreadNotifications } from '../../api/notification'
import './header.scss'
import { useWindowDimensions } from '../../utils'

interface HeaderProps {}

export const Header = (props: HeaderProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isNewNotification } = useAppSelector((state) => state.notifications)
    const { t, i18n } = useTranslation()
    const [state, setState] = useState({
        languageMenuVisible: false,
        userMenuVisible: false,
        hamburgerMenuVisible: false,
        notificationsVisible: false,
        searchActive: false,
    })

    const { width } = useWindowDimensions()

    const [registerToModalVisible, setRegisterToModalVisible] = useState(false)

    const menu = useAppSelector((state) => state.base.menu)
    const user = useAppSelector((state) => state.login.user)

    useEffect(() => {
        hasUnreadNotifications()
    }, [])

    const onChangeLanguageClick = (lng: string) => {
        i18n.changeLanguage(lng)
        localStorage.setItem('language', lng)

        setState((prevState) => ({ ...prevState, languageMenuVisible: !state.languageMenuVisible }))
    }

    const onMenuItemClick = (id: number) => {
        dispatch(UpdateMenu({ menu: id }))
    }

    const onSellCarClick = () => {
        if (user) {
            if (user.address == null) {
                //setRegisterToModalVisible(!registerToModalVisible)
                navigate('/my-profile')
            } else {
                dispatch(ResetCarForm({}))
                dispatch(UpdateAddCarTab({ tab: 'cardetails' }))
                onMenuItemClick(Menu.SellACar)
                navigate('/add-car')
            }
        } else {
            dispatch(ShowLoginModal({ visible: true, carId: null }))
        }
    }

    const onSearchButtonClick = async (event: KeyboardEvent<HTMLInputElement>) => {
        event.preventDefault()

        await getCars(true)
    }

    const onSearchChange = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        dispatch(UpdateCarsParams({ search: event.target.value }))

        if (event.target.value.length === 0) {
            await getCars(true)
        }
    }

    const onNotificationClick = () => {
        if (user) {
            listNotifications()
            setState((prevState) => ({ ...prevState, notificationsVisible: !state.notificationsVisible }))

            if (width < 1080) {
                document.body.style.overflow = 'hidden'
            }
        } else {
            dispatch(ShowLoginModal({ visible: true, carId: null }))
        }
    }

    return (
        <header className={`header`}>
            <Link to="/" onClick={() => onMenuItemClick(Menu.Auctions)}>
                <img alt="Lot Number One logo" className={`header__logo`} src="/images/logo.svg" />
            </Link>

            <ul className="header__menu-bar">
                <li className="menu-bar-item">
                    <Link className={`title ${Menu.Auctions === menu ? `active` : ``}`} to={'/'} onClick={() => onMenuItemClick(Menu.Auctions)}>
                        {t('header_menu_auctions_button')}
                    </Link>
                    <div className={`indicator ${Menu.Auctions === menu ? `active` : ``}`} />
                </li>

                <li className="menu-bar-item">
                    <Link
                        className={`title ${Menu.HowItWorks === menu ? `active` : ``}`}
                        to={'how-it-works'}
                        onClick={() => {
                            dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.HowItWorks }))
                            onMenuItemClick(Menu.HowItWorks)
                        }}
                    >
                        {t('header_menu_how_its_works_button')}
                    </Link>
                    <div className={`indicator ${Menu.HowItWorks === menu ? `active` : ``}`} />
                </li>

                <li className="menu-bar-item">
                    <Link className={`title ${Menu.EVDatabase === menu ? `active` : ``}`} to={'electric-car-database'} onClick={() => onMenuItemClick(Menu.EVDatabase)}>
                        {t('header_menu_ev_database_button')}
                    </Link>
                    <div className={`indicator ${Menu.EVDatabase === menu ? `active` : ``}`} />
                </li>

                <li className="menu-bar-item">
                    <Link className={`title ${Menu.AboutUs === menu ? `active` : ``}`} to={'why-choose'} onClick={() => onMenuItemClick(Menu.AboutUs)}>
                        {t('header_menu_why_choose_button')}
                    </Link>
                    <div className={`indicator ${Menu.AboutUs === menu ? `active` : ``}`} />
                </li>
            </ul>

            <div className="header__login-div">
                <div className="top-div">
                    <div className="sell-a-car-div" onClick={onSellCarClick}>
                        <label className="sell-a-car-label"> {t('header_sell_a_car_button')}</label>
                        <label className="its-free-label"> {t('header_its_free_button')}</label>
                    </div>
                    <div className={`login-div ${user ? `hidden` : ``}`} onClick={() => dispatch(ShowLoginModal({ visible: true, carId: null }))}>
                        <label className="sign-up-label"> {t('header_sign_up_button')}</label>
                        <img alt="Right arrow icon" className="arrow-img" src="/images/ic_arrow_right.svg" />
                    </div>

                    <div className={`user-div ${user ? `` : `hidden`}`}>
                        <div className="notification-container" onClick={onNotificationClick}>
                            <img className={`notification`} alt="Notification icon" src="/images/ic_notification.svg" />
                            {isNewNotification ? <div className="indicator" /> : null}
                        </div>
                        <div className={`profile-div`} onClick={() => setState((prevState) => ({ ...prevState, userMenuVisible: !state.userMenuVisible }))}>
                            <img className={`profile-img`} alt="Profile icon" src="/images/ic_profile.svg" />
                            <img className={`arrow-img`} alt="Profile icon" src="/images/ic_arrow_down.svg" />
                        </div>

                        <UserMenu open={state.userMenuVisible} onClose={() => setState((prevState) => ({ ...prevState, userMenuVisible: !state.userMenuVisible }))} />
                    </div>
                </div>
                <label className="login-description"> {t('header_sign_up_description')}</label>
            </div>

            <div className="header__language-button" onClick={() => setState((prevState) => ({ ...prevState, languageMenuVisible: !state.languageMenuVisible }))}>
                <label className="language-label">{`${i18n.language.includes('en') ? t('header_langugage_en_short_button') : i18n.language.includes('de') ? t('header_langugage_de_short_button') : t('header_langugage_fr_short_button')}`}</label>

                <img className="arrow-img" alt="Arrow icon" src="/images/ic_arrow_down.svg" />
                <LanguageMenu
                    type="web"
                    open={state.languageMenuVisible}
                    onItemClick={onChangeLanguageClick}
                    onClose={() => {
                        setState((prevState) => ({ ...prevState, languageMenuVisible: !state.languageMenuVisible }))
                    }}
                />
            </div>

            <div className={`header__mobile`}>
                {window.location.pathname === '/' && <img className={`search`} alt="Arrow icon" src="/images/ic_search.svg" onClick={() => setState((prevState) => ({ ...prevState, searchActive: !state.searchActive }))} />}
                <div className="notification-container">
                    <img className={`notification`} alt="Notification icon" src="/images/ic_notification.svg" onClick={onNotificationClick} />
                    {isNewNotification ? <div className="indicator" /> : null}
                </div>
                <img
                    className={`menu`}
                    alt="Menu icon"
                    src="/images/ic_menu.svg"
                    onClick={() =>
                        setState((prevState) => ({
                            ...prevState,
                            hamburgerMenuVisible: !state.hamburgerMenuVisible,
                        }))
                    }
                />

                <div className={`search-div ${state.searchActive ? `show` : `hide`}`}>
                    <SearchField
                        closeAlwaysVisible={true}
                        onSearch={onSearchButtonClick}
                        onChange={onSearchChange}
                        placeholder={t('auctions_search_placeholder')}
                        onClose={async () => {
                            setState((prevState) => ({ ...prevState, searchActive: !state.searchActive }))
                            dispatch(UpdateCarsParams({ search: '' }))
                            await getCars(true)
                        }}
                    />
                </div>
            </div>

            <NotificationsMenu
                open={state.notificationsVisible}
                onSettingsClick={() => {
                    setState((prevState) => ({ ...prevState, notificationsVisible: !state.notificationsVisible }))
                    navigate('/notification-settings')
                    dispatch(UpdateMenu({ menu: Menu.NotificationSettings }))

                    document.body.style.overflowY = 'auto'
                }}
                onClose={() => {
                    setState((prevState) => ({ ...prevState, notificationsVisible: !state.notificationsVisible }))

                    document.body.style.overflowY = 'auto'
                }}
            />

            <HamburgerMenu
                open={state.hamburgerMenuVisible}
                menu={menu}
                onClose={() => {
                    setState((prevState) => ({ ...prevState, hamburgerMenuVisible: !state.hamburgerMenuVisible }))
                }}
            />

            {registerToModalVisible ? (
                <RegisterToModal
                    type={'sell-a-car'}
                    onClose={() => {
                        setRegisterToModalVisible(!registerToModalVisible)
                    }}
                    onSuccess={(type: string) => {
                        setRegisterToModalVisible(!registerToModalVisible)
                    }}
                    onHowBiddingWorksClick={() => {}}
                    onAddPaymentCardClick={() => {}}
                />
            ) : null}
        </header>
    )
}
