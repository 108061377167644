import { Modal } from '../modal'
import { useTranslation } from 'react-i18next'
import './buyers-fee.modal.scss'

interface BuyersFeeModalProps {
    onClose: () => void
}

export const BuyersFeeModal = (props: BuyersFeeModalProps) => {
    const { t } = useTranslation()

    return (
        <Modal title={t('modal_buyers_fee_title')} onCloseClick={props.onClose}>
            <div className="buyers-fee-modal">
                <label className="buyers-fee-modal__top-description">{t('modal_buyers_fee_top_description')}</label>
                <label className="buyers-fee-modal__description">{t('modal_buyers_fee_middle_description')}</label>
                <div className="buyers-fee-modal__description" dangerouslySetInnerHTML={{ __html: t('modal_buyers_fee_bottom_description') }}></div>
            </div>
        </Modal>
    )
}
