import { MutableRefObject } from 'react'
import './submenu.scss'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../redux'
import { UpdateCurrentSection } from '../../../redux/actions/faqs/update-current-section.action'

interface SubmenuProps {
    refs: MutableRefObject<HTMLDivElement | null>[]
    menuItems: { title: string }[]
}

export const Submenu = (props: SubmenuProps) => {
    const dispatch = useDispatch()
    const currentSection = useAppSelector((state) => state.faqs.currentSection)

    return (
        <div className="submenu-div">
            {props.menuItems.map((item, index) => {
                return (
                    <div
                        key={index}
                        dangerouslySetInnerHTML={{ __html: item.title }}
                        onClick={() => {
                            window.scrollTo(0, (props.refs[index].current?.offsetTop ?? 0) - 104)
                            dispatch(UpdateCurrentSection({ currentSection: index }))
                        }}
                        className={currentSection === index ? 'submenu-item active' : 'submenu-item'}
                    ></div>
                )
            })}
        </div>
    )
}
