import { useTranslation } from 'react-i18next'
import { Auction } from '../../../../entity'
import { Blurhash } from 'react-blurhash'
import { useState } from 'react'
import { Battery } from '../../../../components'
import { StatusEnum } from '../../../../enum'
import { formatTimeLeft } from '../../../../utils'
import { useNavigate } from 'react-router-dom'
import './bid-history-element.scss'

interface BidHistoryElementtProps {
    auction: Auction
}

export const BidHistoryElement = (props: BidHistoryElementtProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [imageLoaded, setImageLoaded] = useState(false)

    return (
        <li key={props.auction.id} className={`bid-history-element`}>
            <div className="bid-history-element__image-div">
                {props.auction.car != null && props.auction.car.mediaFiles != null && props.auction.car.mediaFiles.length > 0 && (
                    <Blurhash className={`blurhash-img ${imageLoaded ? `hidden` : ``}`} hash={props.auction.car != null && props.auction.car.mediaFiles != null && props.auction.car.mediaFiles.length > 0 ? props.auction.car.mediaFiles[0].blurHash : ''} width={'100%'} height={'100%'} resolutionX={32} resolutionY={32} punch={1} />
                )}

                <img
                    alt="Car"
                    className="car-img"
                    src={props.auction.car != null && props.auction.car.mediaFiles != null && props.auction.car.mediaFiles.length > 0 ? props.auction.car.mediaFiles[0].thumbnail : '/images/ic_no_image.svg'}
                    onLoad={() => {
                        setImageLoaded(true)
                    }}
                />
            </div>

            <div className="bid-history-element__information-div">
                <div className={`name-div`} onClick={(e) => navigate(`/auction/${props.auction.car?.id}`)}>
                    <h3 className="name">
                        {props.auction.car?.manufacturingYear} {props.auction.car?.make} {props.auction.car?.model}
                    </h3>
                    <label className="address">
                        {props.auction.car?.address?.postalCode} {props.auction.car?.address?.city}
                    </label>
                </div>

                <div className="price-div">
                    <div className={`bid-div`}>
                        <p className="sub-title">{t('car_card_current_bid_title')}</p>
                        <div className="price-div">
                            <div className="price-inner-div">
                                <h3 className={`price primary`}> {props.auction.currentPrice.toLocaleString('de-CH', { minimumFractionDigits: 0 })}</h3>
                                <label className={`currency primary`}>{t('car_card_chf_title')}</label>
                            </div>
                            <label className={`currency bottom `}>{'/ ' + props.auction.bidCount + ' ' + t('auction_bids_title')}</label>
                        </div>
                    </div>
                    <div className="time-left-div">
                        <p className={`sub-title ${new Date(props.auction.endDate).getTime() - new Date().getTime() < 0 ? 'inactive' : ''}`}>{props.auction.status !== StatusEnum.Expired ? t('car_card_time_left_title') : t('car_card_end_date_title')}</p>

                        <Battery type={'normal'} auction={props.auction} />
                    </div>
                </div>

                <div className={`bids-div`}>
                    <label className="text">{props.auction.bids?.length + ' ' + t('auction_bids_title')}</label>
                    <label className="price">{(props.auction.bids ? props.auction.bids[props.auction.bids?.length - 1].amount : 0).toLocaleString('de-CH', { minimumFractionDigits: 0 }) + ' ' + t('car_card_chf_title')}</label>
                    <label className="text">{formatTimeLeft(props.auction.bids ? props.auction.bids[props.auction.bids?.length - 1].created : 0, t)}</label>
                    {/* <label className="text">{new Date(props.auction.bids ? props.auction.bids[0].created : 0).toLocaleString('de-CH', { year: 'numeric', month: 'short', day: 'numeric' })}</label> */}
                </div>
            </div>
        </li>
    )
}
