import { useTranslation } from 'react-i18next'
import './toggle.scss'

interface ToggleProps {
    enabled: boolean
    onChange: (enabled: boolean) => void
}

export const Toggle = (props: ToggleProps) => {
    const { t, i18n } = useTranslation()

    return (
        <div
            className={`toggle ${props.enabled ? 'active' : ''} ${i18n.language.includes('fr') ? `fr` : ``}`}
            onClick={() => {
                props.onChange(!props.enabled)
            }}
        >
            <label className={`type ${props.enabled ? 'active-on' : 'inactive-on'}`}>{t('button_on')}</label>

            <label className={`type ${!props.enabled ? 'inactive-off' : 'active-off'}`}>{t('button_off')}</label>
        </div>
    )
}
