import { useTranslation } from 'react-i18next'
import { Button, Dropdown } from '../../../../components'
import { ChangeEvent, useEffect, useRef } from 'react'
import { InputField } from '../../../../components/field'
import { useAppSelector } from '../../../../redux'
import { useDispatch } from 'react-redux'
import { UpdateCarsParams } from '../../../../redux/actions'
import { filterData, getCars } from '../../../../api'
import './filter.scss'

interface FilterProps {
    isHidden: boolean
    onCloseClick: () => void
}

export const Filter = (props: FilterProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { make, model, startYear, endYear, batteryCapacity, minMileage, maxMileage } = useAppSelector((state) => state.cars)
    const { makeArray, modelArray, yearArray, count, minMileageFilter, maxMileageFilter } = useAppSelector((state) => state.cars.filters)

    const ref = useRef<HTMLDivElement>(null)

    function assertIsNode(e: EventTarget | null): asserts e is Node {
        if (!e || !('nodeType' in e)) {
            throw new Error(`Node expected`)
        }
    }
    useEffect(() => {
        const handleOutSideClick = (e: MouseEvent) => {
            assertIsNode(e.target)

            if (!ref.current?.contains(e.target) && (e.target as Element).className != 'filter-button' && (e.target as Element).className != 'filter-img' && (e.target as Element).className != 'title ') {
                props.onCloseClick()
            }
        }

        if (!props.isHidden) {
            window.addEventListener('mousedown', handleOutSideClick)
        }
        return () => {
            window.removeEventListener('mousedown', handleOutSideClick)
        }
    }, [ref, props.isHidden])

    const onCancelClick = async () => {
        dispatch(UpdateCarsParams({ make: null, model: null, startYear: null, endYear: null, minMileage: null, maxMileage: null, batteryCapacity: null }))

        await filterData()
        await getCars(true)
    }

    const onViewResultClick = async () => {
        await getCars(true)
    }

    const onMinMileageChange = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        if (Number(event.target.value) <= Number(maxMileageFilter)) {
            dispatch(UpdateCarsParams({ minMileage: event.target.value }))
        }

        if (event.target.value.length != 0 && Number(event.target.value) >= Number(minMileageFilter) && Number(event.target.value) <= Number(maxMileageFilter)) {
            await filterData()
        }
    }

    const onMaxMileageChange = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        if (Number(event.target.value) <= Number(maxMileageFilter)) {
            dispatch(UpdateCarsParams({ maxMileage: event.target.value }))
        }

        if (event.target.value.length != 0 && Number(event.target.value) >= Number(minMileageFilter) && Number(event.target.value) <= Number(maxMileageFilter)) {
            await filterData()
        }
    }

    const getMakeArray = () => {
        const items: [{ id: string; value: string }] = [{ id: '', value: t('modal_report_select_title') }]
        makeArray.map((item, index) => items.push({ id: item, value: item }))
        items.shift()

        items.sort(function (a, b) {
            return a.value.localeCompare(b.value)
        })
        return items
    }

    const getModelArray = () => {
        const items: [{ id: string; value: string }] = [{ id: '', value: t('modal_report_select_title') }]
        modelArray.map((item, index) => items.push({ id: item, value: item }))
        items.shift()

        items.sort(function (a, b) {
            return a.value.localeCompare(b.value)
        })
        return items
    }

    const getYearArray = () => {
        const items: [{ id: number; value: string }] = [{ id: -1, value: t('modal_report_select_title') }]
        yearArray.map((item, index) => items.push({ id: item, value: item.toString() }))
        items.shift()

        items.sort(function (a, b) {
            return a.value.localeCompare(b.value)
        })
        return items
    }

    const getBatteryCapacityArray = () => {
        const items: [{ id: number; value: string }] = [{ id: -1, value: t('modal_report_select_title') }]
        items.push({ id: 0, value: 'min. 0 kWh' })
        items.push({ id: 30, value: 'min. 30 kWh' })
        items.push({ id: 50, value: 'min. 50 kWh' })
        items.push({ id: 75, value: 'min. 75 kWh' })
        items.push({ id: 100, value: 'min. 100 kWh' })

        items.shift()
        return items
    }

    return (
        <div className={`filter ${props.isHidden ? `hide` : `show`}`} ref={ref}>
            <div className="filter__title-div">
                <h2 className="title">{t('auctions_filters_button')}</h2>
                <img
                    className={`close`}
                    alt="Close icon"
                    src="/images/ic_close.svg"
                    onClick={() => {
                        onCancelClick()

                        props.onCloseClick()
                    }}
                />
            </div>
            <Dropdown
                deleteEnabled={false}
                selectItemTitle={t('filter_make_placeholder')}
                value={make}
                items={getMakeArray()}
                error={''}
                onChange={async (id: string) => {
                    dispatch(UpdateCarsParams({ make: id }))
                    await filterData()
                }}
            />

            <Dropdown
                deleteEnabled={false}
                selectItemTitle={t('filter_maodel_placeholder')}
                value={model}
                items={getModelArray()}
                error={''}
                onChange={async (id: string) => {
                    dispatch(UpdateCarsParams({ model: id }))
                    await filterData()
                }}
            />

            <div className="filter__year-div">
                <label className="title">{t('filter_year_title')}</label>
                <Dropdown
                    deleteEnabled={false}
                    selectItemTitle={t('filter_select_placeholder')}
                    value={startYear}
                    items={getYearArray()}
                    error={''}
                    onChange={async (id: string) => {
                        dispatch(UpdateCarsParams({ startYear: id }))
                        await filterData()
                    }}
                />

                <label className="title">-</label>
                <Dropdown
                    deleteEnabled={false}
                    selectItemTitle={t('filter_select_placeholder')}
                    value={endYear}
                    items={getYearArray()}
                    error={''}
                    onChange={async (id: string) => {
                        dispatch(UpdateCarsParams({ endYear: id }))
                        await filterData()
                    }}
                />
            </div>

            <div className="filter__mileage-div">
                <label className="title">{t('filter_milage_title')}</label>

                <InputField
                    type="number"
                    min={minMileageFilter != null ? Number(minMileageFilter) : 0}
                    max={maxMileageFilter != null ? Number(maxMileageFilter) : 0}
                    field={{
                        error: '',
                        value: minMileage ?? '',
                    }}
                    onChange={onMinMileageChange}
                    placeholder={t('filter_min_placeholder')}
                />

                <label className="title">-</label>
                <InputField
                    type="number"
                    min={minMileageFilter != null ? Number(minMileageFilter) : 0}
                    max={maxMileageFilter != null ? Number(maxMileageFilter) : 0}
                    field={{
                        error: '',
                        value: maxMileage ?? '',
                    }}
                    onChange={onMaxMileageChange}
                    placeholder={t('filter_max_placeholder')}
                />
            </div>

            <div className="filter__battery-div">
                <label className="title">{t('filter_battery_capacity_title')}</label>

                <Dropdown
                    deleteEnabled={false}
                    selectItemTitle={t('filter_select_placeholder')}
                    value={batteryCapacity}
                    items={getBatteryCapacityArray()}
                    error={''}
                    onChange={async (id: number) => {
                        dispatch(UpdateCarsParams({ batteryCapacity: id }))
                        await filterData()
                    }}
                />
            </div>

            <div className="filter__button-div">
                <Button
                    default={true}
                    title={t('filter_cancel_button')}
                    onClick={() => {
                        onCancelClick()
                        props.onCloseClick()
                    }}
                />

                <Button
                    title={t('filter_view_result_button') + `${count != null ? ' (' + count + ')' : ''}`}
                    onClick={(e) => {
                        onViewResultClick()
                        props.onCloseClick()
                    }}
                />
            </div>
        </div>
    )
}
