import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../redux'
import { LoadMoreButton } from '../../../../components'
import { CarsBoughtPagination } from '../../../../redux/actions'
import { getCarsBought } from '../../../../api'
import { CarsReservePriceNotMetElement } from './components/cars-reserve-price-not-met-element'
import { CarsBoughtElement } from './components/cars-bought-element'
import './cars-bought.tab.scss'

interface CarsBoughtTabProps {}

export const CarsBoughtTab = (props: CarsBoughtTabProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { reservePriceNotMetCars, bidOnCars, pagination } = useAppSelector((state) => state.user.carsBought)

    const onLoadMoreClick = async () => {
        dispatch(CarsBoughtPagination({}))
        await getCarsBought()
    }

    return (
        <main className="tab tab-cars-bought">
            <div className="tab-div">
                <div className="inner-container-div">
                    <label className={`title`}>{t('my_ev_garage_reserve_price_not_met_title') + ' (' + pagination.notMetCount + ')'}</label>

                    <ul className={`car-list ${pagination.notMetCount > 0 ? `` : `hidden`}`}>
                        <div className={`divider`} />
                        {reservePriceNotMetCars.map((car, index) => (
                            <CarsReservePriceNotMetElement car={car} />
                        ))}
                    </ul>
                </div>

                <div className="inner-container-div">
                    <label className={`title`}>{t('my_ev_garage_cars_bought_bid_on_title') + ' (' + pagination.bidOnCount + ')'}</label>

                    <ul className={`car-list ${pagination.bidOnCount > 0 ? `` : `hidden`}`}>
                        <div className={`divider`} />
                        {bidOnCars.map((car, index) => (
                            <CarsBoughtElement car={car} />
                        ))}
                    </ul>
                </div>

                <div className={`bottom-div ${pagination.bidOnCount + pagination.notMetCount > 12 ? `` : `hidden`}`}>
                    <LoadMoreButton onClick={onLoadMoreClick} />
                </div>
            </div>
        </main>
    )
}
