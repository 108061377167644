import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../redux'
import { useEffect } from 'react'
import { getTermsAndConditions } from '../../api'
import './terms.page.scss'

export const TermsPage = () => {
    const { t, i18n } = useTranslation()
    const terms = useAppSelector((state) => state.terms.terms)

    useEffect(() => {
        getTermsAndConditions()
    }, [i18n.language])

    return (
        <main className="page page-terms">
            <div className="main-div">
                <h2>{t('terms_and_conditions_title')}</h2>
                <div className="container-div">{terms?.contentHtml && <div className="content" dangerouslySetInnerHTML={{ __html: terms.contentHtml }} />}</div>
            </div>
        </main>
    )
}
