import { ChangeEvent, KeyboardEvent, MouseEvent, useRef } from 'react'
import './search.field.scss'
import '../field.scss'

interface SearchFieldProps {
    value?: string

    placeholder?: string
    closeAlwaysVisible?: boolean

    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    onSearch?: (event: KeyboardEvent<HTMLInputElement>) => void
    onClose: () => void
}

export const SearchField = (props: SearchFieldProps) => {
    const inputRef = useRef<HTMLInputElement>(null)

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === `Enter` && props.onSearch) {
            props.onSearch(e)
        }
    }

    const onClose = (e: MouseEvent) => {
        e.preventDefault()
        inputRef.current!!.value = ''
        props.onClose()
    }

    return (
        <div className="field field--search">
            <img alt="Search icon" className="search-img" src="/images/ic_search.svg" />

            <input ref={inputRef} onChange={props.onChange} placeholder={props.placeholder} required={false} type="search" onKeyDown={onKeyDown} />

            <img alt="Close icon" className={`close-img ${props.value === '' && props.closeAlwaysVisible !== true ? `hidden` : ''}`} src="/images/ic_close.svg" onClick={onClose} />
        </div>
    )
}
