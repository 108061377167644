import { createAction } from '@reduxjs/toolkit'
import { Car } from '../../../entity'

interface Payload {
    cars: Car[]
    page: number
    pageSize: number
    draftCount: number
    approvedCount: number
    notMetCount: number
    reset: boolean
}

export const CarsAuctionedSuccess = createAction<Payload>('CARS__CARS_AUCTIONED_SUCCESS')
