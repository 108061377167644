import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../redux'
import { Filter } from './components/filter/filter'
import { EvDatabaseCarsPagination } from '../../redux/actions'
import { getEvDatabaseCars } from '../../api'
import { EvDatabaseTable } from './components/table/ev-database-table'
import { FilterModal } from './components/filter/filter-modal'
import { useState } from 'react'
import './ev-database.page.scss'

interface EvDatabasePageProps {
    onCarSelected?: CallableFunction
}

export const EvDatabasePage = (props: EvDatabasePageProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { cars } = useAppSelector((state) => state.evDatabase)
    const { page, pageSize, count } = useAppSelector((state) => state.evDatabase.pagination)

    let [filterModalVisible, setFilterModalVisible] = useState(false)

    const _onPageChange = async (pageNr: number) => {
        dispatch(EvDatabaseCarsPagination({ page: pageNr }))
        await getEvDatabaseCars(false)
    }

    return (
        <main className="page page-ev-database">
            <div className="main-div">
                <div className="content-with-filter-div">
                    <div className="filter-div">
                        <Filter isInModal={false} onClose={async () => {}} />
                    </div>
                    <div className="content-div">
                        <div className="content-div__title">
                            <h2>{t('ev_database_title')}</h2>
                            <div
                                className="content-div__title__button"
                                onClick={() => {
                                    setFilterModalVisible(!filterModalVisible)
                                }}
                            >
                                <img src="/images/ev-database/ic_open_filter.svg"></img>
                            </div>
                        </div>
                        <div className="content-div-title">
                            <p>{t('ev_database_body')}</p>
                            <h4>{t('ev_database_search_title')}</h4>
                        </div>
                        <EvDatabaseTable dataset={cars ?? []} pagination={{ page: page, pageSize: pageSize, count: count, onPageChange: _onPageChange }} />
                    </div>
                </div>
            </div>
            {filterModalVisible && (
                <FilterModal
                    message=""
                    onClose={() => {
                        setFilterModalVisible(false)
                    }}
                ></FilterModal>
            )}
        </main>
    )
}
