import { createReducer } from '@reduxjs/toolkit'
import { HowItWorksState, PrivacyState } from '../../states'
import { HowItWorksSection } from '../../../enum/how-it-works-section-enum'
import { UpdateHowItWorksSection } from '../../actions'

const initialState: HowItWorksState = {
    currentSection: HowItWorksSection.HowItWorks,
}

export const howItWorksReducer = createReducer(initialState, (builder) => {
    builder.addCase(UpdateHowItWorksSection, (state, action) => {
        state.currentSection = action.payload.currentSection
    })
})
