import { store } from '../../redux'
import { ErrorDTO, HasNewNotificationsDTO } from '../../dto'
import { ErrorMessage } from '../../redux/actions'
import { NetworkCall } from '../../utils'
import {NewNotification} from "../../redux/actions/notification";

export const hasUnreadNotifications = async () => {
    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')
    if (token === null) {
        return
    }

    return await new NetworkCall('GET', `/1.0.0/notifications/unread`)
        .setHeader( `Authorization`, `Bearer ${ token }` )
        .then(200, HasNewNotificationsDTO, (responseBody) => {
            store.dispatch(NewNotification(responseBody))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
