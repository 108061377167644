import { PropsWithChildren, forwardRef } from 'react'
import './section.scss'
import { SectionTitle } from './section-title'
import { SectionBody } from './section-body'

interface SectionProps {
    title?: string
    body?: string
    isSub?: boolean
    isGray?: boolean
    additionalClassNames?: string
}

export const Section = forwardRef<HTMLDivElement, PropsWithChildren<SectionProps>>((props, ref) => {
    const _getSectionClassName = () => ['section', props.isSub ? 'sub' : '', props.isGray ? 'gray' : '', props.additionalClassNames ?? ''].join(' ').trim()

    const _getTitle = () => (
        <>
            {props.title && (
                <SectionTitle>
                    {props.title && !props.isSub && <h2 dangerouslySetInnerHTML={{ __html: props.title }}></h2>}
                    {props.title && props.isSub && (
                        <div className={'section-title'}>
                            <h3 dangerouslySetInnerHTML={{ __html: props.title }}></h3>
                        </div>
                    )}
                </SectionTitle>
            )}
        </>
    )

    const _getBody = () => (
        <>
            {props.body && (
                <SectionBody>
                    <div dangerouslySetInnerHTML={{ __html: props.body }}></div>
                </SectionBody>
            )}
            {props.children && <SectionBody>{props.children}</SectionBody>}
        </>
    )

    return (
        <>
            {(props.title || props.body || props.children) && (
                <div className={_getSectionClassName()} ref={ref}>
                    {_getTitle()}
                    {_getBody()}
                </div>
            )}
        </>
    )
})
