import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../redux'
import { useEffect } from 'react'
import { getPrivacyPolicy } from '../../api'
import './privacy.page.scss'

interface PrivacyPageProps {}

export const PrivacyPage = (props: PrivacyPageProps) => {
    const { t, i18n } = useTranslation()

    const { privacy } = useAppSelector((state) => state.privacy)

    useEffect(() => {
        getPrivacyPolicy()
    }, [i18n.language])

    return (
        <main className="page page-privacy">
            <div className="main-div">
                <h2>{t('privacy_title')}</h2>
                <div className="container-div">{privacy?.contentHtml && <div className="content" dangerouslySetInnerHTML={{ __html: privacy?.contentHtml }} />}</div>
            </div>
        </main>
    )
}
