import { configureStore } from '@reduxjs/toolkit'
import {
    baseReducer,
    signUpReducer,
    howItWorksReducer,
    contactUsReducer,
    termsReducer,
    loginReducer,
    carsReducer,
    carReducer,
    userReducer,
    commentsReducer,
    questionsReducer,
    bidsReducer,
    impressumReducer,
    privacyReducer,
    aboutUsReducer,
    evDatabaseReducer,
    notificationReducer
} from './reducers'
import { faqsReducer } from './reducers/faqs'

export const store = configureStore({
    reducer: {
        base: baseReducer,
        signUp: signUpReducer,
        login: loginReducer,
        user: userReducer,
        cars: carsReducer,
        car: carReducer,
        comments: commentsReducer,
        questions: questionsReducer,
        bids: bidsReducer,
        terms: termsReducer,
        impressum: impressumReducer,
        privacy: privacyReducer,
        contactUs: contactUsReducer,
        faqs: faqsReducer,
        howItWorks: howItWorksReducer,
        aboutUs: aboutUsReducer,
        evDatabase: evDatabaseReducer,
        notifications: notificationReducer
    },
})
