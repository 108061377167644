import { useTranslation } from 'react-i18next'
import './photo-checklist-reminders.scss'

interface PhotoChecklistRemindersProps {}

export const PhotoChecklistReminders = (props: PhotoChecklistRemindersProps) => {
    const { t } = useTranslation()

    return (
        <div className="photo-checklist-reminders">
            <div className="photo-checklist-reminders__card">
                <div className="photo-checklist-reminders__card__title">
                    <h3 dangerouslySetInnerHTML={{ __html: '1.' }}></h3>
                </div>
                <div className="photo-checklist-reminders__card__body" dangerouslySetInnerHTML={{ __html: t('guide_photo_checklist_guide_reminder_1') }}></div>
            </div>
            <div className="photo-checklist-reminders__card">
                <div className="photo-checklist-reminders__card__title">
                    <h3 dangerouslySetInnerHTML={{ __html: '2.' }}></h3>
                </div>
                <div className="photo-checklist-reminders__card__body" dangerouslySetInnerHTML={{ __html: t('guide_photo_checklist_guide_reminder_2') }}></div>
            </div>
            <div className="photo-checklist-reminders__card">
                <div className="photo-checklist-reminders__card__title">
                    <h3 dangerouslySetInnerHTML={{ __html: '2.' }}></h3>
                </div>
                <div className="photo-checklist-reminders__card__body" dangerouslySetInnerHTML={{ __html: t('guide_photo_checklist_guide_reminder_3') }}></div>
            </div>
        </div>
    )
}
