import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../redux'
import { ResetCarForm, ShowLoginModal, UpdateAddCarTab, UpdateCurrentSection, UpdateHowItWorksSection, UpdateMenu } from '../../../redux/actions'
import { Button } from '../../buttons'
import { AboutUsSection, HowItWorksSection, Menu } from '../../../enum'
import { logout } from '../../../api/users'
import { useState } from 'react'
import { LanguageMenu } from '../language-menu/language-menu'
import './hamburger-menu.scss'

interface HamburgerMenuProps {
    open: boolean
    menu: number
    onClose: () => void
}

export const HamburgerMenu = (props: HamburgerMenuProps) => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const user = useAppSelector((state) => state.login.user)
    const menu = useAppSelector((state) => state.base.menu)

    const [userMenuVisible, setUserMenuVisible] = useState(false)
    const [howItWorksMenuVisible, setHowItWorksMenuVisible] = useState(false)
    const [whyMenuVisible, setWhyMenuVisible] = useState(false)
    const [linksMenuVisible, setLinksMenuVisible] = useState(false)

    const [languageMenuVisible, setLanguageMenuVisible] = useState(false)

    const onMenuItemClick = (id: number, close: boolean = false) => {
        dispatch(UpdateMenu({ menu: id }))
        if (close) {
            props.onClose()
        }
    }

    const onSellCarClick = () => {
        if (user) {
            if (user.address == null) {
                //setRegisterToModalVisible(!registerToModalVisible)
                navigate('/my-profile')
                props.onClose()
            } else {
                dispatch(ResetCarForm({}))
                dispatch(UpdateAddCarTab({ tab: 'cardetails' }))
                onMenuItemClick(Menu.SellACar)
                navigate('/add-car')
                props.onClose()
            }
        } else {
            dispatch(ShowLoginModal({ visible: true, carId: null }))

            props.onClose()
        }
    }

    const onChangeLanguageClick = (lng: string) => {
        i18n.changeLanguage(lng)
        localStorage.setItem('language', lng)

        setLanguageMenuVisible(!languageMenuVisible)
    }

    return (
        <div className={`hamburger-menu ${props.open ? `` : `hidden`}`}>
            <div className="hamburger-menu__header">
                <div className="language-div" onClick={() => setLanguageMenuVisible(!languageMenuVisible)}>
                    <label className="language">{`${i18n.language.includes('en') ? t('header_langugage_en_short_button') : i18n.language.includes('de') ? t('header_langugage_de_short_button') : t('header_langugage_fr_short_button')}`}</label>

                    <img className={`arrow-img ${languageMenuVisible ? 'rotate' : ''}`} alt="Arrow icon" src="/images/ic_arrow_down.svg" />

                    <LanguageMenu
                        type="mobile"
                        open={languageMenuVisible}
                        onItemClick={onChangeLanguageClick}
                        onClose={() => {
                            setLanguageMenuVisible(!languageMenuVisible)
                        }}
                    />
                </div>

                <img className="close-button" alt="Close button" src="/images/ic_close.svg" onClick={() => props.onClose()} />
            </div>

            <div className="hamburger-menu__login-div">
                <div className={`login-button-div ${user ? `hidden` : ``}`}>
                    <Button
                        type={'black'}
                        title={t('header_sign_up_button')}
                        onClick={() => {
                            dispatch(ShowLoginModal({ visible: true, carId: null }))
                            props.onClose()
                        }}
                    />
                </div>

                <div className={`user-div ${user ? `` : `hidden`}`} onClick={() => setUserMenuVisible(!userMenuVisible)}>
                    <img className={`profile-img`} alt="Profile icon" src="/images/ic_profile.svg" />

                    <div className={`username-div`}>
                        <label className={`username`}>{user && user.username ? user.username : ''}</label>
                        <label className={`address`}>{user && user.address ? user.address?.postalCode + ' ' + user.address?.city : ''}</label>
                    </div>
                    <img className={`arrow-img ${userMenuVisible ? 'rotate' : ''}`} alt="Arrow icon" src="/images/ic_arrow_down.svg" />
                </div>

                <div className={`user-profile-menu ${userMenuVisible ? `` : `hidden`}`}>
                    <div className={`user-profile-menu__divider-div`} />

                    <ul className="user-profile-menu__menu-div">
                        <li
                            className={`menu-item`}
                            onClick={() => {
                                setUserMenuVisible(!userMenuVisible)
                                navigate('/my-profile')
                                dispatch(UpdateMenu({ menu: Menu.MyProfile }))
                                props.onClose()
                            }}
                        >
                            <label className={`title ${menu === Menu.MyProfile ? `active` : ``}`}>{t('header_profile_my_profile_button')}</label>
                        </li>
                        <li
                            className={`menu-item`}
                            onClick={() => {
                                setUserMenuVisible(!userMenuVisible)
                                navigate('/my-ev-garage')
                                dispatch(UpdateMenu({ menu: Menu.MyEVGarage }))
                                props.onClose()
                            }}
                        >
                            <label className={`title  ${menu === Menu.MyEVGarage ? `active` : ``}`}>{t('header_profile_my_ev_garage_button')}</label>
                        </li>
                        <li
                            className={`menu-item`}
                            onClick={() => {
                                setUserMenuVisible(!userMenuVisible)
                                navigate('/my-activity')
                                dispatch(UpdateMenu({ menu: Menu.MyActivity }))
                                props.onClose()
                            }}
                        >
                            <label className={`title  ${menu === Menu.MyActivity ? `active` : ``}`}>{t('header_profile_my_activity_button')}</label>
                        </li>
                        <li
                            className={`menu-item`}
                            onClick={() => {
                                setUserMenuVisible(!userMenuVisible)
                                navigate('/notification-settings')
                                dispatch(UpdateMenu({ menu: Menu.NotificationSettings }))
                                props.onClose()
                            }}
                        >
                            <label className={`title  ${menu === Menu.NotificationSettings ? `active` : ``}`}>{t('header_profile_notification_settings_button')}</label>
                        </li>
                        <li
                            className={`menu-item`}
                            onClick={() => {
                                setUserMenuVisible(!userMenuVisible)
                                dispatch(UpdateMenu({ menu: Menu.Auctions }))
                                logout()
                                navigate(`/`)
                                props.onClose()
                            }}
                        >
                            <label className={`title`}>{t('header_profile_log_out_button')}</label>
                            <img className={`logout-img`} alt="Logout icon" src="/images/ic_logout.svg" />
                        </li>
                    </ul>
                </div>
            </div>

            <ul className="hamburger-menu__menu-list">
                <li className="menu-item">
                    <Link className={`title ${Menu.Auctions === props.menu ? `active` : ``}`} to={'/'} onClick={() => onMenuItemClick(Menu.Auctions, true)}>
                        {t('header_menu_auctions_button')}
                    </Link>
                </li>

                <li className="menu-item column">
                    <div className="inner-div">
                        <Link className={`title `} to={'how-it-works'} onClick={() => setHowItWorksMenuVisible(!howItWorksMenuVisible)}>
                            {t('header_menu_how_its_works_button')}
                        </Link>
                        <img className={`arrow-img ${howItWorksMenuVisible ? 'rotate' : ''}`} alt="Arrow icon" src="/images/ic_arrow_down.svg" />
                    </div>

                    <ul className={`sub-menu-list ${howItWorksMenuVisible ? '' : 'hidden'}`}>
                        <li
                            className={`menu-item`}
                            onClick={() => {
                                setHowItWorksMenuVisible(!howItWorksMenuVisible)
                                navigate('/how-it-works')
                                dispatch(UpdateMenu({ menu: Menu.HowItWorks_HowItWorks }))
                                dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.HowItWorks }))

                                props.onClose()
                            }}
                        >
                            <label className={`title ${menu === Menu.HowItWorks_HowItWorks ? `active` : ``}`}>{t('header_menu_how_its_works_button')}</label>
                        </li>

                        <li
                            className={`menu-item`}
                            onClick={() => {
                                setHowItWorksMenuVisible(!howItWorksMenuVisible)
                                navigate('/how-it-works')
                                dispatch(UpdateMenu({ menu: Menu.HowItWorks_BuyingElectricCar }))
                                dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.BuyingCar }))

                                props.onClose()
                            }}
                        >
                            <label className={`title ${menu === Menu.HowItWorks_BuyingElectricCar ? `active` : ``}`}>{t('footer_buying_an_electric_car_button')}</label>
                        </li>
                        <li
                            className={`menu-item`}
                            onClick={() => {
                                setHowItWorksMenuVisible(!howItWorksMenuVisible)
                                navigate('/how-it-works')
                                dispatch(UpdateMenu({ menu: Menu.HowItWorks_SellingElectricCar }))
                                dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.SellingCar }))

                                props.onClose()
                            }}
                        >
                            <label className={`title ${menu === Menu.HowItWorks_SellingElectricCar ? `active` : ``}`}>{t('footer_selling_an_electric_car_button')}</label>
                        </li>

                        <li
                            className={`menu-item`}
                            onClick={() => {
                                setHowItWorksMenuVisible(!howItWorksMenuVisible)
                                navigate('/how-it-works')
                                dispatch(UpdateMenu({ menu: Menu.HowItWorks_ConcludingTheSale }))
                                dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.Concluding }))

                                props.onClose()
                            }}
                        >
                            <label className={`title ${menu === Menu.HowItWorks_ConcludingTheSale ? `active` : ``}`}>{t('how_it_works_concluding_menu')}</label>
                        </li>

                        <li
                            className={`menu-item`}
                            onClick={() => {
                                setHowItWorksMenuVisible(!howItWorksMenuVisible)
                                navigate('/how-it-works')
                                dispatch(UpdateMenu({ menu: Menu.HowItWorks_WhatIsReservePrice }))
                                dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.ReservePrice }))

                                props.onClose()
                            }}
                        >
                            <label className={`title ${menu === Menu.HowItWorks_WhatIsReservePrice ? `active` : ``}`}>{t('how_it_works_reserve_price_menu')}</label>
                        </li>

                        <li
                            className={`menu-item`}
                            onClick={() => {
                                setHowItWorksMenuVisible(!howItWorksMenuVisible)
                                navigate('/how-it-works')
                                dispatch(UpdateMenu({ menu: Menu.HowItWorks_BatteryStateOfHealth }))
                                dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.Battery }))

                                props.onClose()
                            }}
                        >
                            <label className={`title ${menu === Menu.HowItWorks_BatteryStateOfHealth ? `active` : ``}`}>{t('how_it_works_battery_menu')}</label>
                        </li>
                    </ul>
                </li>

                <li className="menu-item">
                    <Link className={`title ${Menu.EVDatabase === props.menu ? `active` : ``}`} to={'electric-car-database'} onClick={() => onMenuItemClick(Menu.EVDatabase, true)}>
                        {t('header_menu_ev_database_button')}
                    </Link>
                </li>

                <li className="menu-item column">
                    <div className="inner-div">
                        <Link className={`title`} to={'why-choose'} onClick={() => setWhyMenuVisible(!whyMenuVisible)}>
                            {t('header_menu_why_choose_button')}
                        </Link>
                        <img className={`arrow-img ${whyMenuVisible ? 'rotate' : ''}`} alt="Arrow icon" src="/images/ic_arrow_down.svg" />
                    </div>

                    <ul className={`sub-menu-list ${whyMenuVisible ? '' : 'hidden'}`}>
                        <li
                            className={`menu-item`}
                            onClick={() => {
                                setWhyMenuVisible(!whyMenuVisible)
                                navigate('/why-choose')
                                dispatch(UpdateMenu({ menu: Menu.AboutUs_WhyChooseLotNumberOne }))
                                dispatch(UpdateCurrentSection({ currentSection: AboutUsSection.WhyChoose }))

                                props.onClose()
                            }}
                        >
                            <label className={`title ${menu === Menu.AboutUs_WhyChooseLotNumberOne ? `active` : ``}`}>{t('header_menu_why_choose_button')}</label>
                        </li>
                        <li
                            className={`menu-item`}
                            onClick={() => {
                                setWhyMenuVisible(!whyMenuVisible)
                                navigate('/why-choose')
                                dispatch(UpdateMenu({ menu: Menu.AboutUs_AboutUs }))
                                dispatch(UpdateCurrentSection({ currentSection: AboutUsSection.AboutUs }))

                                props.onClose()
                            }}
                        >
                            <label className={`title ${menu === Menu.AboutUs_AboutUs ? `active` : ``}`}>{t('header_about_us_button')}</label>
                        </li>

                        <li
                            className={`menu-item`}
                            onClick={() => {
                                setWhyMenuVisible(!whyMenuVisible)
                                navigate('/why-choose')
                                dispatch(UpdateMenu({ menu: Menu.AboutUs_YourExclusiveBenefits }))
                                dispatch(UpdateCurrentSection({ currentSection: AboutUsSection.Benefits }))

                                props.onClose()
                            }}
                        >
                            <label className={`title ${menu === Menu.AboutUs_YourExclusiveBenefits ? `active` : ``}`}>{t('header_exclusive_benefits_button')}</label>
                        </li>
                    </ul>
                </li>

                <li className="menu-item column">
                    <div className="inner-div">
                        <Link className={`title ${Menu.WhyGoElectric === props.menu ? `active` : ``}`} to={'why-choose'} onClick={() => setLinksMenuVisible(!linksMenuVisible)}>
                            {t('header_menu_helpful_links_button')}
                        </Link>
                        <img className={`arrow-img ${linksMenuVisible ? 'rotate' : ''}`} alt="Arrow icon" src="/images/ic_arrow_down.svg" />
                    </div>

                    <ul className={`sub-menu-list ${linksMenuVisible ? '' : 'hidden'}`}>
                        <li
                            className={`menu-item`}
                            onClick={() => {
                                setLinksMenuVisible(!linksMenuVisible)
                                navigate('/why-go-electric')
                                dispatch(UpdateMenu({ menu: Menu.WhyGoElectric }))
                                props.onClose()
                            }}
                        >
                            <label className={`title ${menu === Menu.WhyGoElectric ? `active` : ``}`}>{t('footer_why_go_electric_button')}</label>
                        </li>

                        <li
                            className={`menu-item`}
                            onClick={() => {
                                setLinksMenuVisible(!linksMenuVisible)
                                navigate('/guide')
                                dispatch(UpdateMenu({ menu: Menu.Guide }))
                                props.onClose()
                            }}
                        >
                            <label className={`title ${menu === Menu.Guide ? `active` : ``}`}>{t('footer_photo_video_guide_button')}</label>
                        </li>
                        <li
                            className={`menu-item`}
                            onClick={() => {
                                setLinksMenuVisible(!linksMenuVisible)
                                navigate('/faqs')
                                dispatch(UpdateMenu({ menu: Menu.FAQ }))
                                props.onClose()
                            }}
                        >
                            <label className={`title ${menu === Menu.FAQ ? `active` : ``}`}>{t('footer_faqs_button')}</label>
                        </li>
                    </ul>
                </li>
            </ul>

            <div className="sell-a-car-div" onClick={onSellCarClick}>
                <label className="sell-a-car-label"> {t('header_sell_a_car_button')}</label>
                <label className="its-free-label"> {t('header_its_free_button')}</label>
            </div>
        </div>
    )
}
