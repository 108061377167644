import { useEffect, useRef, useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { StickyAuction } from './components'
import { IconButton, ViewPager } from '../../components'
import { CarInformations } from './components/car-information'
import { CommentsQuestionBids } from './components/comments-questions-bids'
import { BidModal, HowBiddingWorksModal, ReportModal, RegisterToModal, CreaditCardModal } from '../../components/modals'
import { useWindowDimensions } from '../../utils'
import Lightbox from 'yet-another-react-lightbox'
import Video from 'yet-another-react-lightbox/plugins/video'
import 'yet-another-react-lightbox/styles.css'
import { useAppSelector } from '../../redux'
import { favourite, getBids, translate } from '../../api'
import { useDispatch } from 'react-redux'
import { ErrorMessage, ShowLoginModal, UpdateAuction } from '../../redux/actions'
import { CarStatusEnum, MediaFileTypeEnum, ReportTypeEnum } from '../../enum'
import { ShowToast } from '../../redux/actions'
import { AuctionBidResponseDTO, AuctionErrorDTO, AuthenticationErrorDTO } from '../../dto'
import { io } from 'socket.io-client'
import { Blurhash } from 'react-blurhash'
import { GalleryItem } from './components/gallery-item/gallery-item'
import { BuyersFeeModal } from '../../components/modals/buyers-fee'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import './auction.page.scss'

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`)

interface AuctionPageProps {}

export const AuctionPage = (props: AuctionPageProps) => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { width } = useWindowDimensions()

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')
    const socket = io(`${process.env.REACT_APP_API_ORIGIN}`, {
        extraHeaders: {
            Authorization: token !== null ? `Bearer ${token}` : '',
        },
    })

    const commentRef = useRef<HTMLDivElement>(null)

    const user = useAppSelector((state) => state.login.user)
    const car = useAppSelector((state) => state.car.car)
    const commentCount = useAppSelector((state) => state.comments.pagination.count)

    const [imageLoaded, setImageLoaded] = useState(false)
    const [reportModalVisible, setReportModalVisible] = useState(false)
    const [reportType, setReportType] = useState(ReportTypeEnum.Car)
    const [reportId, setReportId] = useState(car ? car.id : 0)
    const [bidModalVisible, setBidModalVisible] = useState(false)
    const [registerToModalVisible, setRegisterToModalVisible] = useState(false)
    const [registerToModalType, setRegisterToModalType] = useState<'test-drive' | 'bid'>('test-drive')
    const [howBiddingWorksModalVisible, setHowBiddingWorksModalVisible] = useState(false)
    const [creaditCardModalVisible, setCreaditCardModalVisible] = useState(false)
    const [buyersFeeModalVisible, setBuyersFeeModalVisible] = useState(false)
    const [isFavourite, setIsFavourite] = useState(car?.favourite)
    const [openGallery, setOpenGallery] = useState(false)
    const [primaryImageUrl, setPrimaryImageUrl] = useState(car?.mediaFiles != null && car?.mediaFiles.length !== 0 && car?.mediaFiles!![0].url != null && car?.mediaFiles!![0].url.length !== 0 ? car?.mediaFiles!![0].url : '')
    const [primaryImageIndex, setPrimaryImageIndex] = useState(0)
    const [isSocketConnected, setIsSocketConnected] = useState(socket.connected)
    const [translatedOwnersTake, setTranslatedOwnersTake] = useState('')
    const [sellWithUsVisible, setSellWithUsVisible] = useState(true)

    const iframeContainerRef = useRef<HTMLDivElement>(null)
    const iframeRef = useRef<HTMLIFrameElement>(null)

    const [iframeUrl, setIframeUrl] = useState('')
    const [paymentIntentId, setPaymentIntentId] = useState('')
    const [buyersFee, setBuyersFee] = useState(0)
    const [amount, setAmount] = useState(0)

    var timesRefreshed = 0

    useEffect(() => {
        // if (car?.status !== CarStatusEnum.Approved) {
        //     navigate('/')
        // }

        if (car?.isArchived) {
            navigate('/')
        }
    }, [])

    const onLoad = () => {
        if (timesRefreshed === 1) {
            setIframeUrl('')

            socket.emit('authentication', { auctionId: car?.auctions[0].id, userId: user?.id, amount: amount, buyersFee: buyersFee, paymentIntentId: paymentIntentId })
        }

        timesRefreshed++
    }

    useEffect(() => {
        if (width > 1080) {
            window.addEventListener('scroll', isAuctionSticky)
            return () => {
                window.removeEventListener('scroll', isAuctionSticky)
            }
        } else {
            const stickyAuctionDiv = document.querySelector('.sticky-auction')
            stickyAuctionDiv?.classList.remove('sticky-position')
        }
    }, [width])

    useEffect(() => {
        setPrimaryImageUrl(car?.mediaFiles != null && car?.mediaFiles.length !== 0 && car?.mediaFiles!![0].url != null && car?.mediaFiles!![0].url.length !== 0 ? car?.mediaFiles!![0].url : '')
    }, [car])

    useEffect(() => {
        // socket.connect()

        function onConnect() {
            setIsSocketConnected(true)
        }

        function onDisconnect() {
            setIsSocketConnected(false)
        }

        function onAuctionEvent(auctionResponse: AuctionBidResponseDTO) {
            if (car?.auctions[0].id === auctionResponse.auction.id) {
                dispatch(UpdateAuction({ auction: auctionResponse.auction, sameUserId: user?.id === auctionResponse.userId }))

                getBids(car.auctions[0].id, true)
            }
        }

        function onErrorEvent(auctionError: AuctionErrorDTO) {
            if (user?.id === auctionError.userId && car?.auctions[0].id === auctionError.auctionId) {
                dispatch(ErrorMessage({ title: '', message: auctionError.error.message, code: auctionError.error.code }))
            }
        }

        function onAuthenticationErrorEvent(auctionError: AuthenticationErrorDTO) {
            if (user?.id === auctionError.userId && car?.auctions[0].id === auctionError.auctionId) {
                setIframeUrl(auctionError.url)

                setPaymentIntentId(auctionError.paymentIntentId)
                setAmount(auctionError.amount)
                setBuyersFee(auctionError.buyersFee)
            }
        }

        socket.on('connect', onConnect)
        socket.on('disconnect', onDisconnect)
        socket.on('auction', onAuctionEvent)
        socket.on('error', onErrorEvent)
        socket.on('authenticationError', onAuthenticationErrorEvent)

        return () => {
            socket.off('connect', onConnect)
            socket.off('disconnect', onDisconnect)
            socket.off('auction', onAuctionEvent)
            socket.off('error', onErrorEvent)
            socket.off('authenticationError', onAuthenticationErrorEvent)

            //socket.disconnect()
        }
    }, [])

    const isAuctionSticky = (e: Event) => {
        const stickyAuctionDiv = document.querySelector('.sticky-auction')

        if (window.scrollY >= 250) {
            stickyAuctionDiv?.classList.add('sticky-position')
        } else {
            stickyAuctionDiv?.classList.remove('sticky-position')
        }
    }

    const onMagnifyingGlassClick = () => {
        //setPrimaryImageIndex(0)
        setOpenGallery(true)
    }

    const onFavouriteClick = async () => {
        if (user) {
            await favourite({
                id: car!!.id,
                onSuccess() {
                    setIsFavourite(!isFavourite)
                },
            })
        } else {
            dispatch(ShowLoginModal({ visible: true, carId: car ? car.id : null }))
        }
    }

    const onReportClick = () => {
        if (user) {
            setReportModalVisible(!reportModalVisible)
        } else {
            dispatch(ShowLoginModal({ visible: true, carId: car ? car.id : null }))
        }
    }

    const onBidClick = () => {
        if (user) {
            if (user.role == null) {
                navigate('/my-profile')
            }

            if (user.address == null || user.stripeCardId == null) {
                setRegisterToModalType('bid')
                setRegisterToModalVisible(!registerToModalVisible)
            } else {
                setBidModalVisible(!bidModalVisible)
            }
        } else {
            dispatch(ShowLoginModal({ visible: true, carId: car ? car.id : null }))
        }
    }

    const onAskTestDriveClick = () => {
        if (user) {
            if (user.role == null) {
                navigate('/my-profile')
            } else {
                setRegisterToModalType('test-drive')
                setRegisterToModalVisible(!registerToModalVisible)
            }
        } else {
            dispatch(ShowLoginModal({ visible: true, carId: car ? car.id : null }))
        }
    }

    const getGalleryItems = () => {
        const cards: React.ReactElement[] = []

        car?.mediaFiles!!.map((mediaFile, index) =>
            cards.push(
                <GalleryItem
                    index={index}
                    mediaFile={mediaFile}
                    onClick={() => {
                        setPrimaryImageIndex(index)

                        if (mediaFile.type === MediaFileTypeEnum.Photo) {
                            setPrimaryImageUrl(mediaFile.url ?? '')
                        } else {
                            setOpenGallery(true)
                        }
                    }}
                />
            )
        )

        return cards
    }

    const getSlides = () => {
        let slides: any[] = []
        car?.mediaFiles!!.map((mediaFile, index) => {
            if (mediaFile.url != null && mediaFile.url.length !== 0) {
                if (mediaFile.type === MediaFileTypeEnum.Photo) {
                    slides.push({ src: mediaFile.url, type: 'image' })
                } else {
                    slides.push({
                        type: 'video',
                        poster: mediaFile.thumbnail,
                        sources: [
                            {
                                src: mediaFile.url,
                                type: 'video/mp4',
                            },
                        ],
                    })
                }
            }
        })

        return slides
    }

    const onTranslateClick = async () => {
        //  if (user) {
        if ((car?.carDetail?.ownersTake ?? '').length !== 0) {
            await translate({
                text: car?.carDetail?.ownersTake ?? '',
                language: i18n.language.includes('de') ? 'de' : i18n.language.includes('fr') ? 'fr' : 'en',
                onSuccess(text: string) {
                    setTranslatedOwnersTake(text)
                },
            })
        }
        // } else {
        //     dispatch(ShowLoginModal({ visible: true }))
        // }
    }

    const getReservePriceTitle = () => {
        if (car?.reservePrice == null || car?.reservePrice === 0) {
            return t('car_card_no_reserve_title')
        } else if (car?.reservePriceDeleted) {
            return t('car_card_reserve_price_deleted_title')
        } else if (car?.reservePriceLowered) {
            return t('car_card_reserve_price_lowered_title')
        } else {
            return ''
        }
    }

    const getReservePriceTitleStyle = () => {
        if (car?.reservePrice == null || car?.reservePrice === 0) {
            return ``
        } else if (car?.reservePriceDeleted) {
            return 'reserve-price-deleted'
        } else if (car?.reservePriceLowered) {
            return 'reserve-price-deleted'
        } else {
            return `hidden`
        }
    }

    const onClose = (e: MouseEvent) => {
        e.preventDefault()
        setSellWithUsVisible(!sellWithUsVisible)
    }

    return (
        <Elements stripe={stripePromise}>
            <main className="page page-auction">
                <div className="main-div">
                    <div className="details-div">
                        <div className={`left-div ${sellWithUsVisible ? `` : 'small'}`}>
                            <div className="picture-div">
                                <div className="car-img-div">
                                    {car && car.mediaFiles && car.mediaFiles.length > 0 && <Blurhash className={`blurhash-img ${imageLoaded ? `hidden` : ``}`} hash={car && car.mediaFiles && car.mediaFiles.length > 0 ? car.mediaFiles[0].blurHash : ''} width={'100%'} height={'100%'} resolutionX={32} resolutionY={32} punch={1} />}

                                    <img
                                        alt="Car image"
                                        className="car-img"
                                        src={primaryImageUrl != null && primaryImageUrl.length != 0 ? primaryImageUrl : '/images/ic_no_image.svg'}
                                        onLoad={() => {
                                            setImageLoaded(true)
                                        }}
                                    />

                                    <img alt="Magnifying glass icon" className="icon-img" src={'/images/ic_search_white.svg'} onClick={onMagnifyingGlassClick} />
                                </div>

                                <div className="car-details-div">
                                    <div className="detail-item-div">
                                        <label className="title">{t('auction_battery_capacity_title')}</label>
                                        <div className="detail-item-value-div">
                                            <label className="value">{car?.batteryCapacity.toString().replace('.', ',')} </label>
                                            <label className="unit">{t('car_card_kWh_title')}</label>
                                        </div>
                                    </div>

                                    <div className="divider" />

                                    <div className="detail-item-div">
                                        <label className="title">{t('auction_horsepower_title')}</label>

                                        <div className="detail-item-value-div">
                                            <label className="value">{car?.horsepower} </label>
                                            <label className="unit">{t('auction_ps_title')}</label>
                                        </div>
                                    </div>
                                    <div className="divider" />

                                    <div className="detail-item-div">
                                        <label className="title">{t('auction_range_title')}</label>
                                        <div className="detail-item-value-div">
                                            <label className="value">{car?.manufacturerEstimatedRange}* </label>
                                            <label className="unit">{t('car_card_km_title')}</label>
                                        </div>
                                        <label className="detail-item-extra-info-div">*{t('car_card_km_extra_info')}</label>
                                    </div>
                                    <div className="divider" />

                                    <div className="detail-item-div">
                                        <label className="title">{t('auction_mileage_title')}</label>
                                        <div className="detail-item-value-div">
                                            <label className="value">{car?.mileage.toLocaleString()} </label>
                                            <label className="unit">{t('car_card_km_title')}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="gallery-div">
                                <ViewPager cards={getGalleryItems()} />
                            </div>
                        </div>

                        <div className="right-div">
                            <div className={`sell-with-us-div ${sellWithUsVisible ? `` : 'hidden'}`}>
                                <h4 className="sell-with-us-label">{t('auction_sell_with_us_title')}</h4>
                                <img alt="Close icon" className={`close-img`} src="/images/ic_close_white.svg" onClick={onClose} />
                            </div>
                            <div className="right-inner-div">
                                <div className="back-div" onClick={() => navigate(`/`)}>
                                    <img alt="Back icon" className="back-img" src="/images/ic_arrow_left_black.svg" />

                                    <label className="back">{t('auction_back_title')}</label>
                                </div>

                                <div className="top-div">
                                    {car?.status === CarStatusEnum.Approved && (
                                        <>
                                            <IconButton small={true} reverse={true} title={t('auction_add_to_favorites_title')} icon={`/images/${isFavourite ? `ic_favourite_selected.svg` : `ic_favourite.svg`}`} onClick={onFavouriteClick} />

                                            <div className="small-divider-div" />
                                        </>
                                    )}
                                    <IconButton
                                        small={true}
                                        reverse={true}
                                        title={t('auction_share_title')}
                                        icon="/images/ic_share.svg"
                                        onClick={() => {
                                            if (navigator.share) {
                                                navigator
                                                    .share({
                                                        title: t('auction_share_title'),
                                                        url: window.location.href,
                                                    })
                                                    .then(() => {
                                                        navigator.clipboard.writeText(window.location.href)
                                                    })
                                                    .catch(console.error)
                                            } else {
                                                navigator.clipboard.writeText(window.location.href)
                                                dispatch(ShowToast({ message: 'toast_link_copied_message' }))
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-details-div">
                        <div className="top-div">
                            <div className="name-div">
                                <h2>
                                    {car?.manufacturingYear} {car?.make} {car?.model}
                                </h2>

                                {car?.status === CarStatusEnum.Approved && <p className={`no-reserve ${getReservePriceTitleStyle()}`}>{getReservePriceTitle()}</p>}
                            </div>

                            {car?.status === CarStatusEnum.Approved && (
                                <>
                                    <div className="divider" />
                                    <IconButton small={true} reverse={true} title={t('auction_report_car_ad_title')} icon="/images/ic_report.svg" onClick={onReportClick} />
                                </>
                            )}
                            <div className="divider" />

                            <div className="seller-div">
                                <div className="user-div">
                                    <label className="type">
                                        {t('auction_seller_title')} {car?.user?.firstName != null ? t('auction_private_title') : t('auction_dealer_title')}
                                    </label>
                                    <label
                                        className="user"
                                        onClick={() => {
                                            if (user) {
                                                navigate(`/user/${car?.user?.id}`)
                                            } else {
                                                dispatch(ShowLoginModal({ visible: true, carId: car ? car.id : null }))
                                            }
                                        }}
                                    >
                                        {/* {car?.user?.firstName != null ? car?.user?.firstName : car?.user?.companyName ?? ''} {car?.user?.lastName != null ? car?.user?.lastName : ''} */}
                                        {car?.user?.username}
                                    </label>
                                    <label className="address">
                                        {car?.user?.address?.postalCode} {car?.user?.address?.city}
                                    </label>
                                </div>

                                {car?.status === CarStatusEnum.Approved && (
                                    <div className="test-drive-div">
                                        <IconButton title={t('auction_ask_for_test_drive_button')} icon="/images/ic_arrow_right_black.svg" onClick={onAskTestDriveClick} />
                                    </div>
                                )}
                            </div>

                            <div className="divider" />

                            <div className="statistics-div">
                                <div className="item-div">
                                    <label className="title">{t('auction_bids_title')}</label>
                                    {/* <div className="value-div pointer" onClick={() => commentRef.current?.scrollIntoView({ behavior: 'smooth' })}> */}
                                    <div className="value-div">
                                        <img alt="Bid icon" className="action-img" src="/images/ic_bid.svg" />
                                        <label className="label">{car?.auctions!![0].bidCount ?? 0}</label>
                                    </div>
                                </div>

                                <div className="item-div">
                                    <label className="title">{t('auction_views_title')}</label>
                                    <div className="value-div views">
                                        <img alt="View icon" className="action-img" src="/images/ic_views.svg" />
                                        <label className="label">{car?.views}</label>
                                    </div>
                                </div>

                                <div className="item-div">
                                    <label className="title">{t('auction_comments_title')}</label>
                                    <div className="value-div views pointer" onClick={() => commentRef.current?.scrollIntoView({ behavior: 'smooth' })}>
                                        <img alt="Comment icon" className="action-img" src="/images/ic_comments.svg" />
                                        <label className="label">{commentCount}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="bottom-div">
                            <div className="detail-item-div">
                                <label className="title">{t('auction_battery_capacity_title')}</label>
                                <div className="detail-item-value-div">
                                    <label className="value">{car?.batteryCapacity.toString().replace('.', ',')} </label>
                                    <label className="unit">{t('car_card_kWh_title')}</label>
                                </div>
                            </div>

                            <div className="detail-item-div">
                                <label className="title">{t('auction_horsepower_title')}</label>

                                <div className="detail-item-value-div">
                                    <label className="value">{car?.horsepower} </label>
                                    <label className="unit">{t('auction_ps_title')}</label>
                                </div>
                            </div>

                            <div className="detail-item-div">
                                <div className="detail-item-value-div">
                                    <label className="title">{t('auction_range_title')}</label>
                                    <label className="title"> *{t('car_card_km_extra_info')}</label>
                                </div>

                                <div className="detail-item-value-div">
                                    <label className="value">{car?.manufacturerEstimatedRange}* </label>
                                    <label className="unit">{t('car_card_km_title')}</label>
                                </div>
                            </div>

                            <div className="detail-item-div">
                                <label className="title">{t('auction_mileage_title')}</label>
                                <div className="detail-item-value-div">
                                    <label className="value">{car?.mileage.toLocaleString()} </label>
                                    <label className="unit">{t('car_card_km_title')}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`auction-div-parent ${car?.status !== CarStatusEnum.Approved ? `auction-ended` : ``}`}>
                        <StickyAuction car={car!!} onReportClick={onReportClick} onTestDriveClick={onAskTestDriveClick} onBidClick={onBidClick} onHowBiddignWorksClick={() => setHowBiddingWorksModalVisible(!howBiddingWorksModalVisible)} onCommentsClick={() => commentRef.current?.scrollIntoView({ behavior: 'smooth' })} />
                    </div>
                    <div className="divider-div" />
                    <div className="owners-take-div">
                        <h2>{t('auction_owner_take_title')}</h2>

                        <div className="content-div">
                            <label className="body-text"> {translatedOwnersTake !== '' ? translatedOwnersTake : car?.carDetail?.ownersTake}</label>

                            <img alt="Translate icon" className="deepl-img" src="/images/ic_deepl.svg" onClick={onTranslateClick} />
                        </div>
                    </div>
                    <div className="divider-div" />
                    <CarInformations car={car!!} deepLEnabled={true} />
                    <div className="divider-div" ref={commentRef} />
                    <CommentsQuestionBids
                        car={car!!}
                        commentRef={commentRef}
                        onReportClick={(id: number, type: number) => {
                            setReportType(type)
                            setReportId(id)
                            onReportClick()
                        }}
                    />
                </div>

                {iframeUrl.length !== 0 ? (
                    <>
                        <div className="iframe-container" ref={iframeContainerRef}>
                            <iframe ref={iframeRef} onLoad={onLoad} src={iframeUrl} frameBorder={'0'} title={'redirect_iframe'} />
                        </div>
                    </>
                ) : null}

                {reportModalVisible ? (
                    <ReportModal
                        id={reportId}
                        type={reportType}
                        onClose={() => {
                            setReportModalVisible(!reportModalVisible)
                            setReportType(ReportTypeEnum.Car)
                            setReportId(car ? car.id : 0)
                        }}
                    />
                ) : null}

                {registerToModalVisible ? (
                    <RegisterToModal
                        type={registerToModalType}
                        onClose={() => {
                            setRegisterToModalVisible(!registerToModalVisible)
                        }}
                        onSuccess={(type: string) => {
                            setRegisterToModalVisible(!registerToModalVisible)

                            if (type === 'bid') {
                                setBidModalVisible(!bidModalVisible)
                            } else {
                                const mailToLink = document.createElement('a')
                                mailToLink.href = 'mailto:' + (car && car.user ? car.user.email : '')
                                mailToLink.click()
                            }
                        }}
                        onHowBiddingWorksClick={() => {
                            setHowBiddingWorksModalVisible(!howBiddingWorksModalVisible)
                        }}
                        onAddPaymentCardClick={() => {
                            setCreaditCardModalVisible(!creaditCardModalVisible)
                        }}
                    />
                ) : null}

                {bidModalVisible ? (
                    <BidModal
                        firstBid={car && car.auctions.length !== 0 && car.auctions[0].isFirstBid !== undefined ? car.auctions[0].isFirstBid : true}
                        currentPrice={car && car.auctions.length !== 0 ? car.auctions[0].currentPrice : 0}
                        onClose={() => {
                            setBidModalVisible(!bidModalVisible)
                        }}
                        onBid={(amount: number) => {
                            socket.emit('bid', { auctionId: car?.auctions[0].id, userId: user?.id, amount: amount })

                            setBidModalVisible(!bidModalVisible)
                        }}
                        onHowBiddingWorksClick={() => {
                            setHowBiddingWorksModalVisible(!howBiddingWorksModalVisible)
                        }}
                        onBuyersFeeClick={() => {
                            setBuyersFeeModalVisible(!buyersFeeModalVisible)
                        }}
                    />
                ) : null}

                {creaditCardModalVisible ? (
                    <Elements stripe={stripePromise}>
                        <CreaditCardModal
                            onClose={() => {
                                setCreaditCardModalVisible(!creaditCardModalVisible)
                            }}
                        />
                    </Elements>
                ) : null}

                {howBiddingWorksModalVisible ? (
                    <HowBiddingWorksModal
                        onClose={() => {
                            setHowBiddingWorksModalVisible(!howBiddingWorksModalVisible)
                        }}
                    />
                ) : null}

                {buyersFeeModalVisible ? (
                    <BuyersFeeModal
                        onClose={() => {
                            setBuyersFeeModalVisible(!buyersFeeModalVisible)
                        }}
                    />
                ) : null}

                <Lightbox plugins={[Video]} index={primaryImageIndex} open={openGallery} close={() => setOpenGallery(false)} slides={getSlides()} />
            </main>
        </Elements>
    )
}
