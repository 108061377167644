import { GuidePageEnum } from '../../../enum/guide-page-enum'
import { GuideBasePage } from '../guide/guide-base.page'
import { Button } from '../../../components'
import { Section } from '../components/section'
import { PhotoChecklistReminders } from './photo-checklist-reminders'
import { PhotoChecklistCheckboxes } from './photo-checklist-checkboxes'
import { PhotoChecklistImages } from './photo-checklist-images'
import { PhotoChecklistCheckboxesCol } from './photo-checklist-checkboxes-col'
import { useState } from 'react'
import { PhotoChecklistCheckbox } from './photo-checklist-checkbox'
import { defaultPhotoChecklistFormData } from './photo-checklist-form-data'
import { useTranslation } from 'react-i18next'
import './photo-checklist.page.scss'

interface PhotoChecklistPageProps {}

export const PhotoChecklistPage = (props: PhotoChecklistPageProps) => {
    const { t, i18n } = useTranslation()

    let [formData, setFormData] = useState(defaultPhotoChecklistFormData)

    return (
        <GuideBasePage page={GuidePageEnum.PhotoChecklist} additionalClassNames="page-photo-checklist">
            <Section title={t('guide_photo_checklist_guide_save_title')}></Section>

            <Section title={t('guide_photo_checklist_guide_reminder_title')}></Section>

            <PhotoChecklistReminders></PhotoChecklistReminders>

            <Section>
                <Section title={t('guide_photo_checklist_guide_exterior_title')} body={t('guide_photo_checklist_guide_exterior_walk_around_body')} isSub={true}></Section>
                <Section isSub={true}>
                    <div className="photo-checklist-exterior-shots">
                        <div className="photo-checklist-exterior-shots__checks">
                            <div className="photo-checklist-exterior-shots__checks__icon">
                                <img alt="Car" src="/images/ic_car_pointer.svg" />
                            </div>
                            <div className="photo-checklist-exterior-shots__checks_checkboxes">
                                <PhotoChecklistCheckboxes>
                                    <PhotoChecklistCheckboxesCol>
                                        <PhotoChecklistCheckbox id="guide_photo_checklist_guide_exterior_walk_around_head_on_front_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                                        <PhotoChecklistCheckbox id="guide_photo_checklist_guide_exterior_walk_around_head_on_rear_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                                        <PhotoChecklistCheckbox id="guide_photo_checklist_guide_exterior_walk_around_driver_side_profile_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                                    </PhotoChecklistCheckboxesCol>
                                    <PhotoChecklistCheckboxesCol>
                                        <PhotoChecklistCheckbox id="guide_photo_checklist_guide_exterior_walk_around_passenger_side_profile_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                                        <PhotoChecklistCheckbox id="guide_photo_checklist_guide_exterior_walk_around_driver_side_front_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                                        <PhotoChecklistCheckbox id="guide_photo_checklist_guide_exterior_walk_around_driver_side_rear_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                                    </PhotoChecklistCheckboxesCol>
                                    <PhotoChecklistCheckboxesCol>
                                        <PhotoChecklistCheckbox id="guide_photo_checklist_guide_exterior_walk_around_passenger_side_front_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                                        <PhotoChecklistCheckbox id="guide_photo_checklist_guide_exterior_walk_around_passenger_side_rear_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                                        <PhotoChecklistCheckbox id="guide_photo_checklist_guide_exterior_walk_around_head_on_front_rear_lights_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                                    </PhotoChecklistCheckboxesCol>
                                </PhotoChecklistCheckboxes>
                            </div>
                        </div>
                        <div className="photo-checklist-exterior-shots__images">
                            <PhotoChecklistImages>
                                <img alt="" src="/images/guide/photo-checklist/exterior-walk-around-01.png" />
                                <img alt="" src="/images/guide/photo-checklist/exterior-walk-around-02.png" />
                                <img alt="" src="/images/guide/photo-checklist/exterior-walk-around-03.png" />
                                <img alt="" src="/images/guide/photo-checklist/exterior-walk-around-04.png" />
                                <img alt="" src="/images/guide/photo-checklist/exterior-walk-around-05.png" />
                                <img alt="" src="/images/guide/photo-checklist/exterior-walk-around-06.png" />
                                <img alt="" src="/images/guide/photo-checklist/exterior-walk-around-07.png" />
                                <img alt="" src="/images/guide/photo-checklist/exterior-walk-around-08.png" />
                                <img alt="" src="/images/guide/photo-checklist/exterior-walk-around-09.png" />
                                <img alt="" src="/images/guide/photo-checklist/exterior-walk-around-10.png" />
                            </PhotoChecklistImages>
                        </div>
                    </div>
                </Section>

                <Section body={t('guide_photo_checklist_guide_exterior_details_body')} isSub={true}></Section>
                <Section isSub={true}>
                    <PhotoChecklistCheckboxes>
                        <PhotoChecklistCheckboxesCol>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_exterior_details_wheels_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_exterior_details_headlights_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_exterior_details_taillights_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                        </PhotoChecklistCheckboxesCol>
                        <PhotoChecklistCheckboxesCol>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_exterior_details_charging_point_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_exterior_details_side_mirrors_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_exterior_details_undercarriage_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                        </PhotoChecklistCheckboxesCol>
                        <PhotoChecklistCheckboxesCol>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_exterior_details_trunk_front_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_exterior_details_roof_tow_bar_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_exterior_details_imperfections_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                        </PhotoChecklistCheckboxesCol>
                    </PhotoChecklistCheckboxes>
                    <PhotoChecklistImages>
                        <img alt="" src="/images/guide/photo-checklist/exterior-details-01.png" />
                        <img alt="" src="/images/guide/photo-checklist/exterior-details-02.png" />
                        <img alt="" src="/images/guide/photo-checklist/exterior-details-03.png" />
                        <img alt="" src="/images/guide/photo-checklist/exterior-details-04.png" />
                        <img alt="" src="/images/guide/photo-checklist/exterior-details-05.png" />
                    </PhotoChecklistImages>
                </Section>
            </Section>
            <Section title={t('guide_photo_checklist_guide_interior_title')}>
                <Section body={t('guide_photo_checklist_guide_interior_dashboard_body')} isSub={true}></Section>
                <Section isSub={true}>
                    <PhotoChecklistCheckboxes>
                        <PhotoChecklistCheckboxesCol>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_dashboard_driver_side_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_dashboard_passenger_side_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_dashboard_rear_seats_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                        </PhotoChecklistCheckboxesCol>
                        <PhotoChecklistCheckboxesCol>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_dashboard_center_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_dashboard_center_console_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_dashboard_steering_wheel_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                        </PhotoChecklistCheckboxesCol>
                        <PhotoChecklistCheckboxesCol>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_dashboard_glove_box_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_dashboard_gauge_cluster_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_dashboard_gear_level_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                        </PhotoChecklistCheckboxesCol>
                    </PhotoChecklistCheckboxes>
                    <PhotoChecklistImages>
                        <img alt="" src="/images/guide/photo-checklist/interior-dashboard-01.png" />
                        <img alt="" src="/images/guide/photo-checklist/interior-dashboard-02.png" />
                        <img alt="" src="/images/guide/photo-checklist/interior-dashboard-03.png" />
                        <img alt="" src="/images/guide/photo-checklist/interior-dashboard-04.png" />
                        <img alt="" src="/images/guide/photo-checklist/interior-dashboard-05.png" />
                        <img alt="" src="/images/guide/photo-checklist/interior-dashboard-06.png" />
                        <img alt="" src="/images/guide/photo-checklist/interior-dashboard-07.png" />
                        <img alt="" src="/images/guide/photo-checklist/interior-dashboard-08.png" />
                        <img alt="" src="/images/guide/photo-checklist/interior-dashboard-09.png" />
                        <img alt="" src="/images/guide/photo-checklist/interior-dashboard-10.png" />
                    </PhotoChecklistImages>
                </Section>
                <Section body={t('guide_photo_checklist_guide_interior_seats_body')} isSub={true}></Section>
                <Section isSub={true}>
                    <PhotoChecklistCheckboxes>
                        <PhotoChecklistCheckboxesCol>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_seats_driver_seat_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_seats_passenger_seat_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_seats_rear_seats_from_sides_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                        </PhotoChecklistCheckboxesCol>
                        <PhotoChecklistCheckboxesCol>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_seats_rear_seats_folded_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_seats_rear_seats_from_behind_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_seats_imperfections_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                        </PhotoChecklistCheckboxesCol>
                        <PhotoChecklistCheckboxesCol></PhotoChecklistCheckboxesCol>
                    </PhotoChecklistCheckboxes>
                    <PhotoChecklistImages>
                        <img alt="" src="/images/guide/photo-checklist/interior-seats-01.png" />
                        <img alt="" src="/images/guide/photo-checklist/interior-seats-02.png" />
                        <img alt="" src="/images/guide/photo-checklist/interior-seats-03.png" />
                        <img alt="" src="/images/guide/photo-checklist/interior-seats-04.png" />
                        <img alt="" src="/images/guide/photo-checklist/interior-seats-05.png" />
                    </PhotoChecklistImages>
                </Section>
                <Section body={t('guide_photo_checklist_guide_interior_other_body')} isSub={true}></Section>
                <Section isSub={true}>
                    <PhotoChecklistCheckboxes>
                        <PhotoChecklistCheckboxesCol>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_other_pedals_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_other_headliner_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_other_trunk_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                        </PhotoChecklistCheckboxesCol>
                        <PhotoChecklistCheckboxesCol>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_other_front_footwells_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_other_rear_footwells_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_other_driver_side_door_panel_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                        </PhotoChecklistCheckboxesCol>
                        <PhotoChecklistCheckboxesCol>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_other_passenger_side_door_panel_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_interior_other_imperfections_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                        </PhotoChecklistCheckboxesCol>
                    </PhotoChecklistCheckboxes>
                    <PhotoChecklistImages>
                        <img alt="" src="/images/guide/photo-checklist/interior-others-01.png" />
                        <img alt="" src="/images/guide/photo-checklist/interior-others-02.png" />
                        <img alt="" src="/images/guide/photo-checklist/interior-others-03.png" />
                        <img alt="" src="/images/guide/photo-checklist/interior-others-04.png" />
                        <img alt="" src="/images/guide/photo-checklist/interior-others-05.png" />
                    </PhotoChecklistImages>
                </Section>
            </Section>
            <Section title={t('guide_photo_checklist_guide_other_title')}>
                <Section body={t('guide_photo_checklist_guide_other_body')} isSub={true}></Section>
                <Section isSub={true}>
                    <PhotoChecklistCheckboxes>
                        <PhotoChecklistCheckboxesCol>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_other_keys_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_other_documents_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_other_service_history_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                        </PhotoChecklistCheckboxesCol>
                        <PhotoChecklistCheckboxesCol>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_other_charging_cable_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_other_tires_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                            <PhotoChecklistCheckbox id="guide_photo_checklist_guide_other_extras_check" formData={formData} setFormData={setFormData}></PhotoChecklistCheckbox>
                        </PhotoChecklistCheckboxesCol>
                        <PhotoChecklistCheckboxesCol></PhotoChecklistCheckboxesCol>
                    </PhotoChecklistCheckboxes>
                    <PhotoChecklistImages>
                        <img alt="" src="/images/guide/photo-checklist/others-01.png" />
                        <img alt="" src="/images/guide/photo-checklist/others-02.png" />
                        <img alt="" src="/images/guide/photo-checklist/others-03.png" />
                        <img alt="" src="/images/guide/photo-checklist/others-04.png" />
                        <img alt="" src="/images/guide/photo-checklist/others-05.png" />
                    </PhotoChecklistImages>
                </Section>
            </Section>
            <div className="photo-checklist-download-section">
                <Button
                    title={t('guide_photo_checklist_guide_download')}
                    onClick={() => {
                        var pdfUrl = 'documents/photo_checklist_en.pdf'
                        var name = 'photo_checklist_en.pdf'

                        if (i18n.language.includes('de')) {
                            pdfUrl = 'documents/photo_checklist_de.pdf'
                            name = 'photo_checklist_de.pdf'
                        }
                        if (i18n.language.includes('fr')) {
                            pdfUrl = 'documents/photo_checklist_fr.pdf'
                            name = 'photo_checklist_fr.pdf'
                        }
                        const link = document.createElement('a')
                        link.href = pdfUrl
                        link.download = name
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                    }}
                />
            </div>
        </GuideBasePage>
    )
}
