import { PropsWithChildren } from 'react'
import './section.scss'

interface SectionProps {
    title?: string
    body?: string
    isSub?: boolean
    additionalClassNames?: string
}

export const Section = (props: PropsWithChildren<SectionProps>) => {
    const _getSectionClassName = () => ['section', props.isSub ? 'sub' : '', props.additionalClassNames ?? ''].join(' ').trim()

    const _getTitle = () => (
        <>
            {props.title && !props.isSub && (
                <div className={'section-title'}>
                    <h2 dangerouslySetInnerHTML={{ __html: props.title }}></h2>
                </div>
            )}
            {props.title && props.isSub && (
                <div className={'section-title'}>
                    <h3 dangerouslySetInnerHTML={{ __html: props.title }}></h3>
                </div>
            )}
        </>
    )

    const _getBody = () => (
        <>
            {props.body && (
                <div className={'section-body'}>
                    <div dangerouslySetInnerHTML={{ __html: props.body }}></div>
                </div>
            )}
            {props.children && <div className={'section-body'}>{props.children}</div>}
        </>
    )

    return (
        <>
            {(props.title || props.body || props.children) && (
                <div className={'section'}>
                    <div className={_getSectionClassName()}>
                        {_getTitle()}
                        {_getBody()}
                    </div>
                </div>
            )}
        </>
    )
}
