import { MutableRefObject, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../redux'
import { HowItWorksSection } from '../../../enum/how-it-works-section-enum'
import { UpdateHowItWorksSection } from '../../../redux/actions'
import { useWindowDimensions } from '../../../utils'
import { useTranslation } from 'react-i18next'
import './submenu.scss'

interface SubmenuProps {
    refs: MutableRefObject<HTMLDivElement | null>[]
}

export const Submenu = (props: SubmenuProps) => {
    const dispatch = useDispatch()
    const currentSection = useAppSelector((state) => state.howItWorks.currentSection)
    const { width } = useWindowDimensions()
    const { t } = useTranslation()

    useEffect(() => {
        if (width > 640) {
            setTimeout(() => {
                if (currentSection === HowItWorksSection.HowItWorks) {
                    window.scrollTo(0, (props.refs[0].current?.offsetTop ?? 0) - 104)
                }
                if (currentSection === HowItWorksSection.BuyingCar) {
                    window.scrollTo(0, (props.refs[1].current?.offsetTop ?? 0) - 104)
                }
                if (currentSection === HowItWorksSection.SellingCar) {
                    window.scrollTo(0, (props.refs[2].current?.offsetTop ?? 0) - 104)
                }
                if (currentSection === HowItWorksSection.ReservePrice) {
                    window.scrollTo(0, (props.refs[4].current?.offsetTop ?? 0) - 104)
                }
                if (currentSection === HowItWorksSection.Concluding) {
                    window.scrollTo(0, (props.refs[3].current?.offsetTop ?? 0) - 104)
                }
                if (currentSection === HowItWorksSection.Battery) {
                    window.scrollTo(0, (props.refs[5].current?.offsetTop ?? 0) - 104)
                }
            }, 250)
        }
    }, [currentSection])

    return (
        <div className="how-it-works-submenu-div">
            <div
                dangerouslySetInnerHTML={{ __html: t('how_it_works_how_it_works_menu') }}
                onClick={() => {
                    //window.scrollTo(0, (props.refs[0].current?.offsetTop ?? 0) - 104)
                    dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.HowItWorks }))
                }}
                className={currentSection === HowItWorksSection.HowItWorks ? 'submenu-item active' : 'submenu-item'}
            ></div>
            <div
                dangerouslySetInnerHTML={{ __html: t('how_it_works_buying_menu') }}
                onClick={() => {
                    // window.scrollTo(0, (props.refs[1].current?.offsetTop ?? 0) - 104)
                    dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.BuyingCar }))
                }}
                className={currentSection === HowItWorksSection.BuyingCar ? 'submenu-item active' : 'submenu-item'}
            ></div>
            <div
                dangerouslySetInnerHTML={{ __html: t('how_it_works_selling_menu') }}
                onClick={() => {
                    //window.scrollTo(0, (props.refs[2].current?.offsetTop ?? 0) - 104)
                    dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.SellingCar }))
                }}
                className={currentSection === HowItWorksSection.SellingCar ? 'submenu-item active' : 'submenu-item'}
            ></div>
            <div
                dangerouslySetInnerHTML={{ __html: t('how_it_works_concluding_menu') }}
                onClick={() => {
                    //window.scrollTo(0, (props.refs[3].current?.offsetTop ?? 0) - 104)
                    dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.Concluding }))
                }}
                className={currentSection === HowItWorksSection.Concluding ? 'submenu-item active' : 'submenu-item'}
            ></div>
            <div
                dangerouslySetInnerHTML={{ __html: t('how_it_works_reserve_price_menu') }}
                onClick={() => {
                    //window.scrollTo(0, (props.refs[4].current?.offsetTop ?? 0) - 104)
                    dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.ReservePrice }))
                }}
                className={currentSection === HowItWorksSection.ReservePrice ? 'submenu-item active' : 'submenu-item'}
            ></div>
            <div
                dangerouslySetInnerHTML={{ __html: t('how_it_works_battery_menu') }}
                onClick={() => {
                    //window.scrollTo(0, (props.refs[5].current?.offsetTop ?? 0) - 104)
                    dispatch(UpdateHowItWorksSection({ currentSection: HowItWorksSection.Battery }))
                }}
                className={currentSection === HowItWorksSection.Battery ? 'submenu-item active' : 'submenu-item'}
            ></div>
        </div>
    )
}
