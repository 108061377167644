import { createReducer } from '@reduxjs/toolkit'
import { CommentsState } from '../../states'
import { CommentsFailed, CommentsPagination, CommentsSuccess, CreateCommentFailed, CreateCommentSuccess } from '../../actions'

const initialState: CommentsState = {
    comments: [],
    isLoading: false,
    pagination: {
        page: 1,
        pageSize: 10,
        count: 0,
    },
}

export const commentsReducer = createReducer(initialState, (builder) => {
    builder.addCase(CommentsPagination, (state, action) => {
        state.isLoading = true
        state.pagination.page = state.pagination.page + 1
    })
    builder.addCase(CommentsSuccess, (state, action) => {
        state.isLoading = false

        if (action.payload.reset) {
            state.comments = []
        }
        state.comments = [...state.comments, ...action.payload.comments]
        state.pagination.page = action.payload.page
        state.pagination.pageSize = action.payload.pageSize
        state.pagination.count = action.payload.count
    })
    builder.addCase(CommentsFailed, (state) => {
        state.isLoading = false

        // state.form.email.error = ''
        // state.form.password.error = t('login-page--invalid-username-or-password')
    })
    builder.addCase(CreateCommentSuccess, (state) => {})
    builder.addCase(CreateCommentFailed, (state) => {})
})
