import React, { ChangeEvent, FC, useCallback, useEffect, useRef } from 'react'
import './range-slider.scss'

interface RangeSliderProps {
    min: number
    max: number
    minVal: number
    maxVal: number
    unit: string
    onChange: any
}

export const RangeSlider: FC<RangeSliderProps> = ({ min, max, minVal, maxVal, unit, onChange }) => {
    const range = useRef<HTMLDivElement>(null)

    // Convert to percentage
    const getPercent = useCallback((value: number) => Math.round(((value - min) / (max - min)) * 100), [min, max])

    // Set width of the range to decrease from the left side
    useEffect(() => {
        const minPercent = minVal === null || minVal === undefined ? 0 : getPercent(minVal)
        const maxPercent = getPercent(maxVal)

        if (range.current) {
            range.current.style.left = `${minPercent}%`
            range.current.style.width = `${maxPercent - minPercent}%`
        }
    }, [minVal, getPercent])

    // Set width of the range to decrease from the right side
    useEffect(() => {
        const minPercent = getPercent(minVal)
        const maxPercent = maxVal === null || maxVal === undefined ? 0 : getPercent(maxVal)

        if (range.current) {
            range.current.style.width = `${maxPercent - minPercent}%`
        }
    }, [maxVal, getPercent])

    return (
        <div className="container">
            <input
                type="range"
                min={min}
                max={max}
                value={minVal === null ? 0 : minVal}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const value = Math.min(Number(event.target.value), maxVal === null || maxVal === undefined ? 0 : maxVal - 1)
                    onChange('min', value)
                }}
                className="thumb thumb--left"
                style={{ zIndex: minVal === null || minVal === undefined ? 0 : minVal > max - 100 ? '5' : undefined }}
            />
            <input
                type="range"
                min={min}
                max={max}
                value={maxVal === null ? 0 : maxVal}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const value = Math.max(Number(event.target.value), minVal === null || minVal === undefined ? 0 : minVal + 1)
                    onChange('max', value)
                }}
                className="thumb thumb--right"
            />

            <div className="slider">
                <div className="slider__track"></div>
                <div ref={range} className="slider__range"></div>
                <div className="slider__left-value">
                    {minVal} {unit}
                </div>
                <div className="slider__right-value">
                    {maxVal} {unit}
                </div>
            </div>
        </div>
    )
}
