import { MediaFile } from '../../../../entity'
import { useState } from 'react'
import { MediaFileTypeEnum } from '../../../../enum'
import { Blurhash } from 'react-blurhash'
import './gallery-item.scss'

interface GalleryItemProps {
    index: number
    mediaFile: MediaFile
    onClick: () => void
}

export const GalleryItem = (props: GalleryItemProps) => {
    const [imageLoaded, setImageLoaded] = useState(false)

    return (
        <div
            className="gallery-item"
            key={'gi_' + props.index}
            onClick={(e) => {
                props.onClick()
            }}
        >
            <Blurhash className={`blurhash-img ${imageLoaded ? `hidden` : ``}`} hash={props.mediaFile.blurHash} width={'100%'} height={'100%'} resolutionX={32} resolutionY={32} punch={1} />

            <img
                alt="Car image"
                className="car-img"
                src={props.mediaFile.thumbnail}
                onLoad={() => {
                    setImageLoaded(true)
                }}
            />

            <img alt="Video icon" className={`video-img ${props.mediaFile.type == MediaFileTypeEnum.Photo ? `hidden` : ``}`} src={`/images/ic_video.svg`} />
        </div>
    )
}
