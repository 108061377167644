import { ChangeEvent, KeyboardEvent, useRef, useEffect, CSSProperties } from 'react'
import { useWindowDimensions } from '../../../utils'
import './text.field.scss'

interface TextFieldProps {
    field: {
        error: string | null
        required?: boolean
        value: string
    }
    onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
    placeholder?: string
    description?: string
    category?: string
    id?: string
    maxLength?: number
    disabled?: boolean
    title?: string
    rows?: number
    customStyle?: CSSProperties
}

export const TextField = (props: TextFieldProps) => {
    const textareaRef = useRef<any>(null)

    const { width } = useWindowDimensions()

    const onKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        const target = event.target as HTMLTextAreaElement
        textareaRef.current.style.height = 'inherit'
        textareaRef.current.style.height = `${target.scrollHeight - 28}px`
    }

    useEffect(() => {
        if (textareaRef) {
            textareaRef.current.style.height = 'inherit'
            textareaRef.current.style.height = '0px'
            const scrollHeight = textareaRef.current.scrollHeight
            if (props.field.value.length === 0 && props.placeholder != null && props.placeholder!!.length > 40) {
                textareaRef.current.style.height = scrollHeight - 14 + 'px'
            } else {
                textareaRef.current.style.height = scrollHeight - 28 + 'px'
            }
        }
    }, [textareaRef, props.field.value, width, props.placeholder])

    const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
        event.preventDefault()
        const text = event.clipboardData.getData('text/plain')
        const cleanText = text.replace(/<[^>]+>/g, '')
        document.execCommand('insertText', false, cleanText)
        const target = event.target as HTMLTextAreaElement
        textareaRef.current.style.height = 'inherit'
        textareaRef.current.style.height = `${target.scrollHeight - 28}px`
    }

    return (
        <div className={`field field--text ${props.field.error != null && props.field.error.length > 0 ? ` field--error` : ``}`}>
            {props.title && <h4 className="body-text">{props.title}</h4>}
            <textarea style={props.customStyle} id={props.id} ref={textareaRef} className={props.category} onChange={props.onChange} placeholder={props.placeholder} required={props.field.required !== false} onKeyDown={onKeyDown} maxLength={props.maxLength} rows={props.rows ?? 1} value={props.field.value} onPaste={handlePaste} disabled={props.disabled === true} />
            <div className={`${props.field.error != null && props.field.error.length > 0 ? `field__error-div` : `field--hidden`}`}>
                <img alt="Error icon" className="error-img" src="/images/ic_error.svg" />

                <div className={`message`}>{props.field.error}</div>
            </div>

            <div className={`bottom-desc-div`}>
                {props.description && <p className="desc-text">{props.description} </p>}

                {props.maxLength && (
                    <p className="count-text">
                        {textareaRef && textareaRef.current ? textareaRef.current.value.length : 0} / {props.maxLength}
                    </p>
                )}
            </div>
        </div>
    )
}
