import { Expose, Type } from 'class-transformer'
import { IsDefined, IsNumber, ValidateNested } from 'class-validator'
import { Car } from '../../entity'

export class CarsSoldDTO {
    @Expose()
    @ValidateNested()
    @Type(() => Car)
    data!: Car[]

    @Expose()
    @IsDefined()
    @IsNumber()
    page!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    pageSize!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    didNotSellCount!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    soldCount!: number
}
