import { store } from '../../redux'
import { DefaultDTO, ErrorDTO } from '../../dto'
import { CreateCommentFailed, ErrorMessage, CreateCommentSuccess, ShowToast } from '../../redux/actions'
import { NetworkCall } from '../../utils'
import { getComments } from './get-comments.api'

interface CreateCommentProps {
    carId: number
    comment: string
    parentId?: number
    onSuccess: () => void
}

export const createComment = async (props: CreateCommentProps) => {
    const requestBody = {
        carId: props.carId,
        parentId: props.parentId ? props.parentId : undefined,
        comment: props.comment,
    }

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('POST', `/1.0.0/comments`, requestBody)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(201, DefaultDTO, (responseBody) => {
            store.dispatch(CreateCommentSuccess(responseBody))

            props.onSuccess()

            getComments(props.carId, 'newestFirst', true)

            store.dispatch(ShowToast({ message: 'toast_create_comment_success' }))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
            store.dispatch(CreateCommentFailed({}))
        })
        .call()
}
