import { createReducer } from '@reduxjs/toolkit'
import { BidsState } from '../../states'
import { BidsFailed, BidsPagination, BidsSuccess } from '../../actions'

const initialState: BidsState = {
    bids: [],
    isLoading: false,
    pagination: {
        page: 1,
        pageSize: 10,
        count: 0,
    },
}

export const bidsReducer = createReducer(initialState, (builder) => {
    builder.addCase(BidsPagination, (state, action) => {
        state.isLoading = true
        state.pagination.page = state.pagination.page + 1
    })
    builder.addCase(BidsSuccess, (state, action) => {
        state.isLoading = false

        if (action.payload.reset) {
            state.bids = []
        }
        state.bids = [...state.bids, ...action.payload.bids]
        state.pagination.page = action.payload.page
        state.pagination.pageSize = action.payload.pageSize
        state.pagination.count = action.payload.count
    })
    builder.addCase(BidsFailed, (state) => {
        state.isLoading = false
    })
})
