import { PropsWithChildren, forwardRef, useState } from 'react'
import './faq-item.scss'

interface FaqItemProps {
    title?: string
    body?: string
    isCollapse?: boolean
}

export const FaqItem = forwardRef<HTMLDivElement, PropsWithChildren<FaqItemProps>>((props, ref) => {
    const [isCollapsed, setIsCollapsed] = useState(false)

    const _getTitle = () => (
        <>
            {props.title && (
                <div className={'faq-item-title'} onClick={() => setIsCollapsed(!isCollapsed)}>
                    {props.title && <h4 dangerouslySetInnerHTML={{ __html: props.title }}></h4>}
                    {props.isCollapse && (
                        <div className="collapse">
                            <img className={`arrow-img ${isCollapsed ? 'rotate' : ''}`} alt="Collapse icon" src="/images/ic_arrow_down.svg" />
                        </div>
                    )}
                </div>
            )}
        </>
    )

    const _getBody = () => (
        <>
            {props.body && !props.isCollapse && (
                <div className={'faq-item-body'}>
                    <div dangerouslySetInnerHTML={{ __html: props.body }}></div>
                </div>
            )}
            {props.body && props.isCollapse && isCollapsed && (
                <>
                    <div className={'faq-item-body border'}>{props.body}</div>
                </>
            )}
        </>
    )

    return (
        <>
            {(props.title || props.body) && (
                <div className="faq-item" ref={ref}>
                    {_getTitle()}
                    {_getBody()}
                </div>
            )}
        </>
    )
})
