import { PropsWithChildren } from 'react'

interface SectionBodyProps {
    additionalClassNames?: string
}

export const SectionBody = (props: PropsWithChildren<SectionBodyProps>) => {
    const _getClassName = () => ['section-body', props.additionalClassNames ?? ''].join(' ').trim()

    return <>{props.children && <div className={_getClassName()}>{props.children}</div>}</>
}
