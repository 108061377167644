import { useTranslation } from 'react-i18next'
import { Button } from '../buttons'
import './cookies.scss'

interface CookiesModalProps {
    onReject: () => void
    onAccept: () => void
}

export const CookiesModal = (props: CookiesModalProps) => {
    const { t } = useTranslation()

    return (
        <div className={`cookies`}>
            <p className="message">{t('cookies_message')}</p>

            <div className="cookies__bottom-div">
                <Button title={t('button_reject')} onClick={props.onReject} />
                <Button title={t('button_accept')} onClick={props.onAccept} />
            </div>
        </div>
    )
}
