import { LoaderFunctionArgs } from 'react-router-dom'
import { bidHistory, commentHistory, me } from '../api'

export const myActivityPageLoader = async (args: LoaderFunctionArgs) => {
    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')
    if (token !== null) {
        //TODO azért van itt a me mert ha újratölti az oldalt akkor nincs token és a mási két ws elhasal maitta, szépíteni
        await me(token)

        await bidHistory(null, true)
        await commentHistory(null, true)
    }

    return null
}
