import { store } from '../../redux'
import { DefaultDTO, StripeErrorDTO } from '../../dto'
import { ErrorMessage, ShowToast } from '../../redux/actions'
import { NetworkCall } from '../../utils'

interface PayBuyersFeeProps {
    auctionId: number
    onSuccess: () => void
    onErrror: (error: StripeErrorDTO) => void
}

export const payBuyersFee = async (props: PayBuyersFeeProps) => {
    const requestBody = {
        auctionId: props.auctionId,
    }

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('POST', `/1.0.0/users/fee`, requestBody)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(201, DefaultDTO, (responseBody) => {
            props.onSuccess()

            store.dispatch(ShowToast({ message: 'toast_pay_buyers_fee_success' }))
        })
        .catch((response) => {
            const error = response as StripeErrorDTO

            if (error.code === 190005) {
                props.onErrror(error)
            } else {
                store.dispatch(ErrorMessage({ title: '', message: error.message, code: error.code }))
            }
        })
        .call()
}
