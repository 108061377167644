import { store } from '../../redux'
import { DocumentsDTO, ErrorDTO } from '../../dto'
import { NetworkCall } from '../../utils'
import { ErrorMessage, ShowToast } from '../../redux/actions'
import { AddDocuments } from '../../redux/actions/car'

interface UplpoadDocumentProps {
    files: File[]
    onSuccess: () => void
    onError: () => void
}

export const uplpoadDocument = async (props: UplpoadDocumentProps) => {
    let formData = new FormData()

    props.files.map((file: File) => {
        formData.append(`files`, file as Blob)
    })

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('POST', `/1.0.0/documents`, formData)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(201, DocumentsDTO, (responseBody: any) => {
            store.dispatch(AddDocuments({ documents: responseBody.documents }))

            props.onSuccess()

            store.dispatch(ShowToast({ message: 'toast_add_file_success' }))
        })
        .catch((response) => {
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))

            props.onError()
        })
        .call()
}
