import { createAction } from '@reduxjs/toolkit'
import { Question } from '../../../entity'

interface Payload {
    questions: Question[]
    page: number
    pageSize: number
    count: number
    reset: boolean
}

export const QuestionsSuccess = createAction<Payload>('QUESTIONS__QUESTIONS_SUCCESS')
