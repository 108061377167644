import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Header, Footer, Highlights, Button, CookiesModal } from '../..'
import { AcceptTermsModal, ForgotPasswordModal, LoginModal, PayBuyersFeeModal, ResendRegistrationEmailModal } from '../../modals'
import { useAppSelector } from '../../../redux'
import { MessageModal } from '../../modals'
import { Toast } from '../..'
import { useEffect, useState } from 'react'
import { ResetCarForm, ShowAcceptTermsModal, ShowLoginModal, UpdateAddCarTab, UpdateMenu } from '../../../redux/actions'
import { useDispatch } from 'react-redux'
import { Car } from '../../../entity'
import { listUnpaidCars } from '../../../api/users'
import { Menu } from '../../../enum'
import './default.layout.scss'

import meta from '../../../assets/meta.json'
import { useTranslation } from 'react-i18next'

interface DefaultLayoutProps {}

export const DefaultLayout = (props: DefaultLayoutProps) => {
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useAppSelector((state) => state.login)
    const { unpaidCars } = useAppSelector((state) => state.user)
    const [unpaidCar, setUnpaidCar] = useState(null as Car | null)
    const [unpaidAuctionsFetched, setUnpaidAuctionsFetched] = useState(false)
    const [showFloatingButton, setShowFloatingButton] = useState(localStorage.getItem(`showFloatingButton`) === 'true' || localStorage.getItem(`showFloatingButton`) === null)
    const [resendRegistrationEmailModalVisibility, setResendRegistrationEmailModalVisibility] = useState(false)
    const [cookiesVisibility, setCookiesVisibility] = useState(localStorage.getItem(`cookiesvisibility`) === 'true' || localStorage.getItem(`cookiesvisibility`) === null)

    useEffect(() => {
        if (user !== null && !unpaidAuctionsFetched) {
            setUnpaidAuctionsFetched(true)
            listUnpaidCars()
        }

        setShowFloatingButton(localStorage.getItem(`showFloatingButton`) === 'true' || localStorage.getItem(`showFloatingButton`) === null)
    }, [user])

    useEffect(() => {
        if (unpaidCars.length > 0) {
            setUnpaidCar(unpaidCars[0])
        }
    }, [unpaidCars])

    useEffect(() => {
        // @ts-ignore
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' })
    }, [location])

    useEffect(() => {
        if (user !== null && !(location.pathname.startsWith(`/terms`) || location.pathname.startsWith(`/privacy-policy`)) && localStorage.getItem(`termsChanged`) === `true`) {
            dispatch(ShowAcceptTermsModal())
        }
    }, [user, dispatch, location])

    useEffect(() => {
        document.title = 'LOT NUMBER ONE I Online Electric Car Marketplace in Switzerland'
        const description = document.querySelector("meta[name='description']")
        description?.setAttribute('content', 'Lot Number One is an online auction marketplace that connects sellers and buyers of battery electric cars in Switzerland.')

        meta.map((item) => {
            if (item.path === window.location.pathname) {
                document.title = item.title
                const description = document.querySelector("meta[name='description']")
                description?.setAttribute('content', item.desc)
            }
        })
    }, [location])

    const onHideFloatingButtonClcik = () => {
        setShowFloatingButton(false)
        localStorage.setItem(`showFloatingButton`, `false`)
    }

    const [forgotPasswordModalVisible, setForgotPasswordModalVisible] = useState(false)

    const { loginVisible, errorMessage, toastMessage, showAcceptTermsModal } = useAppSelector((state) => state.base)

    return (
        <div className={`layout layout--default`}>
            <Header />

            <Outlet />

            <Highlights />

            <Footer />

            {/* <button
                className="jira_issue_btn"
                onClick={() => {
                    const blankWindow = window.open('about:blank')
                    const document = blankWindow?.document
                    document?.open()
                    document?.write(`${Constants.JIRA_ISSUE_COLLECTOR_SCRIPT}`)
                    document?.close()
                }}
            >
                Create JIRA Ticket
            </button> */}

            {loginVisible ? (
                <LoginModal
                    onForgotPasswordClick={() => {
                        setForgotPasswordModalVisible(!forgotPasswordModalVisible)
                    }}
                    onResendVerificationEmailModalVisible={() => {
                        setResendRegistrationEmailModalVisibility(!resendRegistrationEmailModalVisibility)
                    }}
                />
            ) : null}

            {forgotPasswordModalVisible ? (
                <ForgotPasswordModal
                    onClose={() => {
                        setForgotPasswordModalVisible(!forgotPasswordModalVisible)
                    }}
                />
            ) : null}

            {errorMessage ? <MessageModal message={errorMessage} /> : null}

            {toastMessage ? <Toast message={toastMessage} /> : null}

            {showAcceptTermsModal ? <AcceptTermsModal /> : null}

            {unpaidCar ? (
                <PayBuyersFeeModal
                    car={unpaidCar}
                    onClose={() => {
                        setUnpaidCar(null)
                    }}
                />
            ) : null}

            {showFloatingButton && !window.location.pathname.includes('auction/') ? (
                <div className="floating-button">
                    <div className="close-button" onClick={onHideFloatingButtonClcik}>
                        <img alt="Close icon" src="/images/ic_close.svg" />
                    </div>
                    <Button
                        title={t('guide_photo_guide_section_selling_button')}
                        onClick={() => {
                            if (user) {
                                if (user.address == null) {
                                    //setRegisterToModalVisible(!registerToModalVisible)
                                    navigate('/my-profile')
                                } else {
                                    dispatch(ResetCarForm({}))
                                    dispatch(UpdateMenu({ menu: Menu.SellACar }))
                                    dispatch(UpdateAddCarTab({ tab: 'cardetails' }))
                                    navigate('/add-car')
                                }
                            } else {
                                dispatch(ShowLoginModal({ visible: true, carId: null }))
                            }
                        }}
                    />
                </div>
            ) : null}

            {resendRegistrationEmailModalVisibility ? (
                <ResendRegistrationEmailModal
                    onClose={() => {
                        setResendRegistrationEmailModalVisibility(!resendRegistrationEmailModalVisibility)
                    }}
                />
            ) : null}

            {cookiesVisibility ? (
                <CookiesModal
                    onAccept={() => {
                        setCookiesVisibility(!cookiesVisibility)
                        localStorage.setItem(`cookiesvisibility`, `false`)
                    }}
                    onReject={() => {
                        setCookiesVisibility(!cookiesVisibility)
                        localStorage.setItem(`cookiesvisibility`, `false`)
                    }}
                />
            ) : null}
        </div>
    )
}
