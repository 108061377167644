import { useState } from 'react'
import './tabview.scss'

interface TabViewProps {
    children: JSX.Element | JSX.Element[]
    tabs: { [key: string]: string }
}

export const TabView = (props: TabViewProps) => {
    const [activeKey, setActiveKey] = useState(Object.keys(props.tabs)[0])
    const children = Array.isArray(props.children) ? props.children : [props.children]

    return (
        <div className="tab-view">
            <header>
                {Object.keys(props.tabs).map((key: string) => (
                    <div className={`tab${activeKey === key ? ` active` : ``}`} key={key} onClick={() => setActiveKey(key)}>
                        <label className={`title${activeKey === key ? ` active` : ``}`}>{props.tabs[key]}</label>

                        <div className={`indicator${activeKey === key ? ` active` : ``}`} />
                    </div>
                ))}
            </header>

            <div className={`tab-view-pages${Object.keys(props.tabs).indexOf(activeKey) === 0 ? ` ` : ` rounded`}`}>{children.filter((child: JSX.Element) => child.key === activeKey)}</div>
        </div>
    )
}
