import { BidHistoryDTO, ErrorDTO } from '../../dto'
import { store } from '../../redux'
import { CarsWonFailed, CarsWonSuccess, ErrorMessage } from '../../redux/actions'
import { NetworkCall } from '../../utils'

export const carsWon = async (userId: number | null, reset: boolean = false) => {
    const page = reset ? 1 : store.getState().user.carsWon.pagination.page
    const pageSize = store.getState().user.carsWon.pagination.pageSize

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')
    const id = userId ? userId : store.getState().login.user?.id

    return await new NetworkCall('GET', `/1.0.0/users/${id}/cars-won/?page=${page}&pageSize=${pageSize}`)
        .setHeader('Authorization', `Bearer ${token}`)
        .then(200, BidHistoryDTO, (responseBody) => {
            store.dispatch(CarsWonSuccess({ auctions: responseBody.data, page: responseBody.page, pageSize: responseBody.pageSize, count: responseBody.count, reset: reset }))
        })
        .catch((response) => {
            store.dispatch(CarsWonFailed({}))
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
