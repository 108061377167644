export const defaultPhotoChecklistFormData: Map<string, boolean> = new Map([
    ['guide_photo_checklist_guide_exterior_walk_around_head_on_front_check', false],
    ['guide_photo_checklist_guide_exterior_walk_around_head_on_rear_check', false],
    ['guide_photo_checklist_guide_exterior_walk_around_driver_side_profile_check', false],
    ['guide_photo_checklist_guide_exterior_walk_around_passenger_side_profile_check', false],
    ['guide_photo_checklist_guide_exterior_walk_around_driver_side_front_check', false],
    ['guide_photo_checklist_guide_exterior_walk_around_driver_side_rear_check', false],
    ['guide_photo_checklist_guide_exterior_walk_around_passenger_side_front_check', false],
    ['guide_photo_checklist_guide_exterior_walk_around_passenger_side_rear_check', false],
    ['guide_photo_checklist_guide_exterior_walk_around_head_on_front_rear_lights_check', false],
    ['guide_photo_checklist_guide_exterior_details_wheels_check', false],
    ['guide_photo_checklist_guide_exterior_details_charging_point_check', false],
    ['guide_photo_checklist_guide_exterior_details_trunk_front_check', false],
    ['guide_photo_checklist_guide_exterior_details_headlights_check', false],
    ['guide_photo_checklist_guide_exterior_details_side_mirrors_check', false],
    ['guide_photo_checklist_guide_exterior_details_roof_tow_bar_check', false],
    ['guide_photo_checklist_guide_exterior_details_taillights_check', false],
    ['guide_photo_checklist_guide_exterior_details_undercarriage_check', false],
    ['guide_photo_checklist_guide_exterior_details_imperfections_check', false],
    ['guide_photo_checklist_guide_interior_dashboard_driver_side_check', false],
    ['guide_photo_checklist_guide_interior_dashboard_center_check', false],
    ['guide_photo_checklist_guide_interior_dashboard_glove_box_check', false],
    ['guide_photo_checklist_guide_interior_dashboard_passenger_side_check', false],
    ['guide_photo_checklist_guide_interior_dashboard_center_console_check', false],
    ['guide_photo_checklist_guide_interior_dashboard_gauge_cluster_check', false],
    ['guide_photo_checklist_guide_interior_dashboard_rear_seats_check', false],
    ['guide_photo_checklist_guide_interior_dashboard_steering_wheel_check', false],
    ['guide_photo_checklist_guide_interior_dashboard_gear_level_check', false],
    ['guide_photo_checklist_guide_interior_seats_driver_seat_check', false],
    ['guide_photo_checklist_guide_interior_seats_rear_seats_folded_check', false],
    ['guide_photo_checklist_guide_interior_seats_passenger_seat_check', false],
    ['guide_photo_checklist_guide_interior_seats_rear_seats_from_behind_check', false],
    ['guide_photo_checklist_guide_interior_seats_rear_seats_from_sides_check', false],
    ['guide_photo_checklist_guide_interior_seats_imperfections_check', false],
    ['guide_photo_checklist_guide_interior_other_pedals_check', false],
    ['guide_photo_checklist_guide_interior_other_front_footwells_check', false],
    ['guide_photo_checklist_guide_interior_other_passenger_side_door_panel_check', false],
    ['guide_photo_checklist_guide_interior_other_headliner_check', false],
    ['guide_photo_checklist_guide_interior_other_rear_footwells_check', false],
    ['guide_photo_checklist_guide_interior_other_imperfections_check', false],
    ['guide_photo_checklist_guide_interior_other_trunk_check', false],
    ['guide_photo_checklist_guide_interior_other_driver_side_door_panel_check', false],
    ['guide_photo_checklist_guide_other_keys_check', false],
    ['guide_photo_checklist_guide_other_charging_cable_check', false],
    ['guide_photo_checklist_guide_other_documents_check', false],
    ['guide_photo_checklist_guide_other_tires_check', false],
    ['guide_photo_checklist_guide_other_service_history_check', false],
    ['guide_photo_checklist_guide_other_extras_check', false],
]);
