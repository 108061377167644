import { useTranslation } from 'react-i18next'
import { Car } from '../../../../../entity'
import { Blurhash } from 'react-blurhash'
import { useState } from 'react'
import { Button } from '../../../../../components'
import { CarStatusEnum } from '../../../../../enum'
import { useAppSelector } from '../../../../../redux'
import { PayBuyersFeeModal } from '../../../../../components/modals'
import { BuyersFeeModal } from '../../../../../components/modals/buyers-fee'
import './cars-bought-element.scss'

interface CarsBoughtElementProps {
    car: Car
}

export const CarsBoughtElement = (props: CarsBoughtElementProps) => {
    const { t } = useTranslation()

    const [imageLoaded, setImageLoaded] = useState(false)

    const { user } = useAppSelector((state) => state.login)

    const [payBuyersFeeeModalVisible, setPayBuyersFeeeModalVisible] = useState(false)
    const [buyersFeeeModalVisible, setBuyersFeeeModalVisible] = useState(false)

    return (
        <li key={props.car.id} className={`cars-bought-element`}>
            <div className="cars-bought-element__image-div">
                {props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 && <Blurhash className={`blurhash-img ${imageLoaded ? `hidden` : ``}`} hash={props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 ? props.car.mediaFiles[0].blurHash : ''} width={'100%'} height={'100%'} resolutionX={32} resolutionY={32} punch={1} />}

                <img
                    alt="Car"
                    className="car-img"
                    src={props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 ? props.car.mediaFiles[0].thumbnail : '/images/ic_no_image.svg'}
                    onLoad={() => {
                        setImageLoaded(true)
                    }}
                />
            </div>

            <div className="cars-bought-element__information-div">
                <div className={`name-div`}>
                    <div className={`name-inner-div`}>
                        <h3 className="name">
                            {props.car?.manufacturingYear} {props.car?.make} {props.car?.model}
                        </h3>
                        <label className="address">
                            {props.car?.address?.postalCode} {props.car?.address?.city}
                        </label>
                    </div>

                    <p className={`status paid ${props.car.auctions[0].winnerUserId === user?.id && props.car.status === CarStatusEnum.Paid ? `` : `hidden`} `}>{t('my_ev_garage_buyers_fee_paid_title')}</p>
                    <p className={`status overdue ${props.car.auctions[0].winnerUserId === user?.id && (props.car.status === CarStatusEnum.Overdue || props.car.status === CarStatusEnum.Unpaid) ? `` : `hidden`} `}>{t('my_ev_garage_buyers_fee_overdue_title')}</p>
                    <p className={`status overdue ${props.car.auctions[0].winnerUserId !== user?.id || props.car.status === CarStatusEnum.DidNotSell ? `` : `hidden`} `}>{t('my_ev_garage_not_won_title')}</p>
                </div>

                <div className="price-div">
                    <div className={`bid-div`}>
                        <p className="sub-title">{props.car.status === CarStatusEnum.Sold || props.car.status === CarStatusEnum.Paid || props.car.status === CarStatusEnum.Unpaid ? t('car_card_final_price_title') : t('my_ev_garage_highest_bid_title')}</p>
                        <div className="price-div">
                            <h3 className={`price `}> {props.car.auctions[0].currentPrice.toLocaleString('de-CH', { minimumFractionDigits: 0 })}</h3>
                            <label className={`currency `}>{t('car_card_chf_title')}</label>
                        </div>
                    </div>

                    {props.car.auctions[0].winnerUserId === user?.id && (props.car.status === CarStatusEnum.Sold || props.car.status === CarStatusEnum.Overdue || props.car.status === CarStatusEnum.Unpaid) && (
                        <div className="bid-div">
                            <div className="fee-div">
                                <p className={`sub-title`}>{t('my_ev_garage_buyers_fee_title')}</p>
                                <img alt="Info icon" className="info-img" src="/images/ic_info_gray.svg" onClick={() => setBuyersFeeeModalVisible(!buyersFeeeModalVisible)} />
                            </div>

                            <div className="price-div">
                                <h3 className={`price primary ${props.car.status === CarStatusEnum.Unpaid || props.car.status === CarStatusEnum.Overdue ? `red` : ``}`}> {props.car.auctions[0].finalBuyersFee?.toLocaleString('de-CH', { minimumFractionDigits: 0 })}</h3>
                                <label className={`currency primary ${props.car.status === CarStatusEnum.Unpaid || props.car.status === CarStatusEnum.Overdue ? `red` : ``}`}>{t('car_card_chf_title')}</label>
                            </div>
                        </div>
                    )}
                </div>

                {props.car.auctions[0].winnerUserId === user?.id && props.car.status === CarStatusEnum.Sold && (
                    <Button
                        title={t('my_ev_garage_pay_now_button').replace('{amount}', '' + props.car.auctions[0].finalBuyersFee?.toLocaleString('de-CH', { minimumFractionDigits: 0 }))}
                        onClick={() => {
                            setPayBuyersFeeeModalVisible(!payBuyersFeeeModalVisible)
                        }}
                    />
                )}

                {props.car.auctions[0].winnerUserId === user?.id && props.car.status === CarStatusEnum.Unpaid && (
                    <Button
                        type={'red'}
                        title={t('my_ev_garage_pay_now_button').replace('{amount}', '' + props.car.auctions[0].finalBuyersFee?.toLocaleString('de-CH', { minimumFractionDigits: 0 }))}
                        onClick={() => {
                            setPayBuyersFeeeModalVisible(!payBuyersFeeeModalVisible)
                        }}
                    />
                )}

                {props.car.auctions[0].winnerUserId === user?.id && props.car.status === CarStatusEnum.Overdue && (
                    <Button
                        type={'red'}
                        title={t('button_contact_admin')}
                        onClick={() => {
                            const mailToLink = document.createElement('a')
                            mailToLink.target = '_blank'
                            mailToLink.rel = 'noopener noreferrer'
                            mailToLink.href = 'mailto:contact@lotnumberone.ch'
                            mailToLink.click()
                        }}
                    />
                )}
            </div>

            {payBuyersFeeeModalVisible ? (
                <PayBuyersFeeModal
                    car={props.car}
                    onClose={() => {
                        setPayBuyersFeeeModalVisible(!payBuyersFeeeModalVisible)
                    }}
                />
            ) : null}

            {buyersFeeeModalVisible ? (
                <BuyersFeeModal
                    onClose={() => {
                        setBuyersFeeeModalVisible(!buyersFeeeModalVisible)
                    }}
                />
            ) : null}
        </li>
    )
}
