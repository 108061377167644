import { store } from '../../redux'
import { CarsAuctionedDTO, ErrorDTO } from '../../dto'
import { CarsAuctionedFailed, CarsAuctionedSuccess, ErrorMessage } from '../../redux/actions'
import { NetworkCall } from '../../utils'

export const getCarsAuctioned = async (reset: boolean = false) => {
    const page = reset ? 1 : store.getState().user.carsAuctioned.pagination.page
    const pageSize = store.getState().user.carsAuctioned.pagination.pageSize

    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    return await new NetworkCall('GET', `/1.0.0/cars/ev-garage/cars-auctioned/?page=${page}&pageSize=${pageSize}`)
        .setHeader('Authorization', token !== null ? `Bearer ${token}` : null)
        .then(200, CarsAuctionedDTO, (responseBody) => {
            store.dispatch(CarsAuctionedSuccess({ cars: responseBody.data, page: responseBody.page, pageSize: responseBody.pageSize, draftCount: responseBody.draftCount, approvedCount: responseBody.approvedCount, notMetCount: responseBody.notMetCount, reset: reset }))
        })
        .catch((response) => {
            store.dispatch(CarsAuctionedFailed({}))
            store.dispatch(ErrorMessage({ title: '', message: (response as ErrorDTO).message, code: (response as ErrorDTO).code }))
        })
        .call()
}
