import { createAction } from '@reduxjs/toolkit'
import { Car } from '../../../entity'

interface Payload {
    cars: Car[]
    page: number
    pageSize: number
    count: number
    reset: boolean
}

export const CommentHistorySuccess = createAction<Payload>('USER__COMMENT_HISTORY_SUCCESS')
