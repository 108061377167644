import { useTranslation } from 'react-i18next'
import { Car } from '../../../../entity'
import { Blurhash } from 'react-blurhash'
import { useState } from 'react'
import { Battery } from '../../../../components'
import { CommentHistoryCommentElement } from './comment-history-comment-element'
import { StatusEnum } from '../../../../enum'
import { useNavigate } from 'react-router-dom'
import './comment-history-element.scss'

interface CommentHistoryElementProps {
    car: Car
}

export const CommentHistoryElement = (props: CommentHistoryElementProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [imageLoaded, setImageLoaded] = useState(false)

    return (
        <li key={props.car.id} className={`comment-history-element`}>
            <div className="comment-history-element__image-div">
                {props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 && <Blurhash className={`blurhash-img ${imageLoaded ? `hidden` : ``}`} hash={props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 ? props.car.mediaFiles[0].blurHash : ''} width={'100%'} height={'100%'} resolutionX={32} resolutionY={32} punch={1} />}
                <img
                    alt="Car"
                    className="car-img"
                    src={props.car != null && props.car.mediaFiles != null && props.car.mediaFiles.length > 0 ? props.car.mediaFiles[0].thumbnail : '/images/ic_no_image.svg'}
                    onLoad={() => {
                        setImageLoaded(true)
                    }}
                />
            </div>

            <div className="comment-history-element__information-div">
                <div className={`name-div`} onClick={(e) => navigate(`/auction/${props.car.id}`)}>
                    <h3 className="name">
                        {props.car?.manufacturingYear} {props.car?.make} {props.car?.model}
                    </h3>
                    <label className="address">
                        {props.car?.address?.postalCode} {props.car?.address?.city}
                    </label>
                </div>

                <div className="price-div">
                    <div className={`bid-div`}>
                        <p className="sub-title">{t('car_card_current_bid_title')}</p>
                        <div className="price-div">
                            <h3 className={`price primary`}> {(props.car != null && props.car.auctions != null && props.car.auctions.length > 0 ? props.car.auctions[0].currentPrice : 0).toLocaleString('de-CH', { minimumFractionDigits: 0 })}</h3>
                            <label className={`currency primary`}>{t('car_card_chf_title')}</label>

                            <label className={`currency `}>{'/ ' + (props.car != null && props.car.auctions != null && props.car.auctions.length > 0 ? props.car.auctions[0].bidCount : 0) + ' ' + t('auction_bids_title')}</label>
                        </div>
                    </div>
                    <div className="time-left-div">
                        <p className={`sub-title ${new Date(props.car.auctions[0].endDate).getTime() - new Date().getTime() < 0 ? 'inactive' : ''}`}>{props.car.auctions[0].status !== StatusEnum.Expired ? t('car_card_time_left_title') : t('car_card_end_date_title')}</p>

                        <Battery type={'normal'} auction={props.car.auctions[0]} />
                    </div>
                </div>

                <div className={`comments-div`}>
                    <div className="title-div">
                        <img alt="Comments icon" className="icon" src={'/images/ic_comments.svg'} />

                        <label className="text">{t('auction_comments_title') + ':'}</label>
                    </div>

                    <ul className="comment-list">
                        {props.car.comments?.map((comment) => (
                            <CommentHistoryCommentElement comment={comment} />
                        ))}
                    </ul>
                </div>
            </div>
        </li>
    )
}
