import {createAction} from "@reduxjs/toolkit";
import {Notification} from "../../../entity";

interface Payload {
    data: Notification[]
    page: number;
    pageSize: number;
    count: number;
}

export const ListNotifications = createAction<Payload>('NOTIFICATION__LIST_NOTIFICATIONS')