import { useTranslation } from 'react-i18next'
import { NotificationElement } from './notification-element'
import { useEffect, useRef } from 'react'
import { listNotifications } from '../../../api'
import { useAppSelector } from '../../../redux'
import './notifications-menu.scss'

interface NotificationsMenuProps {
    open: boolean
    onClose: () => void
    onSettingsClick: () => void
}

export const NotificationsMenu = (props: NotificationsMenuProps) => {
    const { t } = useTranslation()
    const { notifications, pagination } = useAppSelector((state) => state.notifications)

    const onLoadMoreClick = async () => {
        await listNotifications(pagination.page + 1)
    }

    const ref = useRef<HTMLDivElement>(null)

    function assertIsNode(e: EventTarget | null): asserts e is Node {
        if (!e || !('nodeType' in e)) {
            throw new Error(`Node expected`)
        }
    }

    useEffect(() => {
        const handleOutSideClick = (e: MouseEvent) => {
            assertIsNode(e.target)

            if (!ref.current?.contains(e.target) && (e.target as Element).className !== 'notification') {
                props.onClose()
            }
        }

        if (props.open) {
            window.addEventListener('mousedown', handleOutSideClick)
        }
        return () => {
            window.removeEventListener('mousedown', handleOutSideClick)
        }
    }, [ref, props.open])

    return (
        <div className={`notifications-menu ${props.open ? `` : `hidden`}`} ref={ref}>
            <img alt="Close icon" className={`close-img `} src="/images/ic_close.svg" onClick={() => props.onClose()} />

            <div className="notifications-menu__header">
                <h4> {t('notifications_title')} </h4>
                <img className="settings-button" alt="Settings button" src="/images/ic_settings.svg" onClick={() => props.onSettingsClick()} />
            </div>

            <div className={`notifications-menu__divider-div`} />

            <ul className={`notifications-menu__list ${pagination.count === 0 ? `hidden` : ``}`}>
                {notifications.map((notification, index) => (
                    <NotificationElement notification={notification} onClose={props.onClose} />
                ))}
            </ul>

            <div className={`empty-list-div ${pagination.count !== 0 ? `hidden` : ``}`}>
                <label>{t('notifications_empty_title')}</label>
            </div>

            <label className={`load-more ${pagination.count <= pagination.pageSize * pagination.page ? `hidden` : ``}`} onClick={onLoadMoreClick}>
                {t('car_information_load_more_button')}
            </label>
        </div>
    )
}
