import { Modal } from '../modal'
import { useTranslation } from 'react-i18next'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { FormEvent } from 'react'
import { Button } from '../../buttons'
import { useDispatch } from 'react-redux'
import { ErrorMessage, ShowToast, UpdateUserForm, UpdateUserModalForm } from '../../../redux/actions'
import { modifyUser } from '../../../api'
import './credit-card.modal.scss'
import { useAppSelector } from '../../../redux'
import { RoleEnum } from '../../../enum'

interface CreaditCardModalProps {
    onClose: () => void
}

export const CreaditCardModal = (props: CreaditCardModalProps) => {
    const { t } = useTranslation()
    const stripe = useStripe()
    const elements = useElements()
    const dispatch = useDispatch()

    const { form } = useAppSelector((state) => state.user)

    const onSaveClick = async (ev: FormEvent) => {
        ev.preventDefault()

        dispatch(UpdateUserForm({ key: 'role', error: null, value: form.role.value }))

        dispatch(UpdateUserForm({ key: 'firstName', error: null, value: form.firstName.value }))
        dispatch(UpdateUserForm({ key: 'lastName', error: null, value: form.lastName.value }))
        dispatch(UpdateUserForm({ key: 'companyName', error: null, value: form.companyName.value }))
        dispatch(UpdateUserForm({ key: 'uidNumber', error: null, value: form.uidNumber.value }))

        dispatch(UpdateUserForm({ key: 'address', error: null, value: form.address.value }))
        dispatch(UpdateUserForm({ key: 'city', error: null, value: form.city.value }))
        dispatch(UpdateUserForm({ key: 'postalCode', error: null, value: form.postalCode.value }))
        dispatch(UpdateUserForm({ key: 'phoneNumber', error: null, value: form.phoneNumber.value }))

        var fildsChecked = true

        if (form.role.value == null) {
            dispatch(UpdateUserForm({ key: 'role', error: t('error_my_profile_first_name_empty'), value: '' }))
            fildsChecked = false
        }

        if (form.role.value === RoleEnum.Private) {
            if (form.firstName.value.length === 0) {
                dispatch(UpdateUserForm({ key: 'firstName', error: t('error_my_profile_first_name_empty'), value: '' }))
                fildsChecked = false
            }

            if (form.lastName.value.length === 0) {
                dispatch(UpdateUserForm({ key: 'lastName', error: t('error_my_profile_last_name_empty'), value: '' }))
                fildsChecked = false
            }
        } else {
            if (form.companyName.value.length === 0) {
                dispatch(UpdateUserForm({ key: 'companyName', error: t('error_my_profile_company_name_empty'), value: '' }))
                fildsChecked = false
            }

            if (form.uidNumber.value.length === 0) {
                dispatch(UpdateUserForm({ key: 'uidNumber', error: t('error_my_profile_uid_number_empty'), value: '' }))
                fildsChecked = false
            }
        }

        if (!form.english.value && !form.german.value && !form.italian.value && !form.french.value) {
            dispatch(UpdateUserForm({ key: 'english', error: t('error_signup_language'), value: false }))
            fildsChecked = false
        }

        if (form.address.value.length === 0) {
            dispatch(UpdateUserForm({ key: 'address', error: t('error_my_profile_address_empty'), value: '' }))
            fildsChecked = false
        }

        if (form.city.value.length === 0) {
            dispatch(UpdateUserForm({ key: 'city', error: t('error_my_profile_city_empty'), value: '' }))
            fildsChecked = false
        }

        if (form.postalCode.value.length === 0) {
            dispatch(UpdateUserForm({ key: 'postalCode', error: t('error_my_profile_postal_code_empty'), value: '' }))
            fildsChecked = false
        }

        if (form.phoneNumber.value.length === 0) {
            dispatch(UpdateUserForm({ key: 'phoneNumber', error: t('error_my_profile_phone_empty'), value: '' }))
            fildsChecked = false
        }

        if (!fildsChecked) {
            props.onClose()
        } else if (stripe && elements) {
            const cardElement = elements.getElement('card')

            if (cardElement != null) {
                const { token, error } = await stripe.createToken(cardElement)

                if (error) {
                    dispatch(ErrorMessage({ title: 'createToken error', message: error.message ?? '', code: 0 }))
                } else {
                    dispatch(UpdateUserForm({ key: 'cardId', error: null, value: token.id }))
                    dispatch(UpdateUserModalForm({ key: 'cardId', error: null, value: token.id }))

                    props.onClose()

                    await modifyUser({
                        onSuccess() {
                            dispatch(ShowToast({ message: 'toast_update_profile_success' }))
                        },
                        onError() {
                            dispatch(UpdateUserForm({ key: 'cardId', error: null, value: '' }))
                            dispatch(UpdateUserModalForm({ key: 'cardId', error: null, value: '' }))
                        },
                    })
                }
            }
        } else {
            console.log("Stripe.js hasn't loaded yet.")
        }
    }

    const baseStripeElementOptions = {
        hidePostalCode: true,
        style: {
            base: {
                fontFamily: 'Lato, sans-serif', // set integrated font family
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                color: '#000000',
                '::placeholder': {
                    color: '#000000',
                },
            },
            invalid: {
                color: '#9e2146',
            },
        },
    }

    return (
        <Modal title={t('modal_add_creadit_card_title')} onCloseClick={props.onClose}>
            <div className="credit-card-modal">
                <form>
                    <CardElement options={baseStripeElementOptions} />
                </form>

                <div className="credit-card-modal__button-div">
                    <Button default={true} title={t('button_cancel')} onClick={props.onClose} />
                    <Button title={t('modal_add_creadit_add_card_button')} onClick={onSaveClick} />
                </div>
            </div>
        </Modal>
    )
}
