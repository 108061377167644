import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../components'
import { PasswordField } from '../../components/field'
import { resetPassword } from '../../api'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { validatePassword } from '../../utils/validate.util'
import './reset-password.page.scss'

interface ResetPasswordPageProps {}

export const ResetPasswordPage = (props: ResetPasswordPageProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const token = useLoaderData()

    const [newPassword, setNewPassword] = useState('')
    const [newPasswordError, setNewPasswordError] = useState('')
    const [reNewPassword, setReNewPassword] = useState('')
    const [reNewPasswordError, setReNewPasswordError] = useState('')

    const onResetPasswordClick = async () => {
        setNewPasswordError('')
        setReNewPasswordError('')

        const newPasswordValidation = validatePassword(newPassword)
        if (newPasswordValidation != null) {
            setNewPasswordError(t(newPasswordValidation))
            return
        }

        if (newPassword !== reNewPassword) {
            setReNewPasswordError(t('error_change_password_missmatch'))
            return
        }

        if (token) {
            await resetPassword({
                token: token as string,
                password: newPassword,
                onSuccess() {
                    navigate('/')
                },
            })
        }
    }

    useEffect(() => {
        if (token != null && !token) {
            navigate('/')
        }
    }, [token])

    return (
        <main className="page page-reset-password">
            <div className="main-div">
                <h2 className="label">{t('reset_password_title')} </h2>

                {/* <form action="" className="form-div" ref={formElement}> */}

                <div className="form-div">
                    <div className="user-information-div">
                        <PasswordField
                            showStrongPassword={true}
                            field={{ error: newPasswordError, value: newPassword }}
                            onChange={(e) => {
                                setNewPasswordError('')
                                setNewPassword(e.target.value)
                            }}
                            title={t('modal_change_password_new_password_placeholder')}
                            placeholder={''}
                        />

                        <PasswordField
                            showStrongPassword={true}
                            field={{ error: reNewPasswordError, value: reNewPassword }}
                            onChange={(e) => {
                                setReNewPasswordError('')
                                setReNewPassword(e.target.value)
                            }}
                            title={t('modal_change_password_re_new_password_placeholder')}
                            placeholder={''}
                        />
                    </div>

                    <Button title={t('button_reset_password')} onClick={onResetPasswordClick} />
                </div>
            </div>
        </main>
    )
}
