import { InputField, PasswordField } from '../../field'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '../../checkbox'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { store, useAppSelector } from '../../../redux'
import { RemoveCarId, ShowLoginModal, UpdateLoginForm } from '../../../redux/actions'
import { validateEmail, validatePassword } from '../../../utils/validate.util'
import { login } from '../../../api/'
import { Button } from '../../buttons'
import { Modal } from '../modal'
import { useEffect } from 'react'
import { getMessaging, getToken } from 'firebase/messaging'
import { setPushToken } from '../../../api/users'
import './login.modal.scss'

interface LoginModalProps {
    onForgotPasswordClick: () => void
    onResendVerificationEmailModalVisible: () => void
}

export const LoginModal = (props: LoginModalProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { form } = useAppSelector((state) => state.login)
    const carId = useAppSelector((state) => state.base.carId)

    useEffect(() => {
        const pushToken = sessionStorage.getItem('pushToken')

        if (navigator.serviceWorker && pushToken === null) {
            getToken(getMessaging())
                .then(async (token) => {
                    sessionStorage.setItem('pushToken', token)
                    if (store.getState().login.user !== null) {
                        await setPushToken(token)
                    }
                })
                .catch((reason) => {
                    sessionStorage.setItem('pushToken', `blocked: ${reason}`)
                })
        }
    }, [])

    const onLoginClick = async () => {
        dispatch(UpdateLoginForm({ key: 'email', error: null, value: form.email.value }))
        dispatch(UpdateLoginForm({ key: 'password', error: null, value: form.password.value }))

        const emailValidation = validateEmail(form.email.value)
        if (emailValidation != null) {
            dispatch(UpdateLoginForm({ key: 'email', error: t(emailValidation), value: form.email.value }))
            return
        }

        const passwordValidation = validatePassword(form.password.value, true)
        if (passwordValidation != null) {
            dispatch(UpdateLoginForm({ key: 'password', error: t(passwordValidation), value: form.password.value }))
            return
        }

        // if (!formElement.current?.reportValidity()) {
        //     return
        // }

        await login({
            onSuccess(user) {
                dispatch(ShowLoginModal({ visible: false, carId: null }))

                if (user.address == null) {
                    navigate('/my-profile')
                } else {
                    navigate('/')

                    if (carId != null) {
                        navigate(`/auction/${carId}`)
                        dispatch(RemoveCarId({}))
                    } else {
                        navigate('/')
                    }
                }
            },
            onErrror(code) {
                if (code === 100005) {
                    props.onResendVerificationEmailModalVisible()
                }
            },
        })
    }

    return (
        <Modal title={t('modal_login_title')} onCloseClick={() => dispatch(ShowLoginModal({ visible: false, carId: null }))}>
            <div className="login-modal">
                <InputField type="email" field={form.email} placeholder={t('')} title={t('signup_email_title')} onChange={(e) => dispatch(UpdateLoginForm({ key: 'email', value: e.target.value, error: null }))} />

                <PasswordField showStrongPassword={false} field={form.password} placeholder={t('')} title={t('signup_password_title')} onChange={(e: any) => dispatch(UpdateLoginForm({ key: 'password', value: e.target.value, error: null }))} />

                <div className="login-modal__remember-me-div">
                    <Checkbox
                        error={''}
                        title={t('modal_login_remember_me_title')}
                        checked={form.rememberMe.value}
                        id="remember-me"
                        onChange={(e) => {
                            dispatch(UpdateLoginForm({ key: 'rememberMe', value: !form.rememberMe.value, error: null }))
                        }}
                    />

                    <label
                        className="forgot"
                        onClick={() => {
                            dispatch(ShowLoginModal({ visible: false, carId: null }))
                            props.onForgotPasswordClick()
                        }}
                    >
                        {t('modal_login_forgot_your_password_title')}
                    </label>
                </div>

                <div className="login-modal__button-div">
                    <Button default={true} title={t('button_cancel')} onClick={() => dispatch(ShowLoginModal({ visible: false, carId: null }))} />
                    <Button title={t('button_log_in')} onClick={onLoginClick} />
                </div>

                <div className="login-modal__sign-up-div">
                    <label className="other">{t('modal_login_dont_have_an_account_title')}</label>
                    <label
                        className="sign-up"
                        onClick={() => {
                            navigate('/sign-up')
                            dispatch(ShowLoginModal({ visible: false, carId: null }))
                        }}
                    >
                        {t('modal_login_sign_up_here_title')}
                    </label>
                </div>
            </div>
        </Modal>
    )
}
