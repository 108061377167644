import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { Car } from '../../entity'

export class CarDTO {
    @Expose()
    @ValidateNested()
    @Type(() => Car)
    car!: Car
}
