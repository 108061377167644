import { PropsWithChildren } from 'react'
import './card.scss'

interface CardProps {
    icon?: string
    title?: string
    body?: string
    additionalClassNames?: string
}

export const Card = (props: PropsWithChildren<CardProps>) => {
    const _getCardClassNames = () => ('card ' + (props.additionalClassNames ?? '')).trim()

    return (
        <div className={_getCardClassNames()}>
            {props.icon && (
                <div className={'card-icon-bar'}>
                    <img src={props.icon} />
                </div>
            )}
            {
                <div className={'card-content-bar'}>
                    {props.title && (
                        <div className={'card-title'}>
                            <h3 dangerouslySetInnerHTML={{ __html: props.title }}></h3>
                        </div>
                    )}
                    {props.body && <div className={'card-body'} dangerouslySetInnerHTML={{ __html: props.body }}></div>}
                    {props.children && <div className={'card-body'}>{props.children}</div>}
                </div>
            }
        </div>
    )
}
