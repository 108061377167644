import { createAction } from '@reduxjs/toolkit'
import { Car } from '../../../entity'

interface Payload {
    reservePriceNotMetData: Car[]
    bidOnData: Car[]
    page: number
    pageSize: number
    notMetCount: number
    bidOnCount: number
    reset: boolean
}

export const CarsBoughtSuccess = createAction<Payload>('CARS__CARS_BOUGHT_SUCCESS')
