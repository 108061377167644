import { useState } from 'react'
import { Modal } from '../modal'
import { Button } from '../../buttons'
import { InputField } from '../../field'
import { useTranslation } from 'react-i18next'
import { validateEmail } from '../../../utils/validate.util'
import './change-email.modal.scss'

interface ChangeEmailModalProps {
    onClose: () => void
    onSave: (email:string) => void
}

export const ChangeEmailModal = (props: ChangeEmailModalProps) => {
    const { t } = useTranslation()

    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    
    const onSaveClick = async () => {
        setEmailError('')

        const emailValidation = validateEmail(email)
        if (emailValidation != null) {
            setEmailError(t(emailValidation))
            return
        }

        props.onSave(email)
    }

    return (
        <Modal title={t('modal_change_email_title')} onCloseClick={props.onClose}>
            <div className="change-email-modal">
                <InputField
                    type="email"
                    field={{ error: emailError, value: email }}
                    onChange={(e) => {
                        setEmailError('')
                        setEmail(e.target.value)
                    }}
                    title={t('modal_change_email_new_email_placeholder')}
                    placeholder={''}
                />

                <label >{t('modal_change_email_description')}</label>
            
                <div className="change-email-modal__button-div">
                    <Button default={true} title={t('button_cancel')} onClick={props.onClose} />
                    <Button title={t('button_save')} onClick={onSaveClick} />
                </div>
            </div>
        </Modal>
    )
}
