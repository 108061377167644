import { useTranslation } from 'react-i18next'
import { Button, Checkbox, IconButton } from '../../components'
import { useDispatch } from 'react-redux'
import { ShowToast, UpdateUserForm } from '../../redux/actions'
import { UserSideMenu } from './components/user-side-menu/user-side-menu'
import { InputField, PasswordField, PhoneField } from '../../components/field'
import { useAppSelector } from '../../redux'
import { RoleEnum } from '../../enum'
import { modifyUser } from '../../api'
import { ChangeEvent, useState } from 'react'
import { ChangeEmailModal, ChangePasswordModal, CreaditCardModal, DeleteAccountModal, DeleteCardModal } from '../../components/modals'
import { useNavigate } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import './my-profile.page.scss'

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`)

interface MyProfilePageProps {}

export const MyProfilePage = (props: MyProfilePageProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { form } = useAppSelector((state) => state.user)

    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(false)
    const [changeEmailModalVisible, setChangeEmailModalVisible] = useState(false)
    const [creaditCardModalVisible, setCreaditCardModalVisible] = useState(false)
    const [removeCreaditCardModalVisible, setRemoveCreaditCardModalVisible] = useState(false)

    const [firstOpen, setFirstOpen] = useState(form.role.value === -1)

    const onSaveClick = async () => {
        dispatch(UpdateUserForm({ key: 'role', error: null, value: form.role.value }))

        dispatch(UpdateUserForm({ key: 'firstName', error: null, value: form.firstName.value }))
        dispatch(UpdateUserForm({ key: 'lastName', error: null, value: form.lastName.value }))
        dispatch(UpdateUserForm({ key: 'companyName', error: null, value: form.companyName.value }))
        dispatch(UpdateUserForm({ key: 'uidNumber', error: null, value: form.uidNumber.value }))

        dispatch(UpdateUserForm({ key: 'address', error: null, value: form.address.value }))
        dispatch(UpdateUserForm({ key: 'city', error: null, value: form.city.value }))
        dispatch(UpdateUserForm({ key: 'postalCode', error: null, value: form.postalCode.value }))
        dispatch(UpdateUserForm({ key: 'phoneNumber', error: null, value: form.phoneNumber.value }))

        if (form.role.value == null) {
            dispatch(UpdateUserForm({ key: 'role', error: t('error_my_profile_first_name_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })

            return
        }

        if (form.role.value === RoleEnum.Private) {
            if (form.firstName.value.length === 0) {
                dispatch(UpdateUserForm({ key: 'firstName', error: t('error_my_profile_first_name_empty'), value: '' }))
                document.documentElement.scrollTo({ top: 0, left: 0 })

                return
            }

            if (form.lastName.value.length === 0) {
                dispatch(UpdateUserForm({ key: 'lastName', error: t('error_my_profile_last_name_empty'), value: '' }))
                document.documentElement.scrollTo({ top: 0, left: 0 })

                return
            }
        } else {
            if (form.companyName.value.length === 0) {
                dispatch(UpdateUserForm({ key: 'companyName', error: t('error_my_profile_company_name_empty'), value: '' }))
                document.documentElement.scrollTo({ top: 0, left: 0 })

                return
            }

            if (form.uidNumber.value.length === 0) {
                dispatch(UpdateUserForm({ key: 'uidNumber', error: t('error_my_profile_uid_number_empty'), value: '' }))
                document.documentElement.scrollTo({ top: 0, left: 0 })

                return
            }
        }

        if (!form.english.value && !form.german.value && !form.italian.value && !form.french.value) {
            dispatch(UpdateUserForm({ key: 'english', error: t('error_signup_language'), value: false }))
            document.documentElement.scrollTo({ top: 0, left: 0 })

            return
        }

        if (form.address.value.length === 0) {
            dispatch(UpdateUserForm({ key: 'address', error: t('error_my_profile_address_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })

            return
        }

        if (form.city.value.length === 0) {
            dispatch(UpdateUserForm({ key: 'city', error: t('error_my_profile_city_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })

            return
        }

        if (form.postalCode.value.length === 0) {
            dispatch(UpdateUserForm({ key: 'postalCode', error: t('error_my_profile_postal_code_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })

            return
        }

        if (form.phoneNumber.value.length === 0) {
            dispatch(UpdateUserForm({ key: 'phoneNumber', error: t('error_my_profile_phone_empty'), value: '' }))
            document.documentElement.scrollTo({ top: 0, left: 0 })

            return
        }

        await modifyUser({
            onSuccess() {
                dispatch(ShowToast({ message: 'toast_update_profile_success' }))
            },
            onError() {},
        })
    }

    const onCheChange = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        let userInput: string = event.target.value.replace(/[^0-9]/g, '')

        if (userInput === '') {
            dispatch(UpdateUserForm({ key: 'uidNumber', value: '', error: null }))
        } else {
            const regex = /^(\d{3})(\d{3})(\d{3}).*/

            const formattedText = userInput.replace(regex, '$1.$2.$3')

            dispatch(UpdateUserForm({ key: 'uidNumber', value: formattedText, error: null }))
        }
    }

    return (
        <main className="page page-my-profile">
            <div className="main-div">
                <UserSideMenu />
                <div className="profile-div">
                    <h2>{t('header_profile_my_profile_button')} </h2>

                    <h4>{t('my_profile_user_type_title')} </h4>

                    <div className={`user-type-div ${form.role.value !== -1 && !firstOpen ? 'disable' : ''} `}>
                        <label
                            className={`type ${form.role.value === RoleEnum.Private ? 'active' : ''} `}
                            onClick={() => {
                                dispatch(UpdateUserForm({ key: 'role', value: RoleEnum.Private, error: null }))
                            }}
                        >
                            {t('my_profile_private_title')}
                        </label>

                        <label
                            className={`type ${form.role.value === RoleEnum.Dealer ? 'active' : ''} `}
                            onClick={() => {
                                dispatch(UpdateUserForm({ key: 'role', value: RoleEnum.Dealer, error: null }))
                            }}
                        >
                            {t('my_profile_dealer_title')}
                        </label>
                    </div>

                    <div className={`profile-sub-div ${form.role.value === -1 ? 'hidden' : ''} `}>
                        <h4>{t('my_profile_user_information_title')} </h4>

                        <div className="container-div">
                            <div className={`name-div ${form.role.value === RoleEnum.Private ? '' : 'hidden'} `}>
                                <InputField type="text" showEditIcon={true} field={form.firstName} placeholder={t('')} title={t('my_profile_first_name_title')} onChange={(e) => dispatch(UpdateUserForm({ key: 'firstName', value: e.target.value, error: null }))} />

                                <InputField type="text" showEditIcon={true} field={form.lastName} placeholder={t('')} title={t('my_profile_last_name_title')} onChange={(e) => dispatch(UpdateUserForm({ key: 'lastName', value: e.target.value, error: null }))} />
                            </div>

                            <div className={`name-div ${form.role.value === RoleEnum.Dealer ? '' : 'hidden'} `}>
                                <InputField type="text" showEditIcon={true} field={form.companyName} placeholder={t('')} title={t('my_profile_company_name_title')} onChange={(e) => dispatch(UpdateUserForm({ key: 'companyName', value: e.target.value, error: null }))} />

                                <div className={`vat-div`}>
                                    <label className={`type `}>{t('my_profile_uid_number_title')}</label>
                                    <div className={`uid-div`}>
                                        <label className={`che-title`}>{t('my_profile_che_title')}</label>
                                        <InputField type="text" showEditIcon={true} field={form.uidNumber} maxLength={11} placeholder={'123.456.789'} onChange={onCheChange} />
                                    </div>{' '}
                                </div>
                            </div>

                            <div className="name-div">
                                <InputField type="text" field={{ value: form.username.value, error: form.username.error, disabled: true }} placeholder={t('')} title={t('signup_username_title')} onChange={(e) => dispatch(UpdateUserForm({ key: 'username', value: e.target.value, error: null }))} />

                                <div className={`vat-div ${form.role.value === RoleEnum.Dealer ? '' : 'hidden'} `}>
                                    <InputField type="text" showEditIcon={true} field={form.vatNumber} placeholder={t('')} title={t('my_profile_vat_number_title')} onChange={(e) => dispatch(UpdateUserForm({ key: 'vatNumber', value: e.target.value, error: null }))} />
                                </div>
                            </div>
                        </div>

                        <h4>{t('signup_languages_spoken_title')} </h4>

                        <div className="container-div">
                            <div className="items-div">
                                <Checkbox error={''} checked={form.english.value} id="en" title={t('signup_english_title')} onChange={(e) => dispatch(UpdateUserForm({ key: 'english', value: !form.english.value, error: null }))} />

                                <Checkbox
                                    error={''}
                                    checked={form.german.value}
                                    id="de"
                                    title={t('signup_german_title')}
                                    onChange={(e) => {
                                        dispatch(UpdateUserForm({ key: 'english', value: form.english.value, error: null }))
                                        dispatch(UpdateUserForm({ key: 'german', value: !form.german.value, error: null }))
                                    }}
                                />

                                <Checkbox
                                    error={null}
                                    checked={form.french.value}
                                    id="fr"
                                    title={t('signup_french_title')}
                                    onChange={(e) => {
                                        dispatch(UpdateUserForm({ key: 'english', value: form.english.value, error: null }))
                                        dispatch(UpdateUserForm({ key: 'french', value: !form.french.value, error: null }))
                                    }}
                                />

                                {/* <Checkbox error={form.english.error} checked={form.italian.value} id="it" title={t('signup_italian_title')} onChange={(e) => dispatch(UpdateUserForm({ key: 'italian', value: !form.italian.value, error: null }))} /> */}
                            </div>

                            <div className={`error-div ${form.english.error != null && form.english.error.length > 0 ? `` : `hidden`}`}>
                                <img alt="Error icon" className="error-img" src="/images/ic_error.svg" />

                                <div className={`message`}>{form.english.error}</div>
                            </div>
                        </div>

                        <h4>{t('my_profile_newsletter_title')} </h4>

                        <div className="container-div">
                            <label className="type active">{t('my_profile_newsletter_subtitle')} </label>
                            <div className="item-div">
                                <Checkbox error={''} checked={form.newsletter.value} id="fr" onChange={(e) => dispatch(UpdateUserForm({ key: 'newsletter', value: !form.newsletter.value, error: null }))} />

                                <label className="small-text">
                                    {t('my_profile_newsletter_first_part_desc')}{' '}
                                    <a href="/privacy-policy" target="_blank">
                                        {t('signup_newsletter_second_part_title')}
                                    </a>
                                </label>
                            </div>
                        </div>

                        <h4>{t('my_profile_email_title')} </h4>

                        <div className="container-div">
                            <label className="type active">
                                {t('my_profile_currentemail_title')} {form.email.value}
                            </label>
                            <Button type={'gray'} title={t('button_change_email_address')} onClick={() => setChangeEmailModalVisible(!changeEmailModalVisible)} />
                        </div>

                        <h4>{t('my_profile_password_title')} </h4>

                        <div className="container-div">
                            <PasswordField showStrongPassword={false} field={{ error: null, value: 'PasswordPassword', disabled: true }} placeholder={t('')} title={t('my_profile_current_password_title')} onChange={(e) => {}} />
                            <Button type={'gray'} title={t('button_change_password')} onClick={() => setChangePasswordModalVisible(!changePasswordModalVisible)} />
                        </div>

                        <h4>{t('my_profile_address_title')} </h4>

                        <div className="container-div">
                            <div className="country-div">
                                <InputField type="text" infoText={t('info_text_country')} field={{ value: t('my_profile_switzlerland_title'), error: null, disabled: true }} placeholder={t('')} title={t('my_profile_country_title')} onChange={(e) => dispatch(UpdateUserForm({ key: 'country', value: e.target.value, error: null }))} />
                            </div>

                            <InputField type="text" showEditIcon={true} field={form.address} placeholder={t('')} title={t('my_profile_address_street_house_title')} onChange={(e) => dispatch(UpdateUserForm({ key: 'address', value: e.target.value, error: null }))} />

                            <div className="name-div">
                                <InputField type="text" showEditIcon={true} field={form.city} placeholder={t('')} title={t('my_profile_city_title')} onChange={(e) => dispatch(UpdateUserForm({ key: 'city', value: e.target.value, error: null }))} />

                                <InputField
                                    type="text"
                                    showEditIcon={true}
                                    field={form.postalCode}
                                    maxLength={4}
                                    placeholder={t('')}
                                    title={t('my_profile_postal_code_title')}
                                    onChange={(event) => {
                                        let userInput: string = event.target.value.replace(/[^0-9]/g, '')
                                        if (userInput.length === 0) {
                                            dispatch(UpdateUserForm({ key: 'postalCode', value: '', error: null }))
                                        }
                                        if (userInput !== '') {
                                            dispatch(UpdateUserForm({ key: 'postalCode', value: userInput, error: null }))
                                        }
                                    }}
                                />
                            </div>

                            <PhoneField
                                area="+41"
                                showEditIcon={true}
                                field={form.phoneNumber}
                                placeholder={t('')}
                                title={t('my_profile_phone_number_title')}
                                onChange={(event) => {
                                    let userInput: string = event.target.value.replace(/[^0-9]/g, '')
                                    if (userInput.length === 0) {
                                        dispatch(UpdateUserForm({ key: 'phoneNumber', value: '', error: null }))
                                    }
                                    if (userInput !== '') {
                                        dispatch(UpdateUserForm({ key: 'phoneNumber', value: userInput, error: null }))
                                    }
                                }}
                            />
                        </div>

                        <h4>{t('my_profile_data_for_bidding_title')} </h4>

                        <div className="container-div">
                            <label className="card-title">{t('my_profile_credit_card_information_for_bidding_title')} </label>

                            {form.cardId.value != null && form.cardId.value !== '' && <label className="sucess-label">{t('my_profile_credit_card_added_successfully_title')} </label>}

                            <Button
                                type={'black'}
                                title={t('button_add_payment_card')}
                                onClick={() => {
                                    setCreaditCardModalVisible(!creaditCardModalVisible)
                                }}
                            />
                            {form.cardId.value != null && form.cardId.value !== '' && <IconButton title={t('button_remove_card')} icon={'/images/ic_delete.svg'} onClick={() => setRemoveCreaditCardModalVisible(!removeCreaditCardModalVisible)} />}
                        </div>

                        <div className="bottom-div">
                            <Button title={t('button_save_changes')} onClick={onSaveClick} />

                            <IconButton title={t('button_delete_account')} icon={'/images/ic_delete.svg'} onClick={() => setDeleteModalVisible(!deleteModalVisible)} />
                        </div>
                    </div>
                </div>
            </div>

            {creaditCardModalVisible ? (
                <Elements stripe={stripePromise}>
                    <CreaditCardModal
                        onClose={() => {
                            setCreaditCardModalVisible(!creaditCardModalVisible)
                        }}
                    />
                </Elements>
            ) : null}

            {changeEmailModalVisible ? (
                <ChangeEmailModal
                    onClose={() => {
                        setChangeEmailModalVisible(!changeEmailModalVisible)
                    }}
                    onSave={async (email: string) => {
                        dispatch(UpdateUserForm({ key: 'role', error: null, value: form.role.value }))

                        dispatch(UpdateUserForm({ key: 'firstName', error: null, value: form.firstName.value }))
                        dispatch(UpdateUserForm({ key: 'lastName', error: null, value: form.lastName.value }))
                        dispatch(UpdateUserForm({ key: 'companyName', error: null, value: form.companyName.value }))
                        dispatch(UpdateUserForm({ key: 'uidNumber', error: null, value: form.uidNumber.value }))

                        dispatch(UpdateUserForm({ key: 'address', error: null, value: form.address.value }))
                        dispatch(UpdateUserForm({ key: 'city', error: null, value: form.city.value }))
                        dispatch(UpdateUserForm({ key: 'postalCode', error: null, value: form.postalCode.value }))
                        dispatch(UpdateUserForm({ key: 'phoneNumber', error: null, value: form.phoneNumber.value }))

                        var fildsChecked = true

                        if (form.role.value == null) {
                            dispatch(UpdateUserForm({ key: 'role', error: t('error_my_profile_first_name_empty'), value: '' }))
                            fildsChecked = false
                        }

                        if (form.role.value === RoleEnum.Private) {
                            if (form.firstName.value.length === 0) {
                                dispatch(UpdateUserForm({ key: 'firstName', error: t('error_my_profile_first_name_empty'), value: '' }))
                                fildsChecked = false
                            }

                            if (form.lastName.value.length === 0) {
                                dispatch(UpdateUserForm({ key: 'lastName', error: t('error_my_profile_last_name_empty'), value: '' }))
                                fildsChecked = false
                            }
                        } else {
                            if (form.companyName.value.length === 0) {
                                dispatch(UpdateUserForm({ key: 'companyName', error: t('error_my_profile_company_name_empty'), value: '' }))
                                fildsChecked = false
                            }

                            if (form.uidNumber.value.length === 0) {
                                dispatch(UpdateUserForm({ key: 'uidNumber', error: t('error_my_profile_uid_number_empty'), value: '' }))
                                fildsChecked = false
                            }
                        }

                        if (!form.english.value && !form.german.value && !form.italian.value && !form.french.value) {
                            dispatch(UpdateUserForm({ key: 'english', error: t('error_signup_language'), value: false }))
                            fildsChecked = false
                        }

                        if (form.address.value.length === 0) {
                            dispatch(UpdateUserForm({ key: 'address', error: t('error_my_profile_address_empty'), value: '' }))
                            fildsChecked = false
                        }

                        if (form.city.value.length === 0) {
                            dispatch(UpdateUserForm({ key: 'city', error: t('error_my_profile_city_empty'), value: '' }))
                            fildsChecked = false
                        }

                        if (form.postalCode.value.length === 0) {
                            dispatch(UpdateUserForm({ key: 'postalCode', error: t('error_my_profile_postal_code_empty'), value: '' }))
                            fildsChecked = false
                        }

                        if (form.phoneNumber.value.length === 0) {
                            dispatch(UpdateUserForm({ key: 'phoneNumber', error: t('error_my_profile_phone_empty'), value: '' }))
                            fildsChecked = false
                        }

                        if (fildsChecked) {
                            dispatch(UpdateUserForm({ key: 'email', error: null, value: email }))

                            await modifyUser({
                                onSuccess() {
                                    dispatch(ShowToast({ message: 'toast_update_profile_success' }))

                                    setChangeEmailModalVisible(!changeEmailModalVisible)
                                },
                                onError() {},
                            })
                        } else {
                            setChangeEmailModalVisible(!changeEmailModalVisible)
                        }
                    }}
                />
            ) : null}

            {changePasswordModalVisible ? (
                <ChangePasswordModal
                    onClose={() => {
                        setChangePasswordModalVisible(!changePasswordModalVisible)
                    }}
                />
            ) : null}

            {deleteModalVisible ? (
                <DeleteAccountModal
                    onClose={() => {
                        setDeleteModalVisible(!deleteModalVisible)
                    }}
                    onDelete={() => {
                        setDeleteModalVisible(!deleteModalVisible)
                        navigate(`/`)
                    }}
                />
            ) : null}

            {removeCreaditCardModalVisible ? (
                <DeleteCardModal
                    onClose={() => {
                        setRemoveCreaditCardModalVisible(!removeCreaditCardModalVisible)
                    }}
                    onDelete={async () => {
                        setRemoveCreaditCardModalVisible(!removeCreaditCardModalVisible)

                        const cardId = form.cardId.value

                        dispatch(UpdateUserForm({ key: 'cardId', error: null, value: '' }))

                        await modifyUser({
                            onSuccess() {
                                dispatch(ShowToast({ message: 'toast_update_profile_success' }))
                            },
                            onError() {
                                dispatch(UpdateUserForm({ key: 'cardId', error: null, value: cardId }))
                            },
                        })
                    }}
                />
            ) : null}
        </main>
    )
}
